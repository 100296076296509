import { BrowserRouter, Route, Routes } from "react-router-dom"
import ConfiguratorRoot from "../ConfiguratorRoot"
import Landing from "../components/orderform/Landing"
// import AdminPanel from "../components/adminpanel/AdminPanel"
import ReactGA from 'react-ga';
import { useEffect } from "react";

const TRACKING_ID = "UA-12608833-6"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const basename = document.querySelector('base')?.getAttribute('href') ?? '/'
const Router = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <BrowserRouter basename={basename}>
            <Routes>
                <Route exact path="/" element={<ConfiguratorRoot />} />
                <Route exact path="/order" element={<Landing />} />
                {/* <Route exact path="/admin" element={<AdminPanel />} /> */}
            </Routes>
        </BrowserRouter>
    )
}

export default Router