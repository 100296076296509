import { useEffect, useRef } from "react";

const HTMLDivRenderer = ({ htmlDivElement }) => {
    const divRef = useRef(null);

    useEffect(() => {
        divRef.current.appendChild(htmlDivElement);
    }, [htmlDivElement]);

    return <div className="transformed-design" style={{ transform: 'scale(0.5)', transformOrigin: 'top left', position: 'absolute', top: '0', left: '0' }} ref={divRef}></div>;
}

export default HTMLDivRenderer;
