import { shallowEqual, useSelector } from "react-redux";
import OutdoorScreen from "./OutdoorScreen";
import Room from "./Room";

const LedRoomWrapper = ({ children }) => {
    const { condition } = useSelector(state => {
        return { condition: state.led.condition }
    }, shallowEqual);

    let data = '';
    if (condition === 'indoor') {
        data = <Room>{children}</Room>
    } else if (condition === 'outdoor') {
        data = <OutdoorScreen>{children}</OutdoorScreen>
    }

    return (
        data
    )
}

export default LedRoomWrapper;