import { memo, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { showModules } from "../../redux/reducers/ledReducer";

const ShowModule = ({ row, col }) => {
    const { showModules: modules, selectedSeries, roomHeight, roomWidth } = useSelector(state => {
        return { showModules: state.led.showModules, selectedSeries: state.selectedSeries.series, roomHeight: state.wall.height, roomWidth: state.wall.width }
    }, shallowEqual);
    const dispatch = useDispatch();

    const data = [];
    for (let i = 0; i < row; i++) {
        const module = [];
        for (let j = 0; j < col; j++) {
            module.push(<td className="cabinet" key={j}></td>);
        }
        data.push(<tr key={i}>{module}</tr>)
    }

    useEffect(() => {
        const { width, height } = document.getElementsByClassName('cabinet')[0].getBoundingClientRect();
        if ((width <= 4 || height <= 4) && width != 0 && height != 0) {
            dispatch(showModules({
                showModules: false
            }))
        }
    }, [row, col, modules, selectedSeries, roomHeight, roomWidth])

    return (
        <table style={{ height: '100%', width: '100%', display: modules ? '' : 'none', position: 'absolute' }}>
            <tbody>
                {data}
            </tbody>
        </table>
    )
}

export default memo(ShowModule);