import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    snapshot: [],
};

const snapshotSlice = createSlice({
    name: 'snapshot',
    initialState: INITIAL_STATE,
    reducers: {
        addSnapshot: (state, action) => {
            let idx = action.payload.index;
            if (!idx) {
                idx = 0;
            }
            state.snapshot[idx] = action.payload.snapshot;
        }
    }
})

export const { addSnapshot } = snapshotSlice.actions;

export default snapshotSlice.reducer;