import { memo, useRef, useState } from "react";
import XMLogo from '../../images/xmLogo.png';
import { useDispatch, useSelector } from "react-redux";
import { addPowerSnapshot } from "../../redux/reducers/orderReducer";
import { POWER_LOOP_LINE_COLOR, DEFAULT_LINE_COLOR } from '../../config/config';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CustomButton from "../common/components/CustomButtom";
import useUpdateEffect from "../../hooks/useUpdateEffect"
import Loader from "../common/components/Loader";

const OrderFormPowerLooping = ({ formData, toggleTab, activeTab }) => {

    const { dataSnapshot, powerSnapshot, powerLooping } = useSelector(state => {
        return {
            dataSnapshot: state.order.dataSnapshot,
            powerSnapshot: state.order.powerSnapshot,
            powerLooping: state.order.powerLooping
        }
    })

    const dispatch = useDispatch();

    let drawingFlag = [];
    const initialFontSize = 15;

    const [loading, setLoading] = useState(false);
    const x = useRef(0);
    const y = useRef(0);
    const svgRef = useRef(null);
    const countPoints = useRef([]);
    const splitterPoints = useRef([]);
    const cableCount = useRef(1);
    const [powerRowData, setPowerRowData] = useState([]);

    let cabinetFitRows = formData === null ? "--" : formData.specSheet.cabinetFitRows;
    let cabinetFitColumns = formData === null ? "--" : formData.specSheet.cabinetFitColumns;
    let seriesName = formData === null ? "--" : formData.specSheet.series;
    let pixelPitch = formData === null ? "--" : formData.dimObj.pixelPitchLength;
    let displaySize = formData === null ? "--" : formData.specSheet.displaySizeMm;
    let displayResolution = formData === null ? "--" : formData.specSheet.displayResolution;
    let dataRedundancy = formData === null ? false : formData.specSheet.dataBackup;
    let powerRedundancy = formData === null ? false : formData.specSheet.powerBackup;
    let hdr = formData === null ? false : formData.specSheet.hdr;

    const drawLine = (x1, y1, x2, y2, showArrow, count, coordinates, lineColor = DEFAULT_LINE_COLOR) => {
        const svg = svgRef.current;
        if (!svg) return;

        let strokeWidth = lineColor === DEFAULT_LINE_COLOR ? 1 : 1.5;

        const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);

        line.setAttribute('stroke', lineColor);
        line.setAttribute('stroke-width', strokeWidth);

        showArrow && line.setAttribute('marker-end', 'url(#powerArrow)');
        svg.appendChild(line);

        if (count >= 0 && countPoints.current.findIndex(ele => ele.coordinates.x1 === coordinates.x1 && ele.coordinates.y1 === coordinates.y1) === -1) {
            countPoints.current.push({ x1, y1, coordinates, count });
        }

        return line;
    }

    const showCableCount = (x1, y1, coordinates) => {
        if (isSplitterPoint({ x: coordinates.x1, y: coordinates.y1 })) {
            return
        }
        const svg = svgRef.current;
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circle.setAttribute('cx', x1);
        circle.setAttribute('cy', y1);
        circle.setAttribute('r', 10);
        circle.setAttribute('fill', '#e14504');

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', x1);
        text.setAttribute('y', y1);
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('alignment-baseline', 'central');
        text.setAttribute('fill', '#ffffff');
        text.setAttribute('font-size', 10);
        const textNode = document.createTextNode(cableCount.current++);
        text.appendChild(textNode);

        svg.appendChild(circle);
        svg.appendChild(text);
    }

    const drawCables = (x1, y1, x2, y2, showArrow = true, checkCondition = true, count = -1, lineColor, xOffset, yOffset, domRect) => {
        if (checkCondition) {
            if (x1 % 2 === 1 && x2 % 2 === 1) {
                drawingFlag.push({ x: x1, y: y1 });
            } else if (drawingFlag.length !== 0 && x1 % 2 === 0 && x2 % 2 === 0) {
                drawingFlag.forEach((ele, index) => {
                    const { x, y } = ele;
                    if (y === y1) {
                        connectCables(x, y, x1, y1, count, lineColor, xOffset, yOffset, domRect);
                        splitterPoints.current.push({ x, y }, { x: x1, y: y1 })
                        drawingFlag.splice(index, 1);
                    }
                })
            }
        }

        const width = domRect.width;
        const height = domRect.height;
        return drawLine(width * x1 - (width * xOffset), height * y1 - (height * yOffset), width * x2 - (width * xOffset), height * y2 - (height * yOffset), showArrow, count, { x1, y1, x2, y2 }, lineColor);
    }

    const removePreviousLines = () => {
        const svg = svgRef.current;
        const lines = svg?.querySelectorAll('line');
        if (lines) {
            lines.forEach(line => line.remove());
        }
    }

    const removePreviousCircles = () => {
        const svg = svgRef.current;
        const circles = svg?.querySelectorAll('circle');
        if (circles) {
            circles.forEach(circle => circle.remove());
        }
    }

    const removePreviousText = () => {
        const svg = svgRef.current;
        const texts = svg?.querySelectorAll('text');
        if (texts) {
            texts.forEach(text => text.remove());
        }
    }

    const drawLoopingDiagram = (lineGroup, lineColor, xOffset, yOffset) => {
        let domRect = document.getElementsByClassName("power-cabinet")[0].getBoundingClientRect();
        lineGroup?.forEach((group, index) => {
            group?.forEach((line, idx) => {
                const { x1, y1, x2, y2 } = line;
                let arrow = true;
                if (group.length !== 1 && idx !== (group.length - 1)) {
                    arrow = false;
                }
                if (['Ace', 'Ace Plus', 'Unify-FHD'].includes(seriesName)) {
                    drawCables(x1, y1, x2, y2, arrow, true, index, lineColor, xOffset, yOffset, domRect);
                } else {
                    if (idx == 0) {
                        drawCables(x1, y1, x2, y2, arrow, false, index, lineColor, xOffset, yOffset, domRect);
                    } else {
                        drawCables(x1, y1, x2, y2, arrow, false, -1, lineColor, xOffset, yOffset, domRect);
                    }
                }
            })
        })

        countPoints.current.forEach((point) => {
            showCableCount(point.x1, point.y1, point.coordinates);
        })

        drawingFlag = [];
        splitterPoints.current = [];
        countPoints.current = [];
        cableCount.current = 1;
    }

    const isSplitterPoint = (point) => {
        return splitterPoints.current.some(pt => (pt.x === point.x && pt.y === pt.y));
    }

    const connectCables = (x1, y1, x2, y2, count, lineColor, xOffset, yOffset, domRect) => {
        const line = drawCables(x1, y1, x2, y2, false, false, count, lineColor, xOffset, yOffset, domRect);
        showCableSplit(line, { x1: x1 + 0.1, y1: y1 + 0.1, x2, y2 }, count, lineColor);
    }

    const showCableSplit = (line, coordinates, count, lineColor) => {
        if (line.getClientRects().length > 0) {
            const length = line.getTotalLength();
            const startPoint = line.getPointAtLength(0);
            const endPoint = line.getPointAtLength(length);
            const midpointX = (startPoint.x + endPoint.x) / 2;
            const midpointY = (startPoint.y + endPoint.y) / 2;

            drawLine(midpointX, midpointY + 30, midpointX, midpointY, true, count, coordinates, lineColor);
        }
    }

    const drawTable = async () => {
        let rowData = [];
        let new_col = cabinetFitColumns;

        let lastOddColFlag = (cabinetFitColumns / 2) !== new_col;

        for (let i = 0; i < cabinetFitRows; i++) {
            const module = [];
            for (let j = 0; j < new_col; j++) {
                if (new_col === cabinetFitColumns) {
                    module.push(<td className={`power-cabinet`} data-value={`${j},${i}`} key={j}></td>);
                } else {
                    let new_class = (j === new_col - 1 && lastOddColFlag) ? 'last-column-cell' : '';
                    module.push(<td className={`power-cabinet ${new_class}`} data-value={`${j},${i}`} key={j}></td>)
                }
            }
            rowData.push(<tr key={i}>{module}</tr>)
        }

        // Adding Hidden row to show cable split for Ace and Unify-FHD
        if (['Ace', 'Ace Plus', 'Unify-FHD'].includes(seriesName)) {
            const moduleHidden = [];
            for (let j = 0; j < cabinetFitColumns; j++) {
                moduleHidden.push(<td style={{ border: 'none' }} className={`power-cabinet`} key={Math.random()}></td>);
            }
            rowData.push(<tr key={Math.random()}>{moduleHidden}</tr>)
            rowData.push(<tr key={Math.random()}>{moduleHidden}</tr>)
        }

        setPowerRowData(rowData);

        setTimeout(function () {
            let xOffset = 0.5;
            let yOffset = 0.5;
            drawLoopingDiagram(powerLooping.points, POWER_LOOP_LINE_COLOR, xOffset, yOffset);
        }, 10);

        setTimeout(function () {
            savePowerLoopingScreenshot();
        }, 20);
    }

    const savePowerLoopingScreenshot = async () => {
        try {
            const originalPowerNode = document.getElementById('power-cabinet-div');
            const clonedPowerNode = originalPowerNode.cloneNode(true);
            dispatch(addPowerSnapshot({
                snapshot: clonedPowerNode
            }))
        } catch (error) {
            console.error("Error while saving Power Looping Screenshot");
        }
    }

    const addDesignDescription = (pdf) => {
        addTextCenter('Details', pdf, 15, 10, 'bold')
        const totalHeight = pdf.getTextDimensions('Series').h * 3;
        checkIfPageOverflow(pdf, totalHeight);

        let dSize = displaySize.split(' - ')[0];

        addTextLeft(`Series: ${seriesName}`, pdf, 11, 1, 7, false, 'bold');
        addTextLeft(`Pixel Pitch: ${pixelPitch}mm`, pdf, 11, 1, 7, false, 'bold');
        addTextLeft(`Display Size: ${dSize}`, pdf, 11, 1, 7, false, 'bold');
        addTextLeft(`Display Resolution (W x H): ${displayResolution}`, pdf, 11, 1, 7, false, 'bold');
        addTextLeft(`LED Cabinets (W x H): ${cabinetFitColumns} x ${cabinetFitRows}`, pdf, 11, 1, 7, false, 'bold');

        let featureArr = [];
        if (dataRedundancy == true) { featureArr.push('Data Redundancy') };
        if (powerRedundancy == true) { featureArr.push('Power Redundancy') };
        if (hdr == true) { featureArr.push('HDR') };

        if (featureArr.length != 0) {
            addTextLeft(`Special Features: ` + featureArr.join(', '), pdf, 11, 1, 7, false, 'bold');
        }

        addPaddingBottom(totalHeight);
    }

    async function* addScreenshot(pdf) {
        try {
            const pdfWidth = pdf.internal.pageSize.getWidth();

            document.body.appendChild(dataSnapshot);
            const dataUrl = await html2canvas(dataSnapshot, { logging: false });
            const imgDataProps = pdf.getImageProperties(dataUrl);
            const pdfDataHeight = (imgDataProps.height * pdfWidth) / imgDataProps.width;
            addImage(pdf, dataUrl, 'CANVAS', pdfWidth - 10, pdfDataHeight + 20, `Data Looping Diagram`);
            document.body.removeChild(dataSnapshot);

            if (dataRedundancy) {
                // pdf.setDrawColor(55, 136, 5);
                let greenText = 'NOTE : Green lines denote data cables. Blue lines denote backup cables.';
                addTextLeft(greenText, pdf, 10, 5, 0, false, 'bold');
                // pdf.setDrawColor(0, 0, 0);
            }

            yield;

            document.body.appendChild(powerSnapshot);
            const powerUrl = await html2canvas(powerSnapshot, { canvasHeight: 700 });
            const imgPowerProps = pdf.getImageProperties(powerUrl);
            const pdfPowerHeight = (imgPowerProps.height * pdfWidth) / imgPowerProps.width;
            addImage(pdf, powerUrl, 'CANVAS', pdfWidth - 10, pdfPowerHeight + 10, `Power Looping Diagram`);
            document.body.removeChild(powerSnapshot);
            yield;

            return;
        } catch (error) {
            console.error('Error while adding looping diagrams to PDF', error);
        }
    }

    const addPaddingBottom = (paddingBottom) => {
        y.current += paddingBottom;
    }

    const addTextCenter = (txt, pdf, fontSize = 15, paddingBottom = 0, fontWeight = 'normal') => {
        if (fontWeight !== 'normal') {
            pdf.setFont("helvetica", '', fontWeight);
        }
        pdf.setFontSize(fontSize);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const { w, h } = pdf.getTextDimensions(txt);

        pdf.text(txt, (pdfWidth - w) / 2, y.current);
        y.current += (h + paddingBottom);
        pdf.setFontSize(initialFontSize);

        if (fontWeight !== 'normal') {
            pdf.setFont("helvetica", '', "normal");
        }

    }

    const addTextRight = (txt, pdf, fontSize = 15, paddingBottom = 0, paddingRight = 0) => {
        pdf.setFontSize(fontSize);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const { w, h } = pdf.getTextDimensions(txt);
        pdf.text(txt, (pdfWidth - w - paddingRight), y.current);
        addPaddingBottom((h + paddingBottom))
        pdf.setFontSize(initialFontSize);
    }

    const drawPDFLine = (pdf, color, ratio, lineWidth = 0.2) => {
        if (lineWidth !== 0.2) {
            pdf.setLineWidth(lineWidth)
        }
        pdf.setDrawColor(...color);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        let startX = x.current;
        if (ratio > 1) {
            startX = x.current + 5;
        }
        const startY = y.current;
        const lineLength = pdfWidth / ratio;

        pdf.line(startX, startY, startX + lineLength, startY);
        pdf.setDrawColor(0, 0, 0);
        if (lineWidth !== 0.2) {
            pdf.setLineWidth(0.5)
        }
    }

    const addTextLeft = (txt, pdf, fontSize = 15, paddingBottom = 0, paddingLeft = 0, underline = false, fontWeight = 'normal') => {
        if (fontWeight !== 'normal') {
            pdf.setFont("helvetica", '', fontWeight);
        }
        pdf.setFontSize(fontSize);
        const { w, h } = pdf.getTextDimensions(txt);
        pdf.text(txt, x.current + 5 + paddingLeft, y.current);
        if (underline) {
            addPaddingBottom(h / 3);
            drawPDFLine(pdf, [255, 69, 4], 2);
        }
        addPaddingBottom((h + paddingBottom));
        pdf.setFontSize(initialFontSize);
        if (fontWeight !== 'normal') {
            pdf.setFont("helvetica", '', 'normal');
        }
    }

    const addNewPage = (pdf) => {
        y.current = 5;
        pdf.addPage();
        pdf.addImage(XMLogo, 'PNG', x.current + 5, y.current, 20, 10, undefined, 'FAST');
        y.current += 22;
    }

    const checkIfPageOverflow = (pdf, contentHeight) => {
        if ((pdf.internal.pageSize.height - (y.current + contentHeight) < 0)) {
            addNewPage(pdf);
        }
    }

    const addImage = (pdf, img, type, width, height, title) => {
        let titleHeight = 0;
        if (title) {
            titleHeight = pdf.getTextDimensions(title).h;
        }

        checkIfPageOverflow(pdf, height + titleHeight);

        addTextCenter(title, pdf, 20, 2);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const leftMargin = (pdfWidth - width) / 2;
        pdf.addImage(img, type, x.current + leftMargin, y.current, width, height, undefined, 'FAST');
        addPaddingBottom((height + 10));
    }

    const exportLoopingData = async () => {
        try {
            const pdf = new jsPDF();
            pdf.setDocumentProperties({
                title: "Xtreme Order Form Looping Diagrams",
                author: "Xtreme Media"
            })

            pdf.addImage(XMLogo, 'PNG', x.current + 5, y.current, 20, 10, undefined, 'FAST');
            addPaddingBottom(20);

            addTextCenter('Order Form Looping Diagrams', pdf, 28, 0, 'bold');
            addTextRight(`- Created on ${new Date().toDateString()}`, pdf, 10, 5, 10, false);
            drawPDFLine(pdf, [0, 0, 0], 1, 0.8);
            addPaddingBottom(15);

            addDesignDescription(pdf);
            const diagramIterator = addScreenshot(pdf);

            for (let i = 0; i < 2; i++) {
                await diagramIterator.next();
            }

            y.current = 10;
            const pdfData = pdf.output("blob");
            const pdfBlob = new Blob([pdfData], { type: "application/pdf" });


            let sName = seriesName.replaceAll(' ', '').replaceAll('-', '')
            pdf.save("OrderForm_LoopingDiagram_" + sName + "_P" + pixelPitch + "_" + parseInt(new Date().getTime() / 1000) + ".pdf");

            return pdfBlob;
        } catch (error) {
            console.error("Error while exporting data", error);
            return null;
        }
    }

    const handleClick = () => {
        setLoading(true);

        async function exportDiagrams() {
            await exportLoopingData();
            setLoading(false);
        };
        exportDiagrams();
    }

    useUpdateEffect(() => {
        if (formData !== null && activeTab == 4) {
            removePreviousLines();
            removePreviousCircles();
            removePreviousText();

            async function drawPowerTable() {
                await drawTable();
            };
            drawPowerTable();
        }
    }, [activeTab, powerLooping.points])

    return (
        <>
            {
                loading && <Loader />
            }
            {['Nyx Modular', 'Flex', 'Iris Façade', 'Iris Facade'].includes(seriesName) && (
                <span style={{ paddingTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>Looping diagram is not available for {seriesName} series.</span>
            )}
            <div hidden={['Nyx Modular', 'Flex', 'Iris Façade', 'Iris Facade'].includes(seriesName)} className='justify-content-center' style={{ display: 'flex', height: '100%', width: '100%', position: 'absolute', zIndex: 1 }}>
                <div id={'power-cabinet-div'} style={{ height: '75%', width: '80%', position: 'absolute' }}>
                    <table id="power-cabinet-matrix" style={{ height: '100%', width: '100%', position: 'absolute', justifyContent: 'center' }}>
                        <tbody>
                            {powerRowData}
                        </tbody>
                    </table>
                    <svg ref={svgRef} style={{ height: '100%', width: '100%', zIndex: 99, position: 'relative' }}>
                        <defs>
                            <marker id="powerArrow" viewBox="0 0 10 10" refX="8" refY="5"
                                markerWidth="5" markerHeight="5"
                                orient="auto-start-reverse">
                                <path d="M 0 0 L 10 5 L 0 10 z" />
                            </marker>
                        </defs>
                    </svg>
                    <CustomButton style={{ float: "right", backgroundColor: "var(--primary-color)", marginTop: '10px' }} onClick={handleClick} disabled={loading} >
                        <span>{loading ? `Exporting...` : `Export as PDF`}</span>
                    </CustomButton>
                </div>
            </div>
        </>
    )

}
export default memo(OrderFormPowerLooping);