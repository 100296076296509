import { Slide, toast } from "react-toastify"
import { AES_METHOD, KEY, IV } from "../../config/api"
var CryptoJS = require("crypto-js");

export const Toast = debounce((type, title, message) => {
    const toastContent = <>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <h6 className='toast-title fw-bold'>{title}</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span>{message}</span>
        </div>
    </>;

    const toastConfig = {
        position: toast.POSITION.BOTTOM_RIGHT,
        // icon: false, 
        transition: Slide,
        hideProgressBar: false,
        autoClose: 2000
    }

    if (type === "success") {
        toast.success(
            toastContent,
            toastConfig
        )
    } else if (type === "error") {
        toast.error(
            toastContent,
            toastConfig
        )
    } else if (type === "warning") {
        toast.warning(
            toastContent,
            toastConfig
        )
    }

}, 500)


export const convertMeterToFt = (m) => {
    return (m * 3.28084).toFixed(2);
}

export const convertFtToMeter = (ft) => {
    return (ft / 3.28084).toFixed(2);
}

export function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
}

// This function is used to encrypt the data as per the aes method, key, iv provided in utility/api.js
// Returns the encrypted data
export const encryptData = (data) => {
    const cipher = CryptoJS.AES.encrypt(data, KEY).toString();
    return cipher
}