import { memo, useRef } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../common/components/CustomButtom";
import { Card, CardBody, CardFooter, Form } from "reactstrap";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";
import ExcelJS from 'exceljs';
import { BorderStyle, Worksheet } from "exceljs";
import '../../css/form.css';
import { UNIFY_SERIES, VEGA_VEGA_PLUS_SERIES } from "../../config/config";

const Specifications = ({ formData, toggleTab }) => {

  const { dataLooping, powerLooping } = useSelector((state) => {
    return {
      dataLooping: state.order.dataLooping,
      powerLooping: state.order.powerLooping,
    }
  })

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const exportExcelFile = () => {
    const ExcelJS = require('exceljs');
    const table = document.getElementById('order-specs-table');
    let sName = formData.specSheet.series.replaceAll(' ', '').replaceAll('-', '');
    let pp = formData.specSheet.pixelPitch.replaceAll('mm', '');
    var workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    let mergeObj = {};
    let ageingMergeObj = {};

    //All thin Borders
    var borderStyles = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    };
    var noneBorder = {
      top: "",
      left: "",
      bottom: "",
      right: ""
    }

    Array.from(table.querySelectorAll('thead th')).forEach(th => {
      const headerRow = worksheet.addRow([]);
      headerRow.getCell(headerRow.cellCount + 1).value = th.textContent;
      headerRow.font = {
        name: 'Calibri (Body)',
        size: 10,
        bold: true,
      }
    });

    Array.from(table.querySelectorAll('thead tr')).forEach(tr => {
      const row = worksheet.addRow([]);
      Array.from(tr.querySelectorAll('td')).forEach(td => {
        row.getCell(row.cellCount + 1).value = td.textContent;
        row.getCell(row.cellCount).font = {
          name: 'Calibri (Body)',
          size: 10,
          bold: true,
        }
      });
    });

    Array.from(table.querySelectorAll('tbody tr')).forEach(tr => {
      const row = worksheet.addRow([]);
      Array.from(tr.querySelectorAll('th')).forEach(th => {

        if (th.classList.contains('group-section')) {
          let section = th.attributes.getNamedItem('section-name');
          let rowspan = th.attributes.getNamedItem('section-rowspan');
          if (section != null && rowspan != null) {
            let endNum = row.number + parseInt(rowspan.nodeValue) - 1;
            mergeObj[section.nodeValue] = { start: 'A' + row.number, end: 'A' + endNum }
          }
        }

        if (th.classList.contains('ageing-header-row')) {
          ageingMergeObj['ageingHeader'] = { start: 'B' + row.number, end: 'E' + row.number }
        }

        row.getCell(row.cellCount + 1).value = th.textContent;
        row.getCell(row.cellCount).font = {
          name: 'Calibri (Body)',
          size: 10,
          bold: true,
        }
        row.getCell(row.cellCount).alignment = {
          vertical: 'middle',
          horizontal: 'center'
        }
      })
      Array.from(tr.querySelectorAll('td')).forEach(td => {
        row.getCell(row.cellCount + 1).value = td.textContent;

        if (td.classList.contains('ageing-detail-row')) {
          ageingMergeObj['ageingDetail' + row.number] = { start: 'C' + row.number, end: 'E' + row.number }
        }
      })
    });

    var cellC3 = worksheet.getCell('C3').value
    worksheet.getCell('D3').value = cellC3

    //Merging
    worksheet.mergeCells('A1:E1')
    worksheet.mergeCells('A2:E2')
    worksheet.mergeCells('A4:E4')
    worksheet.mergeCells('B3:C3')

    worksheet.columns = [{ key: 'A', width: 13 }, { key: 'B', width: 6 }, { key: 'C', width: 38 }, { key: 'D', width: 50 }, { key: 'E', width: 22, alignment: { vertical: 'middle', horizontal: 'center' } }, { key: 'F', width: 22, alignment: { vertical: 'middle', horizontal: 'center' } }, { key: 'G', width: 22, alignment: { vertical: 'middle', horizontal: 'center' } }]

    worksheet.getCell('A1').alignment = {
      vertical: 'middle', horizontal: 'center'
    }

    //WrapText aaplied to specific rows as row height wasn't auto adjusting
    worksheet.getRow(59).height = 46;
    // worksheet.getRow(113).height = 29;
    worksheet.getRow(5).height = 29;
    // worksheet.getRow(122).height = 29;

    worksheet.getRow(4).alignment = {
      vertical: 'middle', horizontal: 'center', wrapText: true
    }
    worksheet.getRow(5).alignment = {
      vertical: 'middle', horizontal: 'center', wrapText: true
    }
    worksheet.getRow(59).alignment = {
      vertical: 'middle', horizontal: 'left', wrapText: true
    }
    // worksheet.getRow(113).alignment = {
    //   vertical: 'middle', horizontal: 'left', wrapText: true
    // }
    // worksheet.getRow(122).alignment = {
    //   vertical: 'middle', horizontal: 'left', wrapText: true
    // }

    //All thin Borders
    var borderStyles = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    };
    var noneBorder = {
      top: "",
      left: "",
      bottom: "",
      right: ""
    }
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        if (!cell.value) {
          cell.value = '';
        }
        cell.border = borderStyles;
      });
    });
    worksheet.getCell('E3').border = borderStyles;
    worksheet.getCell('F5').border = noneBorder;
    worksheet.getCell('F6').border = noneBorder;

    //Merging cells for section headings and Aging Details

    for (const [key, val] of Object.entries(mergeObj)) {
      worksheet.mergeCells(mergeObj[key]['start'], mergeObj[key]['end']);
      // worksheet.mergeCells('C122:E122')

      //Section Headings
      worksheet.getCell(mergeObj[key]['start']).alignment = {
        vertical: 'middle', horizontal: 'center', textRotation: 90
      }
    }

    for (const [key, val] of Object.entries(ageingMergeObj)) {
      worksheet.mergeCells(ageingMergeObj[key]['start'], ageingMergeObj[key]['end'])
    }

    //Outer Border
    const createOuterBorder = (
      worksheet,
      [startCol, startRow],
      [endCol, endRow],
      style = "medium"
    ) => {
      for (let i = startRow; i <= endRow; i++) {
        const leftBorderCell = worksheet.getCell(i, startCol);
        const rightBorderCell = worksheet.getCell(i, endCol);

        leftBorderCell.border = {
          ...leftBorderCell.border,
          left: {
            style,
          },
        };

        rightBorderCell.border = {
          ...rightBorderCell.border,
          right: {
            style,
          },
        };
      }

      for (let i = startCol; i <= endCol; i++) {
        const topBorderCell = worksheet.getCell(startRow, i);
        const bottomBorderCell = worksheet.getCell(endRow, i);

        topBorderCell.border = {
          ...topBorderCell.border,
          top: {
            style,
          },
        };

        bottomBorderCell.border = {
          ...bottomBorderCell.border,
          bottom: {
            style,
          },
        };
      }
    };

    createOuterBorder(worksheet, [1, 1], [5, 4]);

    createOuterBorder(worksheet, [1, 5], [5, 60]);

    createOuterBorder(worksheet, [1, 61], [5, 80]);
    createOuterBorder(worksheet, [6, 61], [7, 80]);

    createOuterBorder(worksheet, [1, 81], [5, 105]);
    createOuterBorder(worksheet, [6, 81], [7, 105]);

    createOuterBorder(worksheet, [1, 106], [5, 111]);

    const buffer = workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, "Specifications_" + sName + "_" + pp + "_" + parseInt(new Date().getTime() / 1000) + ".xlsx");
    });
  };

  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Order Specifications Sheet",
    sheet: "Order Specifications Sheet",
  });
  const onsubmit = () => {
    toggleTab('1');
  };

  let dataLoopingCables = dataLooping.points.length

  let cabinetFitColumns
  let wallMountDesc = "--"
  let cabinetHangingDesc = "--"
  let cabinetHangingQuantity = 0
  let totalRc
  let receiverCardPerCabinet
  let cabinetsPerScreen
  let powerLoopingCables = powerLooping.points.length
  let screenQuantity = 1

  if (formData != null) {
    screenQuantity = formData.specSheet.quantity;

    if (formData.specSheet.dataBackup) {
      dataLoopingCables = dataLooping.points.length * 2
    }
    cabinetFitColumns = formData.specSheet.cabinetFitColumns;

    if (formData.specSheet.series === "Ace") {
      cabinetHangingDesc = 'M8 x 40'
      cabinetHangingQuantity = formData.specSheet.cabinetFitColumns * formData.specSheet.cabinetFitRows * 4
      wallMountDesc = formData.specSheet.cabinetMatrix
      cabinetFitColumns = formData.specSheet.cabinetFitColumns + 1;
    } else if (formData.specSheet.series === "LIT") {
      cabinetHangingDesc = 'M6 x 40'
      cabinetHangingQuantity = formData.specSheet.cabinetFitColumns * formData.specSheet.cabinetFitRows * 4
      wallMountDesc = formData.specSheet.cabinetMatrix
      cabinetFitColumns = formData.specSheet.cabinetFitColumns;
    }
    totalRc = formData.specSheet.totalNumberOfReceiverCard
    receiverCardPerCabinet = formData.specSheet.receiverCardPerCabinet
    cabinetsPerScreen = formData.specSheet.cabinetsPerScreen

    if (["Ace", "Ace Plus"].includes(formData.specSheet.series)) {
      powerLoopingCables = Math.ceil(formData.specSheet.cabinetFitColumns / 2) * Math.ceil(formData.specSheet.cabinetFitRows / (Math.floor(formData.specSheet.maxCabinetPower / 2)))
    } else if (formData.specSheet.series === UNIFY_SERIES) {
      powerLoopingCables = Math.ceil(formData.specSheet.cabinetFitColumns / 2) * Math.ceil(formData.specSheet.cabinetFitRows / formData.specSheet.cabinetFitColumns)
    }
    powerLoopingCables = powerLoopingCables * screenQuantity;
    dataLoopingCables = dataLoopingCables * screenQuantity;
  }

  let srno = 0;

  return (
    <Form onSubmit={handleSubmit(onsubmit)} >
      <Card>
        <CardBody>
          <table ref={tableRef} style={{
            marginTop: "2%",
            backgroundColor: "var(--tertiary-color)",
            borderColor: "var(--primary-color)",
            width: "fit-content",
            minWidth: "-webkit-fill-available",
          }}
            id="order-specs-table"
            className="table table-bordered">
            <thead>
              <tr style={{ backgroundColor: "var(--primary-color)", color: "#fff" }}>
                <th colSpan={8}>Specifications</th>
                {/* <td style={{ color: "#fff" }} colSpan={2}></td> */}
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Project Name</td>
                <td colSpan={2}>
                  {formData == null ? "--" : formData.specSheet.projectName}</td>
                <td style={{ fontWeight: "bold", width: '40%' }}>Project ID</td>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <td colSpan={7} style={{ fontWeight: "bold" }}>
                  Useful for - Imports/Production/Local
                  Purchase/QC/Projects/Inventory/Logistic/Support
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Section</td>
                <td style={{ fontWeight: "bold" }}>Sr. No.</td>
                <td style={{ fontWeight: "bold" }}>Description</td>
                <td style={{ fontWeight: "bold" }}>Requirement</td>
                <td style={{ fontWeight: "bold" }} colSpan={1}>Confirmation/Revision by factory</td>
                <td colSpan={2}></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="group-section no-border-bottom" section-name='general' section-rowspan={55} style={{ borderWidth: "1px 1px", textAlign: "center" }}>
                  General
                </th>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Series Name</td>
                <td>{formData == null ? "--" : formData.specSheet.series}</td>
                <td colSpan={1}></td>
                <td rowSpan={55} colSpan={2}> </td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderSpacing: "0", borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Type</td>
                <td>{formData == null ? "--" : formData.specSheet.type == null ? "--" : formData.specSheet.type}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Pixel Pitch</td>
                <td>{formData == null ? "--" : formData.specSheet.pixelPitch == null ? "--" : formData.specSheet.pixelPitch}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Display Size in mm</td>
                <td>{formData == null ? "--" : formData.specSheet.displaySizeMm == null ? "--" : formData.specSheet.displaySizeMm}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Display Resolution (WxH)</td>
                <td>{formData == null ? "--" : formData.specSheet.displayResolution == null ? "--" : formData.specSheet.displayResolution}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Mounting Type</td>
                <td>{formData == null ? "--" : formData.specSheet.mountingType == null ? "--" : formData.specSheet.mountingType}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Product Mounting Access</td>
                <td>{formData == null ? "--" : formData.specSheet.frontAccess == true ? "Front" : "Back"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Product Maintenance Access</td>
                <td>{formData == null ? "--" : formData.specSheet.frontAccess == true ? "Front" : "Back"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>LED Make (Encapsulation)</td>
                <td>{formData == null ? "--" : formData.specSheet.ledMake == null ? "--" : (formData.specSheet.goldWire == true ? formData.specSheet.ledMake + " - Gold Wire" : formData.specSheet.ledMake + " - Copper Wire")}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>LED Type </td>
                <td>{formData == null ? "--" : formData.specSheet.ledType == null ? "--" : formData.specSheet.ledType}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>LED Lamp Part Number</td>
                <td>{formData == null ? "--" : formData.specSheet.goldWire == true
                  ? ((formData.specSheet.ledPartNumberGold == null) || (formData.specSheet.ledPartNumberGold == 'NA') || (formData.specSheet.ledPartNumberGold == " ") || (formData.specSheet.ledPartNumberGold == ""))
                    ? "--"
                    : formData.specSheet.ledPartNumberGold
                  : ((formData.specSheet.ledPartNumberCopper == null) || (formData.specSheet.ledPartNumberCopper == 'NA') || (formData.specSheet.ledPartNumberCopper == " ") || (formData.specSheet.ledPartNumberCopper == ""))
                    ? "--"
                    : formData.specSheet.ledPartNumberCopper}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>HDR</td>
                <td>{formData == null ? "--" : formData.specSheet.hdr == true ? "Yes" : "Not Required"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Power Redundancy</td>
                <td>{formData == null ? "--" : formData.specSheet.powerBackup == true ? "Yes" : "Not Required"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Data Redundancy</td>
                <td>{formData == null ? "--" : formData.specSheet.dataBackup == true ? "Yes" : "Not Required"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Brightness</td>
                <td>{formData == null ? "--" : ((formData.specSheet.brightness == null) || (formData.specSheet.brightness == 'NA') || (formData.specSheet.brightness == " ") || (formData.specSheet.brightness == "")) ? "--" : formData.specSheet.brightness}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Contrast</td>
                <td>{formData == null ? "--" : ((formData.specSheet.contrast == null) || (formData.specSheet.contrast == 'NA') || (formData.specSheet.contrast == " ") || (formData.specSheet.contrast == "")) ? "--" : formData.specSheet.contrast}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Calibrated Color Temperature (K)</td>
                <td>{formData == null ? "--" : formData.specSheet.calibratedColorTemperature == null ? "--" : formData.specSheet.calibratedColorTemperature}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Power Supply Make</td>
                <td>{formData == null ? "--" : formData.specSheet.powerSupplyMake == null ? "--" : formData.specSheet.powerSupplyMake}</td>
                <td colSpan={1}></td>
              </tr>

              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Spare</td>
                <td>{formData == null ? "--" : formData.specSheet.spare == null ? "--" : formData.specSheet.spare + "%"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Power Supply Model</td>
                <td>{formData == null ? "--" : formData.specSheet.pfc == true
                  ? ((formData.specSheet.powerSupplyPartNumPfc == null) || (formData.specSheet.powerSupplyPartNumPfc == 'NA') || (formData.specSheet.powerSupplyPartNumPfc == " ") || (formData.specSheet.powerSupplyPartNumPfc == ""))
                    ? "--"
                    : formData.specSheet.powerSupplyPartNumPfc
                  : ((formData.specSheet.powerSupplyPartNumNonPfc == null) || (formData.specSheet.powerSupplyPartNumNonPfc == 'NA') || (formData.specSheet.powerSupplyPartNumNonPfc == " ") || (formData.specSheet.powerSupplyPartNumNonPfc == ""))
                    ? "--"
                    : formData.specSheet.powerSupplyPartNumNonPfc}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Power Supply Wattage (Watts)</td>
                <td>{formData == null ? "--" : ((formData.specSheet.powerSupplyWattage == null) || (formData.specSheet.powerSupplyWattage == 'NA') || (formData.specSheet.powerSupplyWattage == " ") || (formData.specSheet.powerSupplyWattage == "")) ? "--" : formData.specSheet.powerSupplyWattage}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Power Supply Output Voltage (V)</td>
                <td>{formData == null ? "--" : ((formData.specSheet.powerSupplyOutputVoltage == null) || (formData.specSheet.powerSupplyOutputVoltage == 'NA') || (formData.specSheet.powerSupplyOutputVoltage == " ") || (formData.specSheet.powerSupplyOutputVoltage == "")) ? "--" : formData.specSheet.powerSupplyOutputVoltage}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Power Supply Output Current (Amp)</td>
                <td>{formData == null ? "--" : ((formData.specSheet.powerSupplyOutputCurrent == null) || (formData.specSheet.powerSupplyOutputCurrent == 'NA') || (formData.specSheet.powerSupplyOutputCurrent == " ") || (formData.specSheet.powerSupplyOutputCurrent == "")) ? "--" : formData.specSheet.powerSupplyOutputCurrent}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Cabinet Material</td>
                <td>{formData == null ? "--" : ((formData.specSheet.cabinetMaterial == null) || (formData.specSheet.cabinetMaterial == 'NA') || (formData.specSheet.cabinetMaterial == " ") || (formData.specSheet.cabinetMaterial == "")) ? "--" : formData.specSheet.cabinetMaterial}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>PFC</td>
                <td>{formData == null ? "--" : formData.specSheet.pfc == true ? "Yes" : "Not Required"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Any Special Shape</td>
                <td>{formData == null ? "--" : ((formData.specSheet.anySpecialShape == null) || (formData.specSheet.anySpecialShape === 'NA') || (formData.specSheet.anySpecialShape == " ") || (formData.specSheet.anySpecialShape == "")) ? "--" : formData.specSheet.anySpecialShape}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Error Detection / Vigilant</td>
                <td>{formData == null ? "--" : ((formData.specSheet.errorDetectionVigilantDb == null) || (formData.specSheet.errorDetectionVigilantDb === 'NA') || (formData.specSheet.errorDetectionVigilantDb == " ") || (formData.specSheet.errorDetectionVigilantDb == ""))
                  ? "--" : formData.specSheet.errorDetectionVigilantDb}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>IP Rating Front</td>
                <td>{formData == null ? "--" : formData.specSheet.ipRatingFront == null ? "--" : formData.specSheet.ipRatingFront}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>IP Rating Back</td>
                <td>{formData == null ? "--" : formData.specSheet.ipRatingBack == null ? "--" : formData.specSheet.ipRatingBack}</td>
                <td colSpan={1}></td>
              </tr>

              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Paint DFT Readings</td>
                <td>{formData == null ? "--" : ((formData.specSheet.paintDFTReadings == null) || (formData.specSheet.paintDFTReadings == 'NA') || (formData.specSheet.paintDFTReadings == '') || (formData.specSheet.paintDFTReadings == ' ')) ? "--" : formData.specSheet.paintDFTReadings}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Driving IC</td>
                <td>{formData == null ? "--" : ((formData.specSheet.drivingIC == null) || (formData.specSheet.drivingIC == 'NA') || (formData.specSheet.drivingIC == " ") || (formData.specSheet.drivingIC == "")) ? "--" : formData.specSheet.drivingIC}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Scan</td>
                <td>{formData == null ? "--" : ((formData.specSheet.scan == null) || (formData.specSheet.scan == 'NA') || (formData.specSheet.scan == " ") || (formData.specSheet.scan == "")) ? "--" : formData.specSheet.scan}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Refresh Rate (Hz)</td>
                <td>{formData == null ? "--" : ((formData.specSheet.refreshRate == null) || (formData.specSheet.refreshRate == 'NA') || (formData.specSheet.refreshRate == " ") || (formData.specSheet.refreshRate == "")) ? "--" : formData.specSheet.refreshRate}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>High Refresh</td>
                <td>{formData == null ? "--" : formData.specSheet.highRefresh == true ? "Yes" : "Not Required"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Receiver Card Model</td>
                <td>{formData == null ? "--" : formData.specSheet.hdr == true
                  ? ((formData.specSheet.receiverCardModelHdr == null) || (formData.specSheet.receiverCardModelHdr == 'NA') || (formData.specSheet.receiverCardModelHdr == " ") || (formData.specSheet.receiverCardModelHdr == ""))
                    ? "--"
                    : formData.specSheet.receiverCardModelHdr
                  : ((formData.specSheet.receiverCardModelNonHdr == null) || (formData.specSheet.receiverCardModelNonHdr == 'NA') || (formData.specSheet.receiverCardModelNonHdr == " ") || (formData.specSheet.receiverCardModelNonHdr == ""))
                    ? "--"
                    : formData.specSheet.receiverCardModelNonHdr}</td>
                <td colSpan={1}></td>
              </tr>
              {/* <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>33. </td>
                <td style={{ fontWeight: "bold" }}>Receiver Card Part Number</td>
                <td>{formData == null ? "--" : formData.specSheet.hdr == true
                  ? ((formData.specSheet.receiverCardPartNumberHdr == null) || (formData.specSheet.receiverCardPartNumberHdr == 'NA') || (formData.specSheet.receiverCardPartNumberHdr == " ") || (formData.specSheet.receiverCardPartNumberHdr == ""))
                    ? "--"
                    : formData.specSheet.receiverCardPartNumberHdr
                  : ((formData.specSheet.receiverCardPartNumberNonHdr == null) || (formData.specSheet.receiverCardPartNumberNonHdr == 'NA') || (formData.specSheet.receiverCardPartNumberNonHdr == " ") || (formData.specSheet.receiverCardPartNumberNonHdr == ""))
                    ? "--"
                    : formData.specSheet.receiverCardPartNumberNonHdr}</td>
                <td colSpan={1}></td>
              </tr> */}
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Hub Card Model</td>
                <td>{formData == null ? "--" : ((formData.specSheet.hubCardModel == null) || (formData.specSheet.hubCardModel == 'NA') || (formData.specSheet.hubCardModel == " ") || (formData.specSheet.hubCardModel == "")) ? "--" : formData.specSheet.hubCardModel}</td>
                <td colSpan={1}></td>
              </tr>
              {/* <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>35. </td>
                <td style={{ fontWeight: "bold" }}>Hub Card Part Number</td>
                <td>{formData == null ? "--" : ((formData.specSheet.hubCardPartNumber == null) || (formData.specSheet.hubCardPartNumber == 'NA') || (formData.specSheet.hubCardPartNumber == " ") || (formData.specSheet.hubCardPartNumber == "")) ? "--" : formData.specSheet.hubCardPartNumber}</td>
                <td colSpan={1}></td>
              </tr> */}
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Ventilation Fans Details</td>
                <td>{formData == null ? "--" : ((formData.specSheet.ventilationFanDetails == null) || (formData.specSheet.ventilationFanDetails == 'NA') || (formData.specSheet.ventilationFanDetails == " ") || (formData.specSheet.ventilationFanDetails == "")) ? "--" : formData.specSheet.ventilationFanDetails}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>XM Logo & BIS Logo Location on Cabinet</td>
                <td>Print on backside of the cabinet</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>BIS Model No.</td>
                <td>{formData == null ? "--" : ((formData.specSheet.bisModelNo == null) || (formData.specSheet.bisModelNo == 'NA') || (formData.specSheet.bisModelNo == " ") || (formData.specSheet.bisModelNo == "")) ? "--" : formData.specSheet.bisModelNo}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Module Size (LxH)</td>
                <td>{formData == null ? "--" : formData.specSheet.moduleSize == null ? "--" : formData.specSheet.moduleSize}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Cabinet size (LxHxD)</td>
                <td>{formData == null ? "--" : formData.specSheet.cabinetSize == null ? "--" : formData.specSheet.cabinetSize}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Cabinet Matrix (L x H)</td>
                <td>{formData == null ? "--" : ((VEGA_VEGA_PLUS_SERIES.includes(formData.specSheet.series) && formData.specSheet.pixelPitch !== "P1.95mm") ? formData.specSheet.vegaCabinetMatrix : formData.specSheet.cabinetMatrix)}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Number of Cabinet(s)</td>
                <td>{formData == null ? "--" : ((VEGA_VEGA_PLUS_SERIES.includes(formData.specSheet.series) && formData.specSheet.pixelPitch !== "P1.95mm") ? formData.specSheet.vegaNoOfCabinets : formData.specSheet.noOfCabinets)}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Number of Module(s)</td>
                <td>{formData == null ? "--" : ((VEGA_VEGA_PLUS_SERIES.includes(formData.specSheet.series) && formData.specSheet.pixelPitch !== "P1.95mm") ? formData.specSheet.vegaNoOfModules : formData.specSheet.noOfModules)}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Mounting Holes</td>
                <td>{formData == null ? "--" : ((formData.specSheet.mountingHoles == null) || (formData.specSheet.mountingHoles == 'NA') || (formData.specSheet.mountingHoles == " ") || (formData.specSheet.mountingHoles == "")) ? "--" : formData.specSheet.mountingHoles}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Mounting Plates dimension from Edges</td>
                <td>{formData == null ? "--" : ((formData.specSheet.mountingPlatesDimensionFromEdge == null) || (formData.specSheet.mountingPlatesDimensionFromEdge == 'NA') || (formData.specSheet.mountingPlatesDimensionFromEdge == " ") || (formData.specSheet.mountingPlatesDimensionFromEdge == "")) ? "--" : formData.specSheet.mountingPlatesDimensionFromEdge}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Cabinet Mounting Plates Details</td>
                <td>{formData == null ? "--" : ((formData.specSheet.cabinetMountingPlateDetails == null) || (formData.specSheet.cabinetMountingPlateDetails == 'NA') || (formData.specSheet.cabinetMountingPlateDetails == " ") || (formData.specSheet.cabinetMountingPlateDetails == "")) ? "--" : formData.specSheet.cabinetMountingPlateDetails}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Module PCB Layer & Thickness </td>
                <td>{formData == null ? "--" : ((formData.specSheet.modulePCBLayerAndThickness == null) || (formData.specSheet.modulePCBLayerAndThickness == 'NA') || (formData.specSheet.modulePCBLayerAndThickness == " ") || (formData.specSheet.modulePCBLayerAndThickness == "")) ? "--" : formData.specSheet.modulePCBLayerAndThickness}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Module PCB Part Number </td>
                <td>{formData == null ? "--" : ((formData.specSheet.modulePCBPartNumber == null) || (formData.specSheet.modulePCBPartNumber == 'NA') || (formData.specSheet.modulePCBPartNumber == " ") || (formData.specSheet.modulePCBPartNumber == "")) ? "--" : formData.specSheet.modulePCBPartNumber}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Packing </td>
                <td>{formData == null ? "--" : ((formData.specSheet.packing == null) || (formData.specSheet.packing == 'NA') || (formData.specSheet.packing == " ") || (formData.specSheet.packing == "")) ? "--" : formData.specSheet.packing}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Grayscale </td>
                <td>{formData == null ? "--" : ((formData.specSheet.grayScale == null) || (formData.specSheet.grayScale == 'NA') || (formData.specSheet.grayScale == " ") || (formData.specSheet.grayScale == "")) ? "--" : formData.specSheet.grayScale}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>LED Controller</td>
                <td>{formData == null ? "--" : formData.specSheet.ledController == null ? "--" : formData.specSheet.ledController}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>PDU</td>
                <td>{formData == null ? "--" : formData.specSheet.pdu == true ? "Yes" : "Not Required"}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Accessories </td>
                <td>{formData == null ? "--" : formData.specSheet.accessories == "" ? "Not Required" : formData.specSheet.accessories}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }}>Media Player</td>
                <td>{formData == null ? "--" : formData.specSheet.mediaPlayer == null ? "--" : formData.specSheet.mediaPlayer}</td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <th className="group-section no-border-bottom" section-name='spares' section-rowspan={20} style={{ borderTop: "solid 1px #e14504", borderWidth: "1px 1px", textAlign: "center" }}>
                  Spares
                </th>
                <th></th>
                <th>Material / Details</th>
                <th>Description</th>
                <th>Total</th>
                <th>Client</th>
                <th>Xtreme</th>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Power Supply</td>
                <td>{formData == null ? "--" : ((formData.specSheet.powerSupplyMake == null) || (formData.specSheet.powerSupplyMake == 'NA') || (formData.specSheet.powerSupplyMake == " ") || (formData.specSheet.powerSupplyMake == "")) ? "--" : formData.specSheet.powerSupplyMake}</td>
                <td>{formData == null ? "--" : formData.specSheet.totalPowerSupplySpare == null ? 0 : formData.specSheet.totalPowerSupplySpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.clientPowerSupplySpare == null ? 0 : formData.specSheet.clientPowerSupplySpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmPowerSupplySpare == null ? 0 : formData.specSheet.xmPowerSupplySpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Receiver Card</td>
                <td>{formData == null ? "--" : formData.specSheet.hdr == true
                  ? ((formData.specSheet.receiverCardModelHdr == null) || (formData.specSheet.receiverCardModelHdr == 'NA') || (formData.specSheet.receiverCardModelHdr == " ") || (formData.specSheet.receiverCardModelHdr == ""))
                    ? "--"
                    : formData.specSheet.receiverCardModelHdr
                  : ((formData.specSheet.receiverCardModelNonHdr == null) || (formData.specSheet.receiverCardModelNonHdr == 'NA') || (formData.specSheet.receiverCardModelNonHdr == " ") || (formData.specSheet.receiverCardModelNonHdr == ""))
                    ? "--" : formData.specSheet.receiverCardModelNonHdr}</td>
                <td>{formData == null ? "--" : formData.specSheet.totalReceiverCardSpare == null ? 0 : formData.specSheet.totalReceiverCardSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.clientReceiverCardSpare == null ? 0 : formData.specSheet.clientReceiverCardSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmReceiverCardSpare == null ? 0 : formData.specSheet.xmReceiverCardSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Transmission Card / Hub-A Card</td>
                <td>{formData == null ? "--" : ((formData.specSheet.transmissionCardModel == null) || (formData.specSheet.transmissionCardModel == 'NA') || (formData.specSheet.transmissionCardModel == " ") || (formData.specSheet.transmissionCardModel == "")) ? "--" : formData.specSheet.transmissionCardModel}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.totalTransmissionCardSpare == null) || (formData.specSheet.totalTransmissionCardSpare == 'NA') || (formData.specSheet.totalTransmissionCardSpare == " ") || (formData.specSheet.totalTransmissionCardSpare == "")) ? 0 : formData.specSheet.totalTransmissionCardSpare}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.clientTransmissionCardSpare == null) || (formData.specSheet.clientTransmissionCardSpare == 'NA') || (formData.specSheet.clientTransmissionCardSpare == " ") || (formData.specSheet.clientTransmissionCardSpare == "")) ? 0 : formData.specSheet.clientTransmissionCardSpare}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.xmTransmissionCardSpare == null) || (formData.specSheet.xmTransmissionCardSpare == 'NA') || (formData.specSheet.xmTransmissionCardSpare == " ") || (formData.specSheet.xmTransmissionCardSpare == "")) ? 0 : formData.specSheet.xmTransmissionCardSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Hub Card / Hub-B Card</td>
                <td>{formData == null ? "--" : ((formData.specSheet.hubCardModel == null) || (formData.specSheet.hubCardModel == 'NA') || (formData.specSheet.hubCardModel == " ") || (formData.specSheet.hubCardModel == "")) ? "--" : formData.specSheet.hubCardModel}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.totalHubCardSpare == null) || (formData.specSheet.totalHubCardSpare == 'NA') || (formData.specSheet.totalHubCardSpare == " ") || (formData.specSheet.totalHubCardSpare == "")) ? 0 : formData.specSheet.totalHubCardSpare}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.clientHubCardSpare == null) || (formData.specSheet.clientHubCardSpare == 'NA') || (formData.specSheet.clientHubCardSpare == " ") || (formData.specSheet.clientHubCardSpare == "")) ? 0 : formData.specSheet.clientHubCardSpare}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.xmHubCardSpare == null) || (formData.specSheet.xmHubCardSpare == 'NA') || (formData.specSheet.xmHubCardSpare == " ") || (formData.specSheet.xmHubCardSpare == "")) ? 0 : formData.specSheet.xmHubCardSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >LED Module</td>
                <td>{formData == null ? "--" : formData.specSheet.pixelPitch == null ? "--" : formData.specSheet.pixelPitch}</td>
                <td>{formData == null ? "--" : formData.specSheet.totalModuleSpare == null ? 0 : formData.specSheet.totalModuleSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.clientModuleSpare == null ? 0 : formData.specSheet.clientModuleSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmModuleSpare == null ? 0 : formData.specSheet.xmModuleSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Main FRC Cable</td>
                <td>{formData == null ? "--" : ((formData.specSheet.mainFrcCable == null) || (formData.specSheet.mainFrcCable == 'NA') || (formData.specSheet.mainFrcCable == " ") || (formData.specSheet.mainFrcCable == "")) ? "--" : formData.specSheet.mainFrcCable}</td>
                <td>{formData == null ? "--" : formData.specSheet.totalMainFrcCableSpare == null ? 0 : formData.specSheet.totalMainFrcCableSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.clientMainFrcCableSpare == null ? 0 : formData.specSheet.clientMainFrcCableSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmMainFrcCableSpare == null ? 0 : formData.specSheet.xmMainFrcCableSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Loop FRC Cable</td>
                <td>{formData == null ? "--" : ((formData.specSheet.loopFrcCable == null) || (formData.specSheet.loopFrcCable == 'NA') || (formData.specSheet.loopFrcCable == " ") || (formData.specSheet.loopFrcCable == "")) ? "--" : formData.specSheet.loopFrcCable}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.totalLoopFrcCableSpare == null) || (formData.specSheet.totalLoopFrcCableSpare == 'NA') || (formData.specSheet.totalLoopFrcCableSpare == " ") || (formData.specSheet.totalLoopFrcCableSpare == "")) ? 0 : formData.specSheet.totalLoopFrcCableSpare}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.clientLoopFrcCableSpare == null) || (formData.specSheet.clientLoopFrcCableSpare == 'NA') || (formData.specSheet.clientLoopFrcCableSpare == " ") || (formData.specSheet.clientLoopFrcCableSpare == "")) ? 0 : formData.specSheet.clientLoopFrcCableSpare}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.xmLoopFrcCableSpare == null) || (formData.specSheet.xmLoopFrcCableSpare == 'NA') || (formData.specSheet.xmLoopFrcCableSpare == " ") || (formData.specSheet.xmLoopFrcCableSpare == "")) ? 0 : formData.specSheet.xmLoopFrcCableSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Loop Power Cable / Connector</td>
                <td>{formData == null ? "--" : ((formData.specSheet.loopPowerCableConnector == null) || (formData.specSheet.loopPowerCableConnector == 'NA') || (formData.specSheet.loopPowerCableConnector == " ") || (formData.specSheet.loopPowerCableConnector == "")) ? "--" : formData.specSheet.loopPowerCableConnector}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.totLPower == null) || (formData.specSheet.totLPower == 'NA') || (formData.specSheet.totLPower == " ") || (formData.specSheet.totLPower == "")) ? 0 : formData.specSheet.totLPower}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.cLPower == null) || (formData.specSheet.cLPower == 'NA') || (formData.specSheet.cLPower == " ") || (formData.specSheet.cLPower == "")) ? 0 : formData.specSheet.cLPower}</td>
                <td>{formData == null ? "--" : ((formData.specSheet.xLPower == null) || (formData.specSheet.xLPower == 'NA') || (formData.specSheet.xLPower == " ") || (formData.specSheet.xLPower == "")) ? 0 : formData.specSheet.xLPower}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Loop Data Cable / Connector</td>
                <td>{formData == null ? "--" : ((formData.specSheet.loopDataCableConnector == null) || (formData.specSheet.loopDataCableConnector == 'NA') || (formData.specSheet.loopDataCableConnector == " ") || (formData.specSheet.loopDataCableConnector == "")) ? "--" : formData.specSheet.loopDataCableConnector}</td>
                <td>{formData == null ? "--" : formData.specSheet.totLData == null ? 0 : formData.specSheet.totLData}</td>
                <td>{formData == null ? "--" : formData.specSheet.cLData == null ? 0 : formData.specSheet.cLData}</td>
                <td>{formData == null ? "--" : formData.specSheet.xLData == null ? 0 : formData.specSheet.xLData}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >5 Volt Connector Cable</td>
                <td>{formData == null ? "--" : ((formData.specSheet.fiveVConnectorCable == null) || (formData.specSheet.fiveVConnectorCable == 'NA') || (formData.specSheet.fiveVConnectorCable == " ") || (formData.specSheet.fiveVConnectorCable == "")) ? "--" : formData.specSheet.fiveVConnectorCable}</td>
                <td>{formData == null ? "--" : formData.specSheet.total5VConnectorCableSpare == null ? 0 : formData.specSheet.total5VConnectorCableSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.client5VConnectorCableSpare == null ? 0 : formData.specSheet.client5VConnectorCableSpare}</td>
                <td>{formData == null ? "--" : formData.specSheet.xm5VConnectorCableSpare == null ? 0 : formData.specSheet.xm5VConnectorCableSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Module Mask (Mesh)</td>
                <td>{formData == null ? "--" : ((formData.specSheet.moduleMask == null) || (formData.specSheet.moduleMask == 'NA') || (formData.specSheet.moduleMask == " ") || (formData.specSheet.moduleMask == "")) ? "--" : formData.specSheet.moduleMask}</td>
                <td>{formData == null ? "--" : formData.specSheet.totalModuleMaskSpare == null ? 0 : formData.specSheet.totalModuleMaskSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmModuleMaskSpare == null ? 0 : formData.specSheet.xmModuleMaskSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Module Mask Comb</td>
                <td>{formData == null ? "--" : ((formData.specSheet.moduleMaskComb == null) || (formData.specSheet.moduleMaskComb == 'NA') || (formData.specSheet.moduleMaskComb == " ") || (formData.specSheet.moduleMaskComb == "")) ? "--" : formData.specSheet.moduleMaskComb}</td>
                <td>{formData == null ? "--" : formData.specSheet.totalModuleMaskCombSpare == null ? 0 : formData.specSheet.totalModuleMaskCombSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmModuleMaskCombSpare == null ? 0 : formData.specSheet.xmModuleMaskCombSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Flexible Repairing PCB</td>
                <td>{formData == null ? "--" : ((formData.specSheet.flexibleRepairingPcb == null) || (formData.specSheet.flexibleRepairingPcb == 'NA') || (formData.specSheet.flexibleRepairingPcb == " ") || (formData.specSheet.flexibleRepairingPcb == "")) ? "--" : formData.specSheet.flexibleRepairingPcb}</td>
                <td>{formData == null ? "--" : formData.specSheet.totalRepairingPcbSpare == null ? 0 : formData.specSheet.totalRepairingPcbSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmRepairingPcbSpare == null ? 0 : formData.specSheet.xmRepairingPcbSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >LED Lamps</td>
                <td>{formData == null ? "--" : ((formData.specSheet.ledType == null) || (formData.specSheet.ledType == 'NA') || (formData.specSheet.ledType == " ") || (formData.specSheet.ledType == "")) ? "--" : formData.specSheet.ledType}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmPixelSpare == null ? 0 : formData.specSheet.xmPixelSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmPixelSpare == null ? 0 : formData.specSheet.xmPixelSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Driving ICs</td>
                <td>{formData == null ? "--" : ((formData.specSheet.drivingIC == null) || (formData.specSheet.drivingIC == 'NA') || (formData.specSheet.drivingIC == " ") || (formData.specSheet.drivingIC == "")) ? "--" : formData.specSheet.drivingIC}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmDrivingIcSpare == null ? 0 : formData.specSheet.xmDrivingIcSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmDrivingIcSpare == null ? 0 : formData.specSheet.xmDrivingIcSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Power ICs</td>
                <td>{formData == null ? "--" : ((formData.specSheet.powerIc == null) || (formData.specSheet.powerIc == 'NA') || (formData.specSheet.powerIc == " ") || (formData.specSheet.powerIc == "")) ? "--" : formData.specSheet.powerIc}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmPowerIcSpare == null ? 0 : formData.specSheet.xmPowerIcSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmPowerIcSpare == null ? 0 : formData.specSheet.xmPowerIcSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Decoder ICs</td>
                <td>{formData == null ? "--" : ((formData.specSheet.decoderIc == null) || (formData.specSheet.decoderIc == 'NA') || (formData.specSheet.decoderIc == " ") || (formData.specSheet.decoderIc == "")) ? "--" : formData.specSheet.decoderIc}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmDecoderIcSpare == null ? 0 : formData.specSheet.xmDecoderIcSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmDecoderIcSpare == null ? 0 : formData.specSheet.xmDecoderIcSpare}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Resistors</td>
                <td>--</td>
                <td>50</td>
                <td>0</td>
                <td>50</td>
              </tr>
              <tr>
                <td></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Module Remover</td>
                <td>{formData == null ? "--" : ((formData.specSheet.moduleRemover == null) || (formData.specSheet.moduleRemover == 'NA') || (formData.specSheet.moduleRemover == " ") || (formData.specSheet.moduleRemover == "")) ? "--" : formData.specSheet.moduleRemover}</td>
                <td>{formData == null ? "--" : formData.specSheet.xmModuleRemoverSpare == null ? 0 : formData.specSheet.xmModuleRemoverSpare}</td>
                <td>0</td>
                <td>{formData == null ? "--" : formData.specSheet.xmModuleRemoverSpare == null ? 0 : formData.specSheet.xmModuleRemoverSpare}</td>
              </tr>
              <tr>
                <th className="group-section no-border-bottom" section-name='accessories' section-rowspan={25} style={{ borderWidth: "1px 1px", textAlign: "center", borderTop: "solid 1px #e14504" }}>
                  Accessories
                </th>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Main Power Cables</td>
                <td>{formData == null ? "--" : ((formData.specSheet.mainPowerCable == null) || (formData.specSheet.mainPowerCable == 'NA') || (formData.specSheet.mainPowerCable == " ") || (formData.specSheet.mainPowerCable == "")) ? "--" : formData.specSheet.mainPowerCable}</td>
                <td>{formData == null ? "--" : powerLoopingCables}</td>
                <td>{formData == null ? "--" : powerLoopingCables}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Loop Power Cables</td>
                <td>{formData == null ? "--" : ((formData.specSheet.loopPowerCable == null) || (formData.specSheet.loopPowerCable == 'NA') || (formData.specSheet.loopPowerCable == " ") || (formData.specSheet.loopPowerCable == "")) ? "--" : formData.specSheet.loopPowerCable}</td>
                <td>{formData == null ? "--" : (formData.specSheet.totalCabinets - powerLoopingCables)}</td>
                <td>{formData == null ? "--" : (formData.specSheet.totalCabinets - powerLoopingCables)}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Main Data Cables</td>
                <td>{formData == null ? "--" : ((formData.specSheet.mainDataCable == null) || (formData.specSheet.mainDataCable == 'NA') || (formData.specSheet.mainDataCable == " ") || (formData.specSheet.mainDataCable == "")) ? "--" : formData.specSheet.mainDataCable}</td>
                <td>{formData === null ? "--" : dataLoopingCables}</td>
                <td>{formData === null ? "--" : dataLoopingCables}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Loop Data Cables (Small)</td>
                <td>{formData == null ? "--" : ((formData.specSheet.loopDataCablesSmall == null) || (formData.specSheet.loopDataCablesSmall == 'NA') || (formData.specSheet.loopDataCablesSmall == " ") || (formData.specSheet.loopDataCablesSmall == "")) ? "--" : formData.specSheet.loopDataCablesSmall}</td>
                <td>{formData === null ? "--" : formData.specSheet.dataBackup == true ? ((formData.specSheet.totalCabinets*2) - dataLoopingCables) : (formData.specSheet.totalCabinets - dataLoopingCables)}</td>
                <td>{formData === null ? "--" : formData.specSheet.dataBackup == true ? ((formData.specSheet.totalCabinets*2) - dataLoopingCables) : (formData.specSheet.totalCabinets - dataLoopingCables)}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Loop Data Cables (Medium)</td>
                <td>{formData == null ? "--" : ((formData.specSheet.loopDataCablesMedium == null) || (formData.specSheet.loopDataCablesMedium == 'NA') || (formData.specSheet.loopDataCablesMedium == " ") || (formData.specSheet.loopDataCablesMedium == "")) ? "--" : formData.specSheet.loopDataCablesMedium}</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Main FRC Cables</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.mainFrcCable == null) || (formData.specSheet.mainFrcCable == 'NA') || (formData.specSheet.mainFrcCable == " ") || (formData.specSheet.mainFrcCable == "")) ? "--" : formData.specSheet.mainFrcCable) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.totMainFrc == null) || (formData.specSheet.totMainFrc == 'NA') || (formData.specSheet.totMainFrc == " ") || (formData.specSheet.totMainFrc == "")) ? 0 : formData.specSheet.totMainFrc) : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.cMainFrc == null) || (formData.specSheet.cMainFrc == 'NA') || (formData.specSheet.cMainFrc == " ") || (formData.specSheet.cMainFrc == "")) ? 0 : formData.specSheet.cMainFrc) : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Loop FRC Cables</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.loopFrcCable == null) || (formData.specSheet.loopFrcCable == 'NA') || (formData.specSheet.loopFrcCable == " ") || (formData.specSheet.loopFrcCable == "")) ? "--" : formData.specSheet.loopFrcCable) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.totLoopFrc == null) || (formData.specSheet.totLoopFrc == 'NA') || (formData.specSheet.totLoopFrc == " ") || (formData.specSheet.totLoopFrc == "")) ? 0 : formData.specSheet.totLoopFrc) : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.cLoopFrc == null) || (formData.specSheet.cLoopFrc == 'NA') || (formData.specSheet.cLoopFrc == " ") || (formData.specSheet.cLoopFrc == "")) ? 0 : formData.specSheet.cLoopFrc) : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >5V Connector Cables</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.fiveVConnectorCable == null) || (formData.specSheet.fiveVConnectorCable == 'NA') || (formData.specSheet.fiveVConnectorCable == " ") || (formData.specSheet.fiveVConnectorCable == "")) ? "--" : formData.specSheet.fiveVConnectorCable) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.tot5VSpare == null) || (formData.specSheet.tot5VSpare == 'NA') || (formData.specSheet.tot5VSpare == " ") || (formData.specSheet.tot5VSpare == "")) ? 0 : formData.specSheet.tot5VSpare) : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.accC5VSpare == null) || (formData.specSheet.accC5VSpare == 'NA') || (formData.specSheet.accC5VSpare == " ") || (formData.specSheet.accC5VSpare == "")) ? 0 : formData.specSheet.accC5VSpare) : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Horizontal Interlock Bolts + Nuts</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? "--" : ((formData.specSheet.horizontalInterlockBoltsNuts == null) || (formData.specSheet.horizontalInterlockBoltsNuts == 'NA') || (formData.specSheet.horizontalInterlockBoltsNuts == " ") || (formData.specSheet.horizontalInterlockBoltsNuts == "")) ? "--" : formData.specSheet.horizontalInterlockBoltsNuts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.totHBolts == null) || (formData.specSheet.totHBolts == 'NA') || (formData.specSheet.totHBolts == " ") || (formData.specSheet.totHBolts == "")) ? 0 : formData.specSheet.totHBolts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.cHBolts == null) || (formData.specSheet.cHBolts == 'NA') || (formData.specSheet.cHBolts == " ") || (formData.specSheet.cHBolts == "")) ? 0 : formData.specSheet.cHBolts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.xmHBolts == null) || (formData.specSheet.xmHBolts == 'NA') || (formData.specSheet.xmHBolts == " ") || (formData.specSheet.xmHBolts == "")) ? 0 : formData.specSheet.xmHBolts}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Vertical Interlock Bolts + Nuts</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? "--" : ((formData.specSheet.verticalInterlockBoltsNuts == null) || (formData.specSheet.verticalInterlockBoltsNuts == 'NA') || (formData.specSheet.verticalInterlockBoltsNuts == " ") || (formData.specSheet.verticalInterlockBoltsNuts == "")) ? "--" : formData.specSheet.verticalInterlockBoltsNuts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.totVBolts == null) || (formData.specSheet.totVBolts == 'NA') || (formData.specSheet.totVBolts == " ") || (formData.specSheet.totVBolts == "")) ? 0 : formData.specSheet.totVBolts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.cVBolts == null) || (formData.specSheet.cVBolts == 'NA') || (formData.specSheet.cVBolts == " ") || (formData.specSheet.cVBolts == "")) ? 0 : formData.specSheet.cVBolts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.xmVBolts == null) || (formData.specSheet.xmVBolts == 'NA') || (formData.specSheet.xmVBolts == " ") || (formData.specSheet.xmVBolts == "")) ? 0 : formData.specSheet.xmVBolts}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Cabinet Mounting Iron Plates</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? "--" : ((formData.specSheet.cabinetMountingIronPlates == null) || (formData.specSheet.cabinetMountingIronPlates == 'NA') || (formData.specSheet.cabinetMountingIronPlates == " ") || (formData.specSheet.cabinetMountingIronPlates == "")) ? "--" : formData.specSheet.cabinetMountingIronPlates}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.totCCabPlates == null) || (formData.specSheet.totCCabPlates == 'NA') || (formData.specSheet.totCCabPlates == " ") || (formData.specSheet.totCCabPlates == "")) ? 0 : formData.specSheet.totCCabPlates}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.cCabPlates == null) || (formData.specSheet.cCabPlates == 'NA') || (formData.specSheet.cCabPlates == " ") || (formData.specSheet.cCabPlates == "")) ? 0 : formData.specSheet.cCabPlates}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Cabinet Mounting Bolts (Structure)</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? "--" : ((formData.specSheet.cabinetMountingBolts == null) || (formData.specSheet.cabinetMountingBolts == 'NA') || (formData.specSheet.cabinetMountingBolts == " ") || (formData.specSheet.cabinetMountingBolts == "")) ? "--" : formData.specSheet.cabinetMountingBolts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.totCabBolts == null) || (formData.specSheet.totCabBolts == 'NA') || (formData.specSheet.totCabBolts == " ") || (formData.specSheet.totCabBolts == "")) ? 0 : formData.specSheet.totCabBolts}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.accCCabBolts == null) || (formData.specSheet.accCCabBolts == 'NA') || (formData.specSheet.accCCabBolts == " ") || (formData.specSheet.accCCabBolts == "")) ? 0 : formData.specSheet.accCCabBolts}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Wall Mount Frame</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? "--" : (["Ace", "LIT", "LIT COB"].includes(formData.specSheet.series) ? wallMountDesc : "--")}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : (["Ace", "LIT", "LIT COB"].includes(formData.specSheet.series) ? cabinetFitColumns : 0)}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : (["Ace", "LIT", "LIT COB"].includes(formData.specSheet.series) ? cabinetFitColumns : 0)}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Cabinet Hanging Bolts</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? "--" : (["Ace", "LIT", "LIT COB"].includes(formData.specSheet.series) ? cabinetHangingDesc : "--")}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : (["Ace", "LIT", "LIT COB"].includes(formData.specSheet.series) ? cabinetHangingQuantity : 0)}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : (["Ace", "LIT", "LIT COB"].includes(formData.specSheet.series) ? cabinetHangingQuantity : 0)}</td>
                <td>{formData == null ? "--" : 0}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Self Threaded Screws (Ply mount)</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? "--" : ((formData.specSheet.selfThreadedScrews == null) || (formData.specSheet.selfThreadedScrews == 'NA') || (formData.specSheet.selfThreadedScrews == " ") || (formData.specSheet.selfThreadedScrews == "")) ? "--" : formData.specSheet.selfThreadedScrews}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.totScrew == null) || (formData.specSheet.totScrew == 'NA') || (formData.specSheet.totScrew == " ") || (formData.specSheet.totScrew == "")) ? 0 : formData.specSheet.totScrew}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? 0 : ((formData.specSheet.cScrew == null) || (formData.specSheet.cScrew == 'NA') || (formData.specSheet.cScrew == " ") || (formData.specSheet.cScrew == "")) ? 0 : formData.specSheet.cScrew}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >SMPS to SMPS Loop Cables</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.smpsToSmpsLoopCables == null) || (formData.specSheet.smpsToSmpsLoopCables == 'NA') || (formData.specSheet.smpsToSmpsLoopCables == " ") || (formData.specSheet.smpsToSmpsLoopCables == "")) ? "--" : formData.specSheet.smpsToSmpsLoopCables) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? ((formData.specSheet.cLSmps - powerLoopingCables) * formData.specSheet.quantity) : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? ((formData.specSheet.cLSmps - powerLoopingCables) * formData.specSheet.quantity) : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >SMPS with Plate</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.smpsWithPlate == null) || (formData.specSheet.smpsWithPlate == 'NA') || (formData.specSheet.smpsWithPlate == " ") || (formData.specSheet.smpsWithPlate == "")) ? "--" : formData.specSheet.smpsWithPlate) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.totSmpsPlates : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.cSmpsPlates : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >SMPS to Receiver Power </td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.smpsToReceiverPower == null) || (formData.specSheet.smpsToReceiverPower == 'NA') || (formData.specSheet.smpsToReceiverPower == " ") || (formData.specSheet.smpsToReceiverPower == "")) ? "--" : formData.specSheet.smpsToReceiverPower) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.totSmpsRc : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.cSmpsRc : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >SMPS Fitting Screw</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.smpsFittingScrew == null) || (formData.specSheet.smpsFittingScrew == 'NA') || (formData.specSheet.smpsFittingScrew == " ") || (formData.specSheet.smpsFittingScrew == "")) ? "--" : formData.specSheet.smpsFittingScrew) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.totSmpsScrew : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.cSmpsScrew : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >SMPS Plate Fitting Screw</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.smpsPlateFittingScrew == null) || (formData.specSheet.smpsPlateFittingScrew == 'NA') || (formData.specSheet.smpsPlateFittingScrew == " ") || (formData.specSheet.smpsPlateFittingScrew == "")) ? "--" : formData.specSheet.smpsPlateFittingScrew) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.totPlateScrew : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.cPlateScrew : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >RC to RC Loop Data </td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.lRCDesc == null) || (formData.specSheet.lRCDesc == 'NA') || (formData.specSheet.lRCDesc == " ") || (formData.specSheet.lRCDesc == "")) ? "--" : formData.specSheet.lRCDesc) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? ((cabinetsPerScreen * receiverCardPerCabinet) - dataLoopingCables * formData.specSheet.quantity) : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? ((cabinetsPerScreen * receiverCardPerCabinet) - dataLoopingCables * formData.specSheet.quantity) : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Receiver Card with Plate</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.rcPlateDesc == null) || (formData.specSheet.rcPlateDesc == 'NA') || (formData.specSheet.rcPlateDesc == " ") || (formData.specSheet.rcPlateDesc == "")) ? "--" : formData.specSheet.rcPlateDesc) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.totRcPlate : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.cRcPlate : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Receiver Card Fitting Screws</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.rcScrewsDesc == null) || (formData.specSheet.rcScrewsDesc == 'NA') || (formData.specSheet.rcScrewsDesc == " ") || (formData.specSheet.rcScrewsDesc == "")) ? "--" : formData.specSheet.rcScrewsDesc) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.totRcScrews : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.cRcScrews : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Receiver Card Plate Fitting Screw</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.rcPScrewsDesc == null) || (formData.specSheet.rcPScrewsDesc == 'NA') || (formData.specSheet.rcPScrewsDesc == " ") || (formData.specSheet.rcPScrewsDesc == "")) ? "--" : formData.specSheet.rcPScrewsDesc) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.totRcPScrews : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? formData.specSheet.cRcPScrews : 0}</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="" style={{ borderWidth: "1px 1px" }}></td>
                <td>{++srno}. </td>
                <td style={{ fontWeight: "bold" }} >Piri Terminal</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (((formData.specSheet.piriTerminal == null) || (formData.specSheet.piriTerminal == 'NA') || (formData.specSheet.piriTerminal == " ") || (formData.specSheet.piriTerminal == "")) ? "--" : formData.specSheet.piriTerminal) : "--"}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (powerLoopingCables * formData.specSheet.quantity) : 0}</td>
                <td>{formData == null ? "--" : formData.specSheet.series === "Nyx Modular" ? (powerLoopingCables * formData.specSheet.quantity) : 0}</td>
                <td>0</td>
              </tr>

              <tr>
                <th className="group-section no-border-bottom" section-name='aging' section-rowspan={6} style={{ borderWidth: "1px 1px", textAlign: "center", borderTop: "solid 1px #e14504" }}>Ageing Details</th>
                <th className="ageing-header-row" colSpan={4}>Standard Ageing Guidelines</th>
                <td colSpan={2} rowSpan={6}></td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>1.</td>
                <td className="ageing-detail-row" colSpan={3}>{formData == null ? "--" : (formData.specSheet.ageingCheck == false ? formData.specSheet.newProd1 : formData.specSheet.warehouse1)}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>2.</td>
                <td className="ageing-detail-row" colSpan={3}>{formData == null ? "--" : (formData.specSheet.ageingCheck == false ? formData.specSheet.newProd2 : formData.specSheet.warehouse2)}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>3.</td>
                <td className="ageing-detail-row" colSpan={3}>{formData == null ? "--" : (formData.specSheet.ageingCheck == false ? formData.specSheet.newProd3 : formData.specSheet.warehouse3)}</td>
              </tr>
              <tr>
                <td className="no-border-bottom" style={{ borderWidth: "1px 1px" }}></td>
                <td>4.</td>
                <td className="ageing-detail-row" colSpan={3}>{formData == null ? "--" : (formData.specSheet.ageingCheck == false ? formData.specSheet.newProd4 : formData.specSheet.warehouse4)}</td>
              </tr>
              <tr>
                <td className="" style={{ borderWidth: "1px 1px" }}></td>
                <td>5.</td>
                <td className="ageing-detail-row" colSpan={3}>{formData == null ? "--" : (formData.specSheet.ageingCheck == false ? formData.specSheet.newProd5 : formData.specSheet.warehouse5)}</td>
              </tr>
            </tbody>
          </table>
        </CardBody>
        <CardFooter>
          <CustomButton style={{ padding: '10px', display: 'flex', float: "right", backgroundColor: "var(--primary-color)", justifyContent: "space-between" }} onClick={exportExcelFile} >
            Export
          </CustomButton>
          <CustomButton style={{ marginRight: '10px', padding: '10px', display: 'flex', float: "right", backgroundColor: "var(--primary-color)", justifyContent: "space-between" }} type="submit">Back</CustomButton>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default memo(Specifications);
