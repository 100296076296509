import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import pot from '../../../images/environments/retail/Pot.svg';

const Pot = () => {
    const [height, setHeight] = useState(200);
    const [hide, setHide] = useState(false);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height, width } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${height * 0.7}px`);

        const potWidth = document.getElementById('retial-pot').width;
        const chairWidth = document.getElementById('retail-chair').width;
        const clothWidth = document.getElementById('retial-cloth').width;

        if ((width + 20) < (potWidth + chairWidth + clothWidth)) {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [wallHeight, wallWidth])
    return (
        <img hidden={hide} id='retial-pot' style={{ position: 'absolute', bottom: '0', right: '0', zIndex: 999 }} height={height} src={pot} alt="pot" />
    )
}

export default Pot;