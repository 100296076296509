import { useMemo } from "react";
import ace from "../../images/cabinets/ace.png";
import aceplus from "../../images/cabinets/aceplus.png";
import crystal from "../../images/cabinets/crystal.png";
import earth from "../../images/cabinets/earth.png";
import earth3in1 from "../../images/cabinets/earthdip.png";
import flex from "../../images/cabinets/flex.png";
import iris from "../../images/cabinets/iris.png";
import irisfacade from "../../images/cabinets/irisfacade.png";
import irisfaçade from "../../images/cabinets/irisfacade.png";
import irisod from "../../images/cabinets/irisod.png";
import lit from "../../images/cabinets/lit.png";
import nyx from "../../images/cabinets/nyx.png";
import nyxcob from "../../images/cabinets/nyxcob.png";
import nyxmodular from "../../images/cabinets/nyxmodular.png";
import nyxod from "../../images/cabinets/nyxod.png";
import nyxvogue from "../../images/cabinets/nyxvogue.png";
import rigelmicro from "../../images/cabinets/rigelmicro.png";
import rigelplus from "../../images/cabinets/rigelplus.png";
import unifyfhd from "../../images/cabinets/unifyfhd.png";
import vega from "../../images/cabinets/vega.png";
import vegaplus from "../../images/cabinets/vegaplus.png";
import vegastudio from "../../images/cabinets/vegastudio.png";
import arena from "../../images/cabinets/arena.png";
import arenaod from "../../images/cabinets/arenaod.png";
import orionod from "../../images/cabinets/orionod.png";
import glowbox from "../../images/cabinets/glowbox.jpg";


const CabinetImage = ({ series }) => {
    const imgSrc = useMemo(() => {
        return {
            ace,
            aceplus,
            crystal,
            earth,
            earth3in1,
            flex,
            iris,
            irisfacade,
            irisfaçade,
            irisod,
            lit,
            nyx,
            nyxcob,
            nyxmodular,
            nyxod,
            nyxvogue,
            rigelmicro,
            rigelplus,
            unifyfhd,
            vega,
            vegaplus,
            vegastudio,
            arena,
            arenaod,
            orionod,
            glowbox
        }
    }, []);

    const cabinetImage = imgSrc[series.toLowerCase().replaceAll(' ', '').replaceAll('-', '')] ? imgSrc[series.toLowerCase().replaceAll(' ', '').replaceAll('-', '')] : ace;
    return (
        <img height='25%' width='25%' src={cabinetImage} alt="Cabinet Image" />
    )
}

export default CabinetImage;