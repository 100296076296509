import { useSelector } from 'react-redux';
import PersonStanding from '../../images/person.png';
import Unit from '../common/components/Unit';
import { viewingDistanceReducer } from '../../reducers/reducers';
import { useReducer } from 'react';

const Person = () => {
    const { viewingDistance, ledCondition } = useSelector(state => {
        return {
            ledCondition: state.led.condition,
            viewingDistance: state.selectedSeries.viewingDistance
        }
    });

    const [viewingRangeState] = useReducer(viewingDistanceReducer, {
        indoorMin: 1,
        indoorMax: 10,
        outdoorMin: 2,
        outdoorMax: 50
    });

    return (
        <div style={{ position: 'absolute', bottom: '0', left: '10%', zIndex: '99' }}>
            <img src={PersonStanding} alt="Person Standing" style={{ height: '100%', width: 'auto' }} />
            {/* <div id="bottom-div"></div> */}
            <p>
                Min. Viewing Distance: <Unit value={viewingDistance == undefined ? (ledCondition == "outdoor" ? viewingRangeState.outdoorMax : viewingRangeState.indoorMax) : viewingDistance} />
            </p>
        </div>
    )
}

export default Person;