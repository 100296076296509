import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedDesign, deleteDesign } from "../../redux/reducers/designReducer";
import { Button, Row } from "reactstrap";

const DeleteDesignForm = () => {
    const { selectedIndex } = useSelector(s => {
        return {
            selectedIndex: s.design.selectedDesignIdx
        }
    })
    const dispatch = useDispatch();

    const deleteAddedDesign = () => {
        dispatch(deleteDesign({
            index: selectedIndex
        }))

        dispatch(changeSelectedDesign({
            selectedDesignIdx: 0
        }))
    }

    return (
        <>
            <Row className="mt-3 mb-3" style={{ marginRight: '0px', marginLeft: '0px', display: (selectedIndex > 0 ? 'block' : 'none') }}>
                <Button onClick={deleteAddedDesign}>Delete Design {selectedIndex + 1}</Button>
            </Row>
        </>
    )
}

export default memo(DeleteDesignForm);