import '../../css/room.css';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeWallDimensions } from '../../redux/reducers/wallReducer';
import { changeFitToCentre } from '../../redux/reducers/ledReducer';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { changeCanvasScale } from '../../redux/reducers/scaleReducer';
import CustomButton from '../common/components/CustomButtom';
import Unit from '../common/components/Unit';
import { convertMeterToFt, Toast } from '../common/Functions';
import Person from './Person';
import { ROOM_BUFFER_TOP, ROOM_BUFFER_BOTTOM, ROOM_BUFFER_LEFT, ROOM_BUFFER_RIGHT, MAX_ROOM_HEIGHT, MAX_ROOM_WIDTH, MIN_ROOM_HEIGHT, MIN_ROOM_WIDTH } from '../../config/config';
import { MAX_ROOM_HEIGHT_LIMIT_REACHED, MAX_ROOM_WIDTH_LIMIT_REACHED, MIN_ROOM_HEIGHT_LIMIT_REACHED, MIN_ROOM_WIDTH_LIMIT_REACHED, WALL_HEIGHT_SMALLER_THAN_LED, WALL_HEIGHT_SMALLER_THAN_LED_POSITION, WALL_WIDTH_SMALLER_THAN_LED, WALL_WIDTH_SMALLER_THAN_LED_POSITION } from '../common/Messages';
import TopProps from '../enviromnents/TopProps';
import BottomProps from '../enviromnents/BottomProps';
import useUpdateEffect from '../../hooks/useUpdateEffect';

const Room = ({ children }) => {
    const [maxRoomDimension, setMaxRoomDimension] = useState(0);
    const { wallHeight, wallWidth, ledRow, ledCol, cabinetHeight, cabinetWidth, distanceTop, distanceLeft, unit, ledCondition } = useSelector(s => {
        return {
            wallHeight: s.wall.height,
            wallWidth: s.wall.width,
            ledRow: s.led.row,
            ledCol: s.led.col,
            cabinetHeight: s.led.cabinetHeight,
            cabinetWidth: s.led.cabinetWidth,
            distanceTop: s.led.distanceTop,
            distanceLeft: s.led.distanceLeft,
            unit: s.wall.unit,
            ledCondition: s.led.condition
        }
    })
    const dispatch = useDispatch();
    const step = 1;

    const handleWallDimensionChange = (height, width) => {
        // console.log("===== handleWallDimensionChange ===== 1");

        if (height != wallHeight) {
            let min_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_HEIGHT) : MIN_ROOM_HEIGHT);
            let max_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_HEIGHT) : MAX_ROOM_HEIGHT);

            let buffer_top = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP) : (ROOM_BUFFER_TOP)));
            let buffer_bottom = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_BOTTOM)));
            let buffer_hgt = buffer_top + buffer_bottom;

            let screenHeight = ledRow * cabinetHeight;
            let totalScreenHeight = parseFloat(screenHeight + distanceTop);
            let heightDiff = parseFloat(totalScreenHeight - (height - buffer_hgt));

            if (height > max_room_hgt) {
                Toast('error', 'Error', MAX_ROOM_HEIGHT_LIMIT_REACHED);
                return;
            }

            if (height < min_room_hgt) {
                Toast('error', 'Error', MIN_ROOM_HEIGHT_LIMIT_REACHED);
                return;
            }

            if (height < (screenHeight + buffer_hgt) && height < wallHeight) {
                Toast('error', 'Error', WALL_HEIGHT_SMALLER_THAN_LED);
                return;
            }

            if ((totalScreenHeight > (height - buffer_bottom) && height < wallHeight && heightDiff > 0)) {
                Toast('error', 'Error', WALL_HEIGHT_SMALLER_THAN_LED_POSITION);
                return;
            }

            height = height < totalScreenHeight ? Math.ceil(totalScreenHeight) : height;
        }

        if (width != wallWidth) {
            let min_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_WIDTH) : MIN_ROOM_WIDTH);
            let max_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_WIDTH) : MAX_ROOM_WIDTH);

            let buffer_left = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT) : (ROOM_BUFFER_LEFT)));
            let buffer_right = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_RIGHT)));
            let buffer_wdt = buffer_left + buffer_right;

            let screenWidth = ledCol * cabinetWidth;
            let totalScreenWidth = parseFloat(screenWidth + distanceLeft);
            let widthDiff = parseFloat(totalScreenWidth - (width - buffer_wdt));

            if (width > max_room_wdt) {
                Toast('error', 'Error', MAX_ROOM_WIDTH_LIMIT_REACHED);
                return;
            }

            if (width < min_room_wdt) {
                Toast('error', 'Error', MIN_ROOM_WIDTH_LIMIT_REACHED);
                return;
            }

            if (width < (screenWidth + buffer_wdt) && width < wallWidth) {
                Toast('error', 'Error', WALL_WIDTH_SMALLER_THAN_LED);
                return;
            }

            if ((totalScreenWidth > (width - buffer_right)) && width < wallWidth && widthDiff > 0) {
                Toast('error', 'Error', WALL_WIDTH_SMALLER_THAN_LED_POSITION);
                return;
            }

            width = width < totalScreenWidth ? Math.ceil(totalScreenWidth) : width;
        }

        dispatch(changeWallDimensions({
            height: parseFloat(height).toFixed(2),
            width: parseFloat(width).toFixed(2)
        }))

        dispatch(changeFitToCentre({
            fitToCentre: false
        }))
    }

    const changeScale = () => {
        const wallDOMHeight = document.getElementById('wall').getBoundingClientRect().height;
        const newScale = wallDOMHeight / wallHeight;
        dispatch(changeCanvasScale({
            scale: newScale
        }))
    }

    const calculateRoomTopBottom = () => {
        try {
            const styleRules = window.getComputedStyle(document.getElementById('bottom'), "::after");
            const heightInPx = styleRules.height;
            const height = heightInPx.substring(0, heightInPx.length - 2);
            // 43 because skew is set to 47, so 90 - 47 = 43 and using basic Math
            const leftToSet = (height / 2) / Math.tan(0.0174533 * 43);
            const top = document.getElementById('top');
            const bottom = document.getElementById('bottom');

            top.style.setProperty("--room-left-before", `${leftToSet}px`);
            top.style.setProperty("--room-left-after", `${-1 * leftToSet}px`);

            bottom.style.setProperty("--room-left-before", `${leftToSet}px`);
            bottom.style.setProperty("--room-left-after", `${-1 * leftToSet}px`);
        } catch (error) {
            console.error(error);
        }
    }

    const setTopBottomWidth = (topWidth, bottomWidth) => {
        document.getElementById('top').style.width = topWidth;
        document.getElementById('bottom').style.width = bottomWidth;
    }

    const calculateWallSize = () => {
        let wHeight = parseFloat(wallHeight);
        let wWidth = parseFloat(wallWidth);

        let buffer_hgt = ledCondition == 'outdoor' ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP + ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_TOP + ROOM_BUFFER_BOTTOM)));
        let buffer_wdt = ledCondition == 'outdoor' ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT + ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_LEFT + ROOM_BUFFER_RIGHT)));
        let bufferWallWidth = wWidth - (buffer_hgt);
        let bufferWallHeight = wHeight - (buffer_wdt);

        try {
            const wallDOM = document.getElementById('wall');
            const bufferWallDOM = document.getElementById('buffer-wall');
            const { height, width } = wallDOM.getBoundingClientRect();

            // let newWidth = (wWidth * maxRoomDimension) / MAX_ROOM_WIDTH;
            // let newHeight = (wHeight * maxRoomDimension) / MAX_ROOM_HEIGHT;

            // let newWallWidth = (newWidth > maxRoomDimension ? maxRoomDimension : newWidth);
            // let newWallHeight = (newHeight > maxRoomDimension ? maxRoomDimension : newHeight);

            // let minDimension = maxRoomDimension * 0.5;
            if (wHeight === wWidth) {
                wallDOM.style.width = `${maxRoomDimension}px`
                wallDOM.style.height = `${maxRoomDimension}px`;

                let bufferHeight = (maxRoomDimension * bufferWallHeight) / wallHeight;
                bufferWallDOM.style.width = `${bufferHeight}px`;
                bufferWallDOM.style.height = `${bufferHeight}px`
                // ------------------------------------
                // wallDOM.style.width = `${(newWidth < minDimension ? minDimension : newWidth)}px`
                // wallDOM.style.height = `${(newHeight < minDimension ? minDimension : newHeight)}px`;
                // ------------------------------------
                // wallDOM.style.width = `${newWallWidth}px`
                // wallDOM.style.height = `${newWallHeight}px`;
                // ------------------------------------
                setTopBottomWidth(wallDOM.style.width, wallDOM.style.width);
                return;
            }

            if (wHeight > wWidth) {
                wallDOM.style.height = `${height}px`
                let wallWdt = (height * wWidth) / wHeight;
                wallDOM.style.width = `${wallWdt}px`;

                bufferWallDOM.style.height = `${((height * bufferWallHeight) / wallHeight)}px`
                bufferWallDOM.style.width = `${((wallWdt * bufferWallWidth) / wallWidth)}px`;
                // ------------------------------------
                // newWallHeight = (newWallHeight < minDimension ? minDimension : newWallHeight);
                // wallDOM.style.height = `${newWallHeight}px`
                // wallDOM.style.width = `${(newWallHeight * wWidth) / wHeight}px`;
                // ------------------------------------
                // wallDOM.style.height = `${newWallHeight}px`
                // wallDOM.style.width = `${newWallWidth}px`;
                // ------------------------------------
                setTopBottomWidth(wallDOM.style.width, wallDOM.style.width);
            } else {
                wallDOM.style.width = `${width}px`
                let wallHgt = (width * wallHeight) / wWidth;
                wallDOM.style.height = `${wallHgt}px`;

                bufferWallDOM.style.width = `${((width * bufferWallWidth) / wallWidth)}px`
                bufferWallDOM.style.height = `${(wallHgt * bufferWallHeight) / wallHeight}px`;
                // ------------------------------------
                // newWallWidth = (newWallWidth < minDimension ? minDimension : newWallWidth);
                // wallDOM.style.width = `${newWallWidth}px`
                // wallDOM.style.height = `${(newWallWidth * wHeight) / wWidth}px`;
                // ------------------------------------
                // wallDOM.style.width = `${newWallWidth}px`
                // wallDOM.style.height = `${newWallHeight}px`;
                // ------------------------------------
                setTopBottomWidth(`${width}px`, `${width}px`);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const { height, width } = document.getElementById('room').getBoundingClientRect();
        setMaxRoomDimension(Math.min(height, width) * 0.85);
    }, [])

    useEffect(() => {
        calculateWallSize();
        calculateRoomTopBottom();
        changeScale();
    }, [wallHeight, wallWidth, maxRoomDimension])

    useUpdateEffect(() => {
        handleWallDimensionChange(wallHeight, wallWidth)
    }, [ledRow, cabinetHeight, ledCol, cabinetWidth])

    return (
        <>
            <div id="room">
                <div id="top">
                    <TopProps />
                </div>

                <div id="wall">
                    <div style={{ position: 'relative', opacity: '1', height: '8px', top: '-1.5rem', borderLeft: '2px solid', borderRight: '2px solid', margin: '0px', color: 'inherit' }}></div>
                    <span className='d-flex align-items-center justify-content-center'
                        style={{ position: 'absolute', bottom: '100%', margin: '5px 5px 20px 5px', borderBottom: 'solid black 2px', paddingBottom: '10px', width: 'inherit', left: '-0.3rem' }}>
                        <CustomButton className='hide-pdf d-flex align-items-center justify-content-center' style={{ width: '30px', marginRight: '3px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                            onClick={() => {
                                handleWallDimensionChange(parseFloat(wallHeight), parseFloat(wallWidth) + step)
                            }} >
                            +
                        </CustomButton>
                        <Unit value={wallWidth} style={{ fontWeight: 'bold' }} />
                        <CustomButton className='hide-pdf d-flex align-items-center justify-content-center' style={{ width: '30px', marginLeft: '3px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                            onClick={() => {
                                handleWallDimensionChange(parseFloat(wallHeight), parseFloat(wallWidth) - step)
                            }}>
                            -
                        </CustomButton>
                    </span>

                    <span style={{ position: 'absolute', left: '100%', bottom: '45%', marginLeft: '20px', borderLeft: 'solid black 2px', paddingLeft: '10px', height: 'inherit', top: '0rem' }}>
                        <div style={{ position: 'absolute', opacity: '1', width: '8px', borderTop: '2px solid', borderBottom: '2px solid', margin: '0px', color: 'inherit', left: '-0.3rem', height: 'inherit' }}></div>
                        <span className='d-flex align-items-center flex-column justify-content-center' style={{ position: 'absolute', bottom: '45%', marginLeft: '8px' }}>
                            <CustomButton className='hide-pdf d-flex align-items-center justify-content-center' style={{ width: '30px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                                onClick={() => {
                                    handleWallDimensionChange(parseFloat(wallHeight) + step, parseFloat(wallWidth));
                                }}>
                                +
                            </CustomButton>
                            <Unit value={wallHeight} style={{ fontWeight: 'bold' }} />
                            <CustomButton className='hide-pdf d-flex align-items-center justify-content-center' style={{ width: '30px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                                onClick={() => {
                                    handleWallDimensionChange(parseFloat(wallHeight) - step, parseFloat(wallWidth));
                                }}>
                                -
                            </CustomButton>
                        </span>
                    </span>
                    {children}
                </div>
                <div id="bottom">
                    <BottomProps />
                </div>
                <Person />
            </div>
        </>
    )
}

export default memo(Room);