import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import benchmid from '../../../images/environments/classroom/BenchMid.svg';

const BenchMid = () => {
    const [height, setHeight] = useState(200);
    const [hide, setHide] = useState(false);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height, width } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${height * 0.35}px`);

        // const clothWidth = document.getElementById('retial-cloth').width;

        // if ((width + 20) < (clothWidth)) {
        //     setHide(true);
        // } else {
        //     setHide(false);
        // }
    }, [wallHeight, wallWidth])
    return (
        <img hidden={hide} id='classroom-benchmid' style={{ position: 'absolute', top: '0', right: '0', zIndex: 999 }} height={height} src={benchmid} alt="classroom-benchmid" />
    )
}

export default BenchMid;