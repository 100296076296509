import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../common/components/CustomButtom";
import VisitorDetailsForm from "./VisitorDetailsForm";

const VisitorDetailsModal = ({ isOpen, setIsOpen, exportData }) => {
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Export Configurator Design
            </ModalHeader>
            <ModalBody>
                <VisitorDetailsForm exportData={exportData} setIsOpen={setIsOpen}/>
            </ModalBody>
        </Modal>
    )
}

export default VisitorDetailsModal;