import { Spinner } from "reactstrap";

const Loader = ({ message }) => {
    console.log(message);
    return (
        <div id='loader' style={{ height: '100%', width: '100%', zIndex: 9999, backgroundColor: '#fcfcfc', position: 'fixed', top: 0, left: 0 }}>

            {message}

            <Spinner className='loader' />
        </div>
    )
}

export default Loader;