import axios from "axios";
import { useContext, useState } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { API_BASE_URL } from "../../config/api";
import { LoaderContext } from "../../context/context";
import CustomButton from "../common/components/CustomButtom";
import Loader from "../common/components/Loader";
import { Toast } from "../common/Functions";

const VisitorDetailsForm = ({ exportData, setIsOpen }) => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [errors, setErrors] = useState({
        name: '',
        lastName: '',
        email: '',
        mobile: ''
    })
    const { loading, setLoading } = useContext(LoaderContext);
    const nameRegex = /^[a-zA-Z ]+$/;
    const lastNameRegex = /^[a-zA-Z\' ]+$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z ]{2,3})+$/;
    const mobileRegex = /^[6-9][0-9]{9}$/;

    const validateName = (name) => {
        if (name.trim().length == 0) {
            return {
                'result': false,
                'message': 'First name cannot be blank.'
            };
        }
        if (name.trim().length < 2) {
            return {
                'result': false,
                'message': 'First name cannot be less than 2 characters.'
            };
        }
        if (name.trim().length > 45) {
            return {
                'result': false,
                'message': 'First name cannot be more than 45 characters.'
            };
        }
        const result = nameRegex.test(name);
        if (!result) {
            return {
                'result': result,
                'message': 'First name only accepts alphabets and spaces.'
            };
        }

        return {
            'result': true,
            'message': ''
        };
    }

    const validateLastName = (name) => {
        if (name.trim().length == 0) {
            return {
                'result': false,
                'message': 'Last name cannot be blank.'
            };
        }
        if (name.trim().length < 1) {
            return {
                'result': false,
                'message': 'Last Name must have minimum 1 character.'
            };
        }
        if (name.trim().length > 45) {
            return {
                'result': false,
                'message': 'Last name cannot be more than 45 characters.'
            };
        }

        const result = lastNameRegex.test(name);
        if (!result) {
            return {
                'result': result,
                'message': "Last Name only accepts alphabets, space and apostrophe (') characters."
            };
        }

        return {
            'result': true,
            'message': ''
        };
    }

    const validateEmail = (email) => {
        const result = emailRegex.test(email);
        return result;
    }

    const validateMobile = (mobile) => {
        const result = mobileRegex.test(mobile);
        return result;
    }

    const saveVisitorDetails = async (name, lastName, email, mobile) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/visitor/save-visitor-details`,
                {
                    first_name: name,
                    last_name: lastName,
                    email: email,
                    mobile: mobile
                }
            );
            const id = response.data.data.visitor_id;
            return id;
        } catch (error) {
            console.error("Error while saving visitor details");
            return null;
        }
    }

    const sendPDFToBackend = async (pdfUrl, visitorID) => {
        try {
            const formData = new FormData();
            formData.append("visitor_id", visitorID);
            formData.append("file", pdfUrl);

            const response = await axios.post(`${API_BASE_URL}/document/upload-document`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response;
        } catch (error) {
            console.error("Error while sending PDF to backend", error);
            return null;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isNameValid = validateName(name);
        const isEmailValid = validateEmail(email);
        const isMobileValid = validateMobile(mobile);
        const isLastNameValid = validateLastName(lastName);

        setErrors({
            name: isNameValid.result == true ? '' : isNameValid.message,
            lastName: isLastNameValid.result == true ? '' : isLastNameValid.message,
            email: isEmailValid ? '' : 'Please enter a valid Email.',
            mobile: isMobileValid ? '' : 'Please enter a valid Mobile.',
        })

        if (!isNameValid.result || !isLastNameValid.result || !isEmailValid || !isMobileValid) {
            return false;
        }

        setLoading(true);
        const visitorID = await saveVisitorDetails(name, lastName, email, mobile);
        if (!visitorID) {
            Toast('error', 'Error', "Error while saving visitor details");
            setLoading(false);
            return false;
        }

        const pdfUrl = await exportData({name: name, lastName: lastName});
        if (!pdfUrl) {
            Toast('error', 'Error', "Error while exporting data ");
            setLoading(false);
            return false;
        }

        const response = await sendPDFToBackend(pdfUrl, visitorID);
        if (!response) {
            Toast('error', 'Error', "Something went wrong.");
            setLoading(false);
            return false;
        }

        Toast('success', 'Success', "Exported data will be mailed to you shortly");
        setIsOpen(false);

        setLoading(false);
    }

    const handleReset = () => {
        setName('');
        setLastName('');
        setEmail('');
        setMobile('');
        setErrors({
            name: '',
            lastName: '',
            email: '',
            mobile: ''
        })
    }

    return (
        <>
            <Form onSubmit={handleSubmit} onReset={handleReset} >
                <FormGroup>
                    <Label for="name">
                        <b className="text-muted">First Name<span style={{color: 'red'}}>*</span></b>
                    </Label>
                    <Input
                        id="name"
                        name="name"
                        placeholder="Enter first name..."
                        type="text"
                        required
                        autoComplete="off"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        invalid={errors.name !== ''}
                        disabled={loading ? true : false}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.name}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="lastName">
                        <b className="text-muted">Last Name<span style={{color: 'red'}}>*</span></b>
                    </Label>
                    <Input
                        id="lastName"
                        name="lastName"
                        placeholder="Enter last name..."
                        type="text"
                        required
                        autoComplete="off"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        invalid={errors.lastName !== ''}
                        disabled={loading ? true : false}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.lastName}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="email">
                        <b className="text-muted">Email<span style={{color: 'red'}}>*</span></b>
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        placeholder="Enter email..."
                        type="text"
                        autoComplete="off"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        invalid={errors.email !== ''}
                        disabled={loading ? true : false}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.email}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="mobile">
                        <b className="text-muted">Mobile<span style={{color: 'red'}}>*</span></b>
                    </Label>
                    <Input
                        id="mobile"
                        name="mobile"
                        placeholder="Enter mobile..."
                        type="number"
                        autoComplete="off"
                        required
                        value={mobile}
                        onChange={e => setMobile(e.target.value)}
                        min={0}
                        invalid={errors.mobile !== ''}
                        disabled={loading ? true : false}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.mobile}
                    </FormFeedback>
                </FormGroup>
                <Row className="d-flex justify-content-center pt-3">
                    <Col md={2}>
                        <CustomButton type="submit" disabled={loading} style={{background: "var(--primary-color)", color: "var(--tertiary-color)", borderColor: "var(--primary-color)"}}>
                            Submit
                        </CustomButton>
                    </Col>
                    <Col md={2}>
                        <CustomButton type="reset" disabled={loading}>
                            Reset
                        </CustomButton>
                    </Col>
                </Row>
            </Form>
            {
                loading && <Loader message="Please wait while we prepare your export" />
            }
        </>
    )
}

export default VisitorDetailsForm;