import { createSlice } from '@reduxjs/toolkit';

const initialUser = () => {
    const storedTs = localStorage.getItem('lastTimeStamp')
    const storedTimeStamp = storedTs == "undefined" ? 0 : storedTs;
    const savedTime = new Date(storedTimeStamp)
    const diff = ((new Date()).getTime() - savedTime.getTime()) / 1000
    if (diff > 1800) {
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        localStorage.removeItem('lastTimeStamp')
    }

    const item = window.localStorage.getItem('userData')
    return (item && item !== "undefined") ? JSON.parse(item) : {}
}

const initialToken = () => {
    const token = window.localStorage.getItem('token')
    return (token && token !== "undefined") ? JSON.parse(token) : ""
}

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userData: initialUser(),
        token: initialToken(),
    },
    reducers: {
        changeLoginDetails: (state, action) => {
            state.userData = action.payload.data.user
            state.token = action.payload.data.access_token
            localStorage.setItem('userData', JSON.stringify(action.payload.data.user))
            localStorage.setItem('token', JSON.stringify(action.payload.data.access_token))
        },
        changeLogoutDetails: (state, action) => {
            state.userData = {}
            state.token = ""
            localStorage.removeItem('userData')
            localStorage.removeItem('token')
            localStorage.removeItem('lastTimeStamp')
        }
    },
})

export const { changeLoginDetails, changeLogoutDetails } = authSlice.actions;

export default authSlice.reducer;