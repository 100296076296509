import { createSlice } from '@reduxjs/toolkit';
import defaultImg from '../../images/scene2.png'

const INITIAL_STATE = {
    content: 'default',
    src: defaultImg,
    type: 'image/jpg'
}

const contentSlice = createSlice({
    name: 'content',
    initialState: INITIAL_STATE,
    reducers: {
        changeContent: (state, action) => {
            state.content = action.payload.content;
            state.src = action.payload.src;
            state.type = action.payload.type;
        }
    }
})

export const { changeContent } = contentSlice.actions;

export default contentSlice.reducer;