import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { LoaderContext } from "../../context/context";
import { addSnapshot } from "../../redux/reducers/snapshotReducer";

const DesignChange = () => {
    const { diagram, setDiagram } = useContext(LoaderContext);
    const handleDiagramChange = e => {
        if (diagram === 'design') {
            saveDiagramScreenShot();
        }
        const diag = e.target.value;
        setDiagram(diag);
    }
    const { selectedDesignIdx } = useSelector(state => {
        return {
            selectedDesignIdx: state.design.selectedDesignIdx
        }
    })
    const dispatch = useDispatch();

    const saveDiagramScreenShot = () => {
        try {
            const originalNode = document.getElementById('main-container');
            const clonedNode = originalNode.cloneNode(true);

            const { height, width } = originalNode.getBoundingClientRect();
            clonedNode.style.width = `${width}px`;
            clonedNode.style.height = `${height}px`;

            const elementsToHide = clonedNode.querySelectorAll('.hide-pdf');
            for (let i = 0; i < elementsToHide.length; i++) {
                elementsToHide[i].setAttribute('style', 'display:none !important');
            }

            const dataLooping = document.getElementById('data-snapshot');
            const clonedDLNode = dataLooping.cloneNode(true);

            const powerLooping = document.getElementById('power-snapshot');
            const clonedPLNode = powerLooping.cloneNode(true);

            dispatch(addSnapshot({
                index: selectedDesignIdx,
                snapshot: [clonedNode, clonedDLNode, clonedPLNode]
            }))
        } catch (error) {
            console.error("Error while saving LED Diagram Screenshot", error.message);
        }
    }

    return (
        <Input type='select' onChange={handleDiagramChange}>
            <option value='design'>Design</option>
            <option value='data-looping'>Data Looping</option>
            <option value='power-looping'>Power Looping</option>
        </Input>
    )
}

export default DesignChange;