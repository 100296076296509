import { useSelector } from 'react-redux';
import ControlRoom from './controlroom/ControlRoom';
import Camera from './newsroom/Camera';
import Desk from './newsroom/Desk';
import ChairRetail from './retial/Chair';
import Cloth from './retial/Cloth';
import Pot from './retial/Pot';
import ControlRoom2 from './controlroom/ControlRoom2';
import Chair from './lobby/Chair';
import DeskLobby from './lobby/Desk';
import Auditorium from './auditorium/Auditorium';
import ClassDesk from './classroom/Desk';
import BenchMid from './classroom/BenchMid';
import Table from './conference/Table';

const BottomProps = () => {
    const { environment, showProps } = useSelector(state => {
        return { environment: state.environment.environment, showProps: state.environment.showProps }
    })

    if (!showProps) {
        return null;
    }

    return (
        <>
            {environment === 'News Room' &&
                <>
                    <Desk />
                </>
            }
            {environment === 'Retail' &&
                <>
                    <ChairRetail />
                    <Cloth />
                    <Pot />
                </>
            }
            {environment === 'Command & Control Room' &&
                <>
                    <ControlRoom />
                    <ControlRoom2 />
                </>
            }
            {environment === 'Lobby' &&
                <>
                    <Chair />
                    <DeskLobby />
                </>
            }
            {environment === 'Auditorium' &&
                <>
                    <Auditorium />
                </>
            }
            {environment === 'Class Room' &&
                <>
                    <ClassDesk />
                    <BenchMid />
                </>
            }
            {environment === 'Conference Room' &&
                <>
                    <Table />
                </>
            }
        </>
    )
}

export default BottomProps;