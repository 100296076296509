import { memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import '../../css/ledContent.css'
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { changeContent } from "../../redux/reducers/contentReducer";

const LedContent = ({ style, autoPlay, loop }) => {
    const { content, design } = useSelector(state => {
        return { content: state.content, design: state.design }
    }, shallowEqual);
    const dispatch = useDispatch();

    useUpdateEffect(() => {
        if (design.designs.length > 0) {
            const idx = design.selectedDesignIdx;
            const selectedDesign = design.designs[idx];
            dispatch(changeContent({
                ...selectedDesign.content
            }))
        }
    }, [design.selectedDesignIdx])

    let contentDOM = '';
    if (content.type.includes('image')) {
        contentDOM = <img id="content-img" style={{ width: '100%', height: '100%', ...style }} src={content.src} alt="LED image" />;
    } else if (content.type.includes('video')) {
        contentDOM = <video autoPlay={autoPlay} loop={loop} id="content-video" style={{ width: '100%', height: '100%', ...style }} src={content.src} />;
    }
    return (
        contentDOM
    )
}

export default memo(LedContent);