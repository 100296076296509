import { useState } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import CustomButton from "../common/components/CustomButtom";

const EnquiryForm = () => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [company, setCompany] = useState('');
    const [requirement, setRequirement] = useState('');
    const [errors, setErrors] = useState({
        name: '',
        lastName: '',
        email: '',
        mobile: '',
        company: '',
        requirement: ''
    })
    const nameRegex = /^[a-zA-Z ]+$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z ]{2,3})+$/;
    const mobileRegex = /^[6-9][0-9]{9}$/;
    const companyRegex = /^[a-zA-Z0-9]+$/;

    const validateName = (name) => {
        if (name.length < 3) {
            return false;
        }
        const result = nameRegex.test(name);
        return result;
    }

    const validateEmail = (email) => {
        const result = emailRegex.test(email);
        return result;
    }

    const validateMobile = (mobile) => {
        const result = mobileRegex.test(mobile);
        return result;
    }

    const validateCompany = (company) => {
        const result = companyRegex.test(company);
        return result;
    }

    const validateRequirement = (requirement) => {
        if (requirement.length < 3 || requirement > 50) {
            return false;
        }
        return true;
    }

    const saveEnquiryDetails = () => {

    }

    const handleSubmit = e => {
        e.preventDefault();

        const isNameValid = validateName(name);
        const isEmailValid = validateEmail(email);
        const isMobileValid = validateMobile(mobile);
        const isLastNameValid = validateName(lastName);
        const isCompanyValid = validateCompany(company);
        const isReqValid = validateRequirement(requirement);

        setErrors({
            name: isNameValid ? '' : 'Please enter a valid First Name.',
            lastName: isLastNameValid ? '' : 'Please enter a valid Last Name.',
            email: isEmailValid ? '' : 'Please enter a valid Email.',
            mobile: isMobileValid ? '' : 'Please enter a valid Mobile.',
            company: isCompanyValid ? '' : 'Please enter a valid Company.',
            requirement: isReqValid ? '' : 'Please enter a valid Requirement.'
        })

        if (!isNameValid || !isEmailValid || !isMobileValid || !isCompanyValid || !isReqValid) {
            return false;
        }

        saveEnquiryDetails();
    }

    const handleReset = () => {
        setName('');
        setLastName('');
        setEmail('');
        setMobile('');
        setRequirement('');
        setCompany('');
        setErrors({
            name: '',
            lastName: '',
            email: '',
            mobile: '',
            requirement: '',
            company: ''
        })
    }

    return (
        <>
            <Form onSubmit={handleSubmit} onReset={handleReset}>
                <FormGroup>
                    <Label for="name">
                        <b className="text-muted">First Name*</b>
                    </Label>
                    <Input
                        id="name"
                        name="name"
                        placeholder="Enter first name..."
                        type="text"
                        required
                        autoComplete="off"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        invalid={errors.name !== ''}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.name}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="lastName">
                        <b className="text-muted">Last Name*</b>
                    </Label>
                    <Input
                        id="lastName"
                        name="lastName"
                        placeholder="Enter last name..."
                        type="text"
                        required
                        autoComplete="off"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        invalid={errors.lastName !== ''}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.lastName}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="email">
                        <b className="text-muted">Email*</b>
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        placeholder="Enter email..."
                        type="text"
                        autoComplete="off"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        invalid={errors.email !== ''}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.email}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="mobile">
                        <b className="text-muted">Mobile*</b>
                    </Label>
                    <Input
                        id="mobile"
                        name="mobile"
                        placeholder="Enter mobile..."
                        type="number"
                        autoComplete="off"
                        required
                        value={mobile}
                        onChange={e => setMobile(e.target.value)}
                        min={0}
                        invalid={errors.mobile !== ''}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.mobile}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="company">
                        <b className="text-muted">Company*</b>
                    </Label>
                    <Input
                        id="company"
                        name="company"
                        placeholder="Enter Company..."
                        type="text"
                        autoComplete="off"
                        required
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        min={0}
                        invalid={errors.company !== ''}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.company}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="requirement">
                        <b className="text-muted">Requirement*</b>
                    </Label>
                    <Input
                        id="requirement"
                        name="requirement"
                        placeholder="Enter requirement..."
                        type="text"
                        autoComplete="off"
                        required
                        value={requirement}
                        onChange={e => setRequirement(e.target.value)}
                        min={0}
                        invalid={errors.requirement !== ''}
                    >

                    </Input>
                    <FormFeedback>
                        {errors.requirement}
                    </FormFeedback>
                </FormGroup>
                <Row className="d-flex justify-content-center pt-3">
                    <Col md={2}>
                        <CustomButton type="submit">
                            Submit
                        </CustomButton>
                    </Col>
                    <Col md={2}>
                        <CustomButton type="reset">
                            Reset
                        </CustomButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default EnquiryForm;