import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  storedMountingTypeList: [],
  storedLocationList: [],
  storedClientTypeList: [],
  storedSeriesList: [],
  storedSeriesTypeList: [],
  storedAspectRatioList: [],
  storedUnitList: [],
  storedVideoProcessorList: [],
  storedAccessoriesList: [],
  storedMediaPlayerList: [],
  storedSpecialShapesList: [],
  orderSeriesList: [],
  orderSeriesTypeList: [],
  dataLooping: { 'points': [], cablesByFormula: 0, cablesByCalculation: 0 },
  powerLooping: { 'points': [], cablesByFormula: 0, cablesByCalculation: 0 },
  dataSnapshot: null,
  powerSnapshot: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState: INITIAL_STATE,
  reducers: {
    addStoredMountingTypeList: (state, action) => {
      state.storedMountingTypeList = action.payload.storedMountingTypeList;
    },
    addStoredLocationList: (state, action) => {
      state.storedLocationList = action.payload.storedLocationList;
    },
    addStoredClientTypeList: (state, action) => {
      state.storedClientTypeList = action.payload.storedClientTypeList;
    },
    addStoredSeriesList: (state, action) => {
      state.storedSeriesList = action.payload.storedSeriesList;
    },
    addStoredSeriesTypeList: (state, action) => {
      state.storedSeriesTypeList = action.payload.storedSeriesTypeList;
    },
    addStoredAspectRatioList: (state, action) => {
      state.storedAspectRatioList = action.payload.storedAspectRatioList;
    },
    addStoredUnitList: (state, action) => {
      state.storedUnitList = action.payload.storedUnitList;
    },
    addStoredVideoProcessorList: (state, action) => {
      state.storedVideoProcessorList = action.payload.storedVideoProcessorList;
    },
    addStoredAccessoriesList: (state, action) => {
      state.storedAccessoriesList = action.payload.storedAccessoriesList;
    },
    addStoredMediaPlayerList: (state, action) => {
      state.storedMediaPlayerList = action.payload.storedMediaPlayerList;
    },
    addStoredSpecialShapesList: (state, action) => {
      state.storedSpecialShapesList = action.payload.storedSpecialShapesList;
    },
    changeOrderSeriesList: (state, action) => {
      state.orderSeriesList = action.payload.orderSeriesList;
    },
    changeOrderSeriesTypeList: (state, action) => {
      state.orderSeriesTypeList = action.payload.orderSeriesTypeList;
    },
    addDataLoopingPoints: (state, action) => {
      state.dataLooping.points = action.payload.points;
      state.dataLooping.cablesByFormula = action.payload.cablesByFormula;
      state.dataLooping.cablesByCalculation = action.payload.cablesByCalculation;
    },
    addPowerLoopingPoints: (state, action) => {
      state.powerLooping.points = action.payload.points;
      state.powerLooping.cablesByFormula = action.payload.cablesByFormula;
      state.powerLooping.cablesByCalculation = action.payload.cablesByCalculation;
    },
    addDataSnapshot: (state, action) => {
      state.dataSnapshot = action.payload.snapshot;
    },
    addPowerSnapshot: (state, action) => {
      state.powerSnapshot = action.payload.snapshot;
    }
  }
})

export const { addStoredMountingTypeList, addStoredLocationList, addStoredClientTypeList, addStoredSeriesList, addStoredSeriesTypeList,
  addStoredAspectRatioList, addStoredUnitList, addStoredVideoProcessorList, addStoredAccessoriesList, addStoredMediaPlayerList, changeOrderSeriesList,
  changeOrderSeriesTypeList, addDataLoopingPoints, addPowerLoopingPoints, addDataSnapshot, addPowerSnapshot, addStoredSpecialShapesList } = orderSlice.actions;

export default orderSlice.reducer;