import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import './css/variables.css';
import './ConfiguratorRoot.css';
import { Row, Col, Container } from 'reactstrap';
import Configurator from './components/configurator/Configurator';
import Canvas from './components/canvas/Canvas';
import { ToastContainer } from 'react-toastify';
import UnitChange from './components/canvas/UnitChange';
import DesignChange from './components/canvas/DesignChange';
import Design from './components/canvas/Design';
import SpecsTable from './components/canvas/SpecsTable';
import { useEffect, useState } from 'react';
import { LoaderContext } from './context/context';
import XMLogo from './images/xmLogo.png'
import LoopingDiagram from './components/canvas/DataLooping';
import FacadeImg from './images/Facade.png';
import OutdoorImg from './images/outdoor-scene.png';
import { shallowEqual, useSelector } from 'react-redux';
import useUpdateEffect from './hooks/useUpdateEffect';
import Loader from './components/common/components/Loader';
import LoginButton from './components/login/LoginButton';
import DisplayMessage from './components/mobile/DisplayMessage';

function ConfiguratorRoot() {
  const [diagram, setDiagram] = useState('design');
  const [loading, setLoading] = useState(true);
  const [isUnify, setIsUnify] = useState(false);
  const [OutdoorBackground, setOutdoorBackground] = useState(OutdoorImg);
  const environment = useSelector(state => state.environment.environment, shallowEqual);

  const [showMobileWarning, setShowMobileWarning] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 1280)
      setShowMobileWarning(true)
  }, [])

  useUpdateEffect(() => {
    // if (['Wall Mounted', 'Rental OD'].includes(environment)) {
    if (['Wall Mounted'].includes(environment)) {
      setOutdoorBackground(FacadeImg);
    } else {
      setOutdoorBackground(OutdoorImg);
    }
  }, [environment])

  return (
    <>
      {!showMobileWarning && (<LoaderContext.Provider value={{ loading, setLoading, diagram, setDiagram, isUnify, setIsUnify }}>
        <Container fluid style={{ height: '100%', zIndex: 9999 }}>
          {
            loading && <Loader />
          }
          <Row style={{ height: '100%' }}>
            <Col md={4} style={{ backgroundColor: '#f7f7f7', height: '100%', padding: 0, /*overflow: 'auto', */ position: 'relative' }}>
              <Configurator />
            </Col>
            <Col md={8} style={{ height: '100%' }}>
              <Row className='d-flex align-items-center' style={{ backgroundColor: '#f7f7f7', borderBottom: 'solid #d0d0d0 1px' }}>
                <Col md={2} sm={7}>
                  <DesignChange />
                </Col>
                <Col md={2}>
                  <UnitChange />
                </Col>
                <Col md={2} >
                  <Design />
                </Col>
                {/* <Col md={2} style={{ marginLeft: 'auto', width: 'auto' }}>
                  <LoginButton/>
                </Col> */}
                <Col md={2} style={{ marginLeft: 'auto', width: 'auto' }}>
                  <a href="https://www.xtreme-media.com" target="_blank"><img src={XMLogo} alt="XM-Logo" style={{ height: '45px', margin: '4px' }} /></a>
                </Col>
              </Row>
              <Row style={{ height: '95%' }}>
                <Col id='main-container' className='bg-img p-0' style={{ height: '100%', backgroundImage: `url(${OutdoorBackground})` }}>
                  <Canvas />
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer />
          <LoopingDiagram id='data-snapshot' type='data' />
          <LoopingDiagram id='power-snapshot' type='power' />
        </Container >
      </LoaderContext.Provider>)}
      {!showMobileWarning && (<SpecsTable style={{ display: 'none' }} forScreenShot={true} />)}
      {showMobileWarning && (<DisplayMessage />)}
    </>
  );
}

export default ConfiguratorRoot;
