import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    designs: [],
    designsAdded: [0],
    selectedDesignIdx: 0
};

const designSlice = createSlice({
    name: 'design',
    initialState: INITIAL_STATE,
    reducers: {
        addDesign: (state, action) => {
            let idx = action.payload.index;
            if (!idx) {
                idx = 0;
            }
            state.designs[idx] = { ...state.designs[idx], ...action.payload.design };

            if (!state.designsAdded.includes(idx)) {
                state.designsAdded.push(idx)
            }
        },
        changeSelectedDesign: (state, action) => {
            state.selectedDesignIdx = action.payload.selectedDesignIdx;
        },
        deleteDesign: (state, action) => {
            let idx = action.payload.index;
            if (idx >= 0 && idx < state.designs.length) {
                state.designsAdded = state.designsAdded.filter(function (item) {
                    return item !== (idx)
                })
            }
        },
        updatePosition: (state, action) => {
            const idx = action.payload.index;
            const position = action.payload.position;
            state.designs[idx] = { ...state.designs[idx], position: { ...position } };
        },
    }
})

export const { addDesign, changeSelectedDesign, deleteDesign, updatePosition } = designSlice.actions;

export default designSlice.reducer;