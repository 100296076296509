import { memo, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoaderContext } from "../../context/context";
import { addSnapshot } from "../../redux/reducers/snapshotReducer";
import SpecsButton from "./SpecsButton";
import SpecsModal from "./SpecsModal";

const Specifications = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const selectedDesignIdx = useSelector(state => state.design.selectedDesignIdx);
    const { diagram } = useContext(LoaderContext);

    const saveDiagramScreenShot = () => {
        try {
            const originalNode = document.getElementById('main-container');
            const clonedNode = originalNode.cloneNode(true);

            const { height, width } = originalNode.getBoundingClientRect();
            clonedNode.style.width = `${width}px`;
            clonedNode.style.height = `${height}px`;

            const elementsToHide = clonedNode.querySelectorAll('.hide-pdf');
            for (let i = 0; i < elementsToHide.length; i++) {
                elementsToHide[i].setAttribute('style', 'display:none !important');

            }
            dispatch(addSnapshot({
                index: selectedDesignIdx,
                snapshot: [clonedNode]
            }))
        } catch (error) {
            console.error("Error while saving Specs Screenshot");
        }
    }

    const handleClick = () => {
        if (diagram === 'design') {
            saveDiagramScreenShot();
        }
        setIsOpen(!isOpen);
    }

    return (
        <>
            <SpecsButton onClick={handleClick} style={{ borderRadius: '50%', padding: '10px', position: 'absolute', bottom: '4%', right: '5em', zIndex: '99', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }} />
            <SpecsModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default memo(Specifications);