import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EnvironmentChangeForm from "./EnvironmentChangeForm";

const EnvironmentChangeModal = ({ isEnvironmentModalOpen, setIsEnvironmentModalOpen, changeLEDCondition }) => {
    const toggle = () => setIsEnvironmentModalOpen(!isEnvironmentModalOpen);

    return (
        <Modal isOpen={isEnvironmentModalOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Delete Designs
            </ModalHeader>
            <ModalBody>
                <EnvironmentChangeForm setIsEnvironmentModalOpen={setIsEnvironmentModalOpen} changeLEDCondition={changeLEDCondition} />
            </ModalBody>
        </Modal>
    )
}

export default EnvironmentChangeModal;