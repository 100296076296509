import axios from "axios";
import { useContext, useState } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { LoaderContext } from "../../context/context";
import { Toast } from "../common/Functions";
import { API_BASE_URL } from "../../config/api";
import CustomButton from "../common/components/CustomButtom";
import { encryptData } from "../common/Functions";
import { useDispatch, useSelector } from "react-redux";
import { changeLoginDetails } from "../../redux/reducers/authReducer";
import { useNavigate } from 'react-router-dom';
import { changeLedCondition } from "../../redux/reducers/ledReducer";

const EnvironmentChangeForm = ({ setIsEnvironmentModalOpen, changeLEDCondition }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsEnvironmentModalOpen(false);
        changeLEDCondition();
    }

    const handleReset = () => {
        setIsEnvironmentModalOpen(false);
    }

    return (
        <Form onSubmit={handleSubmit} onReset={handleReset}>
            <Row>
                <div>Changing the environment, will delete any other existing design(s). <br /><br />Do you still wish to continue?</div>
            </Row>
            <br/><br/>
            <Row className="d-flex justify-content-center pt-3">
                <Col md={2}>
                    <CustomButton type="submit">
                        Yes
                    </CustomButton>
                </Col>
                <Col md={2}>
                    <CustomButton type="reset">
                        No
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    )
}

export default EnvironmentChangeForm;