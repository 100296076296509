import { memo } from "react";
import { Row } from "reactstrap";
import AddDesign from "./AddDesign";
import SettingsTab from "./SettingTab";
import { version } from "../../config/api";

const Configurator = () => {
    return (
        <>
            <Row style={{ height: '10%', width: '100%', margin: '0', backgroundColor: '#fff', fontSize: '2vw', color: '#e14504', borderBottom: 'solid #d0d0d0 1px', borderRight: 'solid #d0d0d0 1px' }}>
                <div style={{ textAlign: 'center'}} >
                    <span style={{ textAlign: 'center', verticalAlign: '-webkit-baseline-middle' }}><b>Xtreme LED Configurator</b><span style={{ fontSize: "0.8rem", verticalAlign: 'sub' }}>{version}</span></span>
                </div>
            </Row>
            <Row style={{ height: '85%', width: '100%', margin: '0', overflow: 'auto', borderRight: 'solid #d0d0d0 1px' }}>
                <SettingsTab />
            </Row>
            <Row style={{ height: '5%', width: '100%', margin: '0', padding: '0', borderTop: 'solid #d0d0d0 1px', borderRight: 'solid #d0d0d0 1px' }}>
                <AddDesign />
            </Row>
        </>
    )
}

export default memo(Configurator);