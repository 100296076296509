import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  clientName: '',
  partnerName: '',
  quotationBy: '',

  mountingType: '',
  location: '',
  clientType: '',
  environment: '',
  orderSeries: '',
  orderSeriesType: '',

  chkDataBackup: false,
  chkPowerBackup: false,
  chkHdr10: false,
  chkFrontAccess: false,
  chkGoldWire: false,
  chkPFC: false,

  quantity: 1,
  warranty: 7,
  spare: '',
  margin: 5,
  
  aspectRatio: '',
  diagonal: '',
  unit: '',
  length: '',
  height: '',
  fit: 'best',
  ledBottomHeight: '',

  air: false,
  videoProcessor: '',
  accessories: '',
  pdu: false,
  mediaPlayer: '',
  specialShapes: '',

  orderSeriesList: [],
  orderSeriesTypeList: [],
};

const selectedOrderSlice = createSlice({
  name: 'selectedOrder',
  initialState: INITIAL_STATE,
  reducers: {
    changeClientName: (state, action) => {
      state.clientName = action.payload.clientName;
    },
    changePartnerName: (state, action) => {
      state.partnerName = action.payload.partnerName;
    },
    changeQuotationBy: (state, action) => {
      state.quotationBy = action.payload.quotationBy;
    },
    changeMountingType: (state, action) => {
      state.mountingType = action.payload.mountingType;
    },
    changeLocation: (state, action) => {
      state.location = action.payload.location;
    },
    changeClientType: (state, action) => {
      state.clientType = action.payload.clientType;
    },
    changeEnvironment: (state, action) => {
      state.environment = action.payload.environment;
    },
    changeOrderSeries: (state, action) => {
      state.orderSeries = action.payload.orderSeries;
    },
    changeOrderSeriesType: (state, action) => {
      state.orderSeriesType = action.payload.orderSeriesType;
    },
    changeChkPowerBackup: (state, action) => {
      state.chkPowerBackup = action.payload.chkPowerBackup;
    },
    changeChkDataBackup: (state, action) => {
      state.chkDataBackup = action.payload.chkDataBackup;
    },
    changeChkHdr10: (state, action) => {
      state.chkHdr10 = action.payload.chkHdr10;
    },
    changeChkFrontAccess: (state, action) => {
      state.chkFrontAccess = action.payload.chkFrontAccess;
    },
    changeChkGoldWire: (state, action) => {
      state.chkGoldWire = action.payload.chkGoldWire;
    },
    changeChkPFC: (state, action) => {
      state.chkPFC = action.payload.chkPFC;
    },
    changeQuantity: (state, action) => {
      state.quantity = action.payload.quantity;
    },
    changeWarranty: (state, action) => {
      state.warranty = action.payload.warranty;
    },
    changeSpare: (state, action) => {
      state.spare = action.payload.spare;
    },
    changeMargin: (state, action) => {
      state.margin = action.payload.margin;
    },
    changeAspectRatio: (state, action) => {
      state.aspectRatio = action.payload.aspectRatio;
    },
    changeDiagonal: (state, action) => {
      state.diagonal = action.payload.diagonal;
    },
    changeUnit: (state, action) => {
      state.unit = action.payload.unit;
    },
    changeLength: (state, action) => {
      state.length = action.payload.length;
    },
    changeHeight: (state, action) => {
      state.height = action.payload.height;
    },
    changeFit: (state, action) => {
      state.fit = action.payload.fit;
    },
    changeLedBottomHeight: (state, action) => {
      state.ledBottomHeight = action.payload.ledBottomHeight;
    },
    changeAir: (state, action) => {
      state.air = action.payload.air;
    },
    changeVideoProcessor: (state, action) => {
      state.videoProcessor = action.payload.videoProcessor;
    },
    changeAccessories: (state, action) => {
      state.accessories = action.payload.accessories;
    },
    changePDU: (state, action) => {
      state.pdu = action.payload.pdu;
    },
    changeMediaPlayer: (state, action) => {
      state.mediaPlayer = action.payload.mediaPlayer;
    },
    changeOrderSeriesList: (state, action) => {
      state.orderSeriesList = action.payload.orderSeriesList;
    },
    changeOrderSeriesTypeList: (state, action) => {
      state.orderSeriesTypeList = action.payload.orderSeriesTypeList;
    },
    changeSpecialShapes: (state, action) => {
      console.log("3")
      state.specialShapes = action.payload.specialShapes;
    },
  }
})

export const { changeClientName, changePartnerName, changeQuotationBy, 
  changeMountingType, changeLocation, changeClientType, changeEnvironment, changeOrderSeries, changeOrderSeriesType, 
  changeChkPowerBackup, changeChkDataBackup, changeChkHdr10, changeChkFrontAccess, changeChkGoldWire, changeChkPFC, 
  changeQuantity, changeWarranty, changeSpare, changeMargin, 
  changeAspectRatio, changeDiagonal, changeUnit, changeLength, changeHeight, changeFit, changeLedBottomHeight,
  changeAir, changeVideoProcessor, changeAccessories, changePDU, changeMediaPlayer, 
  changeOrderSeriesList, changeOrderSeriesTypeList, changeSpecialShapes} = selectedOrderSlice.actions;

  export default selectedOrderSlice.reducer;