import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { LoaderContext } from "../../context/context";
import { changeSelectedDesign, updatePosition } from "../../redux/reducers/designReducer";
import { addSnapshot } from "../../redux/reducers/snapshotReducer";

const Design = () => {
    const { designs, position, selectedDesignIdx } = useSelector(state => {
        return {
            designs: state.design,
            position: state.led.position,
            selectedDesignIdx: state.design.selectedDesignIdx
        }
    });
    const dispatch = useDispatch();
    const { setLoading, diagram } = useContext(LoaderContext);

    const saveDiagramScreenShot = () => {
        try {
            const originalNode = document.getElementById('main-container');
            const clonedNode = originalNode.cloneNode(true);

            const { height, width } = originalNode.getBoundingClientRect();
            clonedNode.style.width = `${width}px`;
            clonedNode.style.height = `${height}px`;

            const elementsToHide = clonedNode.querySelectorAll('.hide-pdf');
            for (let i = 0; i < elementsToHide.length; i++) {
                elementsToHide[i].setAttribute('style', 'display:none !important');
            }

            const dataLooping = document.getElementById('data-snapshot');
            const clonedDLNode = dataLooping.cloneNode(true);

            const powerLooping = document.getElementById('power-snapshot');
            const clonedPLNode = powerLooping.cloneNode(true);

            dispatch(addSnapshot({
                index: selectedDesignIdx,
                snapshot: [clonedNode, clonedDLNode, clonedPLNode]
            }))
        } catch (error) {
            console.error("Error while saving LED Diagram Screenshot", error.message);
        }
    }

    const handleDesignChange = (e) => {
        setLoading(true);
        if (diagram === 'design') {
            saveDiagramScreenShot();
        }
        
        const selectedIdx = parseInt(e.target.value);
        dispatch(changeSelectedDesign({
            selectedDesignIdx: selectedIdx
        }))

        dispatch(updatePosition({
            index: designs.selectedDesignIdx,
            position: { ...position }
        }))
    }

    return (
        <Input type='select' onChange={handleDesignChange} value={designs.selectedDesignIdx}>
            {
                designs?.designs?.map((_, idx) => <option value={idx} key={idx}>Design{idx + 1}</option>)
            }
        </Input>
    )
}

export default Design;