import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    specification: [],
};

const specificationSlice = createSlice({
    name: 'specification',
    initialState: INITIAL_STATE,
    reducers: {
        addSpecification: (state, action) => {
            let idx = action.payload.index;
            if (!idx) {
                idx = 0;
            }
            state.specification[idx] = action.payload.specification;
        }
    }
})

export const { addSpecification } = specificationSlice.actions;

export default specificationSlice.reducer;