import { memo, useContext, useEffect } from "react";
import { LoaderContext } from "../../context/context";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../common/components/CustomButtom";
import { changeWallDimensions, changeLocalWallDimensions } from "../../redux/reducers/wallReducer";
import { changeLedTopBottomLeftDistance, changeFitToCentre } from "../../redux/reducers/ledReducer";
import { MAX_ROOM_HEIGHT_LIMIT_REACHED, MIN_ROOM_HEIGHT_LIMIT_REACHED, MAX_ROOM_WIDTH_LIMIT_REACHED, MIN_ROOM_WIDTH_LIMIT_REACHED, WALL_HEIGHT_SMALLER_THAN_LED, WALL_HEIGHT_SMALLER_THAN_LED_POSITION, WALL_WIDTH_SMALLER_THAN_LED, WALL_WIDTH_SMALLER_THAN_LED_POSITION } from "../common/Messages";
import { convertFtToMeter, convertMeterToFt, debounce, Toast } from "../common/Functions";
import { ROOM_BUFFER_TOP, ROOM_BUFFER_BOTTOM, ROOM_BUFFER_LEFT, ROOM_BUFFER_RIGHT, MAX_ROOM_HEIGHT, MAX_ROOM_WIDTH, MIN_ROOM_HEIGHT, MIN_ROOM_WIDTH } from "../../config/config";
import { Card, CardBody, CardTitle, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";

const RoomForm = () => {
    const { roomHeight, roomWidth, ledRow, ledCol, cabinetHeight, cabinetWidth, unit, distanceTop, distanceLeft, distanceBottom, localWallWidth, localWallHeight, ledCondition, environment } = useSelector(s => {
        return {
            roomHeight: s.wall.height,
            roomWidth: s.wall.width,
            ledRow: s.led.row,
            ledCol: s.led.col,
            cabinetHeight: s.led.cabinetHeight,
            cabinetWidth: s.led.cabinetWidth,
            unit: s.wall.unit,
            distanceTop: s.led.distanceTop,
            distanceLeft: s.led.distanceLeft,
            distanceBottom: s.led.distanceBottom,
            localWallWidth: s.wall.localWidth,
            localWallHeight: s.wall.localHeight,
            ledCondition: s.led.condition,
            environment: s.environment.environment
        }
    })
    const dispatch = useDispatch();
    const { diagram } = useContext(LoaderContext);

    const changeDimension = (height, width, changeState) => {
        // console.log("===== changeDimension ===== 1");

        if (changeState) {
            if (height != roomHeight) {
                let min_room_hgt = unit == "ft" ? convertMeterToFt(MIN_ROOM_HEIGHT) : MIN_ROOM_HEIGHT;
                let max_room_hgt = unit == "ft" ? convertMeterToFt(MAX_ROOM_HEIGHT) : MAX_ROOM_HEIGHT;

                let buffer_top = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP) : (ROOM_BUFFER_TOP)));
                let buffer_bottom = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_BOTTOM)));
                let buffer_hgt = buffer_top + buffer_bottom;

                let screenHeight = ledRow * cabinetHeight;
                let totalScreenHeight = parseFloat(screenHeight + distanceTop).toFixed(2);
                let heightDiff = parseFloat(totalScreenHeight - (height - buffer_hgt));

                if (height > max_room_hgt) {
                    Toast('error', 'Error', MAX_ROOM_HEIGHT_LIMIT_REACHED);
                    return;
                }

                if (height < min_room_hgt) {
                    Toast('error', 'Error', MIN_ROOM_HEIGHT_LIMIT_REACHED);
                    return;
                }

                if (height < (screenHeight + buffer_hgt) && height < roomHeight) {
                    Toast('error', 'Error', WALL_HEIGHT_SMALLER_THAN_LED);
                    return;
                }

                if (totalScreenHeight > (height - buffer_bottom) && height < roomHeight && heightDiff > 0) {
                    Toast('error', 'Error', WALL_HEIGHT_SMALLER_THAN_LED_POSITION);
                    return;
                }

                height = height < totalScreenHeight ? Math.ceil(totalScreenHeight) : height;
            }

            if (width != roomWidth) {
                let min_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_WIDTH) : MIN_ROOM_WIDTH);
                let max_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_WIDTH) : MAX_ROOM_WIDTH);

                let buffer_left = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT) : (ROOM_BUFFER_LEFT)));
                let buffer_right = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_RIGHT)));
                let buffer_wdt = buffer_left + buffer_right;

                let screenWidth = ledCol * cabinetWidth;
                let totalScreenWidth = parseFloat(screenWidth + distanceLeft).toFixed(2);
                let widthDiff = parseFloat(totalScreenWidth - (width - buffer_wdt));
                if (width > max_room_wdt) {
                    Toast('error', 'Error', MAX_ROOM_WIDTH_LIMIT_REACHED);
                    return;
                }

                if (width < min_room_wdt) {
                    Toast('error', 'Error', MIN_ROOM_WIDTH_LIMIT_REACHED);
                    return;
                }

                if (width < (screenWidth + buffer_wdt) && width < roomWidth) {
                    Toast('error', 'Error', WALL_WIDTH_SMALLER_THAN_LED);
                    return;
                }

                if (totalScreenWidth > (width - buffer_right) && width < roomWidth && widthDiff > 0) {
                    Toast('error', 'Error', WALL_WIDTH_SMALLER_THAN_LED_POSITION);
                    return;
                }

                width = width < totalScreenWidth ? Math.ceil(totalScreenWidth) : width;
            }

            dispatch(changeWallDimensions({
                height: parseFloat(height).toFixed(2),
                width: parseFloat(width).toFixed(2)
            }))

            dispatch(changeFitToCentre({
                fitToCentre: false
            }))
        } else {
            dispatch(changeLocalWallDimensions({
                height: height,
                width: width,
            }))
        }
    }

    const handleUnitChange = (value) => {
        let convertedValue = value;
        if (unit === 'ft') {
            convertedValue = Number(convertMeterToFt(value));
        }

        if (unit === 'm') {
            convertedValue = Number(convertFtToMeter(value));
        }

        return Number(convertedValue).toFixed(2);
    }

    const convertUnits = () => {
        dispatch(changeLedTopBottomLeftDistance({
            top: handleUnitChange(distanceTop),
            left: handleUnitChange(distanceLeft),
            bottom: handleUnitChange(distanceBottom)
        }))

        dispatch(changeWallDimensions({
            height: handleUnitChange(roomHeight),
            width: handleUnitChange(roomWidth)
        }));
    }

    useEffect(() => {
        convertUnits();
    }, [unit])

    return (
        <>
            <Form>
                <Card className="mt-3" style={{ marginBottom: '20px' }}>
                    <CardBody>
                        <CardTitle className="text-muted mb-3" tag='h5'>Room Configuration</CardTitle>
                        <hr />
                        <FormGroup>
                            <Row>
                                <Col md={5}>
                                    <Label for="roomWidth">
                                        Wall Width ({unit})
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="roomWidth"
                                            name="roomWidth"
                                            placeholder="Enter Width"
                                            type="number"
                                            value={localWallWidth}
                                            min={MIN_ROOM_WIDTH}
                                            disabled={diagram !== 'design' || (ledCondition == 'outdoor' && (environment != 'Wall Mounted'))}
                                            onChange={e => {
                                                const val = (e.target.value);
                                                const width = val == "" ? "" : parseFloat(Number(val));
                                                changeDimension(parseFloat(roomHeight), width, 0);
                                            }}
                                            onBlur={e => {
                                                const width = parseFloat(Number(e.target.value));
                                                changeDimension(parseFloat(roomHeight), width, 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const width = parseFloat(Number(e.target.value));
                                                    changeDimension(parseFloat(roomHeight), width, 1);
                                                }
                                            }}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (diagram !== 'design') return;
                                            changeDimension(parseFloat(roomHeight), parseFloat(roomWidth) + 1, 1);
                                        }} disabled={diagram !== 'design'} hidden={(ledCondition == 'outdoor' && (environment != 'Wall Mounted'))}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (diagram !== 'design') return;
                                            changeDimension(parseFloat(roomHeight), parseFloat(roomWidth) - 1, 1);
                                        }} disabled={diagram !== 'design'} hidden={(ledCondition == 'outdoor' && (environment != 'Wall Mounted'))}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                                <Col md={5}>
                                    <Label for="roomHeight">
                                        Wall Height ({unit})
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="roomHeight"
                                            name="roomHeight"
                                            placeholder="Enter Height"
                                            type="number"
                                            value={localWallHeight}
                                            min={MIN_ROOM_HEIGHT}
                                            disabled={diagram !== 'design' || (ledCondition == 'outdoor' && (environment != 'Wall Mounted'))}
                                            onChange={e => {
                                                const val = (e.target.value);
                                                const height = val == "" ? "" : parseFloat(Number(val));
                                                changeDimension(height, parseFloat(roomWidth), 0);
                                            }}
                                            onBlur={e => {
                                                const height = parseFloat(Number(e.target.value));
                                                changeDimension(height, parseFloat(roomWidth), 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const height = parseFloat(Number(e.target.value));
                                                    changeDimension(height, parseFloat(roomWidth), 1);
                                                }
                                            }}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (diagram !== 'design') return;
                                            changeDimension(parseFloat(roomHeight) + 1, parseFloat(roomWidth), 1);
                                        }} disabled={diagram !== 'design'} hidden={(ledCondition == 'outdoor' && (environment != 'Wall Mounted'))}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (diagram !== 'design') return;
                                            changeDimension(parseFloat(roomHeight) - 1, parseFloat(roomWidth), 1);
                                        }} disabled={diagram !== 'design'} hidden={(ledCondition == 'outdoor' && (environment != 'Wall Mounted'))}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>
        </>
    )
}

export default memo(RoomForm);