import '../../css/form.css';
import Select from "react-select";
import { object, string, number, array, boolean } from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../common/components/CustomButtom";
import { addDataLoopingPoints, addPowerLoopingPoints, addStoredSpecialShapesList, changeOrderSeriesList, changeOrderSeriesTypeList } from "../../redux/reducers/orderReducer";
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap";
import { memo, useEffect, useRef, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../../config/api"
import { UNIFY_SERIES, UNIFY_SERIES_TYPE_125_ORDER, UNIFY_SERIES_TYPE_156_ORDER, XM_SPARE, VEGA_VEGA_PLUS_195, VEGA_VEGA_PLUS_SERIES } from '../../config/config';

const OrderForm = ({ setFormData, toggleTab, setFormChangeFlag, setPowerTabFlag }) => {
    const dispatch = useDispatch();

    const { userData, storedMountingTypeList, storedLocationList, storedClientTypeList, storedSeriesList, storedSeriesTypeList,
        storedAspectRatioList, storedUnitList, storedVideoProcessorList, storedAccessoriesList, storedMediaPlayerList,
        orderSeriesList, orderSeriesTypeList, storedSpecialShapesList } = useSelector((state) => {
            return {
                userData: state.auth.userData,
                token: state.auth.token,
                storedMountingTypeList: state.order.storedMountingTypeList,
                storedLocationList: state.order.storedLocationList,
                storedClientTypeList: state.order.storedClientTypeList,
                storedSeriesList: state.order.storedSeriesList,
                storedSeriesTypeList: state.order.storedSeriesTypeList,
                storedAspectRatioList: state.order.storedAspectRatioList,
                storedUnitList: state.order.storedUnitList,
                storedVideoProcessorList: state.order.storedVideoProcessorList,
                storedAccessoriesList: state.order.storedAccessoriesList,
                storedMediaPlayerList: state.order.storedMediaPlayerList,
                orderSeriesList: state.order.orderSeriesList,
                orderSeriesTypeList: state.order.orderSeriesTypeList,
                storedSpecialShapesList: state.order.storedSpecialShapesList
            }
        })

    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    }

    const envOptions = [
        {
            value: 1,
            label: 'Indoor'
        },
        {
            value: 2,
            label: 'Outdoor'
        }
    ]
    const [environmentOptions, setEnvironmentOptions] = useState(envOptions)


    let mmConversionFactor = {
        "mm": 1,
        "inch": 25.4,
        "ft": 304.8,
        "mt": 1000
    }

    let inchConversionFactor = {
        "mm": 0.0393700787401575,
        "inch": 1,
        "ft": 12,
        "mt": 39.3701,
    }
    const conversionFactor = {
        "mm": {
            "mm": 1,
            "inch": 0.0393701,
            "ft": 0.00328084,
            "mt": 0.001,
            "cbmtrx": 1,
            "px": 1
        },
        "inch": {
            "mm": 25.4,
            "inch": 1,
            "ft": 0.08333,
            "mt": 0.0254,
            "cbmtrx": 1,
            "px": 1
        },
        "ft": {
            "mm": 304.8,
            "inch": 12,
            "ft": 1,
            "mt": 0.3048,
            "cbmtrx": 1,
            "px": 1
        },
        "mt": {
            "mm": 1000,
            "inch": 39.3701,
            "ft": 3.28084,
            "mt": 1,
            "cbmtrx": 1,
            "px": 1
        },
        "cbmtrx": {
            "mm": 1,
            "inch": 1,
            "ft": 1,
            "mt": 1,
            "cbmtrx": 1,
            "px": 1
        },
        "px": {
            "mm": 1,
            "inch": 1,
            "ft": 1,
            "mt": 1,
            "cbmtrx": 1,
            "px": 1
        }
    }

    const minDimensionLimit = {
        'mt': 0.1,
        'mm': 3,
        'ft': 0.1,
        'inch': 1,
        'cbmtrx': 1,
        'px': 1
    }

    const maxDimensionLimit = {
        'mt': 100,
        'mm': 100000,
        'ft': 328.1,
        'inch': 3937,
        'cbmtrx': 100,
        'px': 10000000
    }

    const getConvertedUnitValue = (fromUnit, toUnit, value) => {
        let factor = conversionFactor[fromUnit][toUnit];
        return parseFloat(value * factor).toFixed(1)
    }

    const INITIAL_UNIT = { value: "mt", label: 'Meter' };
    const [ddUnit, setDDUnit] = useState(INITIAL_UNIT);
    const prevUnitRef = useRef();
    useEffect(() => {
        prevUnitRef.current = ddUnit;
    }, [ddUnit]);

    const defaultValues = {
        clientName: '',
        partnerName: '',
        quotationBy: { value: userData.user_id, label: userData.first_name + " " + userData.last_name },

        mountingType: { value: 1, label: 'Wall Mount' },
        location: { value: 1, label: 'Mumbai' },
        clientType: { value: 1, label: 'Diamond' },
        environment: '',
        series: '',
        seriesType: '',

        quantity: 1,
        warranty: { value: 3, label: '7 Years' },
        spare: '7',
        margin: 30,

        aspectRatio: { value: 0, label: 'Any' },
        diagonal: '',
        unit: ddUnit,
        length: '',
        height: '',
        fit: { value: "best", label: 'Best Fit' },
        ledBottomHeight: 0,

        chkPowerBackup: { value: 2, label: 'No' },
        chkDataBackup: { value: 2, label: 'No' },
        chkHdr10: { value: 2, label: 'No' },
        chkFrontAccess: { value: 2, label: 'No' },
        chkGoldWire: { value: 2, label: 'No' },
        chkPFC: { value: 2, label: 'No' },
        chkHighRefresh: { value: 2, label: 'No' },

        videoProcessor: { value: 0, label: 'Not Required' },
        accessories: { value: 0, label: 'Not Required' },
        mediaPlayer: { value: 0, label: 'Not Required' },
        specialShapes: { value: 0, label: 'Not Required' },
        stockProduct: { value: 2, label: 'No' },

        air: false,
        pdu: { value: 2, label: 'No' },
        videoProcessorQuantity: 0,
        accessoriesQuantity: 0,
        mediaPlayerQuantity: 0,
    }
    const minRef = useRef(0.1)
    const maxRef = useRef(101)
    if (ddUnit.label === "Millimeter") {
        minRef.current = 3
        maxRef.current = 100000
    } else if (ddUnit.label === "Inch") {
        minRef.current = 1
        maxRef.current = 3937
    } else if (ddUnit.label === "Foot") {
        minRef.current = 0.1
        maxRef.current = 328.1
    } else if (ddUnit.label === "Cabinet Matrix") {
        minRef.current = 1
        maxRef.current = 100
    }
    else if (ddUnit.label === "Pixel") {
        minRef.current = 1
        maxRef.current = 10000000
    }
    const UserValidation = object().shape({
        clientName: string()
            .when('clientName', {
                is: (value) => value?.length,
                then: (rule) => rule
                    .min(2, "Client Name cannot be less than 2 characters.")
                    .max(45, "Client Name cannot be more than 45 characters.")
                    // .matches(/^[a-zA-Z0-9\s\']+$/, "Client Name can contain only alphanumeric values and it cannot start with a number.")
                    .matches(/^[A-Za-z][a-zA-Z0-9\s\']+$/, "Client Name can contain only alphanumeric values and it cannot start with a number."),
            }),
        partnerName: string()
            .when('partnerName', {
                is: (value) => value?.length,
                then: (rule) => rule
                    .min(2, "Partner Name cannot be less than 2 characters.")
                    .max(45, "Partner Name cannot be more than 45 characters.")
                    .matches(/^[A-Za-z][a-zA-Z0-9\s\']+$/, "Partner Name can contain only alphanumeric values and it cannot start with a number."),
            }),
        quotationBy: object({
            value: number(),
            label: string().trim()
        }),
        // .required("Quotation By is required."),
        mountingType: object({
            value: number(),
            label: string().trim()
        }),
        // .required("Mounting Type is required."),
        location: object({
            value: number(),
            label: string().trim()
        })
            .required("Location is required."),
        clientType: object({
            value: number(),
            label: string().trim()
        }),
        // .required("Client Type is required."),
        environment: object({
            value: number().positive("Environment is required.").moreThan(0, "Environment is required."),
            label: string().trim()
        })
            .required("Environment is required."),
        series: object({
            value: number().positive("Series is required.").moreThan(0, "Series is required."),
            label: string().trim()
        })
            .required("Series is required."),
        seriesType: object({
            value: number().positive("Series Description is required.").moreThan(0, "Series Description is required."),
            label: string().trim()
        })
            .required("Series Type is required."),
        quantity: number()
            .required("Quantity is required.")
            .min(1, "Quantity cannot be less than 1")
            .max(100, "Quantity cannot be more than 100")
            .integer("Quantity cannot be in decimals")
            .transform((value) => (Number.isNaN(value) ? null : value))
        ,
        warranty: object({
            value: number().positive("Warranty is required.").moreThan(0, "Warranty is required."),
            label: string().trim()
        }),
        spare: number()
            .positive("Spare should be more than 0.")
            .transform((value) => (Number.isNaN(value) ? (['Nyx', 'Nyx Modular', 'Nyx OD'].includes(selectedSeriesOption.label) ? 5 : 7) : value))
            .min(5, "Spare cannot be less than 5")
            .max(50, "Spare cannot be more than 50"),
        margin: number()
            .required("Margin is required.")
            .positive("Margin cannot be negative.")
            .moreThan(0, "Margin cannot be less than 1."),
        aspectRatio: object({
            value: number().positive("Aspect Ratio is required.").moreThan(-1, "Aspect Ratio is required."),
            label: string().trim()
        })
            .required("Aspect Ratio is required."),
        diagonal: number()
            .moreThan(0, "Diagonal should be more than 0.")
            .nullable()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .test('decimal-places', 'Diagonal should have up to 1 decimal places only', value => {
                if (value !== undefined && value !== null) {
                    return /^\d+(\.\d{1,1})?$/.test(value.toString());
                }
                return true;
            }),
        unit: object({
            value: string().nullable().trim(),
            label: string().trim()
        })
            .required("Unit is required."),
        length: number()
            .required("Length is required.")
            .min(minRef.current, "Length must be more than " + minRef.current)
            .max(maxRef.current, "Length must be less than " + maxRef.current)
            .transform((value) => (Number.isNaN(value) ? null : value))
            .test('decimal-places', 'Length can have up to 1 decimal place only', value => {
                if (value !== undefined && value !== null) {
                    return /^\d+(\.\d{1,1})?$/.test(value.toString());
                }
                return true;
            }),

        height: number()
            .required("Height is required.")
            .min(minRef.current, "Height must be more than " + minRef.current)
            .max(maxRef.current, "Height must be less than " + maxRef.current)
            .transform((value) => (Number.isNaN(value) ? null : value))
            .test('decimal-places', 'Height can have up to 1 decimal place only', value => {
                if (value !== undefined && value !== null) {
                    return /^\d+(\.\d{1,1})?$/.test(value.toString());
                }
                return true;
            }),
        fit: object({
            value: string().trim(),
            label: string().trim()
        })
            .required("Fit is required."),
        ledBottomHeight: number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .positive('Height from Floor to Bottom field cannot be negative')
            .moreThan(-1, 'Height from Floor to Bottom field cannot be negative')
            .test('decimal-places', 'Height should not be above 50 and can have up to 1 decimal place only.', value => {
                if (value !== undefined && value !== null) {
                    return (/^((([0-9])|([1-4][0-9]?))(\.\d{1,1})?)$/.test(value.toString()) || value.toString() == "50");
                }
                return true;
            })
        ,
        videoProcessor: object({
            value: number().positive("Video processor is required.").moreThan(-1, "Video processor is required."),
            label: string().trim()
        })
            .required("Video Processor is required."),
        mediaPlayer: object({
            value: number().positive("Media player is required.").moreThan(-1, "Media player is required."),
            label: string().trim()
        })
            .required("Media Player is required."),
        specialShapes: object({
            value: number().positive("Special Shapes is required.").moreThan(-1, "Special Shapes is required."),
            label: string().trim()
        })
            .required("Special Shapes is required is required."),

        videoProcessorQuantity: number()
            .required("Video Processor Quantity is required.")
            .min(0, "Video Processor Quantity cannot be negative")
            .max(100, "Video Processor Quantity cannot be more than 100")
            .integer("Video Processor Quantity cannot be in decimals")
            .transform((value) => (Number.isNaN(value) ? null : value))
        ,
        accessoriesQuantity: number()
            .required("Accessories Quantity is required.")
            .min(0, "Accessories Quantity cannot be negative")
            .max(100, "Accessories Quantity cannot be more than 100")
            .integer("Accessories Quantity cannot be in decimals")
            .transform((value) => (Number.isNaN(value) ? null : value))
        ,
        mediaPlayerQuantity: number()
            .required("Media Player Quantity is required.")
            .min(0, "Media Player Quantity cannot be negative")
            .max(100, "Media Player Quantity cannot be more than 100")
            .integer("Media Player Quantity cannot be in decimals")
            .transform((value) => (Number.isNaN(value) ? null : value))
        ,
    }, [
        ['clientName', 'clientName'],
        ['partnerName', 'partnerName'],
        ['length', 'length'],
        ['height', 'height'],
        ['diagonal', 'diagonal'],
        ['ledBottomHeight', 'ledBottomHeight'],
        ['videoProcessorQuantity', 'videoProcessorQuantity'],
        ['accessoriesQuantity', 'accessoriesQuantity'],
        ['mediaPlayerQuantity', 'mediaPlayerQuantity'],
    ]);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        getFieldState,
        watch,
        reset,
        clearErrors,
        formState: { errors }
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(UserValidation)
    });

    const calculateDiagonal = async (length, height, setDiagonalFlag) => {
        let unit = getValues('unit').value;
        if (["px", "cbmtrx"].includes(unit)) {
            if (setDiagonalFlag) {
                setValue('diagonal', '');
            }
        } else {
            let diagonal = Math.sqrt((length * length) + (height * height));
            let diagonalInInches = diagonal * inchConversionFactor[unit];
            if (setDiagonalFlag) {
                setValue('diagonal', diagonalInInches.toFixed(1));
            }
            return diagonalInInches;
        }
        return true;
    }

    const getCabinetAndPixelPitchDimensions = async ({ seriesTypeId }) => {
        let result = {};
        result['pixelPitchLength'] = storedSeriesTypeList[seriesTypeId]['pixel_pitch_1'];
        result['pixelPitchHeight'] = storedSeriesTypeList[seriesTypeId]['pixel_pitch_2'];
        result['moduleLength'] = storedSeriesTypeList[seriesTypeId]['module_length'];
        result['moduleHeight'] = storedSeriesTypeList[seriesTypeId]['module_height'];
        result['modulePixelLength'] = storedSeriesTypeList[seriesTypeId]['module_pixel_length'];
        result['modulePixelHeight'] = storedSeriesTypeList[seriesTypeId]['module_pixel_height'];
        result['cabinetLengthMm'] = storedSeriesTypeList[seriesTypeId]['cabinet_1_length_mm'];
        result['cabinetHeightMm'] = storedSeriesTypeList[seriesTypeId]['cabinet_1_height_mm'];
        result['cabinetDepthMm'] = storedSeriesTypeList[seriesTypeId]['cabinet_depth_mm'];
        result['moduleLengthCount'] = storedSeriesTypeList[seriesTypeId]['module_length_count'];
        result['moduleHeightCount'] = storedSeriesTypeList[seriesTypeId]['module_height_count'];
        result['pixelLengthCount'] = storedSeriesTypeList[seriesTypeId]['pixel_length_count'];
        result['pixelHeightCount'] = storedSeriesTypeList[seriesTypeId]['pixel_height_count'];
        result['moduleRemover'] = storedSeriesTypeList[seriesTypeId]['module_remover'];
        return result;
    }

    const selectedSeriesOption = watch('series');
    const selectedUnitOption = watch('unit');
    const selectedEnvironmentOption = watch('environment');
    const selectedSeriesTypeOption = watch('seriesType');
    let selSeriesTypeOption = storedSeriesTypeList[selectedSeriesTypeOption.value]
    const onsubmit = async (data) => {
        let rowCondition;
        let colCondition;
        let cabinetFitRows;
        let cabinetFitColumns;

        let fit = data.fit.value;
        let unit = data.unit.value;
        let ledInputLength = data.length;
        let ledInputHeight = data.height;
        let seriesTypeId = data.seriesType.value;
        let dimObj = await getCabinetAndPixelPitchDimensions({ seriesTypeId });

        let conversionFactorLength = Object.keys(mmConversionFactor).includes(unit) ? mmConversionFactor[unit] : (unit == "cbmtrx" ? dimObj['cabinetLengthMm'] : dimObj['pixelPitchLength']);
        let conversionFactorHeight = Object.keys(mmConversionFactor).includes(unit) ? mmConversionFactor[unit] : (unit == "cbmtrx" ? dimObj['cabinetHeightMm'] : dimObj['pixelPitchHeight']);

        let ledLengthMm = ledInputLength * conversionFactorLength;
        let ledHeightMm = ledInputHeight * conversionFactorHeight;

        let cabinetColumns = (ledLengthMm / dimObj['cabinetLengthMm']).toFixed(2);
        let cabinetRows = (ledHeightMm / dimObj['cabinetHeightMm']).toFixed(2);

        colCondition = rowCondition = fit == "max";
        if (fit == "best") {
            let columnMantissa = parseInt(cabinetColumns.toString().split('.')[2]);
            let rowMantissa = parseInt(cabinetRows.toString().split('.')[2]);

            cabinetFitColumns = columnMantissa < 5 ? Math.floor(cabinetColumns) : Math.ceil(cabinetColumns);
            cabinetFitRows = rowMantissa < 5 ? Math.floor(cabinetRows) : Math.ceil(cabinetRows);

            colCondition = columnMantissa < 5;
            rowCondition = rowMantissa < 5;
        }

        cabinetFitColumns = colCondition ? Math.floor(cabinetColumns) : Math.ceil(cabinetColumns);
        cabinetFitRows = rowCondition ? Math.floor(cabinetRows) : Math.ceil(cabinetRows);

        let finalCabinetLength = cabinetFitColumns * dimObj['cabinetLengthMm'];
        let finalCabinetHeight = cabinetFitRows * dimObj['cabinetHeightMm'];

        let noOfCabinets = cabinetFitColumns * cabinetFitRows;
        let finalNoOfCabinets = noOfCabinets * data.quantity;
        let noOfModules = finalNoOfCabinets * (dimObj['moduleLengthCount'] * dimObj['moduleHeightCount']);

        let temp = '';
        for (let i = 0; i < data.accessories.length; i++) {
            if (data.accessories[i].label != "Not Required") {
                temp = temp + data.accessories[i].label;
                if (i !== data.accessories.length - 1) { temp = temp + ", " }
            }
        }
        data.accessories = temp;

        let sType = storedSeriesTypeList[seriesTypeId];
        let pixelsPerCabinet = dimObj['pixelLengthCount'] * dimObj['pixelHeightCount']
        let totalNumberOfPixels = (pixelsPerCabinet * finalNoOfCabinets);
        let transmissionCard = sType['transmission_card'];
        let transmissionCardPerCabinet = (transmissionCard == "" || transmissionCard == null || transmissionCard == " " || transmissionCard == "NA") ? 0 : parseFloat(transmissionCard);
        // let clientSparePercentage = ((data.spare - 2) / 100);
        let clientSparePercentage = ((data.spare / 100) - XM_SPARE)
        let xmSpare = XM_SPARE
        // if (clientSparePercentage < 0.05) {
        //     clientSparePercentage = 0.05
        // }
        let transmissionCardModel = sType['transmission_card_model'];
        let receiverCardPerCabinet = sType['receiver_card_per_cabinet'];
        let hubCardPerCabinet = sType['hub_card_per_cabinet'];
        let powerSupplyPerCabinet = sType['power_supply_per_cabinet'];
        let refreshRate = sType['refresh_rate'];
        let highRefreshRate = sType['high_refresh_rate'];
        let mountingAccess = sType['access_mode'];

        //Power Supply
        let totalPowerSupply = powerSupplyPerCabinet * finalNoOfCabinets;
        if (data.chkPowerBackup.label === "Yes") {
            totalPowerSupply = finalNoOfCabinets * (powerSupplyPerCabinet + 1)
        }
        let clientPowerSpare = Math.ceil(clientSparePercentage * totalPowerSupply) < 1 ? 1 : Math.ceil(clientSparePercentage * totalPowerSupply)
        let xmPowerSpare = Math.ceil(xmSpare * totalPowerSupply) < 1 ? 0 : Math.ceil(xmSpare * totalPowerSupply)
        if (data.chkPowerBackup.label === "Yes") {
            clientPowerSpare = clientPowerSpare * 2
            xmPowerSpare = xmPowerSpare * 2
        }
        let totalPowerSpare = clientPowerSpare + xmPowerSpare

        let totalReceiverCards = (receiverCardPerCabinet * finalNoOfCabinets);
        let clientRcSpare = Math.ceil(clientSparePercentage * totalReceiverCards) < 1 ? 1 : Math.ceil(clientSparePercentage * totalReceiverCards)
        let xmRcSpare = Math.ceil(xmSpare * totalReceiverCards) < 1 ? 1 : Math.ceil(xmSpare * totalReceiverCards)
        let totalRcSpare = clientRcSpare + xmRcSpare

        let totalTransmissionCards = 0;
        let clientTcSpare = 0;
        let xmTcSpare = 0;
        totalTransmissionCards = (transmissionCardPerCabinet * finalNoOfCabinets);
        clientTcSpare = Math.ceil(clientSparePercentage * totalTransmissionCards) < 1 ? 1 : Math.ceil(clientSparePercentage * totalTransmissionCards)
        xmTcSpare = Math.ceil(xmSpare * totalTransmissionCards) < 1 ? 1 : Math.ceil(xmSpare * totalTransmissionCards)

        let totalTcSpare = clientTcSpare + xmTcSpare

        let totalHubCards = 0;
        let clientHcSpare = 0;
        let xmHcSpare = 0;
        if (sType['hub_card_model'] != "NA") {
            totalHubCards = (sType['hub_card_per_cabinet'] * finalNoOfCabinets)
            clientHcSpare = Math.ceil(clientSparePercentage * hubCardPerCabinet * finalNoOfCabinets) < 1 ? 1 : Math.ceil(clientSparePercentage * hubCardPerCabinet * finalNoOfCabinets)
            xmHcSpare = Math.ceil(xmSpare * (hubCardPerCabinet * finalNoOfCabinets)) < 1 ? 1 : Math.ceil(xmSpare * (hubCardPerCabinet * finalNoOfCabinets))
        }
        let totalHcSpare = xmHcSpare + clientHcSpare

        let cModuleSpare = Math.ceil(clientSparePercentage * noOfModules) < 5 ? 5 : Math.ceil(clientSparePercentage * noOfModules)
        let xModuleSpare = Math.ceil(xmSpare * noOfModules) < 1 ? 1 : Math.ceil(xmSpare * noOfModules)
        let totModuleSpare = cModuleSpare + xModuleSpare

        //Main frc
        let clientMainFrcSpare
        let xmMainFrcSpare
        let mainFrcCableDesc
        let totalNumberOfMainFrc = sType['main_frc'] * finalNoOfCabinets;
        if (sType['main_frc'] == 0 || sType['main_frc'] == null) {
            mainFrcCableDesc = "--"
            xmMainFrcSpare = 0
            clientMainFrcSpare = 0
        } else {
            clientMainFrcSpare = Math.ceil(clientSparePercentage * totalNumberOfMainFrc);
            xmMainFrcSpare = Math.ceil(xmSpare * totalNumberOfMainFrc)
            mainFrcCableDesc = "1 Mtr"
        }
        let totalMainFrcSpare = clientMainFrcSpare + xmMainFrcSpare

        //Loop frc
        let clientLoopFrcSpare;
        let xmLoopFrcSpare;
        let loopFrcDesc;
        let totalNumberOfLoopFrc = sType['loop_frc'] * finalNoOfCabinets;
        if (sType['loop_frc'] == 0 || sType['loop_frc'] == null) {
            clientLoopFrcSpare = 0
            xmLoopFrcSpare = 0
            loopFrcDesc = 0
        } else {
            clientLoopFrcSpare = Math.ceil(clientSparePercentage * totalNumberOfLoopFrc);
            xmLoopFrcSpare = Math.ceil(xmSpare * totalNumberOfLoopFrc)
        }
        let totalLoopFrcSpare = clientLoopFrcSpare + xmLoopFrcSpare

        //Loop Power cable/connector
        let lPowerDesc;
        let clientLPower = 0;
        clientLPower = Math.ceil((noOfCabinets - Math.ceil((((((dimObj['cabinetLengthMm'] / 1000) * (dimObj['cabinetHeightMm'] / 1000) * sType['max_power_watts_meter_sq']) / 230) / 0.75) * noOfCabinets) / 16)) * data.quantity * clientSparePercentage)
        if (clientLPower < 1) {
            clientLPower = 1
        }
        let xmLPower = 0

        //loop data cable/connector
        let lDataDesc;
        let clientLData = 0;

        clientLData = Math.ceil((noOfCabinets - Math.ceil(((finalCabinetLength / dimObj['moduleLength']) * dimObj['modulePixelLength']) * ((finalCabinetHeight / dimObj['moduleHeight']) * dimObj['modulePixelHeight']) / 655350)) * data.quantity * clientSparePercentage)
        if (clientLData < 1) {
            clientLData = 1
        }

        let xmLData = 0

        let volt5PerCabinet = sType['cable_5V_per_cabinet'];
        let volt5Desc = "--";
        let clientVolt5Spare = 0
        let xmVolt5Spare = 0
        clientVolt5Spare = Math.ceil((noOfModules / 3) * clientSparePercentage)

        if (clientVolt5Spare < 3) { xmVolt5Spare = 3 }

        let total5VSpare = clientVolt5Spare + xmVolt5Spare

        let meshPerModule = sType['mesh_per_module']
        let xmMeshSpare = 0;
        if (meshPerModule > 0) {
            xmMeshSpare = Math.ceil(noOfModules * 0.05 * meshPerModule)
            if (xmMeshSpare < 5) { xmMeshSpare = 5 }
        }

        let xmCombSpare = 0
        xmCombSpare = Math.ceil(((dimObj['cabinetLengthMm'] / 1000) * (dimObj['cabinetHeightMm'] / 1000) * noOfCabinets) / 20 * data.quantity);

        let pixelSpare = Math.ceil(totalNumberOfPixels * 0.0005);
        if (pixelSpare < 500) { pixelSpare = 500 }

        let drivingIcSpare = Math.ceil((dimObj['moduleLength'] * dimObj['moduleHeight'] / (sType['scan'].split("1/")[1]) / 16 / dimObj['pixelPitchLength'] / dimObj['pixelPitchHeight'] * 3) * noOfModules * 0.005)
        if (drivingIcSpare < 50) { drivingIcSpare = 50 }

        let powerIcSpare = Math.ceil((((dimObj['cabinetLengthMm'] * cabinetFitColumns / dimObj['pixelPitchLength'] / 1000) * (dimObj['cabinetHeightMm'] * cabinetFitRows / dimObj['pixelPitchHeight'] / 1000)) / 20)) * data.quantity * 50

        let xmModRemoverSpare = 0;
        let moduleRemoverDesc = dimObj['moduleRemover']
        if (moduleRemoverDesc !== "NA" && moduleRemoverDesc != null && moduleRemoverDesc != "") {
            if ((moduleRemoverDesc).trim() === "L Key") {
                xmModRemoverSpare = 2
            }
            else {
                if (["Vega", "Vega Plus", "Vega Studio"].includes(selectedSeriesOption.label)) {
                    xmModRemoverSpare = Math.ceil((((dimObj['cabinetLengthMm'] / 1000) * (dimObj['cabinetHeightMm'] / 1000) * noOfCabinets) / 100) * data.quantity)
                } else {
                    xmModRemoverSpare = Math.ceil((((dimObj['cabinetLengthMm'] / 1000) * (dimObj['cabinetHeightMm'] / 1000) * noOfCabinets) / 20) * data.quantity)
                }
            }
        } else {
            xmModRemoverSpare = 0
        }

        let clientMainPowerSpare = 0;
        clientMainPowerSpare = Math.ceil((((((dimObj['cabinetLengthMm'] / 1000) * (dimObj['cabinetHeightMm'] / 1000) * sType['max_power_watts_meter_sq']) / 230) / 0.75) * noOfCabinets) / 16 * data.quantity);
        let mainPowerDesc = "10 Mtr"
        if (["Ace", "Ace Plus", "LIT CC", "Vega", "Vega Plus", "Unify-FHD", "Unify-QHD"].includes(selectedSeriesOption.label)) {
            mainPowerDesc = "10 Mtr - 2 Split"
            clientMainPowerSpare = clientMainPowerSpare + data.quantity;
        }
        let xmMainPowerSpare = 0

        let loopPowerCableDesc;
        let xmLoopPowerSpare = 0;
        let clientLoopPowerSpare = 0;

        clientLoopPowerSpare = finalNoOfCabinets - clientMainPowerSpare
        xmLoopPowerSpare = 0


        let mainDataCableDesc = "10Mtr"
        let clientMainDataSpare = Math.round((((finalCabinetLength / dimObj['moduleLength']) * dimObj['modulePixelLength'])) * (((finalCabinetHeight / dimObj['moduleHeight']) * dimObj['modulePixelHeight'])) / 655350) * data.quantity
        let xmMainDataSpare = 0

        let loopDataCableSmallDesc;
        let cLoopDataCableSpare = 0;
        let xLoopDataCableSpare = 0
        if (["Ace", "Ace Plus", "LIT CC", "Unify-FHD", "Unify-QHD"].includes(selectedSeriesOption.label)) {
            loopDataCableSmallDesc = "Attached to Cabinet"
            cLoopDataCableSpare = 0
        } else {
            if (selectedSeriesOption.label === "Nyx Modular") {
                loopDataCableSmallDesc = "--"
                cLoopDataCableSpare = 0
                xLoopDataCableSpare = 0
            } else {
                if (selectedSeriesOption.label === "Rigel Plus") {
                    loopDataCableSmallDesc = "2.0 Mtr"
                } else {
                    loopDataCableSmallDesc = "1.5 Mtr"
                }
                cLoopDataCableSpare = finalNoOfCabinets - clientMainDataSpare;
                xLoopDataCableSpare = 0
            }
        }

        let accCLoopFrc = 0;
        let accCMainFrc = 0;
        let accC5VSpare = 0;
        let cLSmps = 0
        if (selectedSeriesOption.label === "Nyx Modular") {
            accCMainFrc = Math.ceil(finalNoOfCabinets * sType['main_frc'])
            accCLoopFrc = Math.ceil(finalNoOfCabinets * sType['loop_frc'])
            accC5VSpare = Math.ceil((dimObj['moduleLengthCount'] * dimObj['moduleHeightCount'] * noOfCabinets) / 3 * data.quantity)
            cLSmps = (noOfCabinets * sType['power_supply_per_cabinet'])
        }


        //horizontal interlock bolts
        let accHBoltsDesc;
        let accVBoltsDesc;
        let accClientHBolts = 0;
        let accXmHBolts = 0
        let accClientVBolts = 0;
        let accXmVBolts = 0

        console.log(((cabinetFitColumns - 1) * cabinetFitRows * 2) * data.quantity)
        accClientHBolts = ((cabinetFitColumns - 1) * cabinetFitRows * 2) * data.quantity
        accClientVBolts = ((cabinetFitRows - 1) * cabinetFitColumns * 2) * data.quantity

        if (["Ace", "Ace Plus"].includes(selectedSeriesOption.label)) {
            accClientVBolts = ((cabinetFitRows - 1) * cabinetFitColumns * 4) * data.quantity
        } else if (selectedSeriesOption.label == "LIT") {
            accClientVBolts = ((cabinetFitRows - 1) * cabinetFitColumns * 3) * data.quantity
        }

        if (selectedSeriesOption.label == "Vega") {
            if (cabinetFitColumns % 2 == 0) {
                accClientHBolts = (((cabinetFitColumns / 2) - 1) * cabinetFitRows * 2) * data.quantity
            } else {
                accClientHBolts = ((((cabinetFitColumns + 1) / 2) - 1) * cabinetFitRows * 2) * data.quantity
            }
        }

        let accCCabPlates = 0;
        accCCabPlates = (cabinetFitColumns + 1) * (cabinetFitRows + 1) * data.quantity

        let accCCabBolts = 0;
        accCCabBolts = finalNoOfCabinets * 4

        let accCScrew = 0;
        if (data.mountingType.label === "Wall Mount") {
            if (VEGA_VEGA_PLUS_SERIES.includes(selectedSeriesOption.label)) {
                accCScrew = finalNoOfCabinets * 4
            } else {
                accCScrew = cabinetFitColumns * 12 * data.quantity
            }
        }

        let accClientSmpsPlates = 0;
        let accClientSmpsRc = 0;
        let accClientSmpsScrew = 0;
        let accClientPlateScrew = 0;
        let accClientRcScrews = 0;
        let accClientRcPScrews = 0;
        let accClientRcPlate = 0;
        let noSMPSPerCabinets = sType['power_supply_per_cabinet'];

        accClientSmpsPlates = ((noOfCabinets * noSMPSPerCabinets) * data.quantity)
        accClientSmpsRc = ((noOfCabinets * noSMPSPerCabinets) * data.quantity)
        accClientSmpsScrew = (noOfCabinets * noSMPSPerCabinets) * 4 * data.quantity
        accClientPlateScrew = (noOfCabinets * noSMPSPerCabinets) * 4 * data.quantity
        accClientRcPlate = ((noOfCabinets * receiverCardPerCabinet)) * data.quantity;
        accClientRcScrews = (noOfCabinets * receiverCardPerCabinet) * 4 * data.quantity
        accClientRcPScrews = (noOfCabinets * receiverCardPerCabinet) * 4 * data.quantity

        let specRefreshRate;

        if (data.chkDataBackup.label === "Yes") {
            clientPowerSpare *= 2;
            xmPowerSpare *= 2;
            clientRcSpare *= 2;
            xmRcSpare *= 2;
            totalRcSpare = clientRcSpare + xmRcSpare
        }
        let scan = sType['scan']
        let modulePCBPartNumber = sType['module_pcb_part_number']
        let hubCardModel = sType['hub_card_model']
        let drivingIC = sType['driving_ic']

        if (data.chkHighRefresh.label === "Yes") {
            specRefreshRate = highRefreshRate
            scan = sType['scan_high_refresh']
            modulePCBPartNumber = sType['module_pcb_part_number_high_refresh']
            hubCardModel = sType['hub_card_model_high_refresh']
            drivingIC = sType['high_refresh_ic']
        } else {
            specRefreshRate = refreshRate
        }

        let mpQuantity = data.mediaPlayerQuantity == 0 || data.mediaPlayer.value == 0 ? '' : ' - ' + data.mediaPlayerQuantity + ' Nos.';
        let vpQuantity = data.videoProcessorQuantity == 0 || data.videoProcessor.value == 0 ? '' : ' - ' + data.videoProcessorQuantity + ' Nos.';
        let accQuantity = data.accessoriesQuantity == 0 || data.accessories == "" ? '' : ' - ' + data.accessoriesQuantity + ' Nos.';
        let partner = data.partnerName == "" ? "" : data.partnerName + "_";
        let client = data.clientName == "" ? "" : data.clientName + "_";
        let jointCabinetsPerScreen = parseInt(cabinetFitColumns / 2) * cabinetFitRows;
        let singleCabinetsPerScreen = parseInt(cabinetFitColumns % 2) * cabinetFitRows;
        let cabinetStrPerScreen = "";
        let cabinetStrTotal = "";
        let vegaCabinetMatrix = ""
        let vegaNoOfModules = "";

        if (VEGA_VEGA_PLUS_SERIES.includes(selectedSeriesOption.label)) {
            cabinetStrPerScreen = " (" + jointCabinetsPerScreen + " Joint Cabinets";
            cabinetStrTotal = jointCabinetsPerScreen * data.quantity + " Joint Cabinets";
            let vegaCol1 = parseInt(cabinetFitColumns / 2);
            let vegaCol2 = parseInt(cabinetFitColumns % 2);
            vegaCabinetMatrix = vegaCol1 + vegaCol2 + " x " + cabinetFitRows + " Cabinets (" + parseInt(cabinetFitColumns / 2) + " x " + cabinetFitRows + " Joint Cabinets";
            vegaNoOfModules = '(' + dimObj['moduleLengthCount'] + ' x ' + dimObj['moduleHeightCount'] + ') x (' + jointCabinetsPerScreen + " Joint Cabinets";
            if (singleCabinetsPerScreen != 0) {
                cabinetStrPerScreen += ", " + singleCabinetsPerScreen + " Single Cabinets";
                cabinetStrTotal += ", " + singleCabinetsPerScreen * data.quantity + " Single Cabinets";
                vegaNoOfModules += " + " + singleCabinetsPerScreen + " Single Cabinets";
            }
            if (parseInt(cabinetFitColumns % 2) != 0) {
                vegaCabinetMatrix += ", " + parseInt(cabinetFitColumns % 2) + " x " + cabinetFitRows + " Single Cabinets";
            }

            cabinetStrPerScreen += ") ";
            vegaCabinetMatrix += ") ";
            vegaNoOfModules += ") "
        }

        let specSheet = {
            accessories: data.accessories + accQuantity,
            accessMode: sType['access_mode'],
            ageingTime: '72 Hrs',
            avgPowerConsumption: sType['avg_power_watts_meter_sq'] + ' W/m2',
            bisModelNo: sType['bis_model'],
            brightness: sType['brightness'] + ' nits',
            contrast: sType['contrast'],
            cabinetMaterial: sType['cabinet_type'],
            cabinetSize: dimObj['cabinetLengthMm'] + 'mm x ' + dimObj['cabinetHeightMm'] + 'mm x ' + dimObj['cabinetDepthMm'] + 'mm',
            cabinetMatrix: cabinetFitColumns + ' x ' + cabinetFitRows + ' = ' + cabinetFitColumns * cabinetFitRows + ' Cabinets / Screen x ' + data.quantity + ' Screen(s)',
            cabinetFitRows: cabinetFitRows,
            cabinetFitColumns: cabinetFitColumns,
            cabinetsPerScreen: noOfCabinets,
            cabinetMountingPlateDetails: sType['cabinet_mounting_plate_details'],
            cabinetWeight: sType['weight_kg_per_meter_sq'] + ' kg/m2',
            calibratedColorTemperature: sType['color_temperature'],
            certification: sType['certification'],
            decoderIc: sType['decoder_ic'],
            displaySizeMm: finalCabinetLength + 'mm x ' + finalCabinetHeight + 'mm - ' + data.quantity + ' Screen(s)',
            displayResolution: Math.round(((finalCabinetLength / dimObj['moduleLength']) * dimObj['modulePixelLength'])) + 'px x ' + Math.round(((finalCabinetHeight / dimObj['moduleHeight']) * dimObj['modulePixelHeight'])) + 'px',
            drivingIC: drivingIC,
            highRefreshIc: sType['high_refresh_ic'],
            fiveVConnectorCable: sType['connector_cable_5V'],
            grayScale: sType['grayscale'],
            hubCardModel: hubCardModel,
            hubCardPerCabinet: hubCardPerCabinet,
            hubCardPartNumber: sType['hub_card_part_num'],
            ipRatingFront: sType['front_ip_rating'],
            ipRatingBack: sType['back_ip_rating'],
            ledController: data.videoProcessor.label + vpQuantity,
            ledPartNumberCopper: sType['led_part_number_copper'],
            ledPartNumberGold: sType['led_part_number_gold'],
            ledMake: sType['make'],
            ledType: sType['led'],
            loopFrcPerCabinet: null,
            loopFrcCable: sType['loop_frc_cable'],
            loopPowerCable: sType['loop_power_cable'],
            loopPowerCableConnector: sType['loop_power_cable_connector'],
            loopDataCable: null,
            loopDataCableConnector: sType['loop_data_cable_connector'],
            loopDataCablesMedium: sType['loop_data_cable_medium'],
            loopDataCablesSmall: sType['loop_data_cable_small'],
            mainDataCable: sType['main_data_cable'],
            mainFrcCable: sType['main_frc_cable'],
            totalNumberOfMainFrc: totalNumberOfMainFrc,
            mainPowerCable: sType['main_power_cable'],
            specFrontAccess: sType['maintenance_access'],
            maxPowerConsumption: sType['max_power_watts_meter_sq'] + ' W/m2',
            maxCabinetPower: sType['max_cabinet_power'],
            mediaPlayer: data.mediaPlayer.label + mpQuantity,
            moduleSize: dimObj['moduleLength'] + 'mm x ' + dimObj['moduleHeight'] + 'mm',
            noOfModules: '(' + dimObj['moduleLengthCount'] + ' x ' + dimObj['moduleHeightCount'] + ') x ' + noOfCabinets + ' Cabinets / Screen' + ' x ' + data.quantity + ' Screen(s)' + ' = ' + noOfModules + ' Modules',
            vegaNoOfModules: vegaNoOfModules + " / Screen x " + data.quantity + ' Screen(s)' + ' = ' + noOfModules + ' Modules',
            mountingHoles: sType['mounting_holes'],
            mountingPlatesDimensionFromEdge: sType['mounting_plates_dimension_from_edge'],
            modulePCBLayerAndThickness: sType['module_pcb_layer_thickness'],
            modulePCBPartNumber: modulePCBPartNumber,
            moduleLenCount: sType['module_length_count'],
            moduleHgtCount: sType['module_height_count'],
            noOfCabinets: noOfCabinets + ' Cabinets ' + cabinetStrPerScreen + ' / Screen x ' + data.quantity + ' Screen(s) = ' + finalNoOfCabinets + ' Cabinets' + cabinetStrTotal,
            totalCabinets: finalNoOfCabinets,
            packing: sType['packing'],
            paintDFTReadings: sType['paint_dft_readings'],
            dataBackup: data.chkDataBackup.label === "Yes" ? true : false,
            powerBackup: data.chkPowerBackup.label === "Yes" ? true : false,
            goldWire: data.chkGoldWire.label === "Yes" ? true : false,
            pdu: data.pdu.label === "Yes" ? true : false,
            hdr: data.chkHdr10.label === "Yes" ? true : false,
            highRefresh: data.chkHighRefresh.label === "Yes" ? true : false,
            frontAccess: data.chkFrontAccess.label === "Yes" ? true : false,
            pfc: data.chkPFC.label === "Yes" ? true : false,
            videoProcessorQ: data.videoProcessorQuantity,
            accessoriesQ: data.accessoriesQuantity,
            mediaPlayerQ: data.mediaPlayerQuantity,
            pixelPitch: 'P' + dimObj['pixelPitchLength'].toFixed(2) + 'mm',
            pixelsPerCabinet: (sType['pixel_length_count'] * sType['pixel_height_count']),
            powerIc: sType['power_ic'],
            powerSupplyModel: sType['power_supply'],
            powerSupplyPartNumNonPfc: sType['power_supply_part_num_non_pfc'],
            powerSupplyPartNumPfc: sType['power_supply_part_num_pfc'],
            PowerSupplyPerCabinet: sType['power_supply_per_cabinet'],
            powerSupplyMake: sType['power_supply'],
            powerSupplyWattage: sType['power_supply_wattage'],
            powerSupplyOutputVoltage: sType['power_supply_output_in_v'],
            powerSupplyOutputCurrent: sType['power_supply_output_in_amp'],
            powerSupplyPerCabinet: sType['power_supply_per_cabinet'],
            mountingType: data.mountingType.label,
            projectName: partner + client + data.location.label + '_' + data.series.label + '_P' + dimObj['pixelPitchLength'].toFixed(2) + 'mm' + "_" + ('0' + new Date().getDate()).slice(-2) + ("0" + (new Date().getMonth() + 1)).slice(-2) + new Date().getFullYear(),
            receiverCardPerCabinet: receiverCardPerCabinet,
            refreshRate: specRefreshRate,
            receiverCardPlateFittingScrew: sType['rc_plate_fitting_screw'],
            receiverCardModelHdr: sType['receiver_card_model_hdr'],
            receiverCardModelNonHdr: sType['receiver_card_model_non_hdr'],
            receiverCardPartNumberHdr: sType['rc_part_num_hdr'],
            receiverCardPartNumberNonHdr: sType['rc_part_num_non_hdr'],
            scan: scan,
            selfThreadedScrews: sType['self_threaded_screws'],
            spare: data.spare,
            series: data.series.label,
            seriesTypeId: seriesTypeId,
            smpsFittingScrew: sType['smps_fitting_screw'],
            smpsPlateFittingScrew: sType['smps_plate_fitting_screw'],
            smpsToSmpsLoopCables: sType['smps_loop_cable'],
            smpsWithPlate: sType['smps_with_plate'],
            smpsToReceiverPower: sType['smps_to_receiver_power'],
            totalMainPowerCables: null,
            totalNumberOfHubCard: totalHubCards,
            totalNumberOfReceiverCard: totalReceiverCards,
            totalNumberOfPixels: totalNumberOfPixels,
            totalNumberOfModules: noOfModules,
            totalNumberOfLoopFrc: totalNumberOfLoopFrc,
            totalNumberOfTransmissionCard: totalTransmissionCards,
            totalNumberOfDrivingIc: null,
            totalNumberOfPowerIc: null,
            totalNumberOfDecoderIc: null,
            totalNumberOfModuleRemover: null,
            totalLoopPowerCableSpare: null,
            totalLoopDataCableSpare: null,
            type: data.environment.label,
            transmissionCardPerCabinet: transmissionCardPerCabinet,
            transmissionCardModel: transmissionCardModel,
            vegaCabinetMatrix: vegaCabinetMatrix + '/ Screen x ' + data.quantity + ' Screen(s)',
            vegaNoOfCabinets: cabinetStrPerScreen + '/ Screen x ' + data.quantity + ' Screen(s) = ' + cabinetStrTotal,
            ventilationFanDetails: sType['ventilation_fan_details'],
            warranty: data.warranty.label,
            quantity: data.quantity,

            xmModuleSpare: xModuleSpare,
            clientModuleSpare: cModuleSpare,
            totalModuleSpare: totModuleSpare,

            clientReceiverCardSpare: clientRcSpare,
            xmReceiverCardSpare: xmRcSpare,
            totalReceiverCardSpare: totalRcSpare,

            clientMainFrcCableSpare: clientMainFrcSpare,
            xmMainFrcCableSpare: xmMainFrcSpare,
            totalMainFrcCableSpare: totalMainFrcSpare,

            totalLoopFrcCableSpare: totalLoopFrcSpare,
            clientLoopFrcCableSpare: clientLoopFrcSpare,
            xmLoopFrcCableSpare: xmLoopFrcSpare,

            totalPowerSupplySpare: totalPowerSpare,
            clientPowerSupplySpare: clientPowerSpare,
            xmPowerSupplySpare: xmPowerSpare,

            totalTransmissionCardSpare: totalTcSpare,
            clientTransmissionCardSpare: clientTcSpare,
            xmTransmissionCardSpare: xmTcSpare,

            totalHubCardSpare: totalHcSpare,
            clientHubCardSpare: clientHcSpare,
            xmHubCardSpare: xmHcSpare,

            client5VConnectorCableSpare: clientVolt5Spare,
            xm5VConnectorCableSpare: xmVolt5Spare,
            total5VConnectorCableSpare: total5VSpare,

            moduleMask: sType['module_mesh'],
            totalModuleMaskSpare: xmMeshSpare,
            xmModuleMaskSpare: xmMeshSpare,

            moduleMaskComb: sType['module_comb'],
            totalModuleMaskCombSpare: xmCombSpare,
            xmModuleMaskCombSpare: xmCombSpare,

            flexibleRepairingPcb: 'P' + dimObj['pixelPitchLength'].toFixed(2) + 'mm',
            xmRepairingPcbSpare: Math.ceil((cModuleSpare + xModuleSpare) * 0.1),
            totalRepairingPcbSpare: Math.ceil((cModuleSpare + xModuleSpare) * 0.1),

            xmPixelSpare: pixelSpare,

            xmDrivingIcSpare: drivingIcSpare,

            xmPowerIcSpare: powerIcSpare,

            xmDecoderIcSpare: powerIcSpare,

            moduleRemover: dimObj['moduleRemover'],
            xmModuleRemoverSpare: xmModRemoverSpare,

            totalMPowerSpare: clientMainPowerSpare + xmMainPowerSpare,
            clientMPowerSpare: clientMainPowerSpare,
            xmMPowerSpare: xmMainPowerSpare,

            xmLoopPowerCableSpare: null,
            clientLoopPowerCableSpare: null,

            totalMainSpare: clientMainDataSpare + xmMainDataSpare,
            clientDataSpare: clientMainDataSpare,
            xmDataSpare: xmMainDataSpare,

            totMainFrc: accCMainFrc,
            cMainFrc: accCMainFrc,

            totLoopFrc: accCLoopFrc,
            cLoopFrc: accCLoopFrc,

            tot5VSpare: accC5VSpare,
            accC5VSpare: accC5VSpare,

            horizontalInterlockBoltsNuts: sType['horizontal_interlock_bolts_nuts'],
            // horizontalInterlockBoltsNuts : accHBoltsDesc,
            totHBolts: accClientHBolts + accXmHBolts,
            cHBolts: accClientHBolts,
            xmHBolts: accXmHBolts,

            verticalInterlockBoltsNuts: sType['vertical_interlock_bolts_nuts'],
            // verticalInterlockBoltsNuts : accVBoltsDesc,
            totVBolts: accClientVBolts + accXmVBolts,
            cVBolts: accClientVBolts,
            xmVBolts: accXmVBolts,

            cabinetMountingIronPlates: sType['cabinet_mounting_iron_plates'],
            // cabinetMountingIronPlates : cabPlatesDesc,
            totCCabPlates: accCCabPlates,
            cCabPlates: accCCabPlates,

            cabinetMountingBolts: sType['cabinet_mounting_bolts'],
            // cabinetMountingBolts : accCabBoltsDesc,
            totCabBolts: accCCabBolts,
            accCCabBolts: accCCabBolts,

            // Self threaded screws
            totScrew: accCScrew,
            cScrew: accCScrew,

            // smps to smps loop
            totLSmps: cLSmps,
            cLSmps: cLSmps,

            // smps with plate
            totSmpsPlates: accClientSmpsPlates,
            cSmpsPlates: accClientSmpsPlates,

            // smps to receiver power
            totSmpsRc: accClientSmpsRc,
            cSmpsRc: accClientSmpsRc,

            // smps fitting screw
            totSmpsScrew: accClientSmpsScrew,
            cSmpsScrew: accClientSmpsScrew,

            //smps plate fitting screw
            totPlateScrew: accClientPlateScrew,
            cPlateScrew: accClientPlateScrew,

            // receiverCardWithPlate: sType['rc_with_plate'],
            totRcPlate: accClientRcPlate,
            cRcPlate: accClientRcPlate,

            //rc to rc loop data
            lRCDesc: sType['rc_loop_data'],

            // receiver card with plate
            rcPlateDesc: sType['rc_with_plate'],
            totRcPlate: accClientRcPlate,
            cRcPlate: accClientRcPlate,

            //receiver card fitting screw
            rcScrewsDesc: sType['rc_fitting_screw'],
            totRcScrews: accClientRcScrews,
            cRcScrews: accClientRcScrews,

            // receiver card plate fitting screw
            rcPScrewsDesc: sType['rc_plate_fitting_screw'],
            totRcPScrews: accClientRcPScrews,
            cRcPScrews: accClientRcPScrews,

            // piri terminal
            piriTerminal: sType['piri_terminal'],

            //loop power cable connector
            totLPower: clientLPower + xmLPower,
            cLPower: clientLPower,
            xLPower: xmLPower,

            //loop data cable connector
            totLData: clientLData + xmLData,
            cLData: clientLData,
            xLData: xmLData,

            //Series type option from DB values
            anySpecialShape: data.specialShapes.label,
            lShapeDB: sType['l_shape'],
            errorDetectionVigilantDb: sType['error_detection'] == 0 ? "Not Required" : "Yes",
            highRefreshDb: sType['high_refresh'],
            pfcDb: sType['pfc'],
            pdrDb: sType['pdr'],
            hdrDb: sType['hdr'],
            frontAccessDb: sType['front_access'],
            goldDb: sType['gold'],
            customCabinetDb: sType['custom_cabinet'],
            highBrightnessDb: sType['high_brightness'],

            //Ageing Details
            ageingCheck: data.stockProduct.label === "Yes" ? true : false,
            newProd1: "White Color - Grey Scale 255 - Brightness 100% - 24 Hours",
            newProd2: "Red Color - Grey Scale 255 - Brightness 100% - 8 Hours",
            newProd3: "Green Color - Grey Scale 255 - Brightness 100% - 8 Hours",
            newProd4: "Blue Color - Grey Scale 255 - Brightness 100% - 8 Hours",
            newProd5: "Video - Grey Scale 255 - Brightness 100% - 24 Hours",
            warehouse1: "White Color - Grey Scale 255 - Brightness 100% - 24 Hours",
            warehouse2: "Red Color - Grey Scale 255 - Brightness 100% - 8 Hours",
            warehouse3: "Green Color - Grey Scale 255 - Brightness 100% - 8 Hours",
            warehouse4: "Blue Color - Grey Scale 255 - Brightness 100% - 8 Hours",
            warehouse5: "Video - Grey Scale 255 - Brightness 100% - 24 Hours",
        }

        const combinedData = {
            dimObj, specSheet, data,
        };
        console.log(specSheet)
        setFormData(combinedData)
        setFormChangeFlag(false);
        setPowerTabFlag(false);
        toggleTab('2')

        let col = specSheet.cabinetFitColumns;
        if (VEGA_VEGA_PLUS_SERIES.includes(specSheet.series) && (!VEGA_VEGA_PLUS_195.includes(dimObj.pixelPitchLength.toFixed(2)))) {
            col = Math.ceil(specSheet.cabinetFitColumns / 2);
        }
        axios.post(`${API_BASE_URL}/series/get-data-loop-points`, {
            cabinet_rows: specSheet.cabinetFitRows,
            cabinet_cols: col,
            series_type_id: specSheet.seriesTypeId,
            hdr: specSheet.hdr == true ? 1 : 0
        }).then((response) => {
            dispatch(addDataLoopingPoints({
                points: response.data.data.coordinate_array,
                cablesByFormula: response.data.data?.cables_by_formula,
                cablesByCalculation: response.data.data?.cables_by_calc,
            }))
        }).catch((error) => {
            console.error(error)
        })

        axios.post(`${API_BASE_URL}/series/get-power-loop-points`, {
            cabinet_rows: specSheet.cabinetFitRows,
            cabinet_cols: specSheet.cabinetFitColumns,
            series_type_id: specSheet.seriesTypeId,
        }).then((response) => {
            dispatch(addPowerLoopingPoints({
                points: response.data.data.coordinate_array,
                cablesByFormula: response.data.data?.cables_by_formula,
                cablesByCalculation: response.data.data?.cables_by_calc,
            }))
        }).catch((error) => {
            console.error(error)
        })
    }

    useEffect(() => {
        if (['Nyx', 'Nyx Modular', 'Nyx OD'].includes(selectedSeriesOption.label)) {
            setValue('warranty', {
                value: '1',
                label: '3 Years'
            })
            setValue('spare', '5')
        } else {
            setValue('warranty', {
                value: '3',
                label: '7 Years'
            })
            setValue('spare', '7')
        }
    }, [selectedSeriesOption])

    useEffect(() => {
        if (['Cabinet Matrix'].includes(selectedUnitOption.label)) {
            setValue('aspectRatio', {
                value: '0',
                label: 'Any'
            })
        }
        if (['Pixel'].includes(selectedUnitOption.label)) {
            setValue('aspectRatio', {
                value: '0',
                label: 'Any'
            })

            let seriesTypeId = getValues('seriesType').value;
            if (seriesTypeId != undefined) {
                let details = storedSeriesTypeList[seriesTypeId];
                let newLength = (details['cabinet_1_length_mm'] / details['module_length']) * details['module_pixel_length'];
                let newHeight = (details['cabinet_1_height_mm'] / details['module_height']) * details['module_pixel_height'];
                setValue('length', Math.ceil(parseFloat(newLength).toFixed(1)))
                setValue('height', Math.ceil(parseFloat(newHeight).toFixed(1)))
            }
        }
    }, [selectedUnitOption])

    return (
        <Col id='main-container-order' className='bg-img p-0' style={{ height: '100%', paddingTop: "2%", paddingLeft: "2%", paddingRight: "2%" }}>
            <Form onSubmit={handleSubmit(onsubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                <Card>
                    <CardBody>
                        <div>
                            <FormGroup>
                                <legend>Client Information</legend>
                                <hr></hr>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Client Name</b></Label>
                                        <Controller id="clientName" name="clientName" control={control}
                                            render={({ field }) => (
                                                <Input
                                                    placeholder="Enter Client Name" autoComplete="off"
                                                    invalid={errors.clientName && true}
                                                    {...field}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {errors.clientName && (
                                            <FormFeedback>{errors.clientName.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Partner Name</b></Label>
                                        <Controller id="partnerName" name="partnerName" control={control}
                                            render={({ field }) => (
                                                <Input
                                                    placeholder="Enter Partner Name" autoComplete="off"
                                                    invalid={errors.partnerName && true}
                                                    {...field}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {errors.partnerName && (
                                            <FormFeedback>{errors.partnerName.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Generated By</b></Label>
                                        <Controller
                                            name="quotationBy"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={
                                                        [
                                                            {
                                                                value: userData.user_id,
                                                                label: userData.first_name + " " + userData.last_name
                                                            }
                                                        ]
                                                    }
                                                    {...field}
                                                    invalid={errors.length && true}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        if (e === null) {
                                                            setValue("quotationBy", {
                                                                value: 0,
                                                                label: "Select Quotation By"
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {/* <option value={userData.user_id}>{`${userData.first_name} ${userData.last_name}`}</option> */}
                                                </Select>
                                            )}
                                        />
                                        {errors.quotationBy && (
                                            <FormFeedback>{errors.quotationBy.message}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <legend>Series Details</legend>
                                <hr></hr>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Mounting Type</b></Label>
                                        <Controller
                                            name="mountingType"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={Object.values(storedMountingTypeList).map((element) => ({
                                                        value: element.led_mounting_id,
                                                        label: element.mounting
                                                    }))}

                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        if (e === null) {
                                                            setValue("mountingType", {
                                                                value: 0,
                                                                label: "Select Mounting Type"
                                                            })
                                                        } else if (e.label === 'Cabinetless' || e.label === 'Indoor Front Access with Structure') {
                                                            setValue("environment", {
                                                                value: 0,
                                                                label: "Select Environment"
                                                            });
                                                            setValue("series", {
                                                                value: 0,
                                                                label: "Select Series"
                                                            });
                                                            setValue("seriesType", {
                                                                value: 0,
                                                                label: "Select Series Type"
                                                            });
                                                            setEnvironmentOptions([{
                                                                value: 1,
                                                                label: 'Indoor'
                                                            }]);

                                                        } else if (e.label === "Pole Mount") {
                                                            setValue("environment", {
                                                                value: 0,
                                                                label: "Select Environment"
                                                            });
                                                            setValue("series", {
                                                                value: 0,
                                                                label: "Select Series"
                                                            });
                                                            setValue("seriesType", {
                                                                value: 0,
                                                                label: "Select Series Type"
                                                            });
                                                            setEnvironmentOptions([{
                                                                value: 2,
                                                                label: 'Outdoor'
                                                            }]);
                                                        }
                                                        else {
                                                            setValue("environment", {
                                                                value: 0,
                                                                label: "Select Environment"
                                                            });
                                                            setValue("series", {
                                                                value: 0,
                                                                label: "Select Series"
                                                            });
                                                            setValue("seriesType", {
                                                                value: 0,
                                                                label: "Select Series Type"
                                                            });
                                                            setEnvironmentOptions(envOptions)
                                                            // setOrderSeriesList(resetSeriesOption)

                                                        }
                                                        dispatch(changeOrderSeriesList({ orderSeriesList: [] }));
                                                        dispatch(changeOrderSeriesTypeList({ orderSeriesTypeList: [] }));
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.mountingType && (
                                            <FormFeedback>{errors.mountingType.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Location <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="location"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={Object.values(storedLocationList).map((element) => ({
                                                        value: element.city_id,
                                                        label: element.name
                                                    }))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        if (e === null) {
                                                            setValue("location", {
                                                                value: 0,
                                                                label: "Select Location"
                                                            })
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.location && (
                                            <FormFeedback>{errors.location.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Client Type</b></Label>
                                        <Controller
                                            name="clientType"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={Object.values(storedClientTypeList).map((element) => ({
                                                        value: element.client_type_id,
                                                        label: element.client
                                                    }))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        if (e === null) {
                                                            setValue("clientType", {
                                                                value: 0,
                                                                label: "Select Client Type"
                                                            })
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.clientType && (
                                            <FormFeedback>{errors.clientType.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Environment <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="environment"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={environmentOptions.map((e) => ({
                                                        value: e.value,
                                                        label: e.label
                                                    }))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setFormChangeFlag(true);

                                                        if (e === null) {
                                                            setValue("environment", {
                                                                value: 0,
                                                                label: "Select Environment"
                                                            });
                                                        }

                                                        else {
                                                            let environment = e.value;
                                                            let selectedSeries = Object.values(storedSeriesList[environment]);

                                                            let mounting = getValues('mountingType').label
                                                            if (mounting === 'Cabinetless') {
                                                                let temp = selectedSeries.filter((value, index, array) => {
                                                                    return value.series_name === 'Nyx Modular';
                                                                });

                                                                selectedSeries = temp;
                                                            } else if (mounting === 'Indoor Front Access with Structure') {
                                                                let temp = selectedSeries.filter((value, index, array) => {
                                                                    return value.front_access != 0;
                                                                });
                                                                selectedSeries = temp;

                                                            }
                                                            dispatch(changeOrderSeriesList({ orderSeriesList: selectedSeries }));
                                                            dispatch(changeOrderSeriesTypeList({ orderSeriesTypeList: [] }));
                                                        }
                                                        setValue("series", {
                                                            value: 0,
                                                            label: "Select Series"
                                                        });
                                                        setValue("seriesType", {
                                                            value: 0,
                                                            label: "Select Series Description"
                                                        });
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.environment && (
                                            <FormFeedback>{errors.environment.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Series <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="series"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={orderSeriesList.map((element) => ({
                                                        value: element.series_id,
                                                        label: element.is_active === 0 ? `${element.series_name} (Not Available)` : element.series_name,
                                                        isDisabled: element.is_active === 0
                                                    }))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        let environment = getValues('environment').value;
                                                        if (e === null) {
                                                            setValue("series", {
                                                                value: 0,
                                                                label: "Select Series"
                                                            });
                                                        } else {
                                                            let seriesId = e.value;
                                                            let selectedSeriesType = {};
                                                            let firstIndoorSeriesTypeIds = Object.keys(storedSeriesList[environment][seriesId]['series_type']);
                                                            for (const key in storedSeriesTypeList) {
                                                                if (firstIndoorSeriesTypeIds.includes(key)) {
                                                                    selectedSeriesType[key] = storedSeriesTypeList[key];
                                                                }
                                                            }
                                                            dispatch(changeOrderSeriesTypeList({ orderSeriesTypeList: Object.values(selectedSeriesType) }));

                                                            setValue("seriesType", {
                                                                value: 0,
                                                                label: "Select Series Description"
                                                            });
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.series && (
                                            <FormFeedback>{errors.series.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Series Description <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="seriesType"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={orderSeriesTypeList.map((element) => ({
                                                        value: element.series_type_id,
                                                        // label: element.name,
                                                        label: element.is_active === 0 ? `${element.name} (Not Available)` : element.name,
                                                        isDisabled: element.is_active === 0
                                                    }))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        if (e === null) {
                                                            setValue("seriesType", {
                                                                value: 0,
                                                                label: "Select Series Description"
                                                            })
                                                        } else {
                                                            if (e.label === UNIFY_SERIES_TYPE_125_ORDER && selectedSeriesOption.label === UNIFY_SERIES) {
                                                                setValue('unit', {
                                                                    value: 'cbmtrx',
                                                                    label: 'Cabinet Matrix'
                                                                });
                                                                setValue('length', 4);
                                                                setValue('height', 4);
                                                                setValue('diagonal', "")
                                                            } else if (e.label === UNIFY_SERIES_TYPE_156_ORDER && selectedSeriesOption.label === UNIFY_SERIES) {
                                                                setValue('unit', {
                                                                    value: 'cbmtrx',
                                                                    label: 'Cabinet Matrix'
                                                                });
                                                                setValue('length', 5);
                                                                setValue('height', 5);
                                                                setValue('diagonal', "")
                                                            }
                                                            let seriesTypeId = e.value;
                                                            if (seriesTypeId != undefined) {
                                                                let details = storedSeriesTypeList[seriesTypeId];
                                                                if (getValues('unit').value == 'px') {
                                                                    let newLength = (details['cabinet_1_length_mm'] / details['module_length']) * details['module_pixel_length'];
                                                                    let newHeight = (details['cabinet_1_height_mm'] / details['module_height']) * details['module_pixel_height'];
                                                                    setValue('length', Math.ceil(parseFloat(newLength).toFixed(1)));
                                                                    setValue('height', Math.ceil(parseFloat(newHeight).toFixed(1)))
                                                                }
                                                                let chkpdr = details['pdr'] == 1 ? { value: 1, label: "Yes" } : { value: 2, label: "No" }
                                                                let chkhdr = details['hdr'] == 1 ? { value: 1, label: "Yes" } : { value: 2, label: "No" }
                                                                let chkgold = details['gold'] == 1 ? { value: 1, label: "Yes" } : { value: 2, label: "No" }
                                                                let chkfa = details['front_access'] == 1 ? { value: 1, label: "Yes" } : { value: 2, label: "No" }
                                                                let chkpfc = details['pfc'] == 1 ? { value: 1, label: "Yes" } : { value: 2, label: "No" }
                                                                let chkhr = details['high_refresh'] == 1 ? { value: 1, label: "Yes" } : { value: 2, label: "No" }
                                                                setValue('chkPowerBackup', chkpdr)
                                                                setValue('chkDataBackup', chkpdr)
                                                                setValue('chkHdr10', chkhdr)
                                                                setValue('chkFrontAccess', chkfa)
                                                                setValue('chkGoldWire', chkgold)
                                                                setValue('chkPFC', chkpfc)
                                                                setValue('chkHighRefresh', chkhr)
                                                            }
                                                        }
                                                    }}
                                                ></Select>
                                            )}
                                        />
                                        {errors.seriesType && (
                                            <FormFeedback>{errors.seriesType.message}</FormFeedback>
                                        )}
                                    </Col>

                                    {/* {orderSeriesTypeList.map((element) => <option disabled={element?.is_active === 0} value={`${element?.series_type_id}`}>{`${element?.name}`}</option>)} */}
                                    {/* {orderSeriesTypeList.map((ele, index) => <option disabled={ele?.is_active === 0} key={index} value={`${ele?.series_type_id}`}>{ele?.name}</option>)} */}

                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <legend>Pricing Details</legend>
                                <hr></hr>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Quantity <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller name="quantity" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Quantity" autoComplete="off"
                                                    invalid={errors.quantity && true}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e == null) {
                                                            setValue("quantity", 1)
                                                        } else {
                                                            if (e.target.value.length == 0) {
                                                                setValue("quantity", 1)
                                                            }
                                                        }
                                                    }}

                                                />
                                            )}
                                        />
                                        {errors.quantity && (
                                            <FormFeedback>{errors.quantity.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Warranty</b></Label>
                                        <Controller
                                            name="warranty"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: '3 Years' },
                                                        { value: 2, label: '5 Years' },
                                                        { value: 3, label: '7 Years' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        if (e === null) {
                                                            setValue("warranty", {
                                                                value: 0,
                                                                label: "Select Warranty"
                                                            })
                                                        } else {
                                                            if (e.value === 1) {
                                                                setValue('spare', 5)
                                                            } else if (e.value === 3) {
                                                                setValue('spare', 7)
                                                            }
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.warranty && (
                                            <FormFeedback>{errors.warranty.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Spare Modules (%)</b></Label>
                                        <Controller id="spare" name="spare" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Spare Modules" autoComplete="off"
                                                    invalid={errors.spare && true}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e == null) {
                                                            setValue("spare", 7)
                                                        } else if (e.target.value.length == 0) {
                                                            setValue("spare", 7)
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.spare && (
                                            <FormFeedback>{errors.spare.message}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <legend>LED Layout Details</legend>
                                <hr></hr>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Aspect Ratio <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="aspectRatio"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[{ value: 0, label: 'Any' }].concat(Object.values(storedAspectRatioList).map((element) => ({
                                                        value: element.aspect_ratio_id,
                                                        label: element.orientation + ' - ' + element.aspect_ratio
                                                    })))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        if (e === null) {
                                                            setValue("aspectRatio", {
                                                                value: 0,
                                                                label: "Select Aspect Ratio"
                                                            })
                                                        } else {
                                                            let arId = e.value;
                                                            if (arId != 0) {
                                                                let arLength = parseInt(storedAspectRatioList[arId].width);
                                                                let arHeight = parseInt(storedAspectRatioList[arId].height);
                                                                let fieldHeight = getValues('height');
                                                                let fieldLength = getValues('length');

                                                                if (fieldLength !== '') {
                                                                    fieldHeight = parseFloat((fieldLength * arHeight) / arLength).toFixed(1);
                                                                } else if (fieldHeight !== '') {
                                                                    fieldLength = parseFloat((fieldHeight * arLength) / arHeight).toFixed(1);
                                                                } else {
                                                                    fieldLength = 0;
                                                                    fieldHeight = 0;
                                                                }

                                                                let unit = getValues('unit').value;
                                                                ["cbmtrx", "px"].includes(unit) ? setValue('height', Math.ceil(fieldHeight)) : setValue('height', fieldHeight);
                                                                ["cbmtrx", "px"].includes(unit) ? setValue('length', Math.ceil(fieldLength)) : setValue('length', fieldLength);

                                                                calculateDiagonal(fieldLength, fieldHeight, true);
                                                            }
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.aspectRatio && (
                                            <FormFeedback>{errors.aspectRatio.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Unit <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="unit"
                                            control={control}

                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    isDisabled={selectedSeriesOption.label === UNIFY_SERIES}
                                                    className="react-select"
                                                    options={Object.values(storedUnitList).map((element) => ({
                                                        value: element.short_code,
                                                        label: element.unit
                                                    }))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        if (e === null) {
                                                            setDDUnit({
                                                                value: 0,
                                                                label: "Select Unit"
                                                            })
                                                            setValue("unit", ddUnit)
                                                        } else {
                                                            setDDUnit(e);

                                                            let currentLength = getValues('length');
                                                            let currentHeight = getValues('height');

                                                            let newLength = (currentLength != 0 && currentLength != "") ? parseFloat(getConvertedUnitValue(prevUnitRef.current.value, e.value, getValues('length'))).toFixed(1) : currentLength;
                                                            let newHeight = (currentHeight != 0 && currentLength != "") ? parseFloat(getConvertedUnitValue(prevUnitRef.current.value, e.value, getValues('height'))).toFixed(1) : currentHeight;

                                                            if (["cbmtrx", "px"].includes(e.value)) {
                                                                setValue('length', Math.ceil(newLength))
                                                                setValue('height', Math.ceil(newHeight))
                                                                setValue('diagonal', '');
                                                            } else {
                                                                setValue('length', newLength)
                                                                setValue('height', newHeight)
                                                            }
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.unit && (
                                            <FormFeedback>{errors.unit.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Fit <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="fit"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: "best", label: "Best Fit" },
                                                        { value: "max", label: "Maximum Fit" },
                                                        { value: "min", label: "Minimum Fit" },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        if (e === null) {
                                                            setValue("fit", {
                                                                value: 0,
                                                                label: "Select Fit"
                                                            })
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.fit && (
                                            <FormFeedback>{errors.fit.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label" disabled={selectedSeriesOption.label === UNIFY_SERIES}><b>Length <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller id="length" name="length" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Length" autoComplete="off"
                                                    disabled={selectedSeriesOption.label === UNIFY_SERIES}
                                                    invalid={errors.length && true}
                                                    {...field}
                                                    value={field.value}
                                                    max={maxDimensionLimit[selectedUnitOption.value]}
                                                    min={minDimensionLimit[selectedUnitOption.value]}
                                                    step="any"
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setValue('length', e.target.value, { shouldValidate: true })
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        let diagonalHeight = getValues('height')
                                                        let unit = selectedUnitOption.value
                                                        let value = parseFloat(getValues('length')).toFixed(1);
                                                        let arId = getValues('aspectRatio').value;
                                                        if (arId != 0 && selectedUnitOption != "px" && selectedUnitOption != "cbmtrx") {
                                                            let arLength = parseInt(storedAspectRatioList[arId].width);
                                                            let arHeight = parseInt(storedAspectRatioList[arId].height);
                                                            let calculatedHeight = (arHeight * value / arLength);
                                                            //console.log("arLength : " + arLength + " ===== arHeight" + arHeight);
                                                            setValue('height', parseFloat(calculatedHeight).toFixed(1));

                                                            calculateDiagonal(value, calculatedHeight, true);
                                                        } else {
                                                            if (diagonalHeight != 0 && e.target.value != 0 && unit != "px" && unit != "cbmtrx") {
                                                                let calculatedDiagonal = (Math.sqrt((diagonalHeight * diagonalHeight) + (e.target.value * e.target.value)));
                                                                let convertedDiagonal = getConvertedUnitValue(unit, "inch", calculatedDiagonal)
                                                                setValue('diagonal', parseFloat(convertedDiagonal).toFixed(1));
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.length && (
                                            <FormFeedback>{errors.length.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Height <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller id="height" name="height" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Height" autoComplete="off"
                                                    disabled={selectedSeriesOption.label === UNIFY_SERIES}
                                                    invalid={errors.height && true}
                                                    {...field}
                                                    value={field.value}
                                                    min={minDimensionLimit[selectedUnitOption.value]}
                                                    max={maxDimensionLimit[selectedUnitOption.value]}
                                                    step="any"
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setValue('height', e.target.value, { shouldValidate: true })
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        let diagonalLength = getValues('length')
                                                        let value = parseFloat(e.target.value).toFixed(1);
                                                        let lengthValue = parseFloat(getValues('length')).toFixed(1);
                                                        let arId = getValues('aspectRatio').value;
                                                        let unit = selectedUnitOption.value;
                                                        if (arId != 0) {
                                                            let arLength = parseInt(storedAspectRatioList[arId].width);
                                                            let arHeight = parseInt(storedAspectRatioList[arId].height);
                                                            let calculatedLength = (arLength * value / arHeight);
                                                            //console.log("arLength : " + arLength + " ===== arHeight" + arHeight);
                                                            setValue('length', parseFloat(calculatedLength).toFixed(1));

                                                            calculateDiagonal(calculatedLength, value, true);
                                                        } else {
                                                            if (diagonalLength != 0 && e.target.value != 0 && unit != "px" && unit != "cbmtrx") {
                                                                let calculatedDiagonal = (Math.sqrt((diagonalLength * diagonalLength) + (e.target.value * e.target.value)));
                                                                let convertedDiagonal = getConvertedUnitValue(unit, "inch", calculatedDiagonal)
                                                                setValue('diagonal', parseFloat(convertedDiagonal).toFixed(1));
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.height && (
                                            <FormFeedback>{errors.height.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Diagonal (Inches)</b></Label>
                                        <Controller id="diagonal" name="diagonal" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Diagonal" autoComplete="off"
                                                    disabled={selectedSeriesOption.label === UNIFY_SERIES}
                                                    invalid={errors.diagonal && true}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        clearErrors('height')
                                                        clearErrors('length')
                                                        let userLength = getValues('length')
                                                        let userHeight = getValues('height')
                                                        let unit = getValues('unit').value;
                                                        let value = getValues('diagonal');
                                                        if (userLength == '' && userHeight == '') {

                                                            if (value != '' && value != 0 && value != NaN && value > 0) {
                                                                setFormChangeFlag(true);
                                                                if (getValues('aspectRatio').value == 0) {
                                                                    setValue('aspectRatio', { value: 1, label: 'Landscape - 16:9' });
                                                                }

                                                                let arId = getValues('aspectRatio').value;
                                                                let arLength = parseInt(storedAspectRatioList[arId].width);
                                                                let arHeight = parseInt(storedAspectRatioList[arId].height);
                                                                let arDiagonal = Math.sqrt((arLength * arLength) + (arHeight * arHeight));

                                                                let calculatedLength = (arLength * value / arDiagonal);
                                                                let calculatedHeight = (arHeight * value / arDiagonal);

                                                                if (["cbmtrx", "px"].includes(unit)) {
                                                                    setValue('unit', { value: "inch", label: 'Inch' });
                                                                    setDDUnit({ value: "inch", label: 'Inch' });
                                                                } else {
                                                                    calculatedLength = getConvertedUnitValue("inch", unit, calculatedLength);
                                                                    calculatedHeight = getConvertedUnitValue("inch", unit, calculatedHeight);
                                                                }
                                                                setValue('length', parseFloat(calculatedLength).toFixed(1));
                                                                setValue('height', parseFloat(calculatedHeight).toFixed(1));
                                                            } else {
                                                                setValue('diagonal', '');
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.diagonal && (
                                            <FormFeedback>{errors.diagonal.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Height from Floor to Bottom of LED (Meters)</b></Label>
                                        <Controller id="ledBottomHeight" name="ledBottomHeight" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Bottom Height" autoComplete="off"
                                                    invalid={errors.ledBottomHeight && true}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e == null) {
                                                            setValue("ledBottomHeight", 0)
                                                        } else {
                                                            if (e.target.value.length == 0) {
                                                                setValue("ledBottomHeight", 0)
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.ledBottomHeight && (
                                            <FormFeedback>{errors.ledBottomHeight.message}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <legend>Add Ons</legend>
                                <hr></hr>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Power Redundancy</b></Label>
                                        <Controller
                                            name="chkPowerBackup"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isDisabled={(selSeriesTypeOption != undefined && selSeriesTypeOption['pdr'] == 0)}
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.chkPowerBackup && (
                                            <FormFeedback>{errors.chkPowerBackup.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Data Redundancy</b></Label>
                                        <Controller
                                            name="chkDataBackup"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isDisabled={(selSeriesTypeOption != undefined && selSeriesTypeOption['pdr'] == 0)}
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.chkDataBackup && (
                                            <FormFeedback>{errors.chkDataBackup.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>HDR10</b></Label>
                                        <Controller
                                            name="chkHdr10"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isDisabled={(selSeriesTypeOption != undefined && selSeriesTypeOption['hdr'] == 0)}
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.chkHdr10 && (
                                            <FormFeedback>{errors.chkHdr10.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Add Front Access</b></Label>
                                        <Controller
                                            name="chkFrontAccess"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isDisabled={(selSeriesTypeOption != undefined && selSeriesTypeOption['front_access'] == 0)}
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.chkFrontAccess && (
                                            <FormFeedback>{errors.chkFrontAccess.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Gold Wire</b></Label>
                                        <Controller
                                            name="chkGoldWire"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isDisabled={(selSeriesTypeOption != undefined && selSeriesTypeOption['gold'] == 0)}
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.chkGoldWire && (
                                            <FormFeedback>{errors.chkGoldWire.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Power Factor Correction (PFC)</b></Label>
                                        <Controller
                                            name="chkPFC"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isDisabled={(selSeriesTypeOption != undefined && selSeriesTypeOption['pfc'] == 0)}
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.chkPFC && (
                                            <FormFeedback>{errors.chkPFC.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>High Refresh</b></Label>
                                        <Controller
                                            name="chkHighRefresh"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isDisabled={(selSeriesTypeOption != undefined && selSeriesTypeOption['high_refresh'] == 0)}
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.chkHighRefresh && (
                                            <FormFeedback>{errors.chkHighRefresh.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Power Distribution Unit</b></Label>
                                        <Controller
                                            name="pdu"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.pdu && (
                                            <FormFeedback>{errors.pdu.message}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <legend>Miscellaneous</legend>
                                <hr></hr>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Video Processor <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="videoProcessor"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[{ value: 0, label: 'Not Required' }].concat(Object.values(storedVideoProcessorList).map((element) => ({
                                                        value: element.video_processor_id,
                                                        label: element.name
                                                    })))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        let vpQuantity = getValues("videoProcessorQuantity")
                                                        if (e === null) {
                                                            setValue("videoProcessor", {
                                                                value: 0,
                                                                label: "Not Required"
                                                            })
                                                        } else if (e.value != 0 && vpQuantity == 0) {
                                                            setValue("videoProcessorQuantity", 1)
                                                        } else if (e.value == 0) {
                                                            setValue("videoProcessorQuantity", 0)
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.videoProcessor && (
                                            <FormFeedback>{errors.videoProcessor.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Accessories <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="accessories"
                                            control={control}
                                            render={({ field }) => (
                                                <Select multiple={false}
                                                    isClearable={false}
                                                    isMulti
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[{ value: 0, label: 'Not Required' }].concat(Object.values(storedAccessoriesList).map((element) => ({
                                                        value: element.accessories_id,
                                                        label: element.name
                                                    })))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        let accQuantity = getValues("accessoriesQuantity")
                                                        if (e === null) {
                                                            setValue("accessories", {
                                                                value: 0,
                                                                label: "Select Accessories"
                                                            })
                                                        } else {
                                                            // let temp = [{ value: 0, label: 'Not Required' }];
                                                            // if (e.length == 0) {
                                                            //     setValue("accessories",temp)
                                                            // } else if (e.length > 1) {
                                                            //     if (e[e.length - 1].value == 0) {
                                                            //         temp = [{ value: 0, label: 'Not Required' }];
                                                            //     } else {
                                                            //         temp = e.filter((value, index, array) => {
                                                            //             return value.label != 'Not Required'
                                                            //         })                                                                    
                                                            //     }
                                                            //     setValue("accessories", temp);
                                                            // }
                                                            if (e.length != 1) {
                                                                let temp = [{ value: 0, label: 'Not Required' }];
                                                                if (e.length > 1) {
                                                                    if (e[e.length - 1].value != 0) {
                                                                        temp = e.filter((value, index, array) => {
                                                                            return value.label != 'Not Required'
                                                                        })
                                                                    }
                                                                    if (e[e.length - 1].value != 0 && accQuantity == 0) {
                                                                        setValue("accessoriesQuantity", 1)
                                                                    }
                                                                    if (e[e.length - 1].value == 0) {
                                                                        setValue("accessoriesQuantity", 0)
                                                                    }
                                                                }
                                                                setValue("accessories", temp);
                                                            }
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.accessories && (
                                            <FormFeedback>{errors.accessories.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Media Player <span style={{ color: 'red' }}>*</span></b></Label>
                                        <Controller
                                            name="mediaPlayer"
                                            control={control}
                                            className="react-select"
                                            classNamePrefix="select"
                                            render={({ field }) => (
                                                <Select
                                                    options={[{ value: 0, label: 'Not Required' }].concat(Object.values(storedMediaPlayerList).map((ele) => ({
                                                        value: ele.media_player_id,
                                                        label: ele.model
                                                    })))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        let mpQuantity = getValues("mediaPlayerQuantity")
                                                        if (e === null) {
                                                            setValue("mediaPlayer", {
                                                                value: 0,
                                                                label: "Select Media Player"
                                                            })
                                                        } else if (e.value != 0 && mpQuantity == 0) {
                                                            setValue("mediaPlayerQuantity", 1)
                                                        } else if (e.value == 0) {
                                                            setValue("mediaPlayerQuantity", 0)
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.mediaPlayer && (
                                            <FormFeedback>{errors.mediaPlayer.message}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Video Processor Quantity</b></Label>
                                        <Controller id="videoProcessorQuantity" name="videoProcessorQuantity" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Video Processor Quantity" autoComplete="off"
                                                    invalid={errors.videoProcessorQuantity && true}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e, { shouldValidate: true })
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e == null) {
                                                            setValue("videoProcessorQuantity", 0, { shouldValidate: true })
                                                        } else {
                                                            if (e.target.value.length == 0) {
                                                                setValue("videoProcessorQuantity", 0, { shouldValidate: true })
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.videoProcessorQuantity && (
                                            <FormFeedback>{errors.videoProcessorQuantity.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Accessories Quantity</b></Label>
                                        <Controller id="accessoriesQuantity" name="accessoriesQuantity" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Accessories Quantity" autoComplete="off"
                                                    invalid={errors.accessoriesQuantity && true}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e, { shouldValidate: true })
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e == null) {
                                                            setValue("accessoriesQuantity", 0, { shouldValidate: true })
                                                        } else {
                                                            if (e.target.value.length == 0) {
                                                                setValue("accessoriesQuantity", 0, { shouldValidate: true })
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.accessoriesQuantity && (
                                            <FormFeedback>{errors.accessoriesQuantity.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Media Player Quantity</b></Label>
                                        <Controller id="mediaPlayerQuantity" name="mediaPlayerQuantity" control={control}
                                            render={({ field }) => (
                                                <Input type="number"
                                                    placeholder="Enter Media Player Quantity" autoComplete="off"
                                                    invalid={errors.mediaPlayerQuantity && true}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e, { shouldValidate: true })
                                                        setFormChangeFlag(true);
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e == null) {
                                                            setValue("mediaPlayerQuantity", 0, { shouldValidate: true })
                                                        } else {
                                                            if (e.target.value.length == 0) {
                                                                setValue("mediaPlayerQuantity", 0, { shouldValidate: true })
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.mediaPlayerQuantity && (
                                            <FormFeedback>{errors.mediaPlayerQuantity.message}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Special Shapes</b></Label>
                                        <Controller
                                            name="specialShapes"
                                            control={control}
                                            classNamePrefix="select"
                                            // theme={selectThemeColors}
                                            className="react-select"
                                            render={({ field }) => (
                                                <Select
                                                    options={[{ value: 0, label: 'None' }].concat(Object.values(storedSpecialShapesList).map((element) => ({
                                                        value: element.special_shape_id,
                                                        label: element.name
                                                    })))}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                        if (e === null) {
                                                            setValue("specialShapes", {
                                                                value: 0,
                                                                label: "None"
                                                            })
                                                        }
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.specialShapes && (
                                            <FormFeedback>{errors.specialShapes.message}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col md="4" className="mb-4">
                                        <Label className="form-label"><b>Stock Product (LED Display)</b></Label>
                                        <Controller
                                            name="stockProduct"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    classNamePrefix="select"
                                                    // theme={selectThemeColors}
                                                    className="react-select"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 2, label: 'No' },
                                                    ]}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFormChangeFlag(true);
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.stockProduct && (
                                            <FormFeedback>{errors.stockProduct.message}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                            </FormGroup>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <CustomButton style={{ float: "right", backgroundColor: "var(--primary-color)" }} type="submit" >Next</CustomButton>
                    </CardFooter>
                </Card>
            </Form>
        </Col>
    )
}

export default memo(OrderForm);