import { useState } from "react";
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import ContentForm from "./ContentForm";
import LedForm from "./LedForm";
import RoomForm from "./RoomForm";
import DeleteDesignForm from "./DeleteDesignForm";

const SettingsTab = () => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (tab) => {
        if (tab !== activeTab) {
            setActiveTab(tab);
        }
    }

    return (
        <Row style={{ height: '5%', width: '100%', margin: '0', padding: '0' }}>
            <Nav tabs style={{ padding: '0' }}>
                <NavItem style={{ width: '50%' }}>
                    <NavLink
                        active={activeTab === '1'}
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => toggle('1')}
                    >
                        Configuration
                    </NavLink>
                </NavItem>
                {/* <NavItem style={{ width: '33.33%' }}>
                    <NavLink
                        active={activeTab === '2'}
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => toggle('2')}
                    >
                        Room
                    </NavLink>
                </NavItem> */}
                <NavItem style={{ width: '50%' }}>
                    <NavLink
                        active={activeTab === '3'}
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => toggle('3')}
                    >
                        Content
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <LedForm />
                    <RoomForm />
                    <DeleteDesignForm />
                </TabPane>
                {/* <TabPane tabId="2">
                    <RoomForm />
                </TabPane> */}
                <TabPane tabId="3">
                    <ContentForm />
                </TabPane>
            </TabContent>
        </Row>
    )
}

export default SettingsTab;