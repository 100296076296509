import { memo } from "react";
import { useSelector } from "react-redux";
import { convertMeterToFt } from "../Functions";

const Unit = ({ value, style }) => {

    const unit = useSelector(state => state.wall.unit);
    return (
        <span style={style}>{`${value}${unit}`}</span>
    )
}

export default memo(Unit);