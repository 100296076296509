import axios from "axios";
import { useCallback, useContext, useEffect, useReducer, memo, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { MAX_CABINET_COLS, MAX_CABINET_ROWS, MAX_ROOM_HEIGHT, MAX_ROOM_WIDTH, MIN_ROOM_HEIGHT, MIN_ROOM_WIDTH, UNIFY_SERIES, UNIFY_SERIES_TYPE_125_CONFIG, UNIFY_SERIES_TYPE_156_CONFIG, VEGA_VEGA_PLUS_195 } from "../../config/config";
import { API_BASE_URL } from "../../config/api";
import { LoaderContext } from "../../context/context";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { viewingDistanceReducer } from "../../reducers/reducers";
import { addDesign, deleteDesign, changeSelectedDesign, updatePosition } from "../../redux/reducers/designReducer";
import { changeEnvironment, changeShowProps } from "../../redux/reducers/environmentReducer";
import {
    changeFitToWall, changeFitToCentre, changeLedCondition, changeLedDimensions, changePosition, resetLedDimensions, resetTransform, changeLocalLedMatrix,
    changeLedTopBottomLeftDistance, changeLocalLedDimensions, changeLocalLedResolution
} from "../../redux/reducers/ledReducer";
import { addCablesByCalc, addCablesByFormula, addDataLoopingPoints, addPowerLoopingPoints } from "../../redux/reducers/loopingDiagramReducer";
import { changeModuleDimensions, changeModuleDimensionsPx, showModules, changeCheckedResolution } from "../../redux/reducers/ledReducer";
import {
    changeApplicationDropDownList, changeApplicationDropDownValue, changePixelPitchDropDownList, changePixelPitchDropDownValue, changeSeries,
    changeSeriesDropDownList, changeSeriesDropDownValue, changeViewingDistance
} from "../../redux/reducers/selectedSeriesReducer";
import { addApplicationList, addSeriesList, addSeriesTypeList } from "../../redux/reducers/seriesReducer";
import { addSpecification } from "../../redux/reducers/specificationsReducer";
import { changeWallDimensions, resetWallDimensions } from "../../redux/reducers/wallReducer";
import CustomButton from "../common/components/CustomButtom";
import { convertFtToMeter, convertMeterToFt, Toast } from "../common/Functions";
import { LED_HEIGHT_EXCEED_WALL, LED_WIDTH_EXCEED_WALL, MAX_ROOM_HEIGHT_LIMIT_REACHED, MAX_ROOM_WIDTH_LIMIT_REACHED, MIN_ROOM_HEIGHT_LIMIT_REACHED, MIN_ROOM_WIDTH_LIMIT_REACHED, WALL_EXCEEDED } from "../common/Messages";
import auditorium1 from '../../images/presets/auditorium/auditorium1.png';
import classroom1 from '../../images/presets/classroom/classroom1.png';
import conference1 from '../../images/presets/conference/conference1.png';
import newsroom1 from '../../images/presets/newsroom/newsroom1.png';
import retail1 from '../../images/presets/retail/retail1.png';
import rental1 from '../../images/presets/rental/rental1.png';
import controlroom1 from '../../images/presets/controlroom/controlroom1.png';
import lobby1 from '../../images/presets/lobby/lobby1.png';
import { changeContent } from "../../redux/reducers/contentReducer";
import { ROOM_BUFFER_LEFT, ROOM_BUFFER_RIGHT, ROOM_BUFFER_TOP, ROOM_BUFFER_BOTTOM, VEGA_VEGA_PLUS_SERIES } from "../../config/config";
import EnvironmentChangeModal from "./EnvironmentChangeModal";

const LedForm = () => {
    const [rSelected, setRSelected] = useState(1);
    const [checked, setChecked] = useState(3);
    const [invalidFlag, setInvalidFlag] = useState(false);
    const cabinetHeightRef = useRef('');
    const cabinetWidthRef = useRef('');
    const [isEnvironmentModalOpen, setIsEnvironmentModalOpen] = useState(false);
    const environmentConditionRef = useRef('');

    const [viewingRangeState, dispatchViewingRange] = useReducer(viewingDistanceReducer, {
        indoorMin: 1,
        indoorMax: 10,
        outdoorMin: 2,
        outdoorMax: 50
    });

    const { setLoading, diagram, isUnify, setIsUnify } = useContext(LoaderContext);
    const { ledRow, ledCol, cabinetHeight, cabinetWidth, cabinetHeightPx, cabinetWidthPx, showModulesFlag, ledCondition, fitToWall, fitToCentre, wallHeight, wallWidth, unit, design, content, seriesList,
        viewingDist, pixelPitchValue, pixelPitchDropDownValue, seriesDropDownValue, pixelPitchDropDownList, seriesDropDownList, environment, applicationDropDownValue,
        applicationDropDownList, applicationList, seriesTypeList, selectedSeries, showProps, localLedRow, localLedCol, distanceTop, distanceLeft, distanceBottom,
        localWidth, localHeight, localHeightPx, localWidthPx, checkedResolution, specification, position } = useSelector(state => {
            return {
                ledRow: state.led.row,
                ledCol: state.led.col,
                cabinetHeight: state.led.cabinetHeight,
                cabinetWidth: state.led.cabinetWidth,
                cabinetHeightPx: state.led.cabinetHeightPx,
                cabinetWidthPx: state.led.cabinetWidthPx,
                showModulesFlag: state.led.showModules,
                ledCondition: state.led.condition,
                fitToWall: state.led.fitToWall,
                fitToCentre: state.led.fitToCentre,
                wallHeight: state.wall.height,
                wallWidth: state.wall.width,
                unit: state.wall.unit,
                design: state.design,
                content: state.content,
                seriesList: state.series.seriesList,
                viewingDist: state.selectedSeries.viewingDistance,
                pixelPitchValue: state.selectedSeries.pixelPitchValue,
                pixelPitchDropDownValue: state.selectedSeries.pixelPitchDropDownValue,
                seriesDropDownValue: state.selectedSeries.seriesDropDownValue,
                seriesDropDownList: state.selectedSeries.seriesDropDownList,
                pixelPitchDropDownList: state.selectedSeries.pixelPitchDropDownList,
                environment: state.environment.environment,
                applicationDropDownValue: state.selectedSeries.applicationDropDownValue,
                applicationDropDownList: state.selectedSeries.applicationDropDownList,
                applicationList: state.series.applicationList,
                seriesTypeList: state.series.seriesTypeList,
                selectedSeries: state.selectedSeries,
                showProps: state.environment.showProps,
                localLedRow: state.led.localRow,
                localLedCol: state.led.localCol,
                distanceTop: state.led.distanceTop,
                distanceLeft: state.led.distanceLeft,
                distanceBottom: state.led.distanceBottom,
                localWidth: state.led.localWidth,
                localHeight: state.led.localHeight,
                localHeightPx: state.led.localHeightPx,
                localWidthPx: state.led.localWidthPx,
                checkedResolution: state.led.checkedResolution,
                specification: state.specification.specification,
                position: state.led.position,
            }
        }, shallowEqual);
    const dispatch = useDispatch();

    const contentOption = {
        'All': auditorium1,
        'Class Room': classroom1,
        'News Room': newsroom1,
        'Auditorium': auditorium1,
        'Conference Room': conference1,
        'Retail': retail1,
        'Command & Control Room': controlroom1,
        'Lobby': lobby1,
        'Rental': rental1,
        'Rental OD': rental1,
    };

    // Added because if a series with cabinet dimensions bigger than Unify-FHD is selected, and then Unify-FHD is selected, 
    // it would change the cabinet matrix to 4x4, but cabinet dimensions would change only after that.
    // Therefore dimensions of the Unify display would be calculated with the old cabinet size, instead of actual Unify cabinet size.
    // A timeout was implemented to give a slight delay in changing the cabinet matrix, i.e. after the cabinet dimensions changed.
    // However, a setTimeout works with old values, and therefere a reference was created for the cabinet dimensions so the updated 
    // cabinet dimensions can be accessed from within the setTimeout function as well.
    useEffect(() => {
        cabinetHeightRef.current = cabinetHeight;
        cabinetWidthRef.current = cabinetWidth;
    }, [cabinetHeight, cabinetWidth]);

    const gcd = function (a, b) {
        let smaller = Math.min(a, b);
        let hcf = 1;

        for (let i = 1; i <= smaller; i++) {
            if (a % i === 0 && b % i === 0) {
                hcf = i;
            }
        }

        return hcf;
    }

    const fetchPowerLoopingPoints = useCallback(() => {

        return axios.post(`${API_BASE_URL}/series/get-power-loop-points`, {
            cabinet_rows: ledRow,
            cabinet_cols: ledCol,
            series_type_id: selectedSeries.series.series_type_id,
        })
    }, [ledRow, ledCol, selectedSeries.series.series_type_id])

    const fetchDataLoopingPoints = useCallback(() => {
        let new_col = ledCol;
        if (VEGA_VEGA_PLUS_SERIES.includes(seriesDropDownValue)) {
            new_col = Math.ceil(ledCol / 2);
        }

        return axios.post(`${API_BASE_URL}/series/get-data-loop-points`, {
            cabinet_rows: ledRow,
            cabinet_cols: new_col,
            series_type_id: selectedSeries.series.series_type_id,
            hdr: 0
        })
    }, [ledRow, ledCol, selectedSeries.series.series_type_id])

    const fetchSeries = () => {
        return axios.post(`${API_BASE_URL}/series/get-series`, {
            series_id: 0
        })
    }

    const fetchSeriesType = () => {
        return axios.post(`${API_BASE_URL}/series/get-series-type`, {
            series_type_id: 0
        })
    }

    const fetchApplications = () => {
        return axios.post(`${API_BASE_URL}/series/get-application`, {
            type: 0
        })
    }

    const addSpecsToStore = useCallback(() => {
        if (seriesTypeList?.length === 0) return;
        if (!selectedSeries?.series.series_type_id) return;

        const specs = seriesTypeList[selectedSeries.series.series_type_id];
        let factor = specs.series_name == "Vega" ? 2 : 1;
        let suffix = specs.series_name == "Vega" && (ledCol % 2) == 1 ? ' + Customised Cabinets' : '';

        let cabinetDimensions = `${specs.cabinet_1_length_mm * factor}mm x ${specs.cabinet_1_height_mm}mm x ${specs.cabinet_depth_mm}mm` + suffix;

        dispatch(addSpecification({
            specification: { ...specs, specCabinetDimensions: cabinetDimensions },
            index: design.selectedDesignIdx
        }))
    }, [design.selectedDesignIdx, seriesTypeList, selectedSeries.series.series_type_id, ledCol])

    useEffect(() => {
        addSpecsToStore();
    }, [addSpecsToStore])

    useEffect(() => {
        if (!selectedSeries.series.series_type_id) return;

        fetchDataLoopingPoints()
            .then(res => {
                dispatch(addDataLoopingPoints({
                    points: res.data.data.coordinate_array,
                    index: design.selectedDesignIdx
                }))
                dispatch(addCablesByFormula({
                    index: design.selectedDesignIdx,
                    type: 'dataLooping',
                    value: res.data.data?.cables_by_formula
                }))
                dispatch(addCablesByCalc({
                    index: design.selectedDesignIdx,
                    type: 'dataLooping',
                    value: res.data.data?.cables_by_calc
                }))
            })
            .catch(error => console.error(error));

        fetchPowerLoopingPoints()
            .then(res => {
                dispatch(addPowerLoopingPoints({
                    points: res.data.data.coordinate_array,
                    index: design.selectedDesignIdx
                }))
                dispatch(addCablesByFormula({
                    index: design.selectedDesignIdx,
                    type: 'powerLooping',
                    value: res.data.data?.cables_by_formula
                }))
                dispatch(addCablesByCalc({
                    index: design.selectedDesignIdx,
                    type: 'powerLooping',
                    value: res.data.data?.cables_by_calc
                }))
            })
            .catch(error => console.error(error));

    }, [fetchDataLoopingPoints, fetchPowerLoopingPoints, design.selectedDesignIdx])

    useEffect(() => {
        setTimeout(() => {
            const wall = document.getElementById('wall');
            const led = document.getElementById('led');
            const bufferWall = document.getElementById('buffer-wall');
            if (!wall || !led) return;

            const { height, width, top, left } = wall.getBoundingClientRect();
            const { height: ledHeight, width: ledWidth } = led.getBoundingClientRect();
            const { top: bufferTop, left: bufferLeft } = bufferWall.getBoundingClientRect();
            dispatch(changePosition({
                x: (width - ledWidth) / 2 - (bufferLeft - left),
                y: (height - ledHeight) / 2 - (bufferTop - top)
            }))
        }, 100)
    }, [])

    const setPixelPitchDropdownValue = (value) => {
        dispatch(changePixelPitchDropDownValue({
            pixelPitchDropDownValue: value
        }))
    }

    const setSeriesDropDownValue = (value) => {
        dispatch(changeSeriesDropDownValue({
            seriesDropDownValue: value
        }))
    }

    const setSeriesDropDownList = (val) => {
        dispatch(changeSeriesDropDownList({
            seriesDropDownList: val
        }))
    }

    const setPixelPitchDropDownList = (val) => {
        dispatch(changePixelPitchDropDownList({
            pixelPitchDropDownList: val
        }))
    }

    const setApplicationDropDownList = (val) => {
        dispatch(changeApplicationDropDownList({
            applicationDropDownList: val
        }))
    }

    const addInitialDataToStore = (values) => {
        dispatch(addSeriesList({
            seriesList: values[0].data.data
        }))
        dispatch(addSeriesTypeList({
            seriesTypeList: values[2].data.data
        }))
        dispatch(addApplicationList({
            applicationList: values[1].data.data
        }))
    }

    const filterApplications = () => {
        const names = [];
        const applicationListByCondition = ledCondition === 'indoor' ? applicationList['1'] : applicationList['2'];
        if (!applicationListByCondition) return;

        for (const [key, value] of Object.entries(applicationListByCondition)) {
            names.push(value.name);
        }

        setApplicationDropDownList(names);

        dispatch(changeApplicationDropDownValue({
            applicationDropDownValue: names[0]
        }))
    }

    const filterViewingDistance = () => {
        const location_type = ledCondition === 'indoor' ? '1' : '2';
        const dataFromLocation = applicationList[location_type];
        if (!dataFromLocation) return;

        const finalData = Object.values(dataFromLocation).find(ele => ele.name === applicationDropDownValue);
        if (!finalData) return;

        if (ledCondition === 'indoor') {
            dispatchViewingRange({
                type: 'setViewingDistanceIndoor',
                indoorMin: unit == 'ft' ? handleUnitChange(finalData.min_distance) : finalData.min_distance,
                indoorMax: unit == 'ft' ? handleUnitChange(finalData.max_distance) : finalData.max_distance
            })
        } else {
            dispatchViewingRange({
                type: 'setViewingDistanceOutdoor',
                outdoorMin: unit == 'ft' ? handleUnitChange(finalData.min_distance) : finalData.min_distance,
                outdoorMax: unit == 'ft' ? handleUnitChange(finalData.max_distance) : finalData.max_distance,
            })
        }
    }

    const filterSeries = () => {
        let min = parseFloat(ledCondition === 'indoor' ? viewingRangeState.indoorMin : viewingRangeState.outdoorMin)
        let max = parseFloat(ledCondition === 'indoor' ? viewingRangeState.indoorMax : viewingRangeState.outdoorMax)
        let vDist = parseFloat(viewingDist);
        if (vDist !== undefined && vDist !== "" && vDist >= min && vDist <= max) {
            const location_type = ledCondition === 'indoor' ? '1' : '2';
            const dataFromLocation = applicationList[location_type];

            const finalData = Object.values(dataFromLocation).find(ele => ele.name === applicationDropDownValue);
            if (!finalData) {
                return;
            }

            let viewingDistInMeter = unit === 'ft' ? Number(convertFtToMeter(parseFloat(vDist))).toFixed(2) : vDist;
            // For handling code break if the viewing distance is set to 1m ,and we choose applications where min. viewing ditance is > 1.
            let viewingDistComparison = viewingDistInMeter < finalData.min_distance ? finalData.min_distance : viewingDistInMeter;

            const recommended = finalData.recommended;
            const seriesIds = finalData.series_id;
            const seriesNames = Object.values(seriesList[location_type]).filter(ele => seriesIds.includes(ele.series_id) && Object.values(ele.series_type).some(inner_elem => inner_elem.pixel_pitch_1 < viewingDistComparison));

            let recommendedValue = seriesNames[0].series_name;
            seriesNames.forEach(function (value, index, array) {
                if (value.series_name == seriesDropDownValue) {
                    recommendedValue = seriesDropDownValue;
                    return;
                }
            })
            const seriesRecomended = [];

            seriesNames.map(ele => {
                const obj = { ...ele };
                obj.recommended = obj.series_id === recommended ? 1 : 0;
                recommendedValue = obj.series_id === recommended ? obj.series_name : recommendedValue;
                if (obj.is_retired == 0) {
                    seriesRecomended.push(obj);
                }
            })

            setSeriesDropDownList(seriesRecomended);
            setSeriesDropDownValue(recommendedValue);
        }
    }

    const filterPixelP = () => {
        let min = ledCondition === 'indoor' ? viewingRangeState.indoorMin : viewingRangeState.outdoorMin
        let max = ledCondition === 'indoor' ? viewingRangeState.indoorMax : viewingRangeState.outdoorMax
        if (viewingDist !== undefined && viewingDist !== "" && viewingDist >= min && viewingDist <= max) {
            const location_type = ledCondition === 'indoor' ? '1' : '2';
            const dataFromLocation = seriesList[location_type];

            const finalData = Object.values(dataFromLocation).find(ele => ele.series_name === seriesDropDownValue && ele.is_retired == 0);
            if (!finalData) {
                return;
            }

            const filteredToViewDist = Object.entries(finalData.series_type).filter(ele => {
                return parseFloat(ele[1].pixel_pitch_1) <= parseFloat(viewingDist) && ele[1].series_type_active == 1
            });

            const pixelPitches = filteredToViewDist.map(ele => {
                let temp = ele[1].pixel_pitch_1;
                if (ele[1].pixel_pitch_1 != ele[1].pixel_pitch_2) {
                    temp = temp + ' - ' + ele[1].pixel_pitch_2;
                }
                if (ele[1].pp_postfix != 0) {
                    temp = temp + ' (' + ele[1].pp_postfix + ')';
                }
                return `${temp}@seriesTypeId=${ele[0]}`;
            });

            setPixelPitchDropDownList(pixelPitches);
            setPixelPitchDropdownValue(pixelPitches[0]);
        }
    }

    const fetchAllData = async () => {
        setLoading(true);
        try {
            const values = await Promise.all([fetchSeries(), fetchApplications(), fetchSeriesType()]);
            addInitialDataToStore(values);

            setTimeout(() => {
                setLoading(false);
            }, 4000)
        } catch (error) {
            console.error("Error while fetching data ", error?.message);
            Toast('error', 'Error', "Error while fetching data: " + error?.message);
        }
    }

    const addNewDesign = () => {
        if ((pixelPitchDropDownValue != undefined)) {

            let noOfTiles = (ledCol * ledRow).toLocaleString('en-IN') + ' (' + ledCol + ' x ' + ledRow + ')';
            if (seriesDropDownValue == "Vega") {
                let jointCabs = parseInt(ledCol / 2);
                let singleCabs = ledCol % 2;

                noOfTiles = ((jointCabs + singleCabs) * ledRow).toLocaleString('en-IN') + ' (' + jointCabs + ' x ' + ledRow + ' - 1000mm Cabinets';
                if (singleCabs != 0) {
                    noOfTiles += ', ' + singleCabs + ' x ' + ledRow + ' - 500mm Cabinets';
                }
                noOfTiles += ')';
            }
            let aspectRatio = (ledCol * cabinetWidthPx) / gcd((ledCol * cabinetWidthPx), (ledRow * cabinetHeightPx)) + ' : ' + (ledRow * cabinetHeightPx) / gcd((ledCol * cabinetWidthPx), (ledRow * cabinetHeightPx))

            dispatch(addDesign(
                {
                    design: {
                        index: design.selectedDesignIdx,
                        ledRow: ledRow,
                        ledCol: ledCol,
                        cabinetHeight: cabinetHeight,
                        cabinetWidth: cabinetWidth,
                        cabinetHeightPx: cabinetHeightPx,
                        cabinetWidthPx: cabinetWidthPx,
                        wallHeight: wallHeight,
                        wallWidth: wallWidth,
                        series: seriesDropDownValue,
                        seriesTypeId: pixelPitchDropDownValue?.split('@seriesTypeId=')[1],
                        pixelPitch: pixelPitchDropDownValue?.split('@seriesTypeId=')[0],
                        fitToWall: fitToWall,
                        fitToCentre: fitToCentre,
                        showModulesFlag: showModulesFlag,
                        ledCondition: ledCondition,
                        content: { ...content },
                        viewingDistance: viewingDist,
                        environment: environment,
                        res4K: (checkedResolution === 2),
                        resFHD: (checkedResolution === 1),
                        application: applicationDropDownValue,
                        position: position,
                        specNoOfTiles: noOfTiles,
                        specAspectRatio: aspectRatio,
                    },
                    index: design.selectedDesignIdx
                }
            ))
        }
    }

    useUpdateEffect(() => {
        if (seriesTypeList?.length === 0) {
            return;
        }
        let ppValue = pixelPitchDropDownValue?.split('@seriesTypeId=')[0];
        if (seriesDropDownValue == UNIFY_SERIES && [UNIFY_SERIES_TYPE_125_CONFIG, UNIFY_SERIES_TYPE_156_CONFIG].includes(ppValue)) {
            setIsUnify(true);
        } else {
            setIsUnify(false);
        }

        const seriesTypeId = pixelPitchDropDownValue?.split('@seriesTypeId=')[1];
        if (!seriesTypeId) return;

        const selected = seriesTypeList[seriesTypeId];
        if (!selected) return;

        let factor = unit == 'm' ? 1000 : 304.8;
        const selectedCabinetHeight = (selected.cabinet_1_height_mm / factor);
        const selectedCabinetWidth = (selected.cabinet_1_length_mm / factor);
        let wHeight = parseFloat(wallHeight);
        let wWidth = parseFloat(wallWidth);

        // let bufferHeight = ledCondition == 'outdoor' ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP + ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_TOP + ROOM_BUFFER_BOTTOM)));
        // let bufferWidth = ledCondition == 'outdoor' ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT + ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_LEFT + ROOM_BUFFER_RIGHT)));
        // if (parseFloat(((ledRow * selectedCabinetHeight) + bufferHeight).toFixed(2)) > wHeight || parseFloat(((ledCol * selectedCabinetWidth) + bufferWidth).toFixed(2)) > wWidth) {
        //     let newRow = (Math.floor(parseFloat((wHeight - bufferHeight) / selectedCabinetHeight)) > 1) ? Math.floor(parseFloat((wHeight - bufferHeight) / selectedCabinetHeight)) : 1;
        //     let newCol = (Math.floor(parseFloat((wWidth - bufferWidth) / selectedCabinetWidth)) > 1) ? Math.floor(parseFloat((wWidth - bufferWidth) / selectedCabinetWidth)) : 1;

        //     console.log("CLD 3");
        //     dispatch(changeLedDimensions({
        //         row: newRow,
        //         col: newCol
        //     }))
        // } else {
        //     setInvalidFlag(false);
        // }

        setTimeout(() => {
            let outDoorWallCondition = ledCondition == 'outdoor' && environment != 'Wall Mounted';
            if (!outDoorWallCondition) {
                let buffer_bottom = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_BOTTOM)));
                let buffer_right = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_RIGHT)));
                let totalHeightBuffer = parseFloat(distanceTop) + buffer_bottom;
                let totalWidthBuffer = parseFloat(distanceLeft) + buffer_right;

                let heightCondition = parseFloat(((ledRow * cabinetHeightRef.current) + totalHeightBuffer).toFixed(2)) > wHeight;
                let widthCondition = parseFloat(((ledCol * cabinetWidthRef.current) + totalWidthBuffer).toFixed(2)) > wWidth

                // if (heightCondition && widthCondition) {
                //     console.log("AND")
                //     let newCol = ledCol;
                //     let newRow = ledRow;
                //     let xPos = position.x;
                //     let yPos = position.y;
                //     let newRowCount = 1;
                //     let newColCount = 1;

                //     // if (heightCondition) {
                //     newRowCount = Math.floor(parseFloat((wHeight - totalHeightBuffer) / cabinetHeightRef.current));
                //     newRow = newRowCount > 1 ? (newRowCount > MAX_CABINET_ROWS ? MAX_CABINET_ROWS : newRowCount) : 1;
                //     yPos = newRowCount < 1 ? 0 : yPos;
                //     // }

                //     // if (widthCondition) {
                //     newColCount = Math.floor(parseFloat((wWidth - totalWidthBuffer) / cabinetWidthRef.current));
                //     newCol = newColCount > 1 ? (newColCount > MAX_CABINET_COLS ? MAX_CABINET_COLS : newColCount) : 1;
                //     xPos = newColCount < 1 ? 0 : xPos;
                //     // }

                //     // let posChangeFlag = false;
                //     if (xPos != position.x || yPos != position.y) {
                //         dispatch(changePosition({
                //             x: xPos,
                //             y: yPos
                //         }))
                //         // posChangeFlag = true;
                //     }

                //     // if (newCol != ledCol) {
                //     dispatch(changeLedDimensions({
                //         row: newRow,
                //         col: newCol
                //     }))
                //     // }

                //     // if (newRow != ledRow) {
                //     //     dispatch(changeLedDimensions({
                //     //         row: newRow,
                //     //         col: ledCol
                //     //     }))
                //     // }
                //     // }, 0)
                // } else if (heightCondition) {
                //     console.log("HEIGHT")
                //     let newRow = ledRow;
                //     let xPos = position.x;
                //     let yPos = position.y;
                //     let newRowCount = 1;

                //     newRowCount = Math.floor(parseFloat((wHeight - totalHeightBuffer) / cabinetHeightRef.current));
                //     newRow = newRowCount > 1 ? (newRowCount > MAX_CABINET_ROWS ? MAX_CABINET_ROWS : newRowCount) : 1;
                //     yPos = newRowCount < 1 ? 0 : yPos;

                //     if (yPos != position.y) {
                //         dispatch(changePosition({
                //             x: xPos,
                //             y: yPos
                //         }))
                //     }

                //     dispatch(changeLedDimensions({
                //         row: newRow,
                //         col: ledCol
                //     }))

                // } else if (widthCondition) {
                //     console.log("WIDTH")
                //     let newCol = ledCol;
                //     let xPos = position.x;
                //     let yPos = position.y;
                //     let newColCount = 1;

                //     newColCount = Math.floor(parseFloat((wWidth - totalWidthBuffer) / cabinetWidthRef.current));
                //     newCol = newColCount > 1 ? (newColCount > MAX_CABINET_COLS ? MAX_CABINET_COLS : newColCount) : 1;
                //     xPos = newColCount < 1 ? 0 : xPos;

                //     if (xPos != position.x) {
                //         dispatch(changePosition({
                //             x: xPos,
                //             y: yPos
                //         }))
                //     }

                //     dispatch(changeLedDimensions({
                //         row: ledRow,
                //         col: newCol
                //     }))
                // }

                if (heightCondition || widthCondition) {

                    let newCol = ledCol;
                    let newRow = ledRow;
                    let xPos = position.x;
                    let yPos = position.y;

                    if (heightCondition) {
                        let newRowCount = Math.floor(parseFloat((wHeight - totalHeightBuffer) / cabinetHeightRef.current));
                        newRow = newRowCount > 1 ? (newRowCount > MAX_CABINET_ROWS ? MAX_CABINET_ROWS : newRowCount) : 1;
                        yPos = newRowCount < 1 ? 0 : yPos;
                    }

                    if (widthCondition) {
                        let newColCount = Math.floor(parseFloat((wWidth - totalWidthBuffer) / cabinetWidthRef.current));
                        newCol = newColCount > 1 ? (newColCount > MAX_CABINET_COLS ? MAX_CABINET_COLS : newColCount) : 1;
                        xPos = newColCount < 1 ? 0 : xPos;
                    }

                    if (xPos != position.x || yPos != position.y) {
                        dispatch(changePosition({
                            x: xPos,
                            y: yPos
                        }))
                    }

                    if (newCol != ledCol || newRow != ledRow) {
                        dispatch(changeLedDimensions({
                            row: newRow,
                            col: newCol
                        }))
                    }
                }
                else {
                    setInvalidFlag(false);
                }
            }
        }, 0)

        dispatch(changeSeries({
            series: selected
        }))

        dispatch(changeModuleDimensions({
            height: selectedCabinetHeight,
            width: selectedCabinetWidth
        }))
        const modulesInWidth = Math.ceil(selected.cabinet_1_length_mm / selected.module_length);
        const modulesInHeight = Math.ceil(selected.cabinet_1_height_mm / selected.module_height);
        const pxInWidth = Number((modulesInWidth * selected.module_pixel_length).toFixed(2));
        const pxInHeight = Number((modulesInHeight * selected.module_pixel_height).toFixed(2));

        dispatch(changeModuleDimensionsPx({
            cabinetHeightPx: pxInHeight,
            cabinetWidthPx: pxInWidth
        }))

        // }, [pixelPitchDropDownValue, ledCol, ledRow, wallHeight, wallWidth, seriesTypeList, orientation, cabinetHeight, cabinetWidth])
    }, [pixelPitchDropDownValue, ledCol, ledRow, wallHeight, wallWidth, seriesTypeList, cabinetHeight, cabinetWidth])

    useUpdateEffect(() => {
        if (seriesDropDownValue === '') {
            return;
        }
        filterPixelP();
    }, [seriesDropDownValue, ledCondition, seriesList, viewingDist])

    useUpdateEffect(() => {
        if (applicationDropDownValue === '') {
            return;
        }
        filterSeries();
    }, [viewingDist, ledCondition, applicationDropDownValue, applicationList])

    useUpdateEffect(() => {
        dispatch(changeViewingDistance({
            viewingDistance: ledCondition === 'indoor' ? viewingRangeState.indoorMax : viewingRangeState.outdoorMax
        }))
    }, [ledCondition, applicationDropDownValue, applicationList])

    useUpdateEffect(() => {
        filterViewingDistance();
    }, [applicationDropDownValue, ledCondition])

    useUpdateEffect(() => {
        filterApplications();
    }, [applicationList, ledCondition])

    useUpdateEffect(() => {
        if (ledCondition == 'outdoor') {
            setTimeout(() => {
                dispatch(changeFitToWall({
                    fitToWall: true
                }))
            }, 5);
        }

        dispatch(changeShowProps({
            showProps: false
        }))
    }, [ledCondition])

    useUpdateEffect(() => {
        const selectedDesign = design.designs[design.selectedDesignIdx];
        if (!selectedDesign) return;

        setTimeout(() => {
            dispatch(changeLedCondition({
                condition: selectedDesign.ledCondition
            }));
        }, 0)
        setTimeout(() => {
            dispatch(changeApplicationDropDownValue({
                applicationDropDownValue: selectedDesign.application
            }));
        }, 50)
        setTimeout(() => {
            dispatch(changeViewingDistance({
                viewingDistance: selectedDesign.viewingDistance
            }))
        }, 100)
        setTimeout(() => {
            setSeriesDropDownValue(selectedDesign.series);
            if (selectedDesign.series === UNIFY_SERIES) {
                setIsUnify(true);
            } else {
                setIsUnify(false);
            }
        }, 150)

        setTimeout(() => {
            const selectedDesign = design.designs[design.selectedDesignIdx];
            const row = selectedDesign.ledRow;
            const col = selectedDesign.ledCol;
            const showModulesFlag = selectedDesign.showModulesFlag;
            const wallHeight = selectedDesign.wallHeight;
            const wallWidth = selectedDesign.wallWidth;
            const fitToWall = selectedDesign.fitToWall;
            const fitToCentre = selectedDesign.fitToCentre;

            dispatch(changeLedDimensions({
                row,
                col
            }))
            dispatch(changeFitToWall({
                fitToWall
            }))
            dispatch(changeFitToCentre({
                fitToCentre
            }))
            dispatch(changeWallDimensions({
                height: wallHeight,
                width: wallWidth
            }))
            dispatch(showModules({
                showModules: showModulesFlag
            }))
            const pixelPitchDropdownVal = `${selectedDesign.pixelPitch}@seriesTypeId=${selectedDesign.seriesTypeId}`;
            setPixelPitchDropdownValue(pixelPitchDropdownVal);
            setLoading(false);

            if (selectedDesign.res4K) {
                dispatch(changeCheckedResolution({ resolution: 2 }));
            } else if (selectedDesign.resFHD) {
                dispatch(changeCheckedResolution({ resolution: 1 }));
            } else {
                dispatch(changeCheckedResolution({ resolution: 3 }));
            }

            if (selectedDesign.position != undefined) {
                const x = selectedDesign.position.x;
                const y = selectedDesign.position.y;
                dispatch(changePosition({
                    x, y
                }))
            }
        }, 200)
    }, [design.selectedDesignIdx])

    useEffect(() => {
        fetchAllData();
    }, [])

    useEffect(() => {
        addNewDesign();
        // }, [ledRow, ledCol, cabinetHeight, cabinetWidth, ledCondition, wallHeight, wallWidth, orientation, fitToWall, fitToCentre, design.selectedDesignIdx, content, showModulesFlag, cabinetHeightPx, cabinetWidthPx, seriesDropDownValue, pixelPitchDropDownValue, viewingDist, environment, checkedResolution, applicationDropDownValue, position])
    }, [ledRow, ledCol, cabinetHeight, cabinetWidth, ledCondition, wallHeight, wallWidth, fitToWall, fitToCentre, design.selectedDesignIdx, content, showModulesFlag, cabinetHeightPx, cabinetWidthPx, seriesDropDownValue, pixelPitchDropDownValue, viewingDist, environment, checkedResolution, applicationDropDownValue, position])

    const changeLEDRowCol = (row, col, changeState) => {
        if (changeState) {
            row = row > 1 ? row : 1;
            col = col > 1 ? col : 1;
        }

        if (row > MAX_CABINET_ROWS) {
            Toast('error', 'Error', `Max allowed rows are ${MAX_CABINET_ROWS}`);
            if (row - 1 == ledRow) {
                return false;
            } else {
                row = MAX_CABINET_ROWS;
            }
        }

        if (col > MAX_CABINET_COLS) {
            Toast('error', 'Error', `Max allowed columns are ${MAX_CABINET_COLS}`);
            if (col - 1 == ledCol) {
                return false;
            } else {
                col = MAX_CABINET_COLS;
            }
        }

        let screenHeight = row * cabinetHeightRef.current;
        let screenWidth = col * cabinetWidthRef.current;

        let outDoorWallCondition = ledCondition == 'outdoor' && environment != 'Wall Mounted';
        if (!outDoorWallCondition) {
            if (row != ledRow) {
                let buffer_top = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP) : (ROOM_BUFFER_TOP)));
                let buffer_bottom = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_BOTTOM)));
                let buffer_hgt = buffer_top + buffer_bottom;

                let totalScreenHeight = parseFloat(screenHeight + distanceTop).toFixed(2);
                let heightDiff = parseFloat(totalScreenHeight - (wallHeight - buffer_hgt));

                if (screenHeight > (wallHeight - buffer_hgt)) {
                    Toast('error', 'Error', LED_HEIGHT_EXCEED_WALL);
                    return false;
                }

                if ((totalScreenHeight > (wallHeight - buffer_bottom) && row > ledRow && heightDiff > 0)) {
                    Toast('error', 'Error', WALL_EXCEEDED);
                    return;
                }
            }

            if (col != ledCol) {
                let buffer_left = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT) : (ROOM_BUFFER_LEFT)));
                let buffer_right = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_RIGHT)));
                let buffer_wdt = buffer_left + buffer_right;

                let totalScreenWidth = parseFloat(screenWidth + distanceLeft).toFixed(2);
                let widthDiff = parseFloat(totalScreenWidth - (wallWidth - buffer_wdt));

                if (screenWidth > (wallWidth - buffer_wdt)) {
                    Toast('error', 'Error', LED_WIDTH_EXCEED_WALL);
                    return false;
                }

                if ((totalScreenWidth > (wallWidth - buffer_right) && col > ledCol && widthDiff > 0)) {
                    Toast('error', 'Error', WALL_EXCEEDED);
                    return;
                }
            }
        }

        if (changeState) {
            if (outDoorWallCondition) {
                if (row != ledRow) {
                    let min_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_HEIGHT) : MIN_ROOM_HEIGHT);
                    let max_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_HEIGHT) : MAX_ROOM_HEIGHT);

                    if (screenHeight > max_room_hgt) {
                        Toast('error', 'Error', MAX_ROOM_HEIGHT_LIMIT_REACHED);
                        return;
                    }

                    if (screenHeight < min_room_hgt) {
                        Toast('error', 'Error', MIN_ROOM_HEIGHT_LIMIT_REACHED);
                        return;
                    }
                }

                if (col != ledCol) {
                    let min_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_WIDTH) : MIN_ROOM_WIDTH);
                    let max_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_WIDTH) : MAX_ROOM_WIDTH);

                    if (screenWidth > max_room_wdt) {
                        Toast('error', 'Error', MAX_ROOM_WIDTH_LIMIT_REACHED);
                        return;
                    }

                    if (screenWidth < min_room_wdt) {
                        Toast('error', 'Error', MIN_ROOM_WIDTH_LIMIT_REACHED);
                        return;
                    }
                }

                dispatch(changeLedDimensions({
                    row,
                    col
                }))

                dispatch(changeWallDimensions({
                    height: screenHeight.toFixed(2),
                    width: screenWidth.toFixed(2)
                }))
            } else {
                dispatch(changeFitToWall({
                    fitToWall: false
                }))
                dispatch(changeFitToCentre({
                    fitToCentre: false
                }))
                dispatch(changeLedDimensions({
                    row,
                    col
                }))
            }
        } else {
            dispatch(changeLocalLedMatrix({
                row,
                col
            }))
        }

        return true;
    }

    const getFit = (val, base) => {
        let result = (parseFloat(Number(val)) / parseFloat(Number(base))).toFixed(2);
        let mantissa = result - Math.floor(result);
        return mantissa >= 0.50 ? Math.ceil(result) : Math.floor(result);
    }

    const changeLEDDimensions = (height, width, changeState) => {
        if (changeState == 0) {
            dispatch(changeLocalLedDimensions({
                height: height,
                width: width
            }))
        } else {
            let row = getFit(height, cabinetHeight);
            let col = getFit(width, cabinetWidth);
            return changeLEDRowCol(row, col, changeState);
        }

        return true;
    }

    const changeLEDResolution = (height, width, changeState) => {
        if (changeState == 0) {
            dispatch(changeLocalLedResolution({
                height: height,
                width: width
            }))
        } else {
            let row = getFit(height, cabinetHeightPx);
            let col = getFit(width, cabinetWidthPx);
            return changeLEDRowCol((row > 1) ? row : 1, (col > 1) ? col : 1, changeState);
        }

        return true;
    }

    const handleShowModules = (val) => {
        dispatch(showModules({
            showModules: val
        }))
    }

    const handleLEDCondition = (e) => {
        const condition = e.target.value;
        environmentConditionRef.current = condition

        if (design.designsAdded.length > 1) {
            setIsEnvironmentModalOpen(true);
        } else {
            changeLEDCondition(condition)
        }
    }

    const changeLEDCondition = (condition = environmentConditionRef.current) => {
        dispatch(resetWallDimensions());
        dispatch(resetLedDimensions());
        dispatch(resetTransform());
        dispatch(changeCheckedResolution({ resolution: 3 }));

        dispatch(changeLedCondition({
            condition
        }));

        if (condition == 'indoor') {
            dispatch(changeFitToWall({ fitToWall: false }))
            setTimeout(() => {
                dispatch(changeFitToCentre({ fitToCentre: true }))
            }, 5);
        }

        // To handle fluctuation of rows when switching from Indoor to Outdoor with "Fit To Wall" option ticked
        if (condition == 'outdoor') {
            dispatch(changeFitToWall({ fitToWall: false }))
        }

        dispatch(deleteDesign({
            index: 2
        }))

        dispatch(deleteDesign({
            index: 1
        }))

        dispatch(changeSelectedDesign({
            selectedDesignIdx: 0
        }))
    }

    const handleFitToWall = (e) => {
        const fitToWall = e.target.checked;
        dispatch(changeFitToWall({
            fitToWall: fitToWall
        }))
    }

    const handleFitToCentre = (e) => {
        const fitToCentre = e.target.checked;
        dispatch(changeFitToCentre({
            fitToCentre
        }))

        if (fitToCentre) {
            const wall = document.getElementById('wall');
            const bufferWall = document.getElementById('buffer-wall');
            const led = document.getElementById('led');
            if (!wall || !led) return;

            const { height, width, top, left } = wall.getBoundingClientRect();
            const { height: ledHeight, width: ledWidth } = led.getBoundingClientRect();
            const { top: bufferTop, left: bufferLeft } = bufferWall.getBoundingClientRect();
            dispatch(changePosition({
                x: ((width - ledWidth) / 2) - (bufferLeft - left),
                y: ((height - ledHeight) / 2) - (bufferTop - top)
            }))
        }
    }

    const handleUnitChange = (value) => {
        let convertedValue = value;
        if (unit === 'ft') {
            convertedValue = Number(convertMeterToFt(value));
        }

        if (unit === 'm') {
            convertedValue = Number(convertFtToMeter(value));
        }

        return Number(convertedValue).toFixed(2);
    }

    const convertUnits = () => {
        dispatch(changeViewingDistance(
            { viewingDistance: handleUnitChange(viewingDist) }
        ))

        dispatchViewingRange({
            type: 'setViewingDistanceIndoor',
            indoorMin: handleUnitChange(viewingRangeState.indoorMin),
            indoorMax: handleUnitChange(viewingRangeState.indoorMax)
        })

        dispatchViewingRange({
            type: 'setViewingDistanceOutdoor',
            outdoorMin: handleUnitChange(viewingRangeState.outdoorMin),
            outdoorMax: handleUnitChange(viewingRangeState.outdoorMax)
        })

        const seriesTypeId = pixelPitchDropDownValue?.split('@seriesTypeId=')[1];
        if (!seriesTypeId) return;

        const selected = seriesTypeList[seriesTypeId];
        if (!selected) return;

        dispatch(changeLedTopBottomLeftDistance({
            top: handleUnitChange(distanceTop),
            left: handleUnitChange(distanceLeft),
            bottom: handleUnitChange(distanceBottom)
        }))

        let factor = unit == 'm' ? 1000 : 304.8;
        dispatch(changeModuleDimensions({
            height: (selected.cabinet_1_height_mm / factor),
            width: (selected.cabinet_1_length_mm / factor)
        }))

        if (design.designsAdded.length > 1) {
            for (let i = 0; i < design.designs.length; i++) {
                if (i != design.selectedDesignIdx && design.designsAdded.includes(i)) {

                    let designDetails = { ...design.designs[i] };
                    designDetails['unit'] = unit;
                    designDetails['cabinetHeight'] = specification[i]['cabinet_1_height_mm'] / factor;
                    designDetails['cabinetWidth'] = specification[i]['cabinet_1_length_mm'] / factor;
                    designDetails['viewingDistance'] = handleUnitChange(design.designs[i]['viewingDistance']);
                    designDetails['wallHeight'] = handleUnitChange(design.designs[i]['wallHeight']);
                    designDetails['wallWidth'] = handleUnitChange(design.designs[i]['wallWidth']);

                    dispatch(addDesign({
                        design: designDetails,
                        index: i
                    }))
                }
            }
        }
    }

    useUpdateEffect(() => {
        convertUnits();
    }, [unit])

    const handleResolutionFHD = () => {
        const rowFHD = Math.ceil(1080 / cabinetHeightPx);
        const colFHD = Math.ceil(1920 / cabinetWidthPx);
        const result = changeLEDRowCol(rowFHD, colFHD, 1);
        if (result) dispatch(changeCheckedResolution({ resolution: 1 }));
    }

    const handleResolution4K = () => {
        const row4K = Math.ceil(2160 / cabinetHeightPx);
        const col4K = Math.ceil(3840 / cabinetWidthPx);
        const result = changeLEDRowCol(row4K, col4K, 1);
        if (result) dispatch(changeCheckedResolution({ resolution: 2 }));
    }

    const handleSeriesChange = (e) => {
        const series = e.target.value;
        setSeriesDropDownValue(series);
        if (series === UNIFY_SERIES) {
            setTimeout(function () {
                changeLEDRowCol(4, 4, 1);
            }, 1);
            setIsUnify(true);
        } else {
            setIsUnify(false);
        }
    }

    const handlePixelPitchChange = (e) => {
        const pixelPitch = e.target.value;
        setPixelPitchDropdownValue(pixelPitch);
        let ppValue = pixelPitch?.split('@seriesTypeId=')[0];
        if (seriesDropDownValue == UNIFY_SERIES && ppValue === UNIFY_SERIES_TYPE_125_CONFIG) {
            changeLEDRowCol(4, 4, 1);
            setIsUnify(true);
            return;
        }
        if (seriesDropDownValue == UNIFY_SERIES && ppValue === UNIFY_SERIES_TYPE_156_CONFIG) {
            changeLEDRowCol(5, 5, 1);
            setIsUnify(true);
            return;
        }

        setIsUnify(false);
    }

    const handleViewingDistanceChange = (e) => {
        const viewingDistance = e.target.value;
        dispatch(changeViewingDistance({
            viewingDistance
        }))
    }

    const changeLEDApplication = (e) => {
        const application = e.target.value;
        const src = contentOption[application] ?? auditorium1;

        dispatch(changeApplicationDropDownValue({
            applicationDropDownValue: application
        }))

        dispatch(changeEnvironment({
            environment: application
        }))

        if (ledCondition == "outdoor" && application != "Wall Mounted") {
            dispatch(changeFitToWall({
                fitToWall: true
            }))
        }

        dispatch(changeContent({
            content: application,
            src: src,
            type: 'image/png'
        }))
    }

    const handleShowProps = e => {
        const checked = e.target.checked;
        dispatch(changeShowProps({
            showProps: checked
        }))
    }

    return (
        <>
            <Form>
                <Card className="mt-3">
                    <CardBody>
                        <CardTitle className="text-muted mb-3" tag='h5'>Model Selection</CardTitle>
                        <hr />
                        <FormGroup>
                            <Label for="condition">
                                Environment
                            </Label>
                            <Input
                                id="condition"
                                name="condition"
                                placeholder="Select Condition"
                                type="select"
                                onChange={handleLEDCondition}
                                value={ledCondition}
                                disabled={diagram !== 'design' || (design.selectedDesignIdx > 0)}
                            >
                                <option value='indoor'>Indoor</option>
                                <option value='outdoor'>Outdoor</option>
                            </Input>
                        </FormGroup>
                        <EnvironmentChangeModal isEnvironmentModalOpen={isEnvironmentModalOpen} setIsEnvironmentModalOpen={setIsEnvironmentModalOpen} changeLEDCondition={changeLEDCondition} />
                        <FormGroup>
                            <Label for="application">
                                Application
                            </Label>
                            <Input
                                id="application"
                                name="application"
                                placeholder="Select Application"
                                type="select"
                                onChange={changeLEDApplication}
                                value={applicationDropDownValue}
                                disabled={diagram !== 'design'}
                            >
                                {applicationDropDownList.map(application => <option value={application} key={application}>{application}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup hidden={applicationDropDownValue === 'All' || ledCondition === 'outdoor'}>
                            <Row className="mt-4">
                                <Col md={5}>
                                    <Label for="showProps">
                                        Show Application Props
                                    </Label>
                                </Col>
                                <Col md={5}>
                                    <Input
                                        id="showProps"
                                        name="showProps"
                                        type="checkbox"
                                        checked={showProps}
                                        disabled={diagram !== 'design'}
                                        onChange={handleShowProps}
                                    >
                                    </Input>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={5}>
                                    <Label for="viewingDistance">
                                        Minimum Viewing Distance:
                                    </Label>
                                </Col>
                                <Col md={3}>
                                    <Input
                                        id="viewingDistanceTxt"
                                        name="viewingDistanceTxt"
                                        placeholder="Viewing Distance"
                                        type="number"
                                        value={(viewingDist)}
                                        min={ledCondition === 'indoor' ? viewingRangeState.indoorMin : viewingRangeState.outdoorMin}
                                        max={ledCondition === 'indoor' ? viewingRangeState.indoorMax : viewingRangeState.outdoorMax}
                                        onChange={handleViewingDistanceChange}
                                        onBlur={
                                            (e) => {
                                                let val = parseFloat(e.target.value);
                                                let min = parseFloat(ledCondition === 'indoor' ? viewingRangeState.indoorMin : viewingRangeState.outdoorMin);
                                                let max = parseFloat(ledCondition === 'indoor' ? viewingRangeState.indoorMax : viewingRangeState.outdoorMax);
                                                const viewingDistance = val == "" || isNaN(val) || val > max ? max : (val < min ? min : val);
                                                dispatch(changeViewingDistance({
                                                    viewingDistance
                                                }))
                                            }
                                        }
                                        disabled={diagram !== 'design'}
                                        style={{ marginBottom: '0.5rem', marginTop: '-0.5rem', textAlign: 'center' }}
                                    >
                                    </Input>
                                </Col>
                                <Col md={2}>
                                    <span >{`${unit === 'ft' ? 'ft' : 'm'}`}</span>
                                </Col>
                            </Row>
                            <Row className="d-flex align-items-center" style={{ border: 'solid 1px #ced4da', borderRadius: '6px', margin: '0px', padding: '5px 0px' }}>
                                <Col md={2}>
                                    <Label id="viewingDistanceMinValue" name="viewingDistanceMinValue" style={{ marginBottom: 'unset' }}>
                                        {(ledCondition === 'indoor' ? viewingRangeState.indoorMin : viewingRangeState.outdoorMin) + ' ' + (unit === 'ft' ? 'ft' : 'm')}
                                    </Label>
                                </Col>
                                <Col md={8}>
                                    <Input
                                        id="viewingDistance"
                                        name="viewingDistance"
                                        placeholder="Select Viewing Distance"
                                        type="range"
                                        step={0.1}
                                        onChange={handleViewingDistanceChange}
                                        min={ledCondition === 'indoor' ? viewingRangeState.indoorMin : viewingRangeState.outdoorMin}
                                        max={ledCondition === 'indoor' ? viewingRangeState.indoorMax : viewingRangeState.outdoorMax}
                                        value={viewingDist}
                                        disabled={diagram !== 'design'}
                                    >
                                    </Input>
                                </Col>
                                <Col md={2} style={{ textAlign: 'right' }}>
                                    <Label id="viewingDistanceMaxValue" name="viewingDistanceMaxValue" style={{ marginBottom: 'unset' }}>
                                        {(ledCondition === 'indoor' ? viewingRangeState.indoorMax : viewingRangeState.outdoorMax) + ' ' + (unit === 'ft' ? 'ft' : 'm')}
                                    </Label>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Label for="series">
                                Series
                            </Label>
                            <Input
                                id="series"
                                name="Series"
                                placeholder="Select series"
                                type="select"
                                onChange={handleSeriesChange}
                                value={seriesDropDownValue}
                                invalid={invalidFlag}
                                disabled={diagram !== 'design'}
                            >
                                {seriesDropDownList.map((ele, index) => <option disabled={ele?.is_active === 0} key={index} value={`${ele?.series_name}`}>{ele?.series_name} {ele?.recommended ? '(Recommended)' : ''} {ele?.is_active === 0 ? '(Not available in the configurator)' : ''}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pixelPitch">
                                Pixel Pitch (mm)
                            </Label>
                            <Input
                                id="pixelPitch"
                                name="pixelPitch"
                                placeholder="Select pixel pitch"
                                type="select"
                                onChange={handlePixelPitchChange}
                                value={pixelPitchDropDownValue}
                                disabled={diagram !== 'design'}
                            >
                                {pixelPitchDropDownList.map(ele => {
                                    return <option key={ele} value={ele}>{ele.split('@seriesTypeId=')[0]}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card className="mt-3">
                    <CardBody>
                        <CardTitle className="text-muted mb-3" tag='h5'>LED Configuration</CardTitle>
                        <hr />
                        <FormGroup>
                            <Label for="area">
                                Area
                            </Label>
                            <Col>
                                <ButtonGroup>
                                    <CustomButton
                                        outline
                                        onClick={() => setRSelected(1)}
                                        active={rSelected === 1}
                                    >
                                        Width / Height
                                    </CustomButton>
                                    <CustomButton
                                        outline
                                        onClick={() => setRSelected(2)}
                                        active={rSelected === 2}
                                    >
                                        Row / Column
                                    </CustomButton>
                                    <CustomButton
                                        outline
                                        onClick={() => {
                                            setRSelected(3);
                                        }}
                                        active={rSelected === 3}
                                    >
                                        Resolution
                                    </CustomButton>
                                </ButtonGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup hidden={rSelected !== 1}>
                            <Row>
                                <Col md={5}>
                                    <Label for="width">
                                        Width ({unit})
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="width"
                                            name="width"
                                            placeholder="Enter Width"
                                            type="number"
                                            step={"any"}
                                            min={1}
                                            value={localWidth}
                                            disabled={checkedResolution !== 3 || diagram !== 'design' || isUnify}
                                            onChange={e => {
                                                const width = (e.target.value);
                                                changeLEDDimensions((ledRow * cabinetHeight).toFixed(2), width, 0);
                                            }}
                                            onBlur={e => {
                                                const width = parseFloat(Number(e.target.value));
                                                changeLEDDimensions((ledRow * cabinetHeight).toFixed(2), width, 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const width = parseFloat(Number(e.target.value));
                                                    changeLEDDimensions((ledRow * cabinetHeight).toFixed(2), width, 1);
                                                }
                                            }}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow, ledCol + 1, 1);
                                        }}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow, ledCol - 1, 1);
                                        }}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                                <Col md={5}>
                                    <Label for="height">
                                        Height ({unit})
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="height"
                                            name="height"
                                            placeholder="Enter Height"
                                            type="number"
                                            step={"any"}
                                            min={1}
                                            value={localHeight}
                                            disabled={checkedResolution !== 3 || diagram !== 'design' || isUnify}
                                            onChange={e => {
                                                const height = (e.target.value);
                                                changeLEDDimensions(height, (ledCol * cabinetWidth).toFixed(2), 0);
                                            }}
                                            onBlur={e => {
                                                const height = parseFloat(Number(e.target.value));
                                                changeLEDDimensions(height, (ledCol * cabinetWidth).toFixed(2), 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const height = parseFloat(Number(e.target.value));
                                                    changeLEDDimensions(height, (ledCol * cabinetWidth).toFixed(2), 1);
                                                }
                                            }}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow + 1, ledCol, 1);
                                        }}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow - 1, ledCol, 1);
                                        }}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup hidden={rSelected !== 2}>
                            <Row>
                                <Col md={5}>
                                    <Label for="row">
                                        Row
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="row"
                                            name="row"
                                            placeholder="Enter Rows"
                                            type="number"
                                            value={localLedRow}
                                            min={1}
                                            onChange={e => {
                                                const val = (e.target.value);
                                                const row = val == "" ? "" : Number(val);
                                                changeLEDRowCol(row, ledCol, 0);
                                            }}
                                            onBlur={e => {
                                                const row = Number(e.target.value);
                                                changeLEDRowCol(row, ledCol, 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const row = Number(e.target.value);
                                                    changeLEDRowCol(row, ledCol, 1);
                                                }
                                            }}
                                            disabled={checkedResolution !== 3 || diagram !== 'design' || isUnify}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow + 1, ledCol, 1)
                                        }}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow - 1, ledCol, 1)
                                        }}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                                <Col md={5}>
                                    <Label for="column">
                                        Column
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="column"
                                            name="column"
                                            placeholder="Enter Columns"
                                            type="number"
                                            value={localLedCol}
                                            min={1}
                                            onChange={e => {
                                                const val = (e.target.value);
                                                const col = val == "" ? "" : Number(val);
                                                changeLEDRowCol(ledRow, col, 0);
                                            }}
                                            onBlur={e => {
                                                const col = Number(e.target.value);
                                                changeLEDRowCol(ledRow, col, 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const col = Number(e.target.value);
                                                    changeLEDRowCol(ledRow, col, 1);
                                                }
                                            }}
                                            disabled={checkedResolution !== 3 || diagram !== 'design' || isUnify}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow, ledCol + 1, 1)
                                        }}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow, ledCol - 1, 1)
                                        }}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup hidden={rSelected !== 3} check>
                            <Row className="mb-3">
                                <Col md={4}>
                                    <Input disabled={diagram !== 'design' || isUnify} type="checkbox" checked={checkedResolution === 1} onClick={handleResolutionFHD} readOnly />
                                    <Label check>
                                        FHD
                                    </Label>
                                </Col>
                                <Col md={4}>
                                    <Input disabled={diagram !== 'design' || isUnify} type="checkbox" checked={checkedResolution === 2} onClick={handleResolution4K} readOnly />
                                    <Label check>
                                        4K
                                    </Label>
                                </Col>
                                <Col md={4}>
                                    <Input disabled={diagram !== 'design' || isUnify} type="checkbox" checked={checkedResolution === 3} onClick={() => { dispatch(changeCheckedResolution({ resolution: 3 })); }} readOnly />
                                    <Label check>
                                        Custom
                                    </Label>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup hidden={rSelected !== 3}>
                            <Row>
                                <Col md={5}>
                                    <Label for="widthpx">
                                        Width (px)
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="widthpx"
                                            name="widthpx"
                                            placeholder="Enter Width"
                                            type="number"
                                            value={localWidthPx}
                                            min={1}
                                            disabled={checkedResolution !== 3 || diagram !== 'design' || isUnify}
                                            onChange={e => {
                                                const width = (e.target.value);
                                                changeLEDResolution((ledRow * cabinetHeightPx).toFixed(2), width, 0);
                                            }}
                                            onBlur={e => {
                                                const width = parseFloat(Number(e.target.value));
                                                changeLEDResolution((ledRow * cabinetHeightPx).toFixed(2), width, 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const width = parseFloat(Number(e.target.value));
                                                    changeLEDResolution((ledRow * cabinetHeightPx).toFixed(2), width, 1);
                                                }
                                            }}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow, ledCol + 1, 1);
                                        }}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow, ledCol - 1, 1);
                                        }}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                                <Col md={5}>
                                    <Label for="heightpx">
                                        Height (px)
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="heightpx"
                                            name="heightpx"
                                            placeholder="Height"
                                            type="number"
                                            value={localHeightPx}
                                            min={1}
                                            disabled={checkedResolution !== 3 || diagram !== 'design' || isUnify}
                                            onChange={e => {
                                                const height = (e.target.value);
                                                changeLEDResolution(height, (ledCol * cabinetWidthPx).toFixed(2), 0);
                                            }}
                                            onBlur={e => {
                                                const height = parseFloat(Number(e.target.value));
                                                changeLEDResolution(height, (ledCol * cabinetWidthPx).toFixed(2), 1);
                                            }}
                                            onKeyUp={e => {
                                                if (e.key == "Enter") {
                                                    const height = parseFloat(Number(e.target.value));
                                                    changeLEDResolution(height, (ledCol * cabinetWidthPx).toFixed(2), 1);
                                                }
                                            }}
                                        >
                                        </Input>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow + 1, ledCol, 1);
                                        }}>
                                            +
                                        </CustomButton>
                                        <CustomButton onClick={() => {
                                            if (checkedResolution !== 3 || diagram !== 'design' || isUnify) return;
                                            changeLEDRowCol(ledRow - 1, ledCol, 1);
                                        }}>
                                            -
                                        </CustomButton>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="mt-3">
                                <Col md={5}>
                                    <Label for="fitToWall">
                                        Fit to Wall
                                    </Label>
                                </Col>
                                <Col md={5}>
                                    <Input
                                        id="fitToWall"
                                        name="fitToWall"
                                        type="checkbox"
                                        checked={fitToWall}
                                        onChange={handleFitToWall}
                                        disabled={checkedResolution !== 3 || diagram !== 'design' || isUnify || (ledCondition == 'outdoor' && environment != 'Wall Mounted')}
                                    >
                                    </Input>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="mt-3">
                                <Col md={5}>
                                    <Label for="fitToCentre">
                                        Fit to Centre
                                    </Label>
                                </Col>
                                <Col md={5}>
                                    <Input
                                        id="fitToCentre"
                                        name="fitToCentre"
                                        type="checkbox"
                                        checked={fitToCentre}
                                        onChange={handleFitToCentre}
                                        disabled={diagram !== 'design' || (ledCondition == 'outdoor' && environment != 'Wall Mounted')}
                                    >
                                    </Input>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="mt-3">
                                <Col md={5}>
                                    <Label for="showModules">
                                        Show Cabinets
                                    </Label>
                                </Col>
                                <Col md={5}>
                                    <Input
                                        id="showModules"
                                        name="showModules"
                                        type="checkbox"
                                        checked={showModulesFlag}
                                        disabled={diagram !== 'design'}
                                        onChange={() => {
                                            handleShowModules(!showModulesFlag);
                                        }}
                                    >
                                    </Input>
                                </Col>
                            </Row>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>
        </>
    )
}

export default memo(LedForm);