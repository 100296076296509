import { shallowEqual, useSelector } from "react-redux";
import { useContext, memo } from 'react';
import { LoaderContext } from "../../context/context";
import { VEGA_VEGA_PLUS_195, VEGA_VEGA_PLUS_SERIES } from "../../config/config";

const Cabinets = ({ children, id }) => {
    const { row, col, seriesDropDownValue, pixelPitchValue, pixelPitchDropDownValue } = useSelector(state => {
        return {
            row: state.led.row,
            col: state.led.col,
            seriesDropDownValue: state.selectedSeries.seriesDropDownValue,
            pixelPitchValue: state.selectedSeries.pixelPitchValue,
            pixelPitchDropDownValue: state.selectedSeries.pixelPitchDropDownValue
        }
    }, shallowEqual);
    const { diagram } = useContext(LoaderContext);
    const data = [];
    let new_col = col;

    let condition = VEGA_VEGA_PLUS_SERIES.includes(seriesDropDownValue) && (id == 'data-snapshot' || diagram === 'data-looping');

    if (condition) {
        new_col = Math.ceil(col / 2);
    }

    let lastOddColFlag = (col / 2) !== new_col;

    for (let i = 0; i < row; i++) {
        const module = [];
        for (let j = 0; j < new_col; j++) {
            if (new_col === col) {
                module.push(<td className={`cabinet ${id == "data-snapshot" ? 'data-snapshot-cabinet' : (id == "power-snapshot" ? 'power-snapshot-cabinet' : '')}`} data-value={`${j},${i}`} key={j}></td>);
            } else {
                let new_class = (j === new_col - 1 && lastOddColFlag) ? 'last-column-cell' : '';
                module.push(<td className={`cabinet ${new_class} ${id == "data-snapshot" ? 'data-snapshot-cabinet' : (id == "power-snapshot" ? 'power-snapshot-cabinet' : '')}`} data-value={`${j},${i}`} key={j}></td>);
            }
        }
        data.push(<tr key={i}>{module}</tr>)
    }

    // Adding Hidden row to show cable split for Ace and Unify-FHD
    if (['Ace', 'Ace Plus', 'Unify-FHD'].includes(seriesDropDownValue)) {
        const moduleHidden = [];
        for (let j = 0; j < col; j++) {
            moduleHidden.push(<td style={{ border: 'none' }} className={`cabinet ${id == "data-snapshot" ? 'data-snapshot-cabinet' : (id == "power-snapshot" ? 'power-snapshot-cabinet' : '')}`} key={Math.random()}></td>);
        }
        data.push(<tr key={Math.random()}>{moduleHidden}</tr>)
        data.push(<tr key={Math.random()}>{moduleHidden}</tr>)
    }

    return (
        <>
            <div id={id} style={{ height: '80%', width: '80%', position: 'absolute', marginTop: '-70px' }}>
                <table id="cabinet-matrix" style={{ height: '100%', width: '100%', position: 'absolute' }} className={`${condition == true ? 'combine-cabinets' : ''}`}>
                    <tbody>
                        {data}
                    </tbody>
                </table>
                {children}

            </div>
        </>
    )
}

export default Cabinets;