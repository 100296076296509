import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { changeUnit } from "../../redux/reducers/wallReducer";

const UnitChange = () => {
    const dispatch = useDispatch();
    const unit = useSelector(state => state.wall.unit);
    const unitSelectRef = useRef();

    const handleUnitChange = (e) => {
        const unit = e.target.value;
        dispatch(changeUnit({
            unit
        }))
    }

    useEffect(() => {
        document.getElementById('unitChange').value = unit;
    }, [])

    return (
        <Input id="unitChange" ref={unitSelectRef} onChange={handleUnitChange} type='select'>
            <option value='m'>Meter (m)</option>
            <option value='ft'>Feet (ft)</option>
        </Input>
    )
}

export default UnitChange;