import { memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDataSnapshot } from "../../redux/reducers/orderReducer";
import { DEFAULT_LINE_COLOR, DATA_LOOP_LINE_COLOR, DATA_LOOP_BACKUP_LINE_COLOR, VEGA_VEGA_PLUS_195, VEGA_VEGA_PLUS_SERIES } from '../../config/config';
import CustomButton from "../common/components/CustomButtom";
import useUpdateEffect from "../../hooks/useUpdateEffect"

const OrderFormDataLooping = ({ formData, toggleTab, activeTab, setPowerTabFlag }) => {

    const { dataLooping } = useSelector(state => {
        return {
            dataLooping: state.order.dataLooping
        }
    })

    const dispatch = useDispatch();

    const saveDataLoopingScreenshot = async () => {
        try {
            const originalDataNode = document.getElementById('data-cabinet-div');
            const clonedDataNode = originalDataNode.cloneNode(true);

            dispatch(addDataSnapshot({
                snapshot: clonedDataNode
            }))
        } catch (error) {
            console.error("Error while saving Data Looping Screenshot");
        }
    }

    const handleClick = () => {
        toggleTab('4')
    }

    let cabinetFitRows = formData === null ? "--" : formData.specSheet.cabinetFitRows;
    let cabinetFitColumns = formData === null ? "--" : formData.specSheet.cabinetFitColumns;
    let seriesTypeId = formData === null ? "--" : formData.specSheet.seriesTypeId;
    let seriesName = formData === null ? "--" : formData.specSheet.series;
    let pixelPitch = formData === null ? "--" : formData.dimObj.pixelPitchLength;
    const svgRef = useRef(null);
    const countPoints = useRef([]);
    let drawingFlag = [];
    const splitterPoints = useRef([]);
    const cableCount = useRef(1);
    const [dataRowData, setDataRowData] = useState([]);

    const vegaStr = useRef('');
    let vegaCondition = VEGA_VEGA_PLUS_SERIES.includes(seriesName) && (!VEGA_VEGA_PLUS_195.includes(pixelPitch.toFixed(2)));

    const drawLine = (x1, y1, x2, y2, showArrow, count, coordinates, lineColor = DEFAULT_LINE_COLOR) => {
        const svg = svgRef.current;
        if (!svg) return;

        let strokeWidth = lineColor === DEFAULT_LINE_COLOR ? 1 : 1.5;

        const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);

        line.setAttribute('stroke', lineColor);
        line.setAttribute('stroke-width', strokeWidth);

        showArrow && line.setAttribute('marker-end', 'url(#dataArrow)');
        svg.appendChild(line);

        if (count >= 0 && countPoints.current.findIndex(ele => ele.coordinates.x1 === coordinates.x1 && ele.coordinates.y1 === coordinates.y1) === -1) {
            countPoints.current.push({ x1, y1, coordinates, count });
        }

        return line;
    }

    const showCableCount = (x1, y1, coordinates, count) => {
        if (isSplitterPoint({ x: coordinates.x1, y: coordinates.y1 })) {
            return
        }
        const svg = svgRef.current;
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circle.setAttribute('cx', x1);
        circle.setAttribute('cy', y1);
        circle.setAttribute('r', 10);
        circle.setAttribute('fill', '#e14504');

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', x1);
        text.setAttribute('y', y1);
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('alignment-baseline', 'central');
        text.setAttribute('fill', '#ffffff');
        text.setAttribute('font-size', 10);
        const textNode = document.createTextNode(cableCount.current++);
        text.appendChild(textNode);

        svg.appendChild(circle);
        svg.appendChild(text);
    }

    const drawCables = (x1, y1, x2, y2, showArrow = true, checkCondition = true, count = -1, lineColor, xOffset, yOffset, domRect) => {
        if (checkCondition) {
            if (x1 % 2 === 1 && x2 % 2 === 1) {
                drawingFlag.push({ x: x1, y: y1 });
            } else if (drawingFlag.length !== 0 && x1 % 2 === 0 && x2 % 2 === 0) {
                drawingFlag.forEach((ele, index) => {
                    const { x, y } = ele;
                    if (y === y1) {
                        connectCables(x, y, x1, y1, count, lineColor, xOffset, yOffset, domRect);
                        splitterPoints.current.push({ x, y }, { x: x1, y: y1 })
                        drawingFlag.splice(index, 1);
                    }
                })
            }
        }

        const width = domRect.width;
        const height = domRect.height;
        return drawLine(width * x1 - (width * xOffset), height * y1 - (height * yOffset), width * x2 - (width * xOffset), height * y2 - (height * yOffset), showArrow, count, { x1, y1, x2, y2 }, lineColor);
    }

    const removePreviousLines = () => {
        const svg = svgRef.current;
        const lines = svg?.querySelectorAll('line');
        if (lines) {
            lines.forEach(line => line.remove());
        }
    }

    const removePreviousCircles = () => {
        const svg = svgRef.current;
        const circles = svg?.querySelectorAll('circle');
        if (circles) {
            circles.forEach(circle => circle.remove());
        }
    }

    const removePreviousText = () => {
        const svg = svgRef.current;
        const texts = svg?.querySelectorAll('text');
        if (texts) {
            texts.forEach(text => text.remove());
        }
    }

    const drawVerticalMedian = (xOffset, yOffset) => {
        let x1, y1, x2, y2, domRect;
        domRect = document.getElementsByClassName("data-cabinet")[0].getBoundingClientRect();
        const width = domRect.width;
        const height = domRect.height;
        const halfWidth = width * xOffset;
        const halfHeight = height * yOffset;

        let new_col = Math.ceil(cabinetFitColumns / 2);
        let lastOddColFlag = cabinetFitColumns / 2 !== new_col;
        vegaStr.current = lastOddColFlag ? 'Unhighlighted cabinets measure 500x500mm.' : '';

        for (let j = 1; j <= new_col; j++) {
            let flag = (j === new_col && lastOddColFlag);
            let lineColour = flag ? DEFAULT_LINE_COLOR : '#e2e2e2';

            x1 = j; y1 = 1;
            x2 = j; y2 = cabinetFitRows;
            drawLine(width * x1 - (halfWidth), 0, width * x2 - (halfWidth), height * y2, false, -1, { x1, y1, x2, y2 }, lineColour);
        }
        if (lastOddColFlag) {
            let penultimateCol = new_col - 1;
            x1 = width * penultimateCol;
            x2 = (width * new_col) - halfWidth;
            for (let i = 0; i <= cabinetFitRows; i++) {
                y1 = height * i;
                y2 = height * i;
                drawLine(x1, y1, x2, y2, false, -1, { x1, y1, x2, y2 }, DEFAULT_LINE_COLOR);
            }
        }
    }

    const drawLoopingDiagram = (lineGroup, lineColor, xOffset, yOffset, cableCountBuffer) => {
        let domRect = document.getElementsByClassName("data-cabinet")[0].getBoundingClientRect();
        lineGroup?.forEach((group, index) => {
            group?.forEach((line, idx) => {
                const { x1, y1, x2, y2 } = line;
                let arrow = true;
                if (group.length !== 1 && idx !== (group.length - 1)) {
                    arrow = false;
                }
                if (idx === 0) {
                    drawCables(x1, y1, x2, y2, arrow, false, index, lineColor, xOffset, yOffset, domRect);
                } else {
                    drawCables(x1, y1, x2, y2, arrow, false, -1, lineColor, xOffset, yOffset, domRect);
                }
            })
        })

        countPoints.current.forEach((point) => {
            showCableCount(point.x1, point.y1, point.coordinates);
        })

        drawingFlag = [];
        splitterPoints.current = [];
        countPoints.current = [];
        cableCount.current = 1 + cableCountBuffer;
    }

    const isSplitterPoint = (point) => {
        return splitterPoints.current.some(pt => (pt.x === point.x && pt.y === pt.y));
    }

    const connectCables = (x1, y1, x2, y2, count, lineColor, xOffset, yOffset, domRect) => {
        const line = drawCables(x1, y1, x2, y2, false, false, count, lineColor, xOffset, yOffset, domRect);
        showCableSplit(line, { x1: x1 + 0.1, y1: y1 + 0.1, x2, y2 }, count, lineColor);
    }

    const showCableSplit = (line, coordinates, count, lineColor) => {
        // If condition added to handle 
        if (line.getClientRects().length > 0) {
            const length = line.getTotalLength();
            const startPoint = line.getPointAtLength(0);
            const endPoint = line.getPointAtLength(length);
            const midpointX = (startPoint.x + endPoint.x) / 2;
            const midpointY = (startPoint.y + endPoint.y) / 2;

            drawLine(midpointX, midpointY + 30, midpointX, midpointY, true, count, coordinates, lineColor);
        }
    }

    const drawTable = async () => {
        let rowData = [];

        let new_col = cabinetFitColumns;
        if (vegaCondition) {
            new_col = Math.ceil(cabinetFitColumns / 2);
        }

        let lastOddColFlag = (cabinetFitColumns / 2) !== new_col;

        for (let i = 0; i < cabinetFitRows; i++) {
            const module = [];
            for (let j = 0; j < new_col; j++) {
                if (new_col === cabinetFitColumns) {
                    module.push(<td className={`data-cabinet`} data-value={`${j},${i}`} key={j}></td>);
                } else {
                    let new_class = (j === new_col - 1 && lastOddColFlag) ? 'last-column-cell' : '';
                    module.push(<td className={`data-cabinet ${new_class}`} data-value={`${j},${i}`} key={j}></td>)
                }
            }
            rowData.push(<tr key={i}>{module}</tr>)
        }

        // Adding Hidden row to show cable split for Ace and Unify-FHD
        if (['Ace', 'Ace Plus', 'Unify-FHD'].includes(seriesName)) {
            const moduleHidden = [];
            for (let j = 0; j < cabinetFitColumns; j++) {
                moduleHidden.push(<td style={{ border: 'none' }} className={`data-cabinet`} key={Math.random()}></td>);
            }
            rowData.push(<tr key={Math.random()}>{moduleHidden}</tr>)
            rowData.push(<tr key={Math.random()}>{moduleHidden}</tr>)
        }

        setDataRowData(rowData);
        setTimeout(function () {
            let dataBackup = formData === null ? false : formData.specSheet.dataBackup;

            let xOffset = dataBackup == true ? 0.75 : 0.5;
            let yOffset = dataBackup == true ? 0.75 : 0.5;

            let cableCountBuffer = dataBackup == true ? dataLooping.points.length : 0;

            if (vegaCondition) {
                drawVerticalMedian(0.5, 0.5);
                xOffset = dataBackup == true ? 0.85 : 0.75;
            }
            drawLoopingDiagram(dataLooping.points, DATA_LOOP_LINE_COLOR, xOffset, yOffset, cableCountBuffer);

            if (dataBackup == true) {
                xOffset = 0.25;
                yOffset = 0.25;
                if (vegaCondition) {
                    xOffset = 0.65;
                }
                drawLoopingDiagram(dataLooping.points, DATA_LOOP_BACKUP_LINE_COLOR, xOffset, yOffset, 0);
            }
        }, 10);

        setTimeout(function () {
            saveDataLoopingScreenshot();
        }, 20);
    }

    useUpdateEffect(() => {
        if (formData !== null && activeTab == 3) {
            setPowerTabFlag(true);

            removePreviousLines();
            removePreviousCircles();
            removePreviousText();

            async function drawDataTable() {
                await drawTable();
            };
            drawDataTable();
        }
    }, [activeTab, dataLooping.points])

    return (
        <>
            {['Nyx Modular','Flex', 'Iris Façade', 'Iris Facade'].includes(seriesName) && (
                <span style={{ paddingTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>Looping diagram is not available for {seriesName} series.</span>
            )}
            <div hidden={['Nyx Modular','Flex', 'Iris Façade', 'Iris Facade'].includes(seriesName)} className='justify-content-center' style={{ justifyContent: 'center', display: 'flex', height: '100%', width: '100%', position: 'absolute', zIndex: 1 }}>

                <div id={'data-cabinet-div'} style={{ height: '65%', width: '80%', position: 'absolute' }}>
                    <table id="data-cabinet-matrix" style={{ height: '100%', width: '100%', position: 'absolute', justifyContent: 'center' }} className={`${vegaCondition == true ? 'combine-cabinets' : ''}`}>
                        <tbody>
                            {dataRowData}
                        </tbody>
                    </table>
                    <svg ref={svgRef} style={{ height: '100%', width: '100%', zIndex: 99, position: 'relative' }}>
                        <defs>
                            <marker id="dataArrow" viewBox="0 0 10 10" refX="8" refY="5"
                                markerWidth="5" markerHeight="5"
                                orient="auto-start-reverse">
                                <path d="M 0 0 L 10 5 L 0 10 z" />
                            </marker>
                        </defs>
                    </svg>
                    <div style={{ textAlign: 'center', marginTop: '2%' }}>
                        {(vegaCondition) && (
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ul>
                                    <li>Data cable looping happens in alternate cabinet columns for {seriesName} series by design.</li>
                                    <li>Cabinets highlighted in blue measure 1000x500mm. {vegaStr.current}</li>
                                </ul>
                            </span>
                        )}
                    </div>
                    <CustomButton style={{ float: "right", backgroundColor: "var(--primary-color)", marginTop: '10px' }} onClick={handleClick} ><span> Next </span></CustomButton>
                </div>
            </div>
        </>
    )
}


export default memo(OrderFormDataLooping);