import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    unit: 'm',
    height: 9.84,
    width: 19.69,
    localHeight: 9.84,
    localWidth: 19.69,
    'ft': {
        height: 9.84,
        width: 19.69
    },
    'm': {
        height: 3,
        width: 6
    }
}

const wallSlice = createSlice({
    name: 'wall',
    initialState: INITIAL_STATE,
    reducers: {
        changeWallDimensions: (state, action) => {
            state.height = action.payload.height;
            state.width = action.payload.width;
            state.localHeight = action.payload.height;
            state.localWidth = action.payload.width;
        },
        resetWallDimensions: (state) => {
            state.height = INITIAL_STATE[state.unit].height;
            state.width = INITIAL_STATE[state.unit].width;
        },
        changeUnit: (state, action) => {
            state.unit = action.payload.unit;
        },
        changeLocalWallDimensions: (state, action) => {
            state.localHeight = action.payload.height;
            state.localWidth = action.payload.width;
        },
    }
})

export const { changeWallDimensions, resetWallDimensions, changeUnit, changeLocalWallDimensions } = wallSlice.actions;

export default wallSlice.reducer;