import { Row } from 'reactstrap';
import XMLogo from '../../images/xmLogo.png'

const DisplayMessage = () => {
    return (
        <div style={{ height: '100%', overflow: 'auto', overflowX: 'hidden' }}>
            <Row style={{ height: '60%', width: '100%', margin: '0', backgroundColor: '#fff' }}>
                <div className="d-flex justify-content-center align-items-center" >
                    <a href="https://www.xtreme-media.com" target="_blank"><img src={XMLogo} alt="XM-Logo" style={{ height: '45px', margin: '4px' }} /></a>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ color: '#e14504', fontSize: '3vw' }} >
                    <b>Xtreme LED Configurator</b>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ margin: '0', backgroundColor: '#fff', fontSize: '2vw', textAlign: 'center' }}>
                    <b>For best results, <br/>please visit us on a bigger screen!</b>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ margin: '0', backgroundColor: '#fff', fontSize: '1.5vw', textAlign: 'center' }}>
                    If your browser or monitor is zoomed in, <br/>please try zooming out and then refreshing the page.
                </div>
            </Row>
        </div >
    )
}

export default DisplayMessage;