import { createSlice } from '@reduxjs/toolkit';

const selectedSeriesSlice = createSlice({
    name: 'selectedSeries',
    initialState: {
        series: {},
        viewingDistance: 10,
        seriesDropDownValue: '',
        pixelPitchValue: '',
        pixelPitchDropDownValue: '',
        pixelPitchDropDownList: [],
        seriesDropDownList: [],
        applicationDropDownValue: '',
        applicationDropDownList: []
    },
    reducers: {
        changeSeries: (state, action) => {
            state.series = action.payload.series;
        },
        changeViewingDistance: (state, action) => {
            state.viewingDistance = action.payload.viewingDistance;
        },
        changeSeriesDropDownValue: (state, action) => {
            state.seriesDropDownValue = action.payload.seriesDropDownValue;
        },
        changePixelPitchDropDownValue: (state, action) => {
            state.pixelPitchDropDownValue = action.payload.pixelPitchDropDownValue;
            state.pixelPitchValue = (action.payload.pixelPitchDropDownValue)?.split('@seriesTypeId=')[0]?.split(' (')[0]
        },
        changePixelPitchDropDownList: (state, action) => {
            state.pixelPitchDropDownList = action.payload.pixelPitchDropDownList;
        },
        changeSeriesDropDownList: (state, action) => {
            state.seriesDropDownList = action.payload.seriesDropDownList;
        },
        changeApplicationDropDownValue: (state, action) => {
            state.applicationDropDownValue = action.payload.applicationDropDownValue;
        },
        changeApplicationDropDownList: (state, action) => {
            state.applicationDropDownList = action.payload.applicationDropDownList;
        },
    }
})

export const { changeSeries, changeViewingDistance, changeSeriesDropDownValue, changePixelPitchDropDownValue, changePixelPitchDropDownList, changeSeriesDropDownList, changeApplicationDropDownValue, changeApplicationDropDownList } = selectedSeriesSlice.actions;

export default selectedSeriesSlice.reducer;