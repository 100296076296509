import { useSelector } from "react-redux";
import Light from "./lobby/Light";
import SpotLight from "./newsroom/SpotLight";
import Lamp from "./retial/Lamp";

const TopProps = () => {
    const { environment, showProps } = useSelector(state => {
        return { environment: state.environment.environment, showProps: state.environment.showProps }
    })

    if (!showProps) {
        return null;
    }

    return (
        <>
            {/* {environment === 'News Room' && <SpotLight />} */}
            {environment === 'Retail' && <Lamp />}
            {/* {environment === 'Lobby' && <Light />} */}
            {/* {environment === 'Auditorium' && <SpotLight />} */}
        </>
    )
}

export default TopProps;