import { memo, useContext, useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { FILE_SIZE_LIMIT, VALID_FILE_FORMATS } from "../../config/config";
import { LoaderContext } from "../../context/context";
import { changeContent } from "../../redux/reducers/contentReducer";
import { changeEnvironment } from "../../redux/reducers/environmentReducer";
import ContentPresets from "../canvas/ContentPresets";
import { Toast } from "../common/Functions";
import { FILE_SIZE_LIMIT_EXCEEDED, INVALID_FILE_FORMAT_UPLOADED } from "../common/Messages";

const ContentForm = () => {
    const { design, selectedDesignIdx } = useSelector(state => {
        return { design: state.design.designs, selectedDesignIdx: state.design.selectedDesignIdx }
    })
    const dispatch = useDispatch();
    const { diagram } = useContext(LoaderContext);

    useEffect(() => {
        const environment = design[selectedDesignIdx]?.environment;
        if (environment) {
            dispatch(changeEnvironment({
                environment
            }))
        }
    }, [selectedDesignIdx])


    const handleContentChange = (e) => {
        try {
            if (e.target.files.length) {
                const fileSize = e.target.files[0].size;
                if (fileSize > FILE_SIZE_LIMIT) {
                    Toast('error', 'Invalid size', FILE_SIZE_LIMIT_EXCEEDED);
                    return;
                }

                const extension = e.target.files[0].type;
                if (!VALID_FILE_FORMATS.includes(extension)) {
                    Toast('error', 'Invalid File Format', INVALID_FILE_FORMAT_UPLOADED);
                    console.error("Invalid File Format");
                    return;
                }

                const imgUrl = URL.createObjectURL(e.target.files[0]);
                dispatch(changeContent({
                    content: 'custom',
                    src: imgUrl,
                    type: extension
                }))

                // Has to be revoked to avoid memory leak
                URL.revokeObjectURL(e.target.files[0]);
            }
        } catch (e) {
            console.error(e);
            Toast('error', 'Error', 'Error while uploading file')
        }
    }

    const ledContentStyles = useMemo(() => {
        return { position: 'static', height: '200px' }
    }, [])

    return (
        <>
            <Form>
                <Card className="mt-3">
                    <CardBody>
                        <CardTitle className="text-muted mb-3" tag='h5'>Content Selection</CardTitle>
                        <FormGroup>
                            <Label for="content">
                                Upload Image / Video
                            </Label>
                            <Input
                                id="content"
                                name="content"
                                type="file"
                                accept={VALID_FILE_FORMATS.join(', ')}
                                onChange={handleContentChange}
                                disabled={diagram !== 'design'}
                            />
                            <FormText>
                                Support Formats: <b className="text-muted">jpg / jpeg / png / mp4</b><br />
                                Max File Size: <b>4 MB</b>
                            </FormText>
                        </FormGroup>
                        <ContentPresets />
                        {/* <Card>
                            <CardBody>
                                <LedContent style={ledContentStyles} autoPlay={false} loop={false} />
                            </CardBody>
                        </Card> */}
                    </CardBody>
                </Card>
            </Form>
        </>
    )
}

export default memo(ContentForm);