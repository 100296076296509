import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../common/components/CustomButtom";
import SpecsTable from "./SpecsTable";

const SpecsModal = ({ isOpen, setIsOpen }) => {
    const toggle = () => setIsOpen(!isOpen);
    const designs = useSelector(state => state.design.designs);

    return (
        <Modal isOpen={isOpen} toggle={toggle} fullscreen>
            <ModalHeader toggle={toggle}>{designs?.length > 1 ? 'Compare Designs' : 'Specifications'}</ModalHeader>
            <ModalBody>
                <SpecsTable forScreenShot={false} />
            </ModalBody>
            <ModalFooter>
                <CustomButton onClick={toggle}>
                    Close
                </CustomButton>
            </ModalFooter>
        </Modal>
    )
}

export default SpecsModal;