export const viewingDistanceReducer = (state, action) => {
    switch (action.type) {
        case 'setViewingDistanceIndoor': {
            return {
                ...state,
                indoorMin: action.indoorMin,
                indoorMax: action.indoorMax
            };
        }
        case 'setViewingDistanceOutdoor': {
            return {
                ...state,
                outdoorMin: action.outdoorMin,
                outdoorMax: action.outdoorMax
            };
        }
        default: {
            return state;
        }
    }
}