import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cloth from '../../../images/environments/retail/Cloth1.svg';

const Cloth = () => {
    const [height, setHeight] = useState(200);
    const [hide, setHide] = useState(false);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height, width } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${height * 0.6}px`);

        // const clothWidth = document.getElementById('retial-cloth').width;

        // if ((width + 20) < (clothWidth)) {
        //     setHide(true);
        // } else {
        //     setHide(false);
        // }
    }, [wallHeight, wallWidth])
    return (
        <img hidden={hide} id='retial-cloth' style={{ position: 'absolute', bottom: '0', zIndex: 999 }} height={height} src={cloth} alt="cloth" />
    )
}

export default Cloth;