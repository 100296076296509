import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    row: 5,
    col: 5,
    condition: 'indoor',
    fitToWall: false,
    fitToCentre: true,
    checkedResolution: 3,
    customResolution: true,
    transform: {
        x: 0,
        y: 0,
        scale: 1
    },
    position: {
        x: 0,
        y: 0
    },
    localRow: 5,
    localCol: 5,
    distanceTop: 0,
    distanceLeft: 0,
    distanceBottom: 0,

    showModules: true,

    cabinetWidth: 0.6,
    cabinetHeight: 0.34,
    cabinetWidthPx: 480,
    cabinetHeightPx: 270,

    localWidth: 0.6,
    localHeight: 0.34,
    localWidthPx: 480,
    localHeightPx: 270,
}

const ledSlice = createSlice({
    name: 'led',
    initialState: INITIAL_STATE,
    reducers: {
        changeLedDimensions: (state, action) => {
            state.row = action.payload.row;
            state.col = action.payload.col;

            state.localRow = action.payload.row;
            state.localCol = action.payload.col;

            state.localHeight = parseFloat(action.payload.row * state.cabinetHeight).toFixed(2);
            state.localWidth = parseFloat(action.payload.col * state.cabinetWidth).toFixed(2);

            state.localHeightPx = action.payload.row * state.cabinetHeightPx;
            state.localWidthPx = action.payload.col * state.cabinetWidthPx;
        },
        changeLedCondition: (state, action) => {
            state.condition = action.payload.condition;
        },
        resetLedDimensions: (state) => {
            state.row = INITIAL_STATE.row;
            state.col = INITIAL_STATE.col;

            state.localRow = INITIAL_STATE.row;
            state.localCol = INITIAL_STATE.col;

            state.localHeight = parseFloat(INITIAL_STATE.row * INITIAL_STATE.cabinetHeight).toFixed(2);
            state.localWidth = parseFloat(INITIAL_STATE.col * INITIAL_STATE.cabinetWidth).toFixed(2);

            state.localHeightPx = INITIAL_STATE.row * INITIAL_STATE.cabinetHeightPx;
            state.localWidthPx = INITIAL_STATE.col * INITIAL_STATE.cabinetWidthPx;
        },
        changeFitToWall: (state, action) => {
            if (state.fitToWall != action.payload.fitToWall) {
                state.fitToWall = action.payload.fitToWall;
            }
        },
        changeFitToCentre: (state, action) => {
            state.fitToCentre = action.payload.fitToCentre;
        },
        changeCheckedResolution: (state, action) => {
            state.checkedResolution = action.payload.resolution;
            state.customResolution = action.payload.resolution == 3 ? true : false;
        },
        changeTransform: (state, action) => {
            state.transform.x = action.payload.x;
            state.transform.y = action.payload.y;
            state.transform.scale = action.payload.scale;
        },
        resetTransform: (state) => {
            state.transform.x = INITIAL_STATE.transform.x;
            state.transform.y = INITIAL_STATE.transform.y;
            state.transform.scale = INITIAL_STATE.transform.scale;
        },
        changePosition: (state, action) => {
            state.position.x = action.payload.x;
            state.position.y = action.payload.y;
        },
        changeLocalLedMatrix: (state, action) => {
            state.localRow = action.payload.row;
            state.localCol = action.payload.col;
        },
        changeLedTopBottomLeftDistance: (state, action) => {
            state.distanceTop = action.payload.top;
            state.distanceLeft = action.payload.left;
            state.distanceBottom = action.payload.bottom;
        },

        changeLocalLedDimensions: (state, action) => {
            state.localHeight = action.payload.height;
            state.localWidth = action.payload.width;
        },
        changeLocalLedResolution: (state, action) => {
            state.localHeightPx = action.payload.cabinetHeightPx;
            state.localWidthPx = action.payload.cabinetWidthPx;
        },

        changeModuleDimensions: (state, action) => {
            if (state.cabinetHeight != action.payload.height) {
                state.cabinetHeight = action.payload.height;
                state.localHeight = parseFloat(state.row * action.payload.height).toFixed(2);
                if (!state.customResolution) {
                    state.checkedResolution = 3;
                    state.customResolution = true;
                }
            }

            if (state.cabinetWidth != action.payload.width) {
                state.cabinetWidth = action.payload.width;
                state.localWidth = parseFloat(state.col * action.payload.width).toFixed(2);
                if (!state.customResolution) {
                    state.checkedResolution = 3;
                    state.customResolution = true;
                }
            }
        },
        showModules: (state, action) => {
            state.showModules = action.payload.showModules
        },
        changeModuleDimensionsPx: (state, action) => {
            if (state.cabinetHeightPx != action.payload.cabinetHeightPx) {

                state.cabinetHeightPx = action.payload.cabinetHeightPx;
                state.localHeightPx = state.row * action.payload.cabinetHeightPx;

                if (!state.customResolution) {
                    state.checkedResolution = 3;
                    state.customResolution = true;
                }
            }

            if (state.cabinetWidthPx != action.payload.cabinetWidthPx) {
                state.cabinetWidthPx = action.payload.cabinetWidthPx;
                state.localWidthPx = state.col * action.payload.cabinetWidthPx;

                if (!state.customResolution) {
                    state.checkedResolution = 3;
                    state.customResolution = true;
                }
            }
        }
    }
})

export const { changeLedDimensions, changeLedCondition, resetLedDimensions, changeFitToWall, changeFitToCentre, changeCheckedResolution, changeTransform, resetTransform, changePosition, changeLocalLedMatrix,
    changeLedTopBottomLeftDistance, changeLocalLedDimensions, changeLocalLedResolution, changeModuleDimensions, showModules, changeModuleDimensionsPx } = ledSlice.actions;

export default ledSlice.reducer;