import { createSlice } from '@reduxjs/toolkit';

const environmentSlice = createSlice({
    name: 'environment',
    initialState: {
        environment: 'All',
        showProps: false
    },
    reducers: {
        changeEnvironment: (state, action) => {
            state.environment = action.payload.environment;
        },
        changeShowProps: (state, action) => {
            state.showProps = action.payload.showProps;
        }
    }
})

export const { changeEnvironment, changeShowProps } = environmentSlice.actions;

export default environmentSlice.reducer;