export const LED_HEIGHT_EXCEED_WALL = 'LED display height limit reached for current wall dimensions.';
export const LED_WIDTH_EXCEED_WALL = 'LED display width limit reached for current wall dimensions.';
export const WALL_HEIGHT_SMALLER_THAN_LED = 'Cannot reduce wall height for currect display dimensions.'
export const WALL_WIDTH_SMALLER_THAN_LED = 'Cannot reduce wall width for currect display dimensions.'
export const WALL_HEIGHT_SMALLER_THAN_LED_POSITION = 'Cannot reduce wall height for currect display position.'
export const WALL_WIDTH_SMALLER_THAN_LED_POSITION = 'Cannot reduce wall width for currect display position.'
export const MIN_ROOM_HEIGHT_LIMIT_REACHED = 'Minimum room height limit reached.';
export const MAX_ROOM_HEIGHT_LIMIT_REACHED = 'Maximum room height limit reached.';
export const MIN_ROOM_WIDTH_LIMIT_REACHED = 'Minimum room width limit reached.';
export const MAX_ROOM_WIDTH_LIMIT_REACHED = 'Maximum room width limit reached.';
export const INVALID_FILE_FORMAT_UPLOADED = 'Invalid file format uploaded.';
export const FILE_SIZE_LIMIT_EXCEEDED = 'Please upload a file less than 4 MB.';
export const WALL_EXCEEDED = 'LED display cannot exceed the the boundary wall.';