import axios from 'axios'

export const version = `1.8.0`
// Test Environment
// export const API_BASE_URL = "http://192.168.60.116:4015"

// Dev Environment
// export const API_BASE_URL = "http://192.168.10.119:3005"

// Production Environment
export const API_BASE_URL = "https://configurator.xtreme-media.com:2445"

export const AES_METHOD = "aes-256-cbc"
export const KEY = "lbwyBzfgzUIvXZFShJuikaWvLJhIVq36"
export const IV = "gKySztfUMx7uQEl7"

export default axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})