import { toCanvas } from "html-to-image";
import { jsPDF } from "jspdf";
import { TfiExport } from "react-icons/tfi";
import { useSelector } from "react-redux";
import CustomButton from "../common/components/CustomButtom";
import XMLogo from '../../images/pdf_export/XM Logo Header.png';
import { useContext, useMemo, useRef, useState } from "react";
import VisitorDetailsModal from "./VisitorDetailsModal";
import { Tooltip } from "reactstrap";
import bgImg from '../../images/brick-wall.png';
import html2canvas from "html2canvas";
import { DOWNLOAD_PDF_IN_FRONTEND, VEGA_VEGA_PLUS_195, VEGA_VEGA_PLUS_SERIES } from "../../config/config";
import { LoaderContext } from "../../context/context";
import { convertFtToMeter, convertMeterToFt } from "../common/Functions";

import ace from '../../images/mechanicaldiagrams/ace.png';
import aceplus from '../../images/mechanicaldiagrams/aceplus.png';
import crystal from '../../images/mechanicaldiagrams/crystal.png';
import earth from '../../images/mechanicaldiagrams/earth.png';
import earth3in1 from '../../images/mechanicaldiagrams/earth3in1.png';
import iris from '../../images/mechanicaldiagrams/iris.png';
import irisod from '../../images/mechanicaldiagrams/irisod.png';
import irisfacade from '../../images/mechanicaldiagrams/irisfacade.png';
import irisfaçade from '../../images/mechanicaldiagrams/irisfacade.png';
import lit from '../../images/mechanicaldiagrams/lit.png';
import nyx from '../../images/mechanicaldiagrams/nyx.png';
import nyxcob from '../../images/mechanicaldiagrams/nyxcob.png';
import nyxodal from '../../images/mechanicaldiagrams/nyxodal.png';
import nyxodms from '../../images/mechanicaldiagrams/nyxodms.png';
import nyxvogue43 from '../../images/mechanicaldiagrams/nyxvogue43.png';
import nyxvogue55 from '../../images/mechanicaldiagrams/nyxvogue55.png';
import rigelmicro from '../../images/mechanicaldiagrams/rigelmicro.png';
import rigelmicro3_91 from '../../images/mechanicaldiagrams/rigelmicro3_91.png';
import rigelplus from '../../images/mechanicaldiagrams/rigelplus.png';
import unifyfhd108wall from '../../images/mechanicaldiagrams/unifyfhd108wall.png';
import unifyfhd108wheels from '../../images/mechanicaldiagrams/unifyfhd108wheels.png';
import unifyfhd135wall from '../../images/mechanicaldiagrams/unifyfhd135wall.png';
import unifyfhd135wheels from '../../images/mechanicaldiagrams/unifyfhd135wheels.png';
import unifyfhd163wall from '../../images/mechanicaldiagrams/unifyfhd163wall.png';
import unifyfhd163wheels from '../../images/mechanicaldiagrams/unifyfhd163wheels.png';
import vega from '../../images/mechanicaldiagrams/vega.png';
import vegaplus from '../../images/mechanicaldiagrams/vegaplus.png';
import vegastudio from '../../images/mechanicaldiagrams/vegastudio.png';
import glowbox from "../../images/mechanicaldiagrams/glowbox.jpg";

import XMFrontHeaderLogo from "../../images/pdf_export/XM Logo Big.png";
import FrontPageImage from "../../images/pdf_export/Delhi Experience Center.png";
import LastPageImage from "../../images/pdf_export/Last Page.png";
import SecondPageImage from "../../images/pdf_export/Bangalore Experience Center.png";
import Footer from "../../images/pdf_export/PDF Footer.png";
import CEO from "../../images/pdf_export/Sanket Rambhia.png"

import aceCab from "../../images/cabinets/ace.png";
import aceplusCab from "../../images/cabinets/aceplus.png";
import crystalCab from "../../images/cabinets/crystal.png"
import earthCab from "../../images/cabinets/earth.png";
import earth3in1Cab from "../../images/cabinets/earthdip.png";
import flexCab from "../../images/cabinets/flex.png";
import irisCab from "../../images/cabinets/iris.png";
import irisfacadeCab from "../../images/cabinets/irisfacade.png";
import irisfaçadeCab from "../../images/cabinets/irisfacade.png";
import irisodCab from "../../images/cabinets/irisod.png";
import litCab from "../../images/cabinets/lit.png";
import nyxCab from "../../images/cabinets/nyx.png";
import nyxcobCab from "../../images/cabinets/nyxcob.png";
import nyxmodularCab from "../../images/cabinets/nyxmodular.png";
import nyxodCab from "../../images/cabinets/nyxod.png";
import nyxvogueCab from "../../images/cabinets/nyxvogue.png";
import rigelmicroCab from "../../images/cabinets/rigelmicro.png";
import rigelplusCab from "../../images/cabinets/rigelplus.png";
import unifyfhdCab from "../../images/cabinets/unifyfhd.png";
import vegaCab from "../../images/cabinets/vega.png";
import vegaplusCab from "../../images/cabinets/vegaplus.png";
import vegastudioCab from "../../images/cabinets/vegastudio.png";
import glowboxCab from "../../images/cabinets/glowbox.jpg";

// Upload the font.ttf file to this site (https://peckconsulting.s3.amazonaws.com/fontconverter/fontconverter.html) and copy and include the generated js file like below
require('../../font/Rubik-normal')
require('../../font/Rubik-bold')

const ExportButton = ({ style }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { snapshot, selectedDesignIdx, design, designsAdded, unit, reduxSeries, loopingDiagram } = useSelector(state => {
        return {
            snapshot: state.snapShot.snapshot,
            selectedDesignIdx: state.design.selectedDesignIdx,
            design: state.design.designs,
            designsAdded: state.design.designsAdded,
            unit: state.wall.unit,
            reduxSeries: state.series,
            loopingDiagram: state.loopingDiagram
        }
    });
    const snaps = useMemo(() => [...snapshot], [snapshot]);
    const x = useRef(0);
    const y = useRef(0);
    const [tooltip, setTooltip] = useState(false);
    const { diagram } = useContext(LoaderContext);
    const textMargin = 15;
    const initialFont = 'Rubik';
    const initialFontSize = 15;
    let pageCount = 1;
    let pdfPageWidth;
    let pdfPageHeight;
    let totalHeaderHeight = 0;
    let totalFooterHeight = 0;

    const mechanicalImgSrc = useMemo(() => {
        return {
            ace,
            aceplus,
            crystal,
            earth,
            earth3in1,
            iris,
            irisod,
            irisfacade,
            irisfaçade,
            lit,
            nyx,
            nyxcob,
            nyxodal,
            nyxodms,
            nyxvogue43,
            nyxvogue55,
            rigelmicro,
            rigelmicro3_91,
            rigelplus,
            unifyfhd108wall,
            unifyfhd108wheels,
            unifyfhd135wall,
            unifyfhd135wheels,
            unifyfhd163wall,
            unifyfhd163wheels,
            vega,
            vegaplus,
            vegastudio,
            glowbox
        }
    }, []);

    const cabinetImgSrc = useMemo(() => {
        return {
            ace: aceCab,
            aceplus: aceplusCab,
            crystal: crystalCab,
            earth: earthCab,
            earth3in1: earth3in1Cab,
            flex: flexCab,
            iris: irisCab,
            irisfacade: irisfacadeCab,
            irisfaçade: irisfaçadeCab,
            irisod: irisodCab,
            lit: litCab,
            nyx: nyxCab,
            nyxcob: nyxcobCab,
            nyxmodular: nyxmodularCab,
            nyxod: nyxodCab,
            nyxvogue: nyxvogueCab,
            rigelmicro: rigelmicroCab,
            rigelplus: rigelplusCab,
            unifyfhd: unifyfhdCab,
            vega: vegaCab,
            vegaplus: vegaplusCab,
            vegastudio: vegastudioCab,
            glowbox: glowboxCab
        }
    }, []);

    const addBlockTitle = (pdf, title = false, titleFontWeight = 'normal', titleAlignment = 'center', titleColor = [0, 0, 0], titleBgColor = [232, 232, 232]) => {
        let method = titleAlignment == "left" ? addTextLeft : (titleAlignment == 'right' ? addTextRight : addTextCenter);
        let params = titleAlignment == "left" ?
            { txt: title, pdf: pdf, fontSize: 17, paddingBottom: 0, paddingLeft: 0, fontWeight: titleFontWeight, newLine: true } :
            (titleAlignment == 'right' ?
                { txt: title, pdf: pdf, fontSize: 17, paddingBottom: 0, paddingRight: 0, fontWeight: titleFontWeight } :
                { txt: title, pdf: pdf, fontSize: 17, paddingBottom: 0, fontWeight: titleFontWeight });

        let effectiveWidth = pdfPageWidth - (textMargin * 2);
        pdf.setFillColor(...titleBgColor);
        pdf.rect(textMargin, y.current, effectiveWidth, (pdfPageWidth * 0.05), 'F');

        addPaddingBottom(7);

        pdf.setTextColor(...titleColor);
        method(...Object.values(params));
        pdf.setTextColor(0, 0, 0);

        addPaddingBottom(6);
    }

    const addNewPage = (pdf, addHeaderFlag = true, addFooterFlag = true, footerFontColor = [255, 255, 255], addFooterLinksFlag = true, footerLinksFontColor = [0, 0, 0],
        incrementPageNumber = true, showPageNumber = true, title = false, titleFontWeight = 'normal', titleAlignment = 'center', titleColor = [0, 0, 0], titleBgColor = [232, 232, 232]) => {

        if (incrementPageNumber) {
            ++pageCount;
        }

        pdf.addPage();
        y.current = 0;
        pdf.setFontSize(initialFontSize);
        pdf.setFont(initialFont, '', 'normal');

        if (addHeaderFlag) {
            y.current = textMargin;
            let imageWidth = pdf.getImageProperties(XMLogo).width;
            let imageHeight = pdf.getImageProperties(XMLogo).height;

            let logoImageWidth = pdfPageWidth * 0.18;
            let logoImageHeight = (imageHeight / imageWidth) * logoImageWidth;

            addImage(pdf, XMLogo, 'png', logoImageWidth, logoImageHeight, false, 'normal', initialFontSize, 'top', 'left', 0, 'left', 0);

            y.current += 8;

            if (title) {
                addBlockTitle(pdf, title, titleFontWeight, titleAlignment, titleColor, titleBgColor);
            }
            totalHeaderHeight = y.current;
        }

        let cursorPosition = y.current;

        if (addFooterFlag) {
            pdf.setTextColor(...footerFontColor);
            let imgWidth = pdf.getImageProperties(Footer).width;
            let imgHeight = pdf.getImageProperties(Footer).height;

            let footerImageWidth = pdfPageWidth;
            let footerImageHeight = (imgHeight / imgWidth) * footerImageWidth;

            let footerFontSize = pdfPageHeight * 0.035;

            pdf.setFontSize(pdfPageHeight * 0.03);
            let linkHeight = pdf.getTextDimensions('Test').h + 3;
            pdf.setFontSize(initialFontSize);

            let footerY = pdfPageHeight - linkHeight - footerImageHeight;
            totalFooterHeight = pdfPageHeight - footerY

            pdf.addImage(Footer, 'png', 0, footerY, footerImageWidth, footerImageHeight - 4)

            let currentY = y.current;

            y.current = footerY + 8;
            pdf.setTextColor(255, 255, 255);
            addTextLink(pdf, 'www.xtreme-media.com', 'https://www.xtreme-media.com', footerFontSize, 'normal', 'left', 0, false, false);
            if (showPageNumber) {
                addTextRight('Page ' + pageCount, pdf, footerFontSize, 0, 0, 'bold');
            }
            pdf.setTextColor(0, 0, 0);

            y.current = currentY;
        }

        if (addFooterLinksFlag) {
            addFooterLinks(pdf, footerLinksFontColor, 'normal');
        }

        y.current = cursorPosition;

        pdf.setFontSize(initialFontSize);
        pdf.setFont(initialFont, '', "normal");
        pdf.setTextColor(0, 0, 0);
    }

    const drawLine = (pdf, color, strokeWidth = 0.2, x1 = textMargin, y1 = y.current, x2 = (pdfPageWidth - textMargin), y2 = y.current) => {
        if (strokeWidth !== 0.2) {
            pdf.setLineWidth(strokeWidth)
        }
        pdf.setDrawColor(...color);

        pdf.line(x1, y1, x2, y2);

        pdf.setDrawColor(0, 0, 0);
        pdf.setLineWidth(0.2)
    }

    const addTextCenter = (txt, pdf, fontSize = 15, paddingBottom = 0, fontWeight = 'normal') => {
        if (fontWeight !== 'normal') {
            pdf.setFont(initialFont, '', fontWeight);
        }
        pdf.setFontSize(fontSize);

        const pdfWidth = pdfPageWidth;
        const { w, h } = pdf.getTextDimensions(txt);

        pdf.text(txt, (pdfWidth - w) / 2, y.current);
        addPaddingBottom(h + paddingBottom);

        pdf.setFontSize(initialFontSize);
        pdf.setFont(initialFont, '', "normal");
    }

    const addTextLeft = (txt, pdf, fontSize = 15, paddingBottom = 0, paddingLeft = 0, fontWeight = 'normal', newLine = true, resetFont = true) => {
        pdf.setFont(initialFont, '', fontWeight);
        pdf.setFontSize(fontSize);

        pdf.text(txt, x.current + textMargin + paddingLeft, y.current);
        if (newLine) {
            const { w, h } = pdf.getTextDimensions(txt);
            addPaddingBottom((h + paddingBottom));
        }

        if (resetFont) {
            pdf.setFontSize(initialFontSize);
            pdf.setFont(initialFont, '', 'normal');
        }
    }

    const addTextRight = (txt, pdf, fontSize = 15, paddingBottom = 0, paddingRight = 0, fontWeight = "normal") => {
        pdf.setFontSize(fontSize);
        if (fontWeight != "normal") {
            pdf.setFont(initialFont, "", fontWeight);
        }
        const pdfWidth = pdfPageWidth;
        const { w, h } = pdf.getTextDimensions(txt);

        pdf.text(txt, (pdfWidth - textMargin - w - paddingRight), y.current);
        addPaddingBottom((h + paddingBottom))

        pdf.setFontSize(initialFontSize);
        pdf.setFont(initialFont, "", "normal");
    }

    const addBackgroundImage = (pdf) => {
        pdf.addImage(bgImg, "PNG", 0, 0, pdfPageWidth, pdfPageHeight, '', 'FAST');
    }

    const addPaddingBottom = (paddingBottom) => {
        y.current += paddingBottom;
    }

    const checkIfPageOverflow = (pdf, contentHeight) => {
        if ((pdf.internal.pageSize.height - (y.current + contentHeight) < 0)) {
            addNewPage(pdf);
        }
    }

    const addImage = (pdf, img, type, width, height, title, fontStyle = "normal", fontSize = initialFontSize, titlePosition = "top", titleDirection = 'center', titlePadding = 0, imagePosition = 'center', imagePadding = 0) => {
        let method = titleDirection == "left" ? addTextLeft : (titleDirection == 'right' ? addTextRight : addTextCenter);

        let params = titleDirection == "left" ?
            { txt: title, pdf: pdf, fontSize: fontSize, paddingBottom: 0, paddingLeft: 0, fontWeight: fontStyle, newLine: true } :
            (titleDirection == 'right' ?
                { txt: title, pdf: pdf, fontSize: fontSize, paddingBottom: 0, paddingRight: 0, fontWeight: fontStyle } :
                { txt: title, pdf: pdf, fontSize: fontSize, paddingBottom: 0, fontWeight: fontStyle });

        let titleHeight = 0;
        if (title) {
            titleHeight = pdf.getTextDimensions(title).h;
        }
        checkIfPageOverflow(pdf, height + titleHeight + titlePadding);

        if (title && titlePosition == "top") {
            method(...Object.values(params));
            addPaddingBottom((titlePadding));
        }

        let leftMargin = textMargin;
        if (imagePosition == 'left') {
            leftMargin += imagePadding;
        }

        if (imagePosition == 'center') {
            leftMargin = (pdfPageWidth - width) / 2;
        }

        if (imagePosition == 'right') {
            leftMargin = pdfPageWidth - width - textMargin - imagePadding;
        }

        pdf.addImage(img, type, x.current + leftMargin, y.current, width, height, undefined, 'FAST');
        addPaddingBottom((height));

        if (title && titlePosition == "bottom") {
            addPaddingBottom((titlePadding));
            method(...Object.values(params));
        }
    }

    function addTextLink(pdf, text, url, fontSize, fontWeight, direction, paddingLeft, resetFont, newLine = true) {
        pdf.setFontSize(fontSize);
        pdf.setFont(initialFont, '', fontWeight);

        let wordLength = pdf.getTextDimensions(text).w;
        let wordHeight = pdf.getTextDimensions(text).h;
        let leftMargin = textMargin;
        if (direction == 'left') {
            leftMargin += paddingLeft
        } else if (direction == 'right') {
            leftMargin = pdf.internal.pageSize.width - wordLength - textMargin
        } else {
            leftMargin = (pdf.internal.pageSize.width - wordLength) / 2;
        }
        pdf.textWithLink(text, leftMargin, y.current, { url: url });
        if (newLine) {
            addPaddingBottom(wordHeight);
        }

        if (resetFont) {
            pdf.setFontSize(initialFontSize);
            pdf.setFont(initialFont, '', 'normal');
        }
    }

    function addFooterLinks(pdf, footerLinksFontColor = [0, 0, 0], fontWeight = 'normal') {
        let footerLinkFontSize = pdfPageHeight * 0.03;

        pdf.setTextColor(...footerLinksFontColor);
        pdf.setFontSize(footerLinkFontSize);
        pdf.setFont(initialFont, '', fontWeight);

        let footerTextXMWidth = pdf.getTextDimensions('© 2024 Xtreme Media Pvt. Ltd. | ').w;
        let footerTextPolicyWidth = pdf.getTextDimensions('Privacy Policy | ').w;
        let footerTextTermsWidth = pdf.getTextDimensions('Terms and Conditions').w;
        let footerTextHeight = pdf.getTextDimensions('© 2024 Xtreme Media Pvt. Ltd. | Privacy Policy | Terms and Conditions').h;

        let footerLeftMargin = ((pdfPageWidth - (footerTextXMWidth + footerTextPolicyWidth + footerTextTermsWidth)) / 2) - textMargin;

        y.current = pdfPageHeight - footerTextHeight - 1;

        addTextLink(pdf, '© 2024 Xtreme Media Pvt. Ltd. | ', 'https://xtreme-media.com', footerLinkFontSize, fontWeight, 'left', footerLeftMargin, false, false);
        addTextLink(pdf, 'Privacy Policy | ', 'https://www.xtreme-media.com/privacy-policy.html', footerLinkFontSize, fontWeight, 'left', footerLeftMargin + footerTextXMWidth, false, false);
        addTextLink(pdf, 'Terms and Conditions', 'https://www.xtreme-media.com/terms-conditions.html', footerLinkFontSize, fontWeight, 'left', footerLeftMargin + footerTextXMWidth + footerTextPolicyWidth, false, true);
        y.current += 2;

        pdf.setFontSize(initialFontSize);
        pdf.setTextColor(0, 0, 0);
    }

    function addLoopingDiagramLegendBlock(pdf, disclaimerTextHeight, blockHeight, blockTitle, blockFontWeight, legendTitle, cableColor) {

        let effectiveWidth = pdfPageWidth - (textMargin * 2);

        y.current = pdfPageHeight - totalFooterHeight - 1 - disclaimerTextHeight - 3 - 6 - (blockHeight * 2) - 7;

        addBlockTitle(pdf, blockTitle, blockFontWeight, 'center', [0, 0, 0], [232, 232, 232]);

        pdf.setLineWidth(0.5);
        pdf.setDrawColor(...cableColor);

        pdf.line(textMargin + 1, y.current, 25, y.current);

        pdf.setDrawColor(0, 0, 0);
        pdf.setFillColor(0, 0, 0);
        pdf.setLineWidth(0.2);

        pdf.triangle(25, y.current - 0.8, 25, y.current + 0.8, 26.6, y.current, 'FD');

        pdf.setFillColor(225, 69, 4);
        pdf.circle((pdfPageWidth / 2) + 0.8, y.current - 0.4, 2, 'F');

        addPaddingBottom(1);

        pdf.setTextColor(255, 255, 255);
        addTextLeft('1', pdf, pdfPageHeight * 0.035, 0, (effectiveWidth / 2), 'normal', false, false);
        pdf.setTextColor(0, 0, 0);

        addTextLeft(legendTitle, pdf, pdfPageHeight * 0.04, 0, 15, 'normal', false, false);
        addTextLeft('Cable Count', pdf, pdfPageHeight * 0.04, 0, (effectiveWidth / 2) + 7, 'normal', true, true);
    }

    // ----------------------------------------------------------------------

    function addFirstPage(visitorDetails, pdf) {
        const { name, lastName } = visitorDetails;
        let fName = name.trim().charAt(0).toUpperCase() + name.trim().slice(1).toLowerCase();
        let lName = lastName.trim().charAt(0).toUpperCase() + lastName.trim().slice(1).toLowerCase();

        let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let dateNum = (new Date()).getDate();
        let dateRemainder = dateNum % 10;
        let dateSuffix = dateNum > 4 ? 'th' : (dateRemainder == 1 ? 'st' : (dateRemainder == 2 ? 'nd' : (dateRemainder == 3 ? 'rd' : 'th')));
        let dateStr = (new Date()).getDate() + dateSuffix + " " + monthArr[(new Date()).getMonth()] + " " + (new Date()).getFullYear();
        let timeStr = (new Date()).toLocaleTimeString();

        let headerTopBoxHeight = pdfPageHeight * 0.45;
        let headerNameBoxHeight = pdfPageHeight * 0.10;
        let headerPictureBoxHeight = pdfPageHeight * 0.41;
        let headerFooterBoxHeight = pdfPageHeight * 0.04;

        let { width, height } = pdf.getImageProperties(XMFrontHeaderLogo);
        let XMFrontHeaderLogoWidth = (pdfPageWidth * 0.30);
        let XMFrontHeaderLogoHeight = (height / width) * XMFrontHeaderLogoWidth;

        let headerTopFontSize = pdfPageHeight * 0.16;
        let headerNameTitleFontSize = pdfPageHeight * 0.05;
        let headerNameFontSize = pdfPageHeight * 0.06;

        // First Page - Black Box
        pdf.setFillColor(0, 0, 0);
        pdf.rect(0, y.current, pdfPageWidth, headerTopBoxHeight, 'F');

        y.current += textMargin + 10;

        addImage(pdf, XMFrontHeaderLogo, 'png', XMFrontHeaderLogoWidth, XMFrontHeaderLogoHeight, false, 'normal', initialFontSize, 'top', 'left', 0, 'left', 0)

        y.current += 40;

        pdf.setTextColor(255, 255, 255);
        addTextLeft('LED', pdf, headerTopFontSize, 1, 0, 'bold', true, false);
        addTextLeft('VIDEOWALL', pdf, headerTopFontSize, 1, 0, 'bold', true, false);
        addTextLeft('CONFIGURATOR', pdf, headerTopFontSize, 1, 0, 'bold', true, true);

        y.current = headerTopBoxHeight;

        pdf.setTextColor(0, 0, 0);

        // First Page - Grey Box
        pdf.setFillColor(232, 232, 232);
        pdf.rect(0, y.current, pdfPageWidth, headerNameBoxHeight, 'F');

        pdf.setFontSize(headerNameTitleFontSize);
        let titleHeight = pdf.getTextDimensions('Generated For : ').h;

        pdf.setFontSize(headerNameFontSize);
        let nameHeight = pdf.getTextDimensions('Generated For : ').h;

        pdf.setFontSize(initialFontSize);

        y.current += (headerNameBoxHeight - (titleHeight + nameHeight + 5));

        addTextLeft('Generated For : ', pdf, headerNameTitleFontSize, 2, 0, 'normal', false);
        addTextRight(dateStr, pdf, headerNameTitleFontSize, 2, 0, 'normal');

        addTextLeft(fName + ' ' + lName, pdf, headerNameFontSize, 0, 0, 'bold', false);
        addTextRight(timeStr, pdf, headerNameTitleFontSize, 0, 0, 'normal');

        y.current = headerTopBoxHeight + headerNameBoxHeight;

        // First Page - White Box
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, y.current, pdfPageWidth, headerPictureBoxHeight, 'F');

        ({ width, height } = pdf.getImageProperties(FrontPageImage))
        let XMFrontImageWidth = (pdfPageWidth - (textMargin * 2));
        let XMFrontImageHeight = headerPictureBoxHeight - (textMargin * 2);

        y.current += textMargin;

        addImage(pdf, FrontPageImage, 'png', XMFrontImageWidth, XMFrontImageHeight, 'Delhi Experience Center', 'normal', pdfPageHeight * 0.04, 'bottom', 'left', 5, 'center', 0);

        y.current = headerTopBoxHeight + headerNameBoxHeight + headerPictureBoxHeight;

        // First Page - Orange Footer
        pdf.setFillColor(225, 69, 4);
        pdf.rect(0, y.current, pdfPageWidth, headerFooterBoxHeight, 'F');

        addFooterLinks(pdf, [255, 255, 255], 'normal');
    }

    function addSecondPage(pdf) {
        addNewPage(pdf, false, true, [255, 255, 255], true, [0, 0, 0], false, true);

        let textFontSize = pdfPageHeight * 0.035;

        let imgHeight = pdfPageHeight * 0.35;
        let imgWidth = pdfPageWidth;

        addImage(pdf, SecondPageImage, 'png', imgWidth, imgHeight, false, 'normal', initialFontSize, 'top', 'left', 0, 'center', 0);
        y.current += 20;

        addTextLeft('ABOUT', pdf, pdfPageHeight * 0.09, 1, 0, 'bold', true, false);
        pdf.setTextColor(255, 69, 4);
        addTextLeft('XTREME MEDIA', pdf, pdfPageHeight * 0.09, 1, 0, 'bold', true, true);

        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(textFontSize);

        let effectiveWidth = pdfPageWidth - (textMargin * 2);

        let imageWidth = pdf.getImageProperties(CEO).width;
        let imageHeight = pdf.getImageProperties(CEO).height;

        let CEOImageWidth = pdfPageWidth * 0.25;
        let CEOImageHeight = (imageHeight / imageWidth) * CEOImageWidth;

        pdf.setFont(initialFont, '', 'bold');

        let boldWidth1 = pdf.getTextDimensions('largest national LED display manufacturer ').w;
        let boldWidth2 = pdf.getTextDimensions('innovation, ').w;
        let boldWidth3 = pdf.getTextDimensions('quality ').w;
        let boldWidth4 = pdf.getTextDimensions('service. ').w;
        let boldWidth5 = pdf.getTextDimensions('warranty ').w;
        let boldWidth6 = pdf.getTextDimensions('Sanket Rambhia, the visionary MD and Founder of Xtreme Media,').w;

        pdf.setFont(initialFont, '', 'normal');
        let normalWidth1 = pdf.getTextDimensions('Xtreme Media, the ').w;
        let normalWidth2 = pdf.getTextDimensions('India. With over 25+ years of expertise, the brand is known for its customer-centric ').w;
        let normalWidth3 = pdf.getTextDimensions('and best-in-class ').w;
        let normalWidth4 = pdf.getTextDimensions('It is the only company in the world to provide an ').w;

        addTextLeft("Xtreme Media, the ", pdf, textFontSize, 0, 0, 'normal', false, false);
        addTextLeft("largest national LED display manufacturer ", pdf, textFontSize, 0, normalWidth1, 'bold', false, false);
        addTextLeft("has over 1500+ LED screens installed across", pdf, textFontSize, 0, normalWidth1 + boldWidth1, 'normal', true, false);

        addTextLeft("India. With over 25+ years of expertise, the brand is known for its customer-centric ", pdf, textFontSize, 0, 0, 'normal', false, false);
        addTextLeft("innovation, ", pdf, textFontSize, 0, normalWidth2, 'bold', false, false);
        addTextLeft("built-to-last", pdf, textFontSize, 0, boldWidth2 + normalWidth2, 'normal', true, false);

        addTextLeft("quality ", pdf, textFontSize, 0, 0, 'bold', false, false)
        addTextLeft("and best-in-class ", pdf, textFontSize, 0, boldWidth3, 'normal', false, false)
        addTextLeft("service. ", pdf, textFontSize, 0, boldWidth3 + normalWidth3, 'bold', false, false)
        addTextLeft("It is the only company in the world to provide an ", pdf, textFontSize, 0, boldWidth3 + normalWidth3 + boldWidth4, 'normal', false, false)
        addTextLeft("Xtreme Platina 7-year", pdf, textFontSize, 0, boldWidth3 + normalWidth3 + boldWidth4 + normalWidth4, 'bold', true, false)

        addTextLeft("warranty ", pdf, textFontSize, 10, 0, 'bold', false, false)
        addTextLeft("at no extra cost on all its LED displays, ensuring peace of mind to its customers.", pdf, textFontSize, 10, boldWidth5, 'normal', true, false)

        let YBeforeImage = y.current;
        let introY = YBeforeImage + (CEOImageHeight * 0.4);

        addImage(pdf, CEO, 'png', CEOImageWidth, CEOImageHeight, false, 'normal', initialFontSize, 'top', 'left', 0, 'left', 0);
        y.current += 10;

        pdf.setFontSize(pdfPageHeight * 0.06)
        let CEOTextHeight = pdf.getTextDimensions('CEO').h;

        pdf.setTextColor(255, 69, 4);
        pdf.setFont(initialFont, '', 'bold');
        pdf.text('Sanket Rambhia', CEOImageWidth + (textMargin * 2), introY + CEOTextHeight + 2);

        pdf.setTextColor(0, 0, 0);
        pdf.setFont(initialFont, '', 'normal');
        pdf.text('CEO & MD', CEOImageWidth + (textMargin * 2), introY);

        pdf.setFontSize(textFontSize);
        pdf.text('"A passionate techie with an entrepreneurial spirit, ', CEOImageWidth + (textMargin * 2), introY + CEOTextHeight + CEOTextHeight + 4);
        pdf.text('Sanket heads Xtreme Media\'s sales & company\'s strategy"', CEOImageWidth + (textMargin * 2), introY + CEOTextHeight + CEOTextHeight + 8);

        addTextLeft("Sanket Rambhia, the visionary MD and Founder of Xtreme Media, ", pdf, textFontSize, 0, 0, 'bold', false, false);
        addTextLeft("has a deep passion for digital display", pdf, textFontSize, 0, boldWidth6, 'normal', true, false);

        let MDIntro = pdf.splitTextToSize("technology. Through his outstanding strategic acumen, he has successfully guided Xtreme Media to be the largest national LED display manufacturer in the LED display industry. He has worn many hats in different departmental areas and finally became the CEO of the company thereafter. He strives to marry software and hardware solutions, thereby creating Indianized products of international quality suitable for Indian market. Motivated by his unwavering ambition, he aspires to attain global leadership in the LED display solutions industry.", effectiveWidth);

        addTextLeft(MDIntro, pdf, textFontSize, 0, 0, 'normal', true);
    }

    const getCurrentScreenShot = () => {
        const originalNode = document.getElementById('main-container');
        const clonedNode = originalNode.cloneNode(true);

        const { height, width } = originalNode.getBoundingClientRect();
        clonedNode.style.width = `${width}px`;
        clonedNode.style.height = `${height}px`;

        const elementsToHide = clonedNode.querySelectorAll('.hide-pdf');
        for (let i = 0; i < elementsToHide.length; i++) {
            elementsToHide[i].setAttribute('style', 'display:none !important');

        }
        const dataLooping = document.getElementById('data-snapshot');
        const clonedDLNode = dataLooping.cloneNode(true);

        const powerLooping = document.getElementById('power-snapshot');
        const clonedPLNode = powerLooping.cloneNode(true);

        snaps[selectedDesignIdx] = [clonedNode, clonedDLNode, clonedPLNode];
    }

    const addDesignDescription = (pdf, index) => {
        pdf.setFont(initialFont, '', "normal");
        const totalHeight = pdf.getTextDimensions('Series').h * 4;
        checkIfPageOverflow(pdf, totalHeight);

        const { series, pixelPitch, ledRow, ledCol, wallHeight, wallWidth, viewingDistance, application, cabinetHeight, cabinetWidth } = design[index];

        let seriesStr = series.toLowerCase().replaceAll(' ', '').replaceAll('-', '');

        let wallWidthFeet = unit == 'm' ? convertMeterToFt(wallWidth) : wallWidth;
        let wallHeightFeet = unit == 'm' ? convertMeterToFt(wallHeight) : wallHeight;
        let cabinetWidthFeet = unit == 'm' ? convertMeterToFt(cabinetWidth) : cabinetWidth;
        let cabinetHeightFeet = unit == 'm' ? convertMeterToFt(cabinetHeight) : cabinetHeight;
        let viewingDistanceFeet = unit == 'm' ? convertMeterToFt(viewingDistance) : viewingDistance;

        let wallWidthMeter = unit == 'm' ? wallWidth : convertFtToMeter(wallWidth);
        let wallHeightMeter = unit == 'm' ? wallHeight : convertFtToMeter(wallHeight);
        let cabinetWidthMeter = unit == 'm' ? cabinetWidth : convertFtToMeter(cabinetWidth);
        let cabinetHeightMeter = unit == 'm' ? cabinetHeight : convertFtToMeter(cabinetHeight);
        let viewingDistanceMeter = unit == 'm' ? viewingDistance : convertFtToMeter(viewingDistance);

        let ppSplit = pixelPitch.split(" (");
        let ppSuffix = ppSplit.length > 1 ? ' (' + ppSplit[1] : '';
        let ppString = ppSplit[0] + " mm" + ppSuffix;

        let matrixValue = ledRow + " x " + ledCol;
        let ledDimFeet = "(" + (ledCol * cabinetWidthFeet).toFixed(2) + " ft x " + (ledRow * cabinetHeightFeet).toFixed(2) + " ft" + ")";
        let ledDimMeter = (ledCol * cabinetWidthMeter).toFixed(2) + " m x " + (ledRow * cabinetHeightMeter).toFixed(2) + " m";

        let wallDimFeet = "(" + wallWidthFeet + " ft x " + wallHeightFeet + " ft" + ")";
        let wallDimMeter = wallWidthMeter + " m x " + wallHeightMeter + " m";

        let viewingDistanceValue = viewingDistanceMeter + " m (" + viewingDistanceFeet + " ft)";

        let applicationValue = application == "" ? "All" : application;

        addPaddingBottom(20);

        addBlockTitle(pdf, "DESIGN " + (index + 1) + " - " + series.toUpperCase() + " SERIES", 'bold', 'center', [0, 0, 0], [232, 232, 232]);

        addPaddingBottom(5);

        let startY = y.current - 5;

        let descFontSize = 10;

        pdf.setFontSize(descFontSize);
        let textHeight = pdf.getTextDimensions('Viewing').h;
        let seriesLabelWidth = pdf.getTextDimensions('Series').w;
        let pitchLabelWidth = pdf.getTextDimensions('Pixel Pitch').w;
        let matrixLabelWidth = pdf.getTextDimensions('LED Cabinet Matrix (L x H)').w;
        let dimensionLabelWidth = pdf.getTextDimensions('LED Dimensions').w;
        let wallDimLabelWidth = pdf.getTextDimensions('Wall Dimensions').w;
        let distanceLabelWidth = pdf.getTextDimensions('Minimum Viewing Distance').w;
        let appLabelWidth = pdf.getTextDimensions('Application').w;

        let seriesWidth = pdf.getTextDimensions(series).w;
        let pitchWidth = pdf.getTextDimensions(ppString).w;
        let matrixWidth = pdf.getTextDimensions(matrixValue).w;
        let dimensionWidthMeter = pdf.getTextDimensions(ledDimMeter).w;
        let dimensionWidthFeet = pdf.getTextDimensions(ledDimFeet).w;
        let wallDimWidthFeet = pdf.getTextDimensions(wallDimFeet).w;
        let wallDimWidthMeter = pdf.getTextDimensions(wallDimMeter).w;
        let distanceWidth = pdf.getTextDimensions(viewingDistanceValue).w;
        let appWidth = pdf.getTextDimensions(applicationValue).w;

        let maxLabelWidth = Math.max(...[seriesLabelWidth, pitchLabelWidth, matrixLabelWidth, dimensionLabelWidth, wallDimLabelWidth, distanceLabelWidth, appLabelWidth]);
        let maxValueWidth = Math.max(...[seriesWidth, pitchWidth, matrixWidth, dimensionWidthMeter, dimensionWidthFeet, wallDimWidthFeet, wallDimWidthMeter, distanceWidth, appWidth]);

        let labelLeftMargin = 3;
        let lineBottomMargin = 4;
        let valueLeftMargin = labelLeftMargin + maxLabelWidth + 5;
        let lineWidthX2 = textMargin + valueLeftMargin + maxValueWidth + 3;

        pdf.setFillColor(248, 229, 224);
        pdf.rect(textMargin, y.current - 5, lineWidthX2 - textMargin, textHeight + lineBottomMargin, 'F');
        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        addTextLeft('Series', pdf, descFontSize, lineBottomMargin, labelLeftMargin, 'normal', false, false);
        addTextLeft(series, pdf, descFontSize, lineBottomMargin, valueLeftMargin, 'normal', true, false);

        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        addTextLeft('Pixel Pitch', pdf, descFontSize, lineBottomMargin, labelLeftMargin, 'normal', false, false);
        addTextLeft(ppString, pdf, descFontSize, lineBottomMargin, valueLeftMargin, 'normal', true, false);

        pdf.setFillColor(248, 229, 224);
        pdf.rect(textMargin, y.current - 5, lineWidthX2 - textMargin, textHeight + lineBottomMargin, 'F');
        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        addTextLeft('LED Cabinet Matrix (L x H)', pdf, descFontSize, lineBottomMargin, labelLeftMargin, 'normal', false, false);
        addTextLeft(matrixValue, pdf, descFontSize, lineBottomMargin, valueLeftMargin, 'normal', true, false);

        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        addTextLeft('LED Dimensions', pdf, descFontSize, lineBottomMargin, labelLeftMargin, 'normal', false, false);
        addTextLeft(ledDimMeter, pdf, descFontSize, lineBottomMargin / 2, valueLeftMargin, 'normal', true, false);
        addTextLeft(ledDimFeet, pdf, descFontSize, lineBottomMargin, valueLeftMargin, 'normal', true, false);

        pdf.setFillColor(248, 229, 224);
        pdf.rect(textMargin, y.current - 5, lineWidthX2 - textMargin, textHeight + lineBottomMargin / 2 + textHeight + lineBottomMargin, 'F');
        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        addTextLeft('Wall Dimensions', pdf, descFontSize, lineBottomMargin, labelLeftMargin, 'normal', false, false);
        addTextLeft(wallDimMeter, pdf, descFontSize, lineBottomMargin / 2, valueLeftMargin, 'normal', true, false);
        addTextLeft(wallDimFeet, pdf, descFontSize, lineBottomMargin, valueLeftMargin, 'normal', true, false);

        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        addTextLeft('Minimum Viewing Distance', pdf, descFontSize, lineBottomMargin, labelLeftMargin, 'normal', false, false);
        addTextLeft(viewingDistanceValue, pdf, descFontSize, lineBottomMargin, valueLeftMargin, 'normal', true, false);

        pdf.setFillColor(248, 229, 224);
        pdf.rect(textMargin, y.current - 5, lineWidthX2 - textMargin, textHeight + lineBottomMargin, 'F');
        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        addTextLeft('Application', pdf, descFontSize, lineBottomMargin, labelLeftMargin, 'normal', false, false);
        addTextLeft(applicationValue, pdf, descFontSize, lineBottomMargin, valueLeftMargin, 'normal', true, false);

        drawLine(pdf, [225, 69, 4], 0.2, textMargin, y.current - 5, lineWidthX2, y.current - 5);

        drawLine(pdf, [225, 69, 4], 0.2, textMargin, startY, textMargin, y.current - 5);
        drawLine(pdf, [225, 69, 4], 0.2, textMargin + valueLeftMargin - 3, startY, textMargin + valueLeftMargin - 3, y.current - 5);
        drawLine(pdf, [225, 69, 4], 0.2, lineWidthX2, startY, lineWidthX2, y.current - 5);

        pdf.setFillColor(255, 255, 255);

        let endY = y.current - 5;

        let cabinetImage = cabinetImgSrc[seriesStr] ? cabinetImgSrc[seriesStr] : aceCab;

        const imgProps = pdf.getImageProperties(cabinetImage);

        let maxImageWidth = pdfPageWidth - (lineWidthX2 + (textMargin * 2));
        let maxImageHeight = endY - startY;
        let imgWidth = maxImageWidth;
        let imgHeight = maxImageHeight;

        if (imgProps.width > imgProps.height) {
            imgHeight = (imgProps.height / imgProps.width) * imgWidth;
        } else {
            imgWidth = (imgProps.width / imgProps.height) * imgHeight;
        }

        let imageY = ((endY - startY) - imgHeight) / 2;
        let imagePadding = (maxImageWidth - imgWidth) / 2;

        y.current = startY + imageY;

        addImage(pdf, cabinetImage, 'PNG', imgWidth, imgHeight, false, 'normal', initialFontSize, 'top', 'left', 0, 'right', imagePadding);

        y.current = endY + 5;

        pdf.setFont(initialFont, '', "normal");
    }

    async function* addDesignScreenshot(pdf, orderedDesignAdded) {
        try {

            for (const index of orderedDesignAdded) {
                addNewPage(pdf, true, true, [255, 255, 255], true, [0, 0, 0], true, true, `DESIGN ${index + 1} - ILLUSTRATION`, 'bold', 'center');
                const snap = snaps[index][0];
                document.body.appendChild(snap);
                const dataUrl = await toCanvas(snap, { canvasHeight: 700 });
                const imgProps = pdf.getImageProperties(dataUrl);

                const pdfHeight = pdfPageHeight * 0.33;
                const pdfWidth = (imgProps.width / imgProps.height) * pdfHeight;

                addImage(pdf, dataUrl, 'CANVAS', pdfWidth, pdfHeight, '** Actual on-site appearance may differ from the above simulated scene.', 'normal', pdfPageHeight * 0.02, 'bottom', 'center', 4, 'center', 0);

                pdf.setFont(initialFont, '', "normal");
                addDesignDescription(pdf, index);

                document.body.removeChild(snap);
                yield;
            }
            return;
        } catch (error) {
            console.error('Error while adding Designs to PDF', error);
        }
    }

    async function* addDataLoopingScreenShot(pdf, orderedDesignAdded) {
        try {
            let currentFontSize = pdf.getFontSize();

            let effectiveWidth = pdfPageWidth - (textMargin * 2);

            pdf.setFontSize(pdfPageHeight * 0.025);
            let disclaimer = pdf.splitTextToSize("* Cabinets shown here are not to scale. This diagram represents the suggested primary data cable starting point and loop cable routing connections. Actual installation may vary. Please contact Xtreme Media for additional information.", effectiveWidth);
            let disclaimerTextHeight = pdf.getTextDimensions(disclaimer).h;

            pdf.setFontSize(currentFontSize);

            let blockHeight = pdfPageWidth * 0.05;

            for (const index of orderedDesignAdded) {
                addNewPage(pdf, true, true, [255, 255, 255], true, [0, 0, 0], true, true, `DESIGN ${index + 1} - DATA LOOPING DIAGRAM`, 'bold', 'center');
                const { series, pixelPitch, ledCol } = design[index];
                const snap = snaps[index][1];
                document.body.appendChild(snap);
                const dataUrl = await html2canvas(snap, { logging: false });
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = effectiveWidth;
                const pdfHeight = (imgProps.height / imgProps.width) * pdfWidth;
                addImage(pdf, dataUrl, 'CANVAS', pdfWidth, pdfHeight, false, 'normal', initialFontSize, 'top', 'left', 0, 'center', 0);
                document.body.removeChild(snap);

                pdf.setFont(initialFont, '', "normal");
                let vegaCondition = VEGA_VEGA_PLUS_SERIES.includes(series);
                if (vegaCondition) {
                    addPaddingBottom(8);
                    let new_col = Math.ceil(ledCol / 2);
                    let lastOddColFlag = ledCol / 2 !== new_col;
                    let vegaStr = lastOddColFlag ? 'Unhighlighted cabinets measure 500x500mm.' : '';

                    pdf.setTextColor(225, 0, 0)
                    addTextLeft('NOTE 1: Data cable looping happens in alternate cabinet columns by design.', pdf, 9, 4, 0, 'bold', true, false);
                    addTextLeft('NOTE 2: Cabinets highlighted in blue measure 1000x500mm. ' + vegaStr, pdf, 9, 4, 0, 'bold', true, true);
                    pdf.setTextColor(0, 0, 0)
                }

                addPaddingBottom(8);
                let dataCableCount = Object.values(loopingDiagram.dataLooping[index]).length;

                addTextLeft('Total no. of data cables : ' + dataCableCount, pdf, pdfPageHeight * 0.04, 10, 0, 'normal', true, true);

                addLoopingDiagramLegendBlock(pdf, disclaimerTextHeight, blockHeight, `DESIGN ${index + 1} - DATA DIAGRAM LEGENDS`, 'bold', 'Data Cable', [55, 136, 5]);

                y.current = pdfPageHeight - totalFooterHeight - 1 - disclaimerTextHeight;

                addTextLeft(disclaimer, pdf, pdfPageHeight * 0.025, 1, 0, 'normal', true, true);

                yield;
            }

            return;
        } catch (error) {
            console.error('Error while adding Data Looping Diagrams to PDF', error);
        }
    }

    async function* addPowerLoopingScreenShot(pdf, orderedDesignAdded) {
        try {
            let currentFontSize = pdf.getFontSize();

            let effectiveWidth = pdfPageWidth - (textMargin * 2);

            pdf.setFontSize(pdfPageHeight * 0.025);
            let disclaimer = pdf.splitTextToSize("* Cabinets shown here are not to scale. This diagram represents the suggested primary power cable starting point and loop cable routing connections. Actual installation may vary. Please contact Xtreme Media for additional information.", effectiveWidth);
            let disclaimerTextHeight = pdf.getTextDimensions(disclaimer).h;

            pdf.setFontSize(currentFontSize);

            let blockHeight = pdfPageWidth * 0.05;

            for (const index of orderedDesignAdded) {
                addNewPage(pdf, true, true, [255, 255, 255], true, [0, 0, 0], true, true, `DESIGN ${index + 1} - POWER LOOPING DIAGRAM`, 'bold', 'center');

                const { series } = design[index];

                const snap = snaps[index][2];
                document.body.appendChild(snap);

                const dataUrl = await html2canvas(snap, { logging: false });
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = effectiveWidth;
                const pdfHeight = (imgProps.height / imgProps.width) * pdfWidth;

                addImage(pdf, dataUrl, 'CANVAS', pdfWidth, pdfHeight, false, 'normal', initialFont, 'top', 'left', 0, 'center', 0);

                document.body.removeChild(snap);

                addPaddingBottom(8);
                let powerCableCount = Object.values(loopingDiagram.powerLooping[index]).length;

                if (['Ace', 'Ace Plus', 'Unify-FHD'].includes(series)) {
                    powerCableCount = Math.ceil(powerCableCount/2);
                }

                addTextLeft('Total no. of power cables : ' + powerCableCount, pdf, pdfPageHeight * 0.04, 10, 0, 'normal', true, true);

                addLoopingDiagramLegendBlock(pdf, disclaimerTextHeight, blockHeight, `DESIGN ${index + 1} - POWER DIAGRAM LEGENDS`, 'bold', 'Power Cable', [225, 69, 4]);

                y.current = pdfPageHeight - totalFooterHeight - 1 - disclaimerTextHeight;

                addTextLeft(disclaimer, pdf, pdfPageHeight * 0.025, 1, 0, 'normal', true, true);

                yield;
            }

            return;
        } catch (error) {
            console.error('Error while adding Power Looping Diagrams to PDF', error);
        }
    }

    function* addMechanicalDrawing(pdf, orderedDesignAdded) {
        try {
            let currentFontSize = pdf.getFontSize();

            let effectiveWidth = pdfPageWidth - (textMargin * 2);

            pdf.setFontSize(pdfPageHeight * 0.025);
            let disclaimer = pdf.splitTextToSize("* This diagram represents the approximate design dimensions and mounting points for the configured video wall. Actual installation may vary. Please contact Xtreme Media for additional information.", effectiveWidth);
            let disclaimerTextHeight = pdf.getTextDimensions(disclaimer).h;

            pdf.setFontSize(currentFontSize);

            let usedSpace = totalHeaderHeight + (6 + disclaimerTextHeight + 4) + totalFooterHeight;
            let usableHeight = pdfPageHeight - usedSpace;

            for (const index of orderedDesignAdded) {
                addNewPage(pdf, true, true, [255, 255, 255], true, [0, 0, 0], true, true, `DESIGN ${index + 1} - CABINET DRAWING`, 'bold', 'center');

                const { series, pixelPitch, seriesTypeId } = design[index];
                let seriesTypeDetails = reduxSeries.seriesTypeList[parseInt(seriesTypeId)];

                let seriesStr = series.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
                let pixelPitchStr = '';

                if (seriesStr == "unifyfhd") {
                    pixelPitchStr = pixelPitch.split(' - ')[0].replaceAll('.', '_');
                    pixelPitchStr = pixelPitchStr == '1_25' ? '108wall' : (pixelPitchStr == '1_56' ? '135wall' : '163wall');
                }

                if (seriesStr == "nyxvogue") {
                    pixelPitchStr = pixelPitch.split(' - ')[0].replaceAll('.', '_');
                    pixelPitchStr = ['1_26', '1_58', '1_89'].includes(pixelPitchStr) ? '55' : '43';
                }

                if (seriesStr == "rigelmicro") {
                    pixelPitchStr = pixelPitch.split(' - ')[0].replaceAll('.', '_');
                    pixelPitchStr = pixelPitchStr == '3_91' ? pixelPitchStr : '';
                }

                if (seriesStr == "nyxod") {
                    pixelPitchStr = seriesTypeDetails.cabinet_type == "MS" ? 'ms' : 'al';
                }


                let cabinetImage = mechanicalImgSrc[seriesStr + pixelPitchStr] ? mechanicalImgSrc[seriesStr + pixelPitchStr] : ace;

                const imgProps = pdf.getImageProperties(cabinetImage);

                let pdfHeight = usableHeight;
                let pdfWidth = (imgProps.width / imgProps.height) * pdfHeight;

                if (pdfWidth > effectiveWidth) {
                    pdfWidth = effectiveWidth;
                    pdfHeight = (imgProps.height / imgProps.width) * pdfWidth;
                }

                addImage(pdf, cabinetImage, 'PNG', pdfWidth, pdfHeight, false, 'normal', initialFontSize, 'top', 'left', 0, 'center', 0);

                y.current = pdfPageHeight - totalFooterHeight - 1 - disclaimerTextHeight;

                addTextLeft(disclaimer, pdf, pdfPageHeight * 0.025, 1, 0, 'normal', true, true);

                yield;
            }
            return;
        } catch (error) {
            console.error('Error while adding Mechanical Drawings to PDF', error);
        }
    }

    const addSpecsScreenshot = async (pdf) => {
        try {
            addNewPage(pdf, true, true, [255, 255, 255], true, [0, 0, 0], true, true, 'SUMMARY', 'bold', 'center');

            const specs = document.getElementById('specs-table');
            const cabinetRow = document.querySelectorAll('.specs-cabinet-row');
            for (let elem of cabinetRow) {
                elem.style.display = 'none';
            }

            specs.style.display = 'table';
            specs.style.fontSize = '1.5vw';
            const specsCanvas = await toCanvas(specs);
            const props = pdf.getImageProperties(specsCanvas);
            const pdfWidth = pdfPageWidth - (2 * textMargin);
            const pdfHeight = (props.height / props.width) * pdfWidth;

            checkIfPageOverflow(pdf, pdfHeight);

            pdf.addImage(specsCanvas, 'CANVAS', x.current + textMargin, y.current, pdfWidth, pdfHeight);
            y.current += pdfHeight;
            // To draw an orange line to complete the bottom summary table border which disappers in 1920x1080 resolution (150% zoom)
            drawLine(pdf, [225, 69, 4], 0.1, textMargin, y.current, pdfPageWidth - textMargin, y.current);
            specs.style.display = 'none';
        } catch (error) {
            console.error('Error while adding Specification to PDF ', error);
        }
    }

    function addLastPage(pdf, pdfPageWidth, pdfPageHeight) {
        addNewPage(pdf, false, false, [255, 255, 255], false, [255, 255, 255], true, false);
        pdf.addImage(LastPageImage, 'png', 0, 0, pdfPageWidth, pdfPageHeight);
        addFooterLinks(pdf, [255, 255, 255], 'bold');
    }

    const exportData = async (visitorDetails) => {
        try {
            const pdf = new jsPDF();
            pdf.setDocumentProperties({ title: "Xtreme LED Configurator", author: "Xtreme Media" });
            y.current = 0;

            pdfPageHeight = pdf.internal.pageSize.getHeight();
            pdfPageWidth = pdf.internal.pageSize.getWidth();

            addFirstPage(visitorDetails, pdf);
            addSecondPage(pdf);

            pdf.setFont(initialFont, "", "normal");

            let orderedDesignAdded = designsAdded.toSorted((a, b) => a - b)

            if (diagram === 'design') {
                getCurrentScreenShot();
            }

            const designIterator = addDesignScreenshot(pdf, orderedDesignAdded);
            const dataIterator = addDataLoopingScreenShot(pdf, orderedDesignAdded);
            const powerIterator = addPowerLoopingScreenShot(pdf, orderedDesignAdded);
            const mechanicalIterator = addMechanicalDrawing(pdf, orderedDesignAdded);

            for (const index of orderedDesignAdded) {
                await designIterator.next();
                await dataIterator.next();
                addPaddingBottom(15);
                await powerIterator.next();
                addPaddingBottom(15);
                mechanicalIterator.next();
            }

            await addSpecsScreenshot(pdf);

            addLastPage(pdf, pdfPageWidth, pdfPageHeight);

            y.current = 10;
            const pdfData = pdf.output("blob");
            const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

            if (DOWNLOAD_PDF_IN_FRONTEND) {
                pdf.save("led_configurator.pdf");
            }
            return pdfBlob;
        } catch (error) {
            console.error("Error while exporting data", error);
            return null;
        }
    }

    const toggle = () => {
        setTooltip(!tooltip)
    }

    return (
        <>
            <CustomButton className="hide-pdf" id="export-tooltip" style={style} onClick={() => setIsOpen(true)}>
                <TfiExport size={25} />
                <Tooltip isOpen={tooltip} placement="top" target="export-tooltip" toggle={toggle}>Export to PDF</Tooltip>
            </CustomButton>
            <VisitorDetailsModal isOpen={isOpen} setIsOpen={setIsOpen} exportData={exportData} />
        </>
    )
}

export default ExportButton;