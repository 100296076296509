import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_ROOM_HEIGHT, MAX_ROOM_WIDTH, MIN_ROOM_HEIGHT, MIN_ROOM_WIDTH } from '../../config/config';
import '../../css/outdoorScreen.css';
import { changeCanvasScale } from '../../redux/reducers/scaleReducer';
import { changeWallDimensions } from '../../redux/reducers/wallReducer';
import { changeFitToCentre } from '../../redux/reducers/ledReducer';
import CustomButton from '../common/components/CustomButtom';
import Unit from '../common/components/Unit';
import { Toast, convertMeterToFt } from '../common/Functions';
import { MAX_ROOM_HEIGHT_LIMIT_REACHED, MAX_ROOM_WIDTH_LIMIT_REACHED, MIN_ROOM_HEIGHT_LIMIT_REACHED, MIN_ROOM_WIDTH_LIMIT_REACHED, WALL_HEIGHT_SMALLER_THAN_LED, WALL_HEIGHT_SMALLER_THAN_LED_POSITION, WALL_WIDTH_SMALLER_THAN_LED, WALL_WIDTH_SMALLER_THAN_LED_POSITION } from '../common/Messages';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { ROOM_BUFFER_TOP, ROOM_BUFFER_BOTTOM, ROOM_BUFFER_LEFT, ROOM_BUFFER_RIGHT } from '../../config/config';

const OutdoorScreen = ({ children }) => {
    const { wallHeight, wallWidth, ledRow, ledCol, cabinetHeight, cabinetWidth, environment, distanceTop, distanceLeft, unit, ledCondition } = useSelector(s => {
        return {
            wallHeight: s.wall.height,
            wallWidth: s.wall.width,
            ledRow: s.led.row,
            ledCol: s.led.col,
            cabinetHeight: s.led.cabinetHeight,
            cabinetWidth: s.led.cabinetWidth,
            environment: s.environment.environment,
            distanceTop: s.led.distanceTop,
            distanceLeft: s.led.distanceLeft,
            unit: s.wall.unit,
            ledCondition: s.led.condition,
        }
    })
    const [maxRoomDimension, setMaxRoomDimension] = useState(0);
    const dispatch = useDispatch();
    const step = 1;

    const changeScale = () => {
        const height = document.getElementById('outdoorWall').getBoundingClientRect().height;
        const newScale = height / wallHeight;
        dispatch(changeCanvasScale({
            scale: newScale
        }))
    }

    const handleWallDimensionChange = (height, width) => {
        let condition = ledCondition == "indoor" || (ledCondition == 'outdoor' && environment == 'Wall Mounted');
        if (height != wallHeight) {
            let min_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_HEIGHT) : MIN_ROOM_HEIGHT);
            let max_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_HEIGHT) : MAX_ROOM_HEIGHT);

            let buffer_top = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP) : (ROOM_BUFFER_TOP)));
            let buffer_bottom = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_BOTTOM)));
            let buffer_hgt = buffer_top + buffer_bottom;

            let screenHeight = ledRow * cabinetHeight;
            let totalScreenHeight = parseFloat(screenHeight + distanceTop).toFixed(2);
            let heightDiff = parseFloat(totalScreenHeight - (height - buffer_hgt));

            if (height > max_room_hgt) {
                Toast('error', 'Error', MAX_ROOM_HEIGHT_LIMIT_REACHED);
                return;
            }

            if (height < min_room_hgt) {
                Toast('error', 'Error', MIN_ROOM_HEIGHT_LIMIT_REACHED);
                return;
            }

            if (condition && (height < (screenHeight + buffer_hgt)) && height < wallHeight) {
                Toast('error', 'Error', WALL_HEIGHT_SMALLER_THAN_LED);
                return;
            }

            if ((totalScreenHeight > (height - buffer_bottom) && height < wallHeight && heightDiff > 0)) {
                Toast('error', 'Error', WALL_HEIGHT_SMALLER_THAN_LED_POSITION);
                return;
            }

            height = height < totalScreenHeight ? Math.ceil(totalScreenHeight) : height;
        }

        if (width != wallWidth) {
            let min_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_WIDTH) : MIN_ROOM_WIDTH);
            let max_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_WIDTH) : MAX_ROOM_WIDTH);

            let buffer_left = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT) : (ROOM_BUFFER_LEFT)));
            let buffer_right = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_RIGHT)));
            let buffer_wdt = buffer_left + buffer_right;

            let screenWidth = ledCol * cabinetWidth;
            let totalScreenWidth = parseFloat(screenWidth + distanceLeft).toFixed(2);
            let widthDiff = parseFloat(totalScreenWidth - (width - buffer_wdt));

            if (width > max_room_wdt) {
                Toast('error', 'Error', MAX_ROOM_WIDTH_LIMIT_REACHED);
                return;
            }

            if (width < min_room_wdt) {
                Toast('error', 'Error', MIN_ROOM_WIDTH_LIMIT_REACHED);
                return;
            }

            if (condition && (width < (screenWidth + buffer_wdt)) && width < wallWidth) {
                Toast('error', 'Error', WALL_WIDTH_SMALLER_THAN_LED);
                return;
            }

            if ((totalScreenWidth > (width - buffer_right) && width < wallWidth && widthDiff > 0)) {
                Toast('error', 'Error', WALL_WIDTH_SMALLER_THAN_LED_POSITION);
                return;
            }

            width = width < totalScreenWidth ? Math.ceil(totalScreenWidth) : width;
        }

        dispatch(changeWallDimensions({
            height: parseFloat(height).toFixed(2),
            width: parseFloat(width).toFixed(2)
        }))

        dispatch(changeFitToCentre({
            fitToCentre: false
        }))
    }

    const calculateWallSize = () => {
        try {
            const containerDOM = document.getElementById('container');
            const outdoorWallDOM = document.getElementById('outdoorWall');
            const bufferWallDOM = document.getElementById('buffer-wall');

            const width = document.getElementById('container').getBoundingClientRect().width;
            const height = document.getElementById('outdoorWall').getBoundingClientRect().height;

            let parsedWallHeight = parseFloat(wallHeight);
            let parsedWallWidth = parseFloat(wallWidth);

            let newWidth = (parsedWallWidth * maxRoomDimension) / MAX_ROOM_WIDTH;
            let newHeight = (parsedWallHeight * maxRoomDimension) / MAX_ROOM_HEIGHT;

            if (parsedWallHeight === parsedWallWidth) {
                containerDOM.style.width = `${maxRoomDimension}px`
                outdoorWallDOM.style.height = `${maxRoomDimension}px`;

                bufferWallDOM.style.width = `${maxRoomDimension}px`
                bufferWallDOM.style.height = `${maxRoomDimension}px`;

                // let halfDim = maxRoomDimension / 2;
                // let innerHght = (newWidth < halfDim ? halfDim : newWidth);

                // containerDOM.style.width = `${innerHght}px`
                // outdoorWallDOM.style.height = `${innerHght}px`;

                // // let tempHeight = maxRoomDimension;
                // // let tempHeight = (parsedWallHeight * maxRoomDimension) / MAX_ROOM_HEIGHT
                // console.log(parsedWallHeight);
                // console.log(maxRoomDimension);
                // // if (parsedWallHeight < (maxRoomDimension / 2)) {
                // //     tempHeight = maxRoomDimension / 2;
                // // }
                // // containerDOM.style.width = `${(newWidth > maxRoomDimension ? maxRoomDimension : newWidth)}px`
                // // outdoorWallDOM.style.height = `${(newHeight > maxRoomDimension ? maxRoomDimension : newHeight)}px`;

                return;
            }

            if (parsedWallHeight > parsedWallWidth) {
                outdoorWallDOM.style.height = `${height}px`
                containerDOM.style.width = `${(height * parsedWallWidth) / parsedWallHeight}px`;

                bufferWallDOM.style.height = `${height}px`
                bufferWallDOM.style.width = `${(height * parsedWallWidth) / parsedWallHeight}px`;

                // outdoorWallDOM.style.height = `${(newHeight > maxRoomDimension ? maxRoomDimension : newHeight)}px`;
                // containerDOM.style.width = `${(newWidth > maxRoomDimension ? maxRoomDimension : newWidth)}px`
            } else {
                containerDOM.style.width = `${width}px`
                outdoorWallDOM.style.height = `${(width * parsedWallHeight) / parsedWallWidth}px`;

                bufferWallDOM.style.width = `${width}px`
                bufferWallDOM.style.height = `${(width * parsedWallHeight) / parsedWallWidth}px`;

                // containerDOM.style.width = `${(newWidth > maxRoomDimension ? maxRoomDimension : newWidth)}px`
                // outdoorWallDOM.style.height = `${(newHeight > maxRoomDimension ? maxRoomDimension : newHeight)}px`;
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        calculateWallSize();
        changeScale();
    }, [wallHeight, wallWidth, maxRoomDimension])

    useEffect(() => {
        const width = document.getElementById('background').getBoundingClientRect().width;
        const height = document.getElementById('background').getBoundingClientRect().height;
        setMaxRoomDimension((Math.min(height, width)) * 0.85)
    }, [])

    useUpdateEffect(() => {
        handleWallDimensionChange(parseFloat(wallHeight), parseFloat(wallWidth));
    }, [ledRow, cabinetHeight, ledCol, cabinetWidth])

    return (
        <div id="background">
            <div id="container">
                <div id="outdoorWall" style={{ borderWidth: `${ledCondition == 'outdoor' && environment != 'Wall Mounted' ? '0px' : '1px'}` }}>
                    <span className='d-flex align-items-center' style={{ position: 'absolute', bottom: '100%', margin: '5px 5px 20px 5px', left: '45%' }}>
                        <CustomButton className='hide-pdf' style={{ width: '30px', marginRight: '3px', backgroundColor: '#e14504a1', borderColor: '#e14504a1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => {
                                handleWallDimensionChange(parseFloat(wallHeight), parseFloat(wallWidth) + step)
                            }} hidden={(ledCondition == 'outdoor' && environment != 'Wall Mounted')}>
                            +
                        </CustomButton>
                        <Unit value={wallWidth} style={{ display: `${ledCondition == 'outdoor' && environment != 'Wall Mounted' ? 'none' : 'block'}` }} />
                        <CustomButton className='hide-pdf' style={{ width: '30px', marginLeft: '3px', backgroundColor: '#e14504a1', borderColor: '#e14504a1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => {
                                handleWallDimensionChange(parseFloat(wallHeight), parseFloat(wallWidth) - step)
                            }} hidden={(ledCondition == 'outdoor' && environment != 'Wall Mounted')}>
                            -
                        </CustomButton>
                    </span>
                    <span className='d-flex align-items-center flex-column' style={{ position: 'absolute', left: '100%', bottom: '45%', margin: '5px 5px 5px 20px' }}>
                        <CustomButton className='hide-pdf' style={{ width: '30px', backgroundColor: '#e14504a1', borderColor: '#e14504a1', display: 'flex', justifyContent: 'center' }}
                            onClick={() => {
                                handleWallDimensionChange(parseFloat(wallHeight) + step, parseFloat(wallWidth));
                            }} hidden={(ledCondition == 'outdoor' && environment != 'Wall Mounted')}>
                            +
                        </CustomButton>
                        <Unit value={wallHeight} style={{ display: `${ledCondition == 'outdoor' && environment != 'Wall Mounted' ? 'none' : 'block'}` }} />
                        <CustomButton className='hide-pdf' style={{ width: '30px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                            onClick={() => {
                                handleWallDimensionChange(parseFloat(wallHeight) - step, parseFloat(wallWidth));
                            }} hidden={(ledCondition == 'outdoor' && environment != 'Wall Mounted')}>
                            -
                        </CustomButton>
                    </span>
                    {children}
                </div>
                {
                    (environment != 'Wall Mounted' && environment != 'Double Pole Mounted') &&
                    <div style={{ position: 'relative', height: '100%' }}>
                        <div id="pole"></div>
                    </div>
                }
                {
                    environment === 'Double Pole Mounted' &&
                    <div style={{ position: 'relative', height: '100%' }}>
                        <div style={{ right: '18%' }} id="pole"></div>
                        <div style={{ left: '18%' }} id="pole"></div>
                    </div>
                }
            </div>
        </div>
    )
}

export default memo(OutdoorScreen);