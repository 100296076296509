import { memo, useRef } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import '../../css/ledDistanceViewer.css'
import Unit from "../common/components/Unit";
import { changeLedTopBottomLeftDistance } from "../../redux/reducers/ledReducer";

const LedDistanceViewer = ({ outerDivRef, bufferDivRef, innerDivRef, position, height, width }) => {
    const { transform, distanceTop, distanceLeft, distanceBottom, fitToCentre, ledCondition, environment, wallWidth, wallHeight } = useSelector(state => {
        return {
            transform: state.led.transform,
            distanceTop: state.led.distanceTop,
            distanceLeft: state.led.distanceLeft,
            distanceBottom: state.led.distanceBottom,
            fitToCentre: state.led.fitToCentre,
            ledCondition: state.led.condition,
            environment: state.environment.environment,
            wallWidth: state.wall.width,
            wallHeight: state.wall.height
        }
    }, shallowEqual);
    const dispatch = useDispatch();

    const arrowTopRef = useRef();
    const arrowBottomRef = useRef();
    const { scale } = useSelector(state => state.scale);

    const calculateDistance = () => {
        const outerDiv = outerDivRef.current;
        const innerDiv = innerDivRef.current;

        const arrowTop = arrowTopRef.current;
        const arrowBottom = arrowBottomRef.current;

        const innerDivRect = innerDiv.getBoundingClientRect();
        const outerDivRect = outerDiv.getBoundingClientRect();

        // distance from the top of the outer div
        let topDistance = innerDivRect.top - outerDivRect.top;

        // distance from the left of the outer div
        const leftDistance = innerDivRect.left - outerDivRect.left;

        // distance from the bottom of the outer div
        let bottomDistance = outerDivRect.bottom - innerDivRect.bottom;

        arrowTop.style.top = `${0}px`;
        arrowTop.style.width = `${(leftDistance / transform.scale)}px`;
        arrowTop.style.height = `${(topDistance / transform.scale)}px`;


        arrowBottom.style.bottom = `${0}px`;
        arrowBottom.style.width = `${(leftDistance / transform.scale)}px`;
        arrowBottom.style.height = `${(bottomDistance / transform.scale)}px`;

        if (fitToCentre == true) {
            let temp = (topDistance + bottomDistance) / 2;
            topDistance = temp;
            bottomDistance = temp;
        }

        const top = parseFloat((((topDistance) / transform.scale) / scale).toFixed(2));
        const left = parseFloat(((leftDistance / transform.scale) / scale).toFixed(2));
        const bottom = parseFloat((((bottomDistance) / transform.scale) / scale).toFixed(2));

        dispatch(changeLedTopBottomLeftDistance({
            top: top,
            left: left,
            bottom: bottom < 0 ? 0 : bottom
        }))
    }

    useEffect(() => {
        setTimeout(function(){
            calculateDistance();
        }, 0);
    }, [position, height, width, wallWidth, wallHeight])

    return (
        <>
            <div ref={arrowTopRef} className="arrow-top-left">
                <span style={{ top: '40%', left: '102%', position: 'absolute', color: '#e14504', display: `${(distanceTop == 0 || (ledCondition == 'outdoor' && environment != 'Wall Mounted')) ? 'none' : 'block'}` }}>
                    <Unit value={distanceTop} />
                </span>
            </div>
            <div ref={arrowBottomRef} className="arrow-bottom-left">
                <span style={{ top: '40%', left: '102%', position: 'absolute', color: '#e14504', display: `${distanceBottom <= 0.01 || (ledCondition == 'outdoor' && environment != 'Wall Mounted') ? 'none' : 'block'}` }}>
                    <Unit value={distanceBottom} />
                </span>
                <span style={{ bottom: '100%', left: '20%', position: 'absolute', color: '#e14504', display: `${distanceLeft == 0 || (ledCondition == 'outdoor' && environment != 'Wall Mounted') ? 'none' : 'block'}` }}>
                    <Unit value={distanceLeft} />
                </span>
            </div>
        </>
    )
}

export default memo(LedDistanceViewer);