import { useEffect, useRef } from "react";

const useUpdateEffect = (callback, dependency) => {
    if (!(callback instanceof Function)) {
        throw new TypeError("Callback has to be of type Function");
    }

    if (!Array.isArray(dependency)) {
        throw new TypeError("Dependency has to be of type Array");
    }

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        
        callback();
    }, dependency);
}

export default useUpdateEffect;