import { useContext, useEffect, useMemo, useRef } from 'react';
import { memo, useCallback, useState } from 'react';
import Draggable from 'react-draggable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import '../../css/led.css'
import { changeLedDimensions, changePosition, changeFitToCentre, changeCheckedResolution, changeFitToWall } from '../../redux/reducers/ledReducer';
import LedDistanceViewer from './LedDistanceViewer';
import ShowModules from './ShowModules';
import LedContent from './LedContent';
import CustomButton from '../common/components/CustomButtom';
import Unit from '../common/components/Unit';
import { Toast, convertMeterToFt } from '../common/Functions';
import { LED_HEIGHT_EXCEED_WALL, LED_WIDTH_EXCEED_WALL, MAX_ROOM_HEIGHT_LIMIT_REACHED, MAX_ROOM_WIDTH_LIMIT_REACHED, MIN_ROOM_HEIGHT_LIMIT_REACHED, MIN_ROOM_WIDTH_LIMIT_REACHED, WALL_EXCEEDED } from '../common/Messages';
import { MAX_CABINET_COLS, MAX_CABINET_ROWS, MAX_ROOM_HEIGHT, MAX_ROOM_WIDTH, MIN_ROOM_HEIGHT, MIN_ROOM_WIDTH } from '../../config/config';
import { LoaderContext } from '../../context/context';
import { changeWallDimensions } from '../../redux/reducers/wallReducer';
import { ROOM_BUFFER_LEFT, ROOM_BUFFER_RIGHT, ROOM_BUFFER_TOP, ROOM_BUFFER_BOTTOM } from '../../config/config';

const Led = () => {
    const boundingWallRef = useRef();
    const bufferWallRef = useRef();
    const ledDisplayRef = useRef();
    const dispatch = useDispatch();
    const { isUnify } = useContext(LoaderContext);

    const { ledRow, ledCol, cabinetHeight, cabinetWidth, scale, wallHeight, wallWidth, fitToWall, position, selectedSeries,
        distanceTop, distanceLeft, fitToCentre, ledCondition, environment, customResolution, unit } = useSelector(s => {
            return {
                ledRow: s.led.row,
                ledCol: s.led.col,
                cabinetHeight: s.led.cabinetHeight,
                cabinetWidth: s.led.cabinetWidth,
                scale: s.scale.scale,
                wallHeight: s.wall.height,
                wallWidth: s.wall.width,
                fitToWall: s.led.fitToWall,
                fitToCentre: s.led.fitToCentre,
                position: s.led.position,
                selectedSeries: s.selectedSeries.series,
                distanceTop: s.led.distanceTop,
                distanceLeft: s.led.distanceLeft,
                ledCondition: s.led.condition,
                environment: s.environment.environment,
                customResolution: s.led.customResolution,
                unit: s.wall.unit,
            }
        }, shallowEqual)

    const ledHeight = ledRow * cabinetHeight * scale;
    const ledWidth = ledCol * cabinetWidth * scale;

    const ledStyles = useMemo(() => {
        return { height: `${ledHeight}px`, width: `${ledWidth}px`, opacity: selectedSeries?.series_name?.toLowerCase().includes('iris') ? '0.5' : selectedSeries?.series_name?.toLowerCase().includes('orion') ?'0.5' :'1' }
    }, [selectedSeries, ledHeight, ledWidth]);

    const setPosition = ({ x, y }) => {
        dispatch(changePosition({
            x,
            y
        }))
        dispatch(changeFitToCentre({
            fitToCentre: false
        }))
    }

    const handleFitToCentre = () => {
        if (fitToCentre) {
            let identifier = ledCondition == "indoor" ? 'wall' : 'outdoorWall';
            const wall = document.getElementById(identifier);
            const bufferWall = document.getElementById('buffer-wall');
            const led = document.getElementById('led');
            if (!wall || !led) return;

            const { height, width, top, left } = wall.getBoundingClientRect();
            const { height: ledHeight, width: ledWidth } = led.getBoundingClientRect();
            const { top: bufferTop, left: bufferLeft } = bufferWall.getBoundingClientRect();
            dispatch(changePosition({
                x: ((width - ledWidth) / 2) - (bufferLeft - left),
                y: ((height - ledHeight) / 2) - (bufferTop - top)
            }))
        }
    }

    const onDrag = useCallback((_, data) => {
        window.requestAnimationFrame(() => {
            setPosition({ x: data.x, y: data.y });
        });
    }, []);

    const handleChangeDimensions = (row, col) => {
        if (row > MAX_CABINET_ROWS) {
            Toast('error', 'Error', `Max allowed rows are ${MAX_CABINET_ROWS}`);
            if (row - 1 == ledRow) {
                return false;
            } else {
                row = MAX_CABINET_ROWS;
            }
        }

        if (col > MAX_CABINET_COLS) {
            Toast('error', 'Error', `Max allowed columns are ${MAX_CABINET_COLS}`);
            if (col - 1 == ledCol) {
                return false;
            } else {
                col = MAX_CABINET_COLS;
            }
        }

        let screenHeight = parseFloat(row * cabinetHeight);
        let screenWidth = parseFloat(col * cabinetWidth);

        // let condition = ledCondition == 'outdoor' && (environment != 'Wall Mounted'|| environment != 'Rental OD');
        let condition = ledCondition == 'outdoor' && (environment != 'Wall Mounted');
        if (condition) {
            if (row != ledRow) {
                let min_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_HEIGHT) : MIN_ROOM_HEIGHT);
                let max_room_hgt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_HEIGHT) : MAX_ROOM_HEIGHT);

                if (screenHeight > max_room_hgt) {
                    Toast('error', 'Error', MAX_ROOM_HEIGHT_LIMIT_REACHED);
                    return;
                }

                if (screenHeight < min_room_hgt) {
                    Toast('error', 'Error', MIN_ROOM_HEIGHT_LIMIT_REACHED);
                    return;
                }
            }

            if (col != ledCol) {
                let min_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MIN_ROOM_WIDTH) : MIN_ROOM_WIDTH);
                let max_room_wdt = parseFloat(unit == "ft" ? convertMeterToFt(MAX_ROOM_WIDTH) : MAX_ROOM_WIDTH);

                if (screenWidth > max_room_wdt) {
                    Toast('error', 'Error', MAX_ROOM_WIDTH_LIMIT_REACHED);
                    return;
                }

                if (screenWidth < min_room_wdt) {
                    Toast('error', 'Error', MIN_ROOM_WIDTH_LIMIT_REACHED);
                    return;
                }
            }

            dispatch(changeLedDimensions({
                row,
                col
            }))

            dispatch(changeWallDimensions({
                height: parseFloat(screenHeight.toFixed(2)),
                width: parseFloat(screenWidth.toFixed(2))
            }))
        } else {
            if (row != ledRow) {
                let buffer_top = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP) : (ROOM_BUFFER_TOP)));
                let buffer_bottom = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_BOTTOM)));
                let buffer_hgt = buffer_top + buffer_bottom;

                let d_top = fitToWall ? buffer_top : distanceTop;
                let totalScreenHeight = parseFloat(screenHeight + d_top);
                let heightDiff = parseFloat(totalScreenHeight - (wallHeight - buffer_hgt));

                if (screenHeight > (wallHeight - buffer_hgt)) {
                    Toast('error', 'Error', LED_HEIGHT_EXCEED_WALL);
                    return false;
                }

                if ((totalScreenHeight > (wallHeight - buffer_bottom) && row > ledRow && heightDiff > 0)) {
                    Toast('error', 'Error', WALL_EXCEEDED);
                    return false;
                }
            }

            if (col != ledCol) {
                let buffer_left = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT) : (ROOM_BUFFER_LEFT)));
                let buffer_right = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_RIGHT)));
                let buffer_wdt = buffer_left + buffer_right;

                let d_left = fitToWall ? buffer_left : distanceLeft;
                let totalScreenWidth = parseFloat(screenWidth + d_left);
                let widthDiff = parseFloat(totalScreenWidth - (wallWidth - buffer_wdt));

                if (screenWidth > (wallWidth - buffer_wdt)) {
                    Toast('error', 'Error', LED_WIDTH_EXCEED_WALL);
                    return false;
                }

                if (((totalScreenWidth > (wallWidth - buffer_right)) && col > ledCol && widthDiff > 0)) {
                    Toast('error', 'Error', WALL_EXCEEDED);
                    return false;
                }
            }

            dispatch(changeFitToCentre({
                fitToCentre: false
            }))

            dispatch(changeLedDimensions({
                row,
                col
            }))

            if (!customResolution) {
                dispatch(changeCheckedResolution({ resolution: 3 }));
            }
        }
    };

    useEffect(() => {
        if (!fitToWall) return;

        setPosition({
            x: 0,
            y: 0
        })

        let bufferHeight = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_TOP + ROOM_BUFFER_BOTTOM) : (ROOM_BUFFER_TOP + ROOM_BUFFER_BOTTOM)));
        let bufferWidth = ledCondition == "outdoor" ? 0 : (parseFloat(unit == "ft" ? convertMeterToFt(ROOM_BUFFER_LEFT + ROOM_BUFFER_RIGHT) : (ROOM_BUFFER_LEFT + ROOM_BUFFER_RIGHT)));

        setTimeout(() => {
            let rowCondition = Math.floor((wallHeight - bufferHeight) / cabinetHeight);
            let colCondition = Math.floor((wallWidth - bufferWidth) / cabinetWidth);
            const row = rowCondition > 1 ? (rowCondition > MAX_CABINET_ROWS ? MAX_CABINET_ROWS : rowCondition) : 1;
            const col = colCondition > 1 ? (colCondition > MAX_CABINET_COLS ? MAX_CABINET_COLS : colCondition) : 1;
            handleChangeDimensions(row, col);

            // let outDoorWallCondition = ledCondition == 'outdoor' && (environment != 'Wall Mounted'|| environment != 'Rental OD');
            let outDoorWallCondition = ledCondition == 'outdoor' && (environment != 'Wall Mounted');
            if (!outDoorWallCondition) {
                setTimeout(() => {
                    dispatch(changeFitToCentre({
                        fitToCentre: true
                    }));
                }, 0)
            }
        }, 10);
    }, [fitToWall, wallHeight, wallWidth, cabinetHeight, cabinetWidth])

    useEffect(() => {
        handleFitToCentre();
    }, [fitToCentre])

    return (
        // <div ref={boundingWallRef} id='bounding-wall' style={{ top: `${ledCondition == 'outdoor' ? '' : '-0.5rem'} `, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //     <div ref={bufferWallRef} id="buffer-wall" style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)', border: 'dotted blue 1px'}}>
        <div ref={boundingWallRef} id='bounding-wall' style={{ top: `${ledCondition == 'outdoor' ? '' : '-0.5rem'} `, position: 'relative' }}>
            <LedDistanceViewer outerDivRef={boundingWallRef} bufferDivRef={bufferWallRef} innerDivRef={ledDisplayRef} position={position} height={ledHeight} width={ledWidth} />
            <div ref={bufferWallRef} id="buffer-wall" style={{ border: '1px dotted #cbcbcb', width: 'calc(100% - 50px)', height: 'calc(100% - 50px)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Draggable onDrag={onDrag} position={position} bounds={'#buffer-wall'}>
                    <div className='d-flex justify-content-center align-items-center' style={{ height: `${ledHeight + 0}px`, width: `${ledWidth + 0}px`, border: '0px dotted gray', zIndex: '99', position: 'relative' }}>
                        <div ref={ledDisplayRef} className='led-display' style={ledStyles} id='led'>
                            <LedContent autoPlay={true} loop={true} />
                            <ShowModules row={ledRow} col={ledCol} />
                        </div>
                        <span style={{ position: 'absolute', top: '-18px', left: '0%', borderBottom: '2px solid blue', width: '100%' }}>
                            <div style={{ position: 'relative', opacity: '1', height: '8px', top: '0.35rem', borderLeft: '2px solid', borderRight: '2px solid', margin: '0px', color: 'blue', width: 'inherit' }}></div>
                            <Unit value={(ledWidth / scale).toFixed(2)} style={{ color: 'blue', position: 'absolute', bottom: '0rem', left: '4rem' }} />
                        </span>
                        <span style={{ position: 'absolute', top: '0rem', borderLeft: 'solid blue 2px', paddingLeft: '5px', height: '100%', right: '-4rem' }}>
                            <div style={{ position: 'absolute', opacity: '1', width: '8px', borderTop: '2px solid blue', borderBottom: '2px solid blue', margin: '0px', color: 'inherit', left: '-0.3rem', height: 'inherit' }}></div>
                            <Unit value={(ledHeight / scale).toFixed(2)} style={{ color: 'blue' }} />
                        </span>
                        {!fitToWall && (<span className='hide-pdf d-flex align-items-center' style={{ position: 'absolute', top: '100%', margin: '5px' }}>
                            <CustomButton className='d-flex align-items-center justify-content-center'
                                style={{ width: '30px', marginRight: '3px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                                onClick={() => {
                                    if (isUnify) {
                                        Toast('warning', 'Error', 'Unify Dimensions cannot be changed');
                                        return;
                                    }
                                    handleChangeDimensions(ledRow, ledCol + 1);
                                }} >
                                +
                            </CustomButton>
                            <span style={{ color: 'blue' }}>{ledCol}</span>
                            <CustomButton style={{ width: '30px', marginLeft: '3px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                                onClick={() => {
                                    if (isUnify) {
                                        Toast('warning', 'Error', 'Unify Dimensions cannot be changed');
                                        return;
                                    }
                                    handleChangeDimensions(ledRow, ledCol === 1 ? ledCol : ledCol - 1)
                                }}>
                                -
                            </CustomButton>
                        </span>)}
                        {!fitToWall && (<span className='hide-pdf d-flex align-items-center flex-column' style={{ position: 'absolute', right: '100%', margin: '5px' }}>
                            <CustomButton className='d-flex align-items-center justify-content-center'
                                style={{ width: '30px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                                onClick={() => {
                                    if (isUnify) {
                                        Toast('warning', 'Error', 'Unify Dimensions cannot be changed');
                                        return;
                                    }
                                    handleChangeDimensions(ledRow + 1, ledCol)
                                }}>
                                +
                            </CustomButton>
                            <span style={{ color: 'blue' }}>{ledRow}</span>
                            <CustomButton style={{ width: '30px', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }}
                                onClick={() => {
                                    if (isUnify) {
                                        Toast('warning', 'Error', 'Unify Dimensions cannot be changed');
                                        return;
                                    }
                                    handleChangeDimensions(ledRow === 1 ? ledRow : ledRow - 1, ledCol)
                                }}>
                                -
                            </CustomButton>
                        </span>)}
                    </div>
                </Draggable>
            </div>
        </div>
    )
}

export default memo(Led);