import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import '../../css/ledContent.css';
import { changeContent } from "../../redux/reducers/contentReducer";
import auditorium1 from '../../images/presets/auditorium/auditorium1.png';
import auditorium2 from '../../images/presets/auditorium/auditorium2.png';
import auditorium3 from '../../images/presets/auditorium/auditorium3.png';
import classroom1 from '../../images/presets/classroom/classroom1.png';
import classroom2 from '../../images/presets/classroom/classroom2.png';
import classroom3 from '../../images/presets/classroom/classroom3.png';
import classroom4 from '../../images/presets/classroom/classroom4.png';
import classroom5 from '../../images/presets/classroom/classroom5.png';
import conference1 from '../../images/presets/conference/conference1.png';
import conference2 from '../../images/presets/conference/conference2.png';
import conference3 from '../../images/presets/conference/conference3.png';
import conference4 from '../../images/presets/conference/conference4.png';
import conference5 from '../../images/presets/conference/conference5.png';
import newsroom1 from '../../images/presets/newsroom/newsroom1.png';
import newsroom2 from '../../images/presets/newsroom/newsroom2.png';
import newsroom3 from '../../images/presets/newsroom/newsroom3.png';
import newsroom4 from '../../images/presets/newsroom/newsroom4.png';
import newsroom5 from '../../images/presets/newsroom/newsroom5.png';
import retail1 from '../../images/presets/retail/retail1.png';
import retail2 from '../../images/presets/retail/retail2.png';
import retail3 from '../../images/presets/retail/retail3.png';
import retail4 from '../../images/presets/retail/retail4.png';
import retail5 from '../../images/presets/retail/retail5.png';
import lobby1 from '../../images/presets/lobby/lobby1.png';
import lobby2 from '../../images/presets/lobby/lobby2.png';
import lobby3 from '../../images/presets/lobby/lobby3.png';
import lobby4 from '../../images/presets/lobby/lobby4.png';
import controlroom1 from '../../images/presets/controlroom/controlroom1.png';
import controlroom2 from '../../images/presets/controlroom/controlroom2.png';
import controlroom3 from '../../images/presets/controlroom/controlroom3.png';
import controlroom4 from '../../images/presets/controlroom/controlroom4.png';
import rental1 from '../../images/presets/rental/rental1.png';
import rental2 from '../../images/presets/rental/rental2.png';
import rental3 from '../../images/presets/rental/rental3.png';
import rental4 from '../../images/presets/rental/rental4.png';
import rental5 from '../../images/presets/rental/rental5.png';

const ContentPresets = () => {
    const { environment } = useSelector(state => {
        return { environment: state.environment.environment, ledcondition: state.led.condition }
    })
    const contentOptions = useMemo(() => {
        return {
            'All': [auditorium1, auditorium2, auditorium3, classroom1, classroom2, classroom3, classroom4, classroom5, conference1, conference2, conference3, conference4, conference5, newsroom1, newsroom2, newsroom3, newsroom4, newsroom5, retail1, retail2, retail3, retail4, retail5, controlroom1, controlroom2, controlroom3, controlroom4, lobby1, lobby2, lobby3, lobby4],
            'Class Room': [classroom1, classroom2, classroom3, classroom4, classroom5],
            'News Room': [newsroom1, newsroom2, newsroom3, newsroom4, newsroom5],
            'Auditorium': [auditorium1, auditorium2, auditorium3],
            'Conference Room': [conference1, conference2, conference3, conference4, conference5],
            'Retail': [retail1, retail2, retail3, retail4, retail5],
            'Command & Control Room': [controlroom1, controlroom2, controlroom3, controlroom4],
            'Lobby': [lobby1, lobby2, lobby3, lobby4],
            'Single Pole Mounted': [retail1, retail2, retail3, retail4, retail5, auditorium1, auditorium2, auditorium3, lobby1, lobby2, lobby3, lobby4],
            'Double Pole Mounted': [retail1, retail2, retail3, retail4, retail5, auditorium1, auditorium2, auditorium3, lobby1, lobby2, lobby3, lobby4],
            'Wall Mounted': [retail1, retail2, retail3, retail4, retail5, auditorium1, auditorium2, auditorium3, lobby1, lobby2, lobby3, lobby4],
            'Rental': [rental1, rental2, rental3, rental4, rental5],
            'Rental OD': [rental1, rental2, rental3, rental4, rental5],
        }
    }, [])
    const dispatch = useDispatch();

    const contentList = useMemo(() => {
        return contentOptions[environment] ?? [];
    }, [environment])

    const handleChangeContent = e => {
        const src = e.target.src;
        dispatch(changeContent({
            content: environment,
            src: src,
            type: 'image/jpg'
        }))
    }

    return (
        <>
            <p>Click to Select Content</p>
            <Row>
                {contentList.map((content, index) => (
                    <Col md={6} key={index}>
                        <Card style={{ cursor: 'pointer' }}>
                            <CardBody>
                                <img onClick={handleChangeContent} id="content-img" style={{ width: '100%', height: '100%', position: 'static', height: '200px' }} src={content} alt="Preset Images" />
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default ContentPresets;