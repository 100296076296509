import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import desk from '../../../images/environments/newsroom/Desk.svg';

const Desk = () => {
    const [height, setHeight] = useState(200);
    const [hide, setHide] = useState(false);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height, width } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${height * 0.6}px`);

        const deskWidth = document.getElementById('newsroom-desk').width;

        // if ((width) < (deskWidth)) {
        //     setHide(true);
        // } else {
        //     setHide(false);
        // }
    }, [wallHeight, wallWidth])

    return (
        <img hidden={hide} id='newsroom-desk' style={{ position: 'absolute', bottom: '0', zIndex: 999 }} height={height} src={desk} alt="desk" />
    )
}

export default Desk;