import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import lamp from '../../../images/environments/retail/Lamp.svg';

const Lamp = () => {
    const [height, setHeight] = useState(200);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height, width } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${height * 0.25}px`);

        // const chairWidth = document.getElementById('retail-chair').width;

        // if ((width + 20) < (deskWidth + cameraWidth)) {
        //     setHide(true);
        // } else {
        //     setHide(false);
        // }
    }, [wallHeight, wallWidth])
    return (
        <>
            <img id='retial-lamp' src={lamp} style={{ position: 'absolute', top: '0', left: '10%', zIndex: 999 }} height={height} alt="retial-lamp" />
        </>

    )
}

export default Lamp;