import { createSlice } from '@reduxjs/toolkit';

const scaleSlice = createSlice({
    name: 'scale',
    initialState: {
        scale: 100
    },
    reducers: {
        changeCanvasScale: (state, action) => {
            state.scale = action.payload.scale;
        }
    }
})

export const { changeCanvasScale } = scaleSlice.actions;

export default scaleSlice.reducer;