import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import controlRoom from '../../../images/environments/controlroom/ControlRoom.svg';

const ControlRoom2 = () => {
    const [height, setHeight] = useState(200);
    const [hide, setHide] = useState(false);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height, width } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${height * 0.3}px`);

        const controlWidth = document.getElementById('control-room').width;
        const chairWidth = document.getElementById('control-chair').width;

        if ((width + 20) < (controlWidth + chairWidth)) {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [wallHeight, wallWidth])
    return (
        <img hidden={hide} id='control-chair' style={{ position: 'absolute', bottom: '0', right: '0', zIndex: 999 }} height={height} src={controlRoom} alt="control-chair" />
    )
}

export default ControlRoom2;