import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import chair from '../../../images/environments/retail/Chair.svg';

const Chair = () => {
    const [height, setHeight] = useState(200);
    const [hide, setHide] = useState(false);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height, width } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${height * 0.3}px`);

        const potWidth = document.getElementById('retial-pot').width;
        const chairWidth = document.getElementById('retail-chair').width;
        const clothWidth = document.getElementById('retial-cloth').width;

        if ((width + 20) < (potWidth + chairWidth + clothWidth)) {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [wallHeight, wallWidth])

    return (
        <img hidden={hide} id='retail-chair' style={{ position: 'absolute', bottom: '0', zIndex: 999, left: '0' }} height={height} src={chair} alt="chair" />
    )
}

export default Chair;