export const VALID_FILE_FORMATS = Object.freeze(['image/jpg', 'image/jpeg', 'image/png', 'video/mp4']);
export const FILE_SIZE_LIMIT = 4194304; // Bytes
export const MAX_ROOM_HEIGHT = 50; // in m
export const MIN_ROOM_HEIGHT = 0.5; // in m
export const MAX_ROOM_WIDTH = 50; // in m   
export const MIN_ROOM_WIDTH = 1; // in m
export const MAX_CABINET_ROWS = 50;
export const MAX_CABINET_COLS = 50;
export const DISABLE_ZOOM = true;
export const DISABLE_ENQUIRY_FORM = true;
export const DISABLE_PDF_EXPORT = false;
export const DISABLE_SPECS_COMPARE = false;
export const DOWNLOAD_PDF_IN_FRONTEND = false;
export const DEFAULT_LINE_COLOR = '#212529';
export const DATA_LOOP_LINE_COLOR = '#378805';
export const DATA_LOOP_BACKUP_LINE_COLOR = '#006ece';
export const POWER_LOOP_LINE_COLOR = '#e14504';
export const ROOM_BUFFER_LEFT = 0.25; // in m
export const ROOM_BUFFER_RIGHT = 0.25; // in m
export const ROOM_BUFFER_TOP = 0.25; // in m
export const ROOM_BUFFER_BOTTOM = 0.25; // in m
export const XM_SPARE = 0.02;
export const VEGA_VEGA_PLUS_195 = ['1.95'];
export const VEGA_VEGA_PLUS_SERIES = ['Vega', 'Vega Plus'];
export const UNIFY_SERIES = 'Unify-FHD';
export const UNIFY_SERIES_TYPE_125_CONFIG = '1.25';
export const UNIFY_SERIES_TYPE_156_CONFIG = '1.56';
export const UNIFY_SERIES_TYPE_125_ORDER = 'Unify-FHD-1.25TI - Die CastAl---PFC-FA-HR----C';
export const UNIFY_SERIES_TYPE_156_ORDER = 'Unify-FHD-1.56TI - Die CastAl-HDR--PFC-FA-HR----C';