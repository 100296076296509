import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    dataLooping: [],
    powerLooping: [],
    cablesByFormula: [],
    cablesByCalc: []
};

const loopingDiagramSlice = createSlice({
    name: 'loopingDiagram',
    initialState: INITIAL_STATE,
    reducers: {
        addDataLoopingPoints: (state, action) => {
            let idx = action.payload.index;
            if (!idx) {
                idx = 0;
            }
            state.dataLooping[idx] = [...action.payload.points];
        },
        addPowerLoopingPoints: (state, action) => {
            let idx = action.payload.index;
            if (!idx) {
                idx = 0;
            }
            state.powerLooping[idx] = [...action.payload.points];
        },
        addCablesByFormula: (state, action) => {
            let idx = action.payload.index;
            if (!idx) {
                idx = 0;
            }

            const obj = {};
            obj[action.payload.type] = action.payload.value;
            state.cablesByFormula[idx] = { ...state.cablesByFormula[idx], ...obj };
        },
        addCablesByCalc: (state, action) => {
            let idx = action.payload.index;
            if (!idx) {
                idx = 0;
            }

            const obj = {};
            obj[action.payload.type] = action.payload.value;
            state.cablesByCalc[idx] = { ...state.cablesByCalc[idx], ...obj };
        },
    }
})

export const { addDataLoopingPoints, addPowerLoopingPoints, addCablesByFormula, addCablesByCalc } = loopingDiagramSlice.actions;

export default loopingDiagramSlice.reducer;