import { combineReducers } from 'redux';
import auth from "./authReducer";
import content from './contentReducer';
import environment from './environmentReducer';
import led from './ledReducer';
import order from './orderReducer';
import selectedOrder from './selectedOrderReducer';
import selectedSeries from './selectedSeriesReducer';
import series from './seriesReducer';
import wall from './wallReducer';
import scale from './scaleReducer';
import design from './designReducer';
import snapShot from './snapshotReducer';
import loopingDiagram from './loopingDiagramReducer';
import specification from './specificationsReducer';

const appReducer = combineReducers({
    auth,
    content,
    environment,
    led,
    order,
    selectedOrder,
    selectedSeries,
    series,
    wall,
    scale,
    design,
    snapShot,
    loopingDiagram,
    specification
})

const rootReducer = (state, action) => {
    return appReducer(state, action);
}

export default rootReducer;
