import { createSlice } from '@reduxjs/toolkit';

const seriesSlice = createSlice({
  name: 'series',
  initialState: {
    seriesList: {},
    seriesTypeList: {},
    pixelPitchList: {},
    applicationList: {},
    viewingRangeList: {}
  },
  reducers: {
    addSeriesList: (state, action) => {
      state.seriesList = action.payload.seriesList;
    },
    addSeriesTypeList: (state, action) => {
      state.seriesTypeList = action.payload.seriesTypeList;
    },
    addPixelPitchList: (state, action) => {
      state.pixelPitchList = action.payload.pixelPitchList;
    },
    addApplicationList: (state, action) => {
      state.applicationList = action.payload.applicationList;
    },
    addViewingRangeList: (state, action) => {
      state.viewingRangeList = action.payload.viewingRangeList;
    }
  }
})

export const { addSeriesList, addPixelPitchList, addSeriesTypeList, addApplicationList, addViewingRangeList } = seriesSlice.actions;

export default seriesSlice.reducer;