import { useState } from "react";
import { VscGitCompare } from "react-icons/vsc";
import { TfiPencilAlt } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import CustomButton from "../common/components/CustomButtom";


const SpecsButton = ({ style, onClick }) => {
    const [tooltip, setTooltip] = useState(false);
    const toggle = () => {
        setTooltip(!tooltip)
    }
    const designs = useSelector(state => state.design.designs);

    return (
        <>
            <CustomButton className="hide-pdf" id="specs-tooltip" style={style} onClick={onClick}>
                {designs?.length > 1 && <VscGitCompare size={25} />}
                {designs?.length === 1 && <TfiPencilAlt size={25} />}
                <Tooltip isOpen={tooltip} placement="top" target="specs-tooltip" toggle={toggle}>{designs?.length > 1 ? 'Compare Designs' : 'Specifications'}</Tooltip>
            </CustomButton>
        </>
    )
}

export default SpecsButton;