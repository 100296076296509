import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import HTMLDivRenderer from "../common/components/HTMLDivRenderer";
import CabinetImage from "./CabinetImage";
import { convertFtToMeter, convertMeterToFt } from "../common/Functions";


const SpecsTable = ({ style, forScreenShot }) => {
    const { design, snapshot, specification, designsAdded, unit } = useSelector(state => {
        return {
            designsAdded: state.design.designsAdded,
            design: state.design.designs,
            snapshot: state.snapShot.snapshot,
            specification: state.specification.specification,
            unit: state.wall.unit
        }
    });
    const [height, setHeight] = useState(200);
    const [width, setWidth] = useState(500);

    useEffect(() => {
        if (forScreenShot || design?.length === 1) return;

        try {
            const { height: designHeight, width: designWidth } = document.getElementsByClassName('transformed-design')[0]?.getBoundingClientRect();
            setHeight(designHeight + 2);
            setWidth(designWidth + 2);
        } catch (error) {
            console.error("Error while getting height-width of designs");
        }
    }, []);

    const gcd = function (a, b) {
        let smaller = Math.min(a, b);
        let hcf = 1;

        for (let i = 1; i <= smaller; i++) {
            if (a % i === 0 && b % i === 0) {
                hcf = i;
            }
        }

        return hcf;
    }


    return (
        <Table style={{ ...style, backgroundColor: 'var(--tertiary-color)', borderColor: 'var(--primary-color)', width: 'max-content', minWidth: '-webkit-fill-available' }} id="specs-table" bordered>
            <thead>
                <tr>
                    <th style={{ backgroundColor: '#f8e5e0' }}>
                        SPECIFICATIONS
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <th key={idx} style={{ backgroundColor: '#f8e5e0' }}>{`DESIGN ${ele.index + 1}`}</th> : '')
                    }
                </tr>
            </thead>
            <tbody>
                <tr style={{ backgroundColor: 'var(--secondary-color)', color: '#fff' }}>
                    <th scope="row">
                        Series
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.series}</td> : '')
                    }
                </tr>
                {
                    !forScreenShot && design?.length > 1 &&
                    <tr >
                        <th scope="row">
                            Design
                        </th>
                        {
                            snapshot.map((ele, idx) => designsAdded.includes(idx) ? <td style={{ position: 'relative', height: `${height}px`, width: `${width}px` }} key={idx}><HTMLDivRenderer htmlDivElement={ele[0]} /></td> : '')
                        }
                    </tr>
                }
                <tr className="specs-cabinet-row">
                    <th scope="row">
                        Cabinet
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx} style={{ textAlign: 'center' }}><CabinetImage series={ele.series} /></td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Pixel Pitch
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${ele.pixelPitch.split(" (")[0]} mm`} {ele.pixelPitch.split(" (")[1] == undefined ? "" : " (" + ele.pixelPitch.split(" (")[1]}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Pixel Density
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{Math.round(['Iris', 'Iris OD'].includes(ele.series) ? ((1000 / parseFloat(ele.pixelPitch.split('-')[0])) * (1000 / parseFloat(ele.pixelPitch.split('-')[1]))) : ((1000 / parseFloat(ele.pixelPitch)) * (1000 / parseFloat(ele.pixelPitch)))).toLocaleString('en-IN')} {`pixels/m`}<sup>2</sup></td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Environment
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.ledCondition.charAt(0).toUpperCase() + ele.ledCondition.slice(1)}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Bonding Wire
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`Copper Wire`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Cabinet Type
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.cabinet_type}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Service Access
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.access_mode}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Front IP Rating
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.front_ip_rating}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Back IP Rating
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.back_ip_rating}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Wall Dimensions (L x H)
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${ele.wallWidth} ${unit} x ${ele.wallHeight} ${unit} 
                        (${unit == 'm' ? convertMeterToFt(ele.wallWidth) : convertFtToMeter(ele.wallWidth)} ${unit == 'm' ? 'ft' : 'm'} x 
                        ${unit == 'm' ? convertMeterToFt(ele.wallHeight) : convertFtToMeter(ele.wallHeight)} ${unit == 'm' ? 'ft' : 'm'})`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Display Dimensions (L x H)
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${(ele.ledCol * ele.cabinetWidth).toFixed(2)} ${unit} x ${(ele.ledRow * ele.cabinetHeight).toFixed(2)} ${unit} 
                        (${unit == 'm' ? (ele.ledCol * convertMeterToFt(ele.cabinetWidth)).toFixed(2) : (ele.ledCol * convertFtToMeter(ele.cabinetWidth)).toFixed(2)} ${unit == 'm' ? 'ft' : 'm'} x 
                        ${unit == 'm' ? (ele.ledRow * convertMeterToFt(ele.cabinetHeight)).toFixed(2) : (ele.ledRow * convertFtToMeter(ele.cabinetHeight)).toFixed(2)} ${unit == 'm' ? 'ft' : 'm'})`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Display Area
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx)
                            ? <td key={idx}>{`${((ele.ledCol * ele.cabinetWidth) * (ele.ledRow * ele.cabinetHeight)).toFixed(2)} ${unit}`}<sup>2</sup>
                                {` (${unit == 'm' ? (convertMeterToFt(ele.ledCol * ele.cabinetWidth) * convertMeterToFt(ele.ledRow * ele.cabinetHeight)).toFixed(2)
                                    : (convertFtToMeter(ele.ledCol * ele.cabinetWidth) * convertFtToMeter(ele.ledRow * ele.cabinetHeight)).toFixed(2)} ${unit == 'm' ? 'ft' : 'm'}`}<sup>2</sup>{`)`}</td>
                            : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Pixel Matrix (L x H)
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${(ele.ledCol * ele.cabinetWidthPx).toLocaleString('en-IN')} x ${(ele.ledRow * ele.cabinetHeightPx).toLocaleString('en-IN')}`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        No. of Tiles (L x H)
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${(ele.specNoOfTiles)}`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Diagonal Size
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>
                            {`${Math.sqrt(((ele.ledCol * ele.cabinetWidth) * (ele.ledCol * ele.cabinetWidth)) + ((ele.ledRow * ele.cabinetHeight) * (ele.ledRow * ele.cabinetHeight))).toFixed(2)} ${unit} (${unit == 'm' ?
                                ((Math.sqrt(((ele.ledCol * ele.cabinetWidth) * (ele.ledCol * ele.cabinetWidth)) + ((ele.ledRow * ele.cabinetHeight) * (ele.ledRow * ele.cabinetHeight))) * 39.3701).toFixed(2)).toLocaleString('en-IN') :
                                ((Math.sqrt(((ele.ledCol * ele.cabinetWidth) * (ele.ledCol * ele.cabinetWidth)) + ((ele.ledRow * ele.cabinetHeight) * (ele.ledRow * ele.cabinetHeight))) * 12).toFixed(2)).toLocaleString('en-IN')} inches)`}
                        </td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Aspect Ratio
                    </th>
                    {
                        design.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${(ele.specAspectRatio)}`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Cabinet Dimensions (L x H x D)
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${(ele.specCabinetDimensions)}`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Cabinet Weight
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`${ele.weight_kg_per_meter_sq} kg/m`}<sup>2</sup></td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Screen Weight Excluding Structure (Approx.)
                    </th>
                    {
                        specification.map((ele, idx) =>
                            design.map((dele, didx) =>
                                designsAdded.includes(idx) && (didx === idx) ? <td key={idx}>{`${unit == 'm' ?(((dele.ledCol * dele.cabinetWidth) * (dele.ledRow * dele.cabinetHeight) * ele.weight_kg_per_meter_sq)).toFixed(2).toLocaleString('en-IN'):(((dele.ledCol * (dele.cabinetWidth/ 3.28084)) * (dele.ledRow * (dele.cabinetHeight/3.28084)) * ele.weight_kg_per_meter_sq)).toFixed(2).toLocaleString('en-IN')} kg `}</td> : ''))
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Max Brightness
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{(ele.brightness).toLocaleString('en-IN')} nits</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Viewing Angle (degree)
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.viewing_angle}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Data Processing
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`14-16 Bit`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Colours
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`Up to 281 Trillion`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Refresh Rate
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{(ele.refresh_rate).toLocaleString('en-IN')} Hz</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Contrast
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.contrast ? ele.contrast : 'NA'}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Max Power
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.max_power_watts_meter_sq} W/m<sup>2</sup></td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Average Power
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.avg_power_watts_meter_sq} W/m<sup>2</sup></td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Max Power Consumption - Screen
                    </th>
                    {
                        specification.map((ele, idx) =>
                            design.map((dele, didx) =>
                                designsAdded.includes(idx) && (didx === idx) ? <td key={idx}>{(((dele.ledCol * dele.cabinetWidth * dele.ledRow * dele.cabinetHeight * ele.max_power_watts_meter_sq) / 1000).toFixed(2)).toLocaleString('en_IN')} kW</td> : ''))
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Average Power Consumption - Screen
                    </th>
                    {
                        specification.map((ele, idx) =>
                            design.map((dele, didx) =>
                                designsAdded.includes(idx) && (didx === idx) ? <td key={idx}>{(((dele.ledCol * dele.cabinetWidth * dele.ledRow * dele.cabinetHeight * ele.avg_power_watts_meter_sq) / 1000).toFixed(2)).toLocaleString('en-IN')} kW</td> : ''))
                    }
                </tr>
                <tr>
                    <th scope="row">
                        Transparency
                    </th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{ele.transparency ? ele.transparency : 'NA'}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">Temperature Range</th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`Operating 0° to 50°C`}</td> : '')
                    }
                </tr>
                <tr>
                    <th scope="row">Humidity</th>
                    {
                        specification.map((ele, idx) => designsAdded.includes(idx) ? <td key={idx}>{`Operating 10 to 90%`}</td> : '')
                    }
                </tr>
            </tbody>
        </Table>
    )
}

export default SpecsTable;