import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import desk from '../../../images/environments/classroom/Desk.svg';

const Desk = () => {
    const [height, setHeight] = useState(200);
    const [hide, setHide] = useState(false);

    const { wallHeight, wallWidth } = useSelector(state => {
        return { wallHeight: state.wall.height, wallWidth: state.wall.width }
    })

    useEffect(() => {
        const { height: h, width: w } = document.getElementById('wall').getBoundingClientRect();
        setHeight(`${h * 0.4}px`);

        // const clothWidth = document.getElementById('retial-cloth').width;

        // if ((width + 20) < (clothWidth)) {
        //     setHide(true);
        // } else {
        //     setHide(false);
        // }
    }, [wallHeight, wallWidth])
    // console.log(height);
    return (
        <img hidden={hide} id='classroom-desk' style={{ position: 'absolute', bottom: '0', left: '0', zIndex: 999 }} height={height} src={desk} alt="classroom-desk" />
    )
}

export default Desk;