import { useContext, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { addDesign, changeSelectedDesign, updatePosition } from "../../redux/reducers/designReducer";
import { addSnapshot } from "../../redux/reducers/snapshotReducer";
import { LoaderContext } from "../../context/context";
import { changeFitToWall } from "../../redux/reducers/ledReducer";

const AddDesign = () => {
    const [activeTab, setActiveTab] = useState(1);
    const { designs, selectedDesignIdx, position, designsAdded } = useSelector(state => {
        return {
            designs: state.design.designs,
            selectedDesignIdx: state.design.selectedDesignIdx,
            position: state.led.position,
            designsAdded: state.design.designsAdded
        }
    }, shallowEqual);

    const { setLoading, diagram } = useContext(LoaderContext);

    const dispatch = useDispatch();

    const saveDiagramScreenShot = () => {
        try {
            const originalNode = document.getElementById('main-container');
            const clonedNode = originalNode.cloneNode(true);

            const { height, width } = originalNode.getBoundingClientRect();
            clonedNode.style.width = `${width}px`;
            clonedNode.style.height = `${height}px`;

            const elementsToHide = clonedNode.querySelectorAll('.hide-pdf');
            for (let i = 0; i < elementsToHide.length; i++) {
                elementsToHide[i].setAttribute('style', 'display:none !important');
            }

            const dataLooping = document.getElementById('data-snapshot');
            // dataLooping.style.display = 'block';
            const clonedDLNode = dataLooping.cloneNode(true);
            // dataLooping.style.display = 'none';

            // clonedDLNode.style.width = `${width}px`;
            // clonedDLNode.style.height = `${height}px`;

            const powerLooping = document.getElementById('power-snapshot');
            // powerLooping.style.display = 'block';
            const clonedPLNode = powerLooping.cloneNode(true);
            // powerLooping.style.display = 'none';

            // clonedPLNode.style.width = `${width}px`;
            // clonedPLNode.style.height = `${height}px`;

            dispatch(addSnapshot({
                index: activeTab - 1,
                snapshot: [clonedNode, clonedDLNode, clonedPLNode]
            }))
        } catch (error) {
            console.error("Error while saving LED Diagram Screenshot", error.message);
        }
    }

    const toggle = (tab) => {
        if (tab !== activeTab) {
            setLoading(true);
            if (diagram === 'design') {
                saveDiagramScreenShot();
            }
            setActiveTab(tab);
        }

        let index = tab - 1;
        if (!designsAdded.includes(index)) {
            dispatch(addDesign({
                design: { ...designs[0], index: index, position: { ...position } },
                index: index
            }))
        }

        if (tab !== (selectedDesignIdx + 1)) {
            dispatch(changeFitToWall({
                fitToWall: false
            }))

            dispatch(changeSelectedDesign({
                selectedDesignIdx: index
            }))

            let tempPos = designs[index] == undefined ? { ...position } : { ...designs[index].position }
            dispatch(updatePosition({
                index: index,
                position: tempPos
            }))
        }
    }

    useUpdateEffect(() => {
        setActiveTab(selectedDesignIdx + 1);
    }, [selectedDesignIdx])

    return (
        <>
            <Nav tabs style={{ padding: '0' }}>
                <NavItem style={{ width: '33.33%' }}>
                    <NavLink
                        id="design-tab1"
                        active={activeTab === 1}
                        className="design-tab d-flex justify-content-center align-items-center"
                        onClick={() => toggle(1)}
                        disabled={diagram !== 'design'}
                    >
                        {designsAdded.includes(0) ? "Design 1" : "Add Design 1"}
                    </NavLink>
                </NavItem>
                <NavItem style={{ width: '33.33%' }}>
                    <NavLink
                        id="design-tab2"
                        active={activeTab === 2}
                        className="design-tab d-flex justify-content-center align-items-center"
                        onClick={() => toggle(2)}
                        disabled={diagram !== 'design'}
                    >
                        {designsAdded.includes(1) ? "Design 2" : "Add Design 2"}
                    </NavLink>
                </NavItem>
                <NavItem style={{ width: '33.33%' }}>
                    <NavLink
                        id="design-tab3"
                        disabled={!designsAdded.includes(1) && !designsAdded.includes(2) || diagram !== 'design'}
                        active={activeTab === 3}
                        className="design-tab d-flex justify-content-center align-items-center"
                        onClick={() => toggle(3)}
                    >
                        {designsAdded.includes(2) ? "Design 3" : "Add Design 3"}
                    </NavLink>
                </NavItem>
            </Nav>
            <div style={{ display: 'none' }} id="hidden-container"></div>
        </>
    )
}

export default AddDesign;