import Cabinets from './Cabinets';
import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DATA_LOOP_LINE_COLOR, POWER_LOOP_LINE_COLOR, DEFAULT_LINE_COLOR, VEGA_VEGA_PLUS_195, VEGA_VEGA_PLUS_SERIES } from '../../config/config';

const LoopingDiagram = ({ type, style, id }) => {
    const { dataLooping, powerLooping, cablesFormula, cablesCalc, selectedDesignIdx, seriesDropdownValue, pixelPitchValue, pixelPitchDropdownValue, ledRow, ledCol } = useSelector(state => {
        return {
            dataLooping: state.loopingDiagram.dataLooping,
            powerLooping: state.loopingDiagram.powerLooping,
            cablesFormula: state.loopingDiagram.cablesByFormula,
            cablesCalc: state.loopingDiagram.cablesByCalc,
            selectedDesignIdx: state.design.selectedDesignIdx,
            seriesDropdownValue: state.selectedSeries.seriesDropDownValue,
            pixelPitchValue: state.selectedSeries.pixelPitchValue,
            pixelPitchDropdownValue: state.selectedSeries.pixelPitchDropDownValue,
            ledRow: state.led.row,
            ledCol: state.led.col
        }
    })
    const [cablesByFormula, setCablesByFormula] = useState(0);
    const [cablesByCalc, setCablesByCalc] = useState(0);
    const svgRef = useRef(null);
    let drawingFlag = [];
    const splitterPoints = useRef([]);
    const countPoints = useRef([]);
    const cableCount = useRef(1);

    let vegaCondition = VEGA_VEGA_PLUS_SERIES.includes(seriesDropdownValue);

    let new_col = Math.ceil(ledCol / 2);
    let lastOddColFlag = ledCol / 2 !== new_col;
    let vegaStr = lastOddColFlag ? 'Unhighlighted cabinets measure 500x500mm.' : '';

    const drawLine = (x1, y1, x2, y2, showArrow, count, coordinates, lineColor = DEFAULT_LINE_COLOR) => {
        const svg = svgRef.current;
        if (!svg) return;

        let strokeWidth = lineColor === DEFAULT_LINE_COLOR ? 1 : 1.5;

        const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);

        line.setAttribute('stroke', lineColor);
        line.setAttribute('stroke-width', strokeWidth);

        showArrow && line.setAttribute('marker-end', 'url(#arrow)');
        svg.appendChild(line);

        if (count >= 0 && countPoints.current.findIndex(ele => ele.coordinates.x1 === coordinates.x1 && ele.coordinates.y1 === coordinates.y1) === -1) {
            countPoints.current.push({ x1, y1, coordinates, count });
        }

        return line;
    }

    const showCableCount = (x1, y1, coordinates, count) => {
        if (isSplitterPoint({ x: coordinates.x1, y: coordinates.y1 })) {
            return
        }
        const svg = svgRef.current;
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circle.setAttribute('cx', x1);
        circle.setAttribute('cy', y1);
        circle.setAttribute('r', 10);
        circle.setAttribute('fill', '#e14504');

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', x1);
        text.setAttribute('y', y1);
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('alignment-baseline', 'central');
        text.setAttribute('fill', '#ffffff');
        text.setAttribute('font-size', 10);
        const textNode = document.createTextNode(cableCount.current++);
        text.appendChild(textNode);

        svg.appendChild(circle);
        svg.appendChild(text);
    }

    const drawCables = (x1, y1, x2, y2, showArrow = true, checkCondition = true, count = -1, lineColor, xOffset, yOffset) => {
        let domRect;
        if (checkCondition) {
            if (x1 % 2 === 1 && x2 % 2 === 1) {
                drawingFlag.push({ x: x1, y: y1 });
            } else if (drawingFlag.length !== 0 && x1 % 2 === 0 && x2 % 2 === 0) {
                drawingFlag.forEach((ele, index) => {
                    const { x, y } = ele;
                    if (y === y1) {
                        connectCables(x, y, x1, y1, count, lineColor, xOffset, yOffset);
                        splitterPoints.current.push({ x, y }, { x: x1, y: y1 })
                        drawingFlag.splice(index, 1);
                    }
                })
            }
        }
        if (id == 'data-snapshot') {
            domRect = document.getElementsByClassName("data-snapshot-cabinet")[0].getBoundingClientRect();
        } else if (id == 'power-snapshot') {
            domRect = document.getElementsByClassName("power-snapshot-cabinet")[0].getBoundingClientRect();
        } else {
            domRect = document.getElementsByClassName("cabinet")[0].getBoundingClientRect();
        }

        const width = domRect.width;
        const height = domRect.height;
        return drawLine(width * x1 - (width * xOffset), height * y1 - (height * yOffset), width * x2 - (width * xOffset), height * y2 - (height * yOffset), showArrow, count, { x1, y1, x2, y2 }, lineColor);
    }

    // const drawCables2 = (x1, y1, x2, y2, showArrow = true, checkCondition = true, count = -1) => {
    //     let domRect;
    //     if (checkCondition) {
    //         if (x1 % 2 === 1 && x2 % 2 === 1) {
    //             drawingFlag.push({ x: x1, y: y1 });
    //         } else if (drawingFlag.length != 0 && x1 % 2 === 0 && x2 % 2 === 0) {
    //             drawingFlag.forEach((ele, index) => {
    //                 const { x, y } = ele;
    //                 if (y === y1) {
    //                     connectCables(x, y, x1, y1, count);
    //                     splitterPoints.current.push({ x, y }, { x: x1, y: y1 })
    //                     drawingFlag.splice(index, 1);
    //                 }
    //             })
    //         }
    //     }
    //     if (['data-snapshot', 'power-snapshot'].includes(id)) {
    //         domRect = document.getElementsByClassName("snapshot-cabinet")[0].getBoundingClientRect();
    //     } else {
    //         domRect = document.getElementsByClassName("cabinet")[0].getBoundingClientRect();
    //     }

    //     const width = domRect.width;
    //     const height = domRect.height;
    //     return drawLine(width * x1 - (width * 0.6), height * y1 - (height * 0.6), width * x2 - (width * 0.6), height * y2 - (height * 0.6), showArrow, count, { x1, y1, x2, y2 });
    // }


    const removePreviousLines = () => {
        const svg = svgRef.current;
        const lines = svg?.querySelectorAll('line');
        if (lines) {
            lines.forEach(line => line.remove());
        }
    }

    const removePreviousCircles = () => {
        const svg = svgRef.current;
        const circles = svg?.querySelectorAll('circle');
        if (circles) {
            circles.forEach(circle => circle.remove());
        }
    }

    const removePreviousText = () => {
        const svg = svgRef.current;
        const texts = svg?.querySelectorAll('text');
        if (texts) {
            texts.forEach(text => text.remove());
        }
    }

    const drawVerticalMedian = (xOffset, yOffset) => {
        let x1, y1, x2, y2, domRect;
        if (id == 'data-snapshot') {
            domRect = document.getElementsByClassName("data-snapshot-cabinet")[0].getBoundingClientRect();
        } else if (id == 'power-snapshot') {
            domRect = document.getElementsByClassName("power-snapshot-cabinet")[0].getBoundingClientRect();
        } else {
            domRect = document.getElementsByClassName("cabinet")[0].getBoundingClientRect();
        }

        const width = domRect.width;
        const height = domRect.height;
        const halfWidth = width * xOffset;
        const halfHeight = height * yOffset;

        if (ledCol == 1) {
            return true;
        }

        for (let j = 1; j <= new_col; j++) {
            let flag = (j === new_col && lastOddColFlag);
            let lineColour = flag ? DEFAULT_LINE_COLOR : '#e2e2e2';

            x1 = j; y1 = 1;
            x2 = j; y2 = ledRow;
            drawLine(width * x1 - (halfWidth), 0, width * x2 - (halfWidth), height * y2, false, -1, { x1, y1, x2, y2 }, lineColour);
        }

        // For drawing the borders of the cells in the last column in case of Vega and Vega Plus
        if (lastOddColFlag) {
            let penultimateCol = new_col - 1;
            x1 = width * penultimateCol;
            x2 = (width * new_col) - halfWidth;
            for (let i = 0; i <= ledRow; i++) {
                y1 = height * i;
                y2 = height * i;
                drawLine(x1, y1, x2, y2, false, -1, { x1, y1, x2, y2 }, DEFAULT_LINE_COLOR);
            }
        }
    }

    const drawLoopingDiagram = (linegroup, lineColor, xOffset, yOffset) => {
        linegroup?.forEach((group, index) => {
            group?.forEach((line, idx) => {
                const { x1, y1, x2, y2 } = line;
                let arrow = true;
                if (group.length !== 1 && idx !== (group.length - 1)) {
                    arrow = false;
                }
                if (['Ace', 'Ace Plus', 'Unify-FHD'].includes(seriesDropdownValue) && type === 'power') {
                    drawCables(x1, y1, x2, y2, arrow, true, index, lineColor, xOffset, yOffset);
                    // drawCables2(x1, y1, x2, y2, arrow, true, index);
                } else {
                    if (idx === 0) {
                        drawCables(x1, y1, x2, y2, arrow, false, index, lineColor, xOffset, yOffset);
                        // drawCables2(x1, y1, x2, y2, arrow, false, index);
                    } else {
                        drawCables(x1, y1, x2, y2, arrow, false, -1, lineColor, xOffset, yOffset);
                        // drawCables2(x1, y1, x2, y2, arrow, false, -1);
                    }
                }
            })
        })

        countPoints.current.forEach((point) => {
            showCableCount(point.x1, point.y1, point.coordinates);
        })

        drawingFlag = [];
        splitterPoints.current = [];
        countPoints.current = [];
        cableCount.current = 1;
    }

    const isSplitterPoint = (point) => {
        return splitterPoints.current.some(pt => (pt.x === point.x && pt.y === pt.y));
    }

    const connectCables = (x1, y1, x2, y2, count, lineColor, xOffset, yOffset) => {
        const line = drawCables(x1, y1, x2, y2, false, false, count, lineColor, xOffset, yOffset);
        showCableSplit(line, { x1: x1 + 0.1, y1: y1 + 0.1, x2, y2 }, count, lineColor);

        // const line2 = drawCables2(x1, y1, x2, y2, false, false, count);
        // showCableSplit(line2, { x1: x1 + 0.1, y1: y1 + 0.1, x2, y2 }, -1);
    }

    const showCableSplit = (line, coordinates, count, lineColor) => {
        // If condition added to handle 
        if (line.getClientRects().length > 0) {
            const length = line.getTotalLength();
            const startPoint = line.getPointAtLength(0);
            const endPoint = line.getPointAtLength(length);
            const midpointX = (startPoint.x + endPoint.x) / 2;
            const midpointY = (startPoint.y + endPoint.y) / 2;

            drawLine(midpointX, midpointY + 30, midpointX, midpointY, true, count, coordinates, lineColor);
        }
    }

    useEffect(() => {
        let xOffset = 0.5;
        let yOffset = 0.5;
        if (type === 'data') {
            if (vegaCondition) {
                drawVerticalMedian(xOffset, yOffset);
                xOffset = (ledCol == 1 ? 0.50 : 0.75);
            }
            drawLoopingDiagram(dataLooping[selectedDesignIdx], DATA_LOOP_LINE_COLOR, xOffset, yOffset);
            setCablesByFormula(cablesFormula[selectedDesignIdx]?.dataLooping);
            setCablesByCalc(cablesCalc[selectedDesignIdx]?.dataLooping);
        } else if (type === 'power') {
            drawLoopingDiagram(powerLooping[selectedDesignIdx], POWER_LOOP_LINE_COLOR, xOffset, yOffset);
            setCablesByFormula(cablesFormula[selectedDesignIdx]?.powerLooping);
            setCablesByCalc(cablesCalc[selectedDesignIdx]?.powerLooping);
        }

        return () => {
            removePreviousLines();
            removePreviousCircles();
            removePreviousText();
        }
    }, [dataLooping, powerLooping, cablesFormula, cablesCalc, selectedDesignIdx, seriesDropdownValue])

    return (
        <>
            <div className='justify-content-center align-items-center' style={{ display: 'flex', height: '100%', width: '100%', backgroundColor: '#fcfcfc', position: 'absolute', zIndex: 1, ...style }}>
                <Cabinets id={id}>
                    <svg ref={svgRef} style={{ height: '100%', width: '100%', zIndex: 99, position: 'relative' }}>
                        <defs>
                            <marker id="arrow" viewBox="0 0 10 10" refX="8" refY="5"
                                markerWidth="5" markerHeight="5"
                                orient="auto-start-reverse">
                                <path d="M 0 0 L 10 5 L 0 10 z" />
                            </marker>
                        </defs>
                    </svg>
                    <div className={`${cablesByFormula == 0 ? 'cable-formula-hide' : 'cable-formula-show'}`}>
                        <b>Cables by formula: {cablesByFormula}</b>
                    </div>
                    <div className={`${cablesByCalc == 0 ? 'cable-calc-hide' : 'cable-calc-show'}`}>
                        <b>Cables by calculation: {cablesByCalc}</b>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '2%' }}><b>
                        <span style={{ border: 'solid 1px', borderRadius: '12px', padding: '0px 6px', background: 'darkorange' }}>i</span> NOTE:</b> To make any changes, please select the "Design" option.
                        {(vegaCondition && ledCol > 1) && (
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ul>
                                    <li>Data cable looping happens in alternate cabinet columns for {seriesDropdownValue} series by design.</li>
                                    <li>Cabinets highlighted in blue measure 1000x500mm. {vegaStr}</li>
                                </ul>
                            </span>
                        )}

                    </div>
                </Cabinets >
            </div >
        </>
    )
}

export default memo(LoopingDiagram);