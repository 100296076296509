import axios from "axios";
import '../../css/form.css';
import OrderForm from "./OrderForm";
import { Toast } from "../common/Functions";
import { useState, useEffect, memo } from "react";
import XMLogo from '../../images/xmLogo.png';
import Specifications from "./Specifications";
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from "../../config/api";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../common/components/CustomButtom";
import { changeLogoutDetails } from "../../redux/reducers/authReducer";
import { Nav, NavItem, Col, Row, TabContent, TabPane, NavLink } from "reactstrap";
import {
    addStoredMountingTypeList, addStoredLocationList, addStoredClientTypeList, addStoredSeriesList, addStoredSeriesTypeList, addStoredAspectRatioList,
    addStoredUnitList, addStoredVideoProcessorList, addStoredAccessoriesList, addStoredMediaPlayerList, addStoredSpecialShapesList
} from "../../redux/reducers/orderReducer";
import OrderFormDataLooping from "./OrderFormDataLooping";
import OrderFormPowerLooping from "./OrderFormPowerLooping";
import { addDataSnapshot } from "../../redux/reducers/orderReducer";
import useUpdateEffect from "../../hooks/useUpdateEffect";


const Landing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(null)
    const { token } = useSelector((state) => { return { token: state.auth.token } })

    const [activeTab, setActiveTab] = useState('1');
    const [formChangeFlag, setFormChangeFlag] = useState(true);
    const [powerTabFlag, setPowerTabFlag] = useState(false);
    const toggle = (tab) => {
        if (tab !== activeTab) {
            setActiveTab(tab);
        }
    }

    const addInitialDataToStore = (values) => {
        dispatch(addStoredMountingTypeList({ storedMountingTypeList: values[0].data.data }));
        dispatch(addStoredLocationList({ storedLocationList: values[1].data.data }));
        dispatch(addStoredClientTypeList({ storedClientTypeList: values[2].data.data }));
        dispatch(addStoredSeriesList({ storedSeriesList: values[3].data.data }));
        dispatch(addStoredSeriesTypeList({ storedSeriesTypeList: values[4].data.data }));
        dispatch(addStoredAspectRatioList({ storedAspectRatioList: values[5].data.data }));
        dispatch(addStoredUnitList({ storedUnitList: values[6].data.data }));
        dispatch(addStoredVideoProcessorList({ storedVideoProcessorList: values[7].data.data }));
        dispatch(addStoredAccessoriesList({ storedAccessoriesList: values[8].data.data }));
        dispatch(addStoredMediaPlayerList({ storedMediaPlayerList: values[9].data.data }));
        dispatch(addStoredSpecialShapesList({ storedSpecialShapesList: values[10].data.data }));
    }

    const apiTokenHeader = {
        headers: {
            'token': token
        }
    };
    const fetchMountingTypeList = () => axios.post(`${API_BASE_URL}/order/get-mount-details`, {}, apiTokenHeader);
    const fetchLocationList = () => axios.post(`${API_BASE_URL}/order/get-city-details`, {}, apiTokenHeader);
    const fetchClientTypeList = () => axios.post(`${API_BASE_URL}/order/get-client-type`, {}, apiTokenHeader);
    const fetchOrderSeries = () => axios.post(`${API_BASE_URL}/series/get-series`, { series_id: 0, short_pixel_pitch: 0 }, apiTokenHeader);
    const fetchOrderSeriesType = () => axios.post(`${API_BASE_URL}/series/get-series-type`, { series_type_id: 0 }, apiTokenHeader);
    const fetchAspectRatioList = () => axios.post(`${API_BASE_URL}/order/get-aspect-ratio`, {}, apiTokenHeader);
    const fetchUnitList = () => axios.post(`${API_BASE_URL}/order/get-unit-details`, {}, apiTokenHeader);
    const fetchVideoProcessorList = () => axios.post(`${API_BASE_URL}/order/get-video-processor-details`, {}, apiTokenHeader);
    const fetchAccessoriesList = () => axios.post(`${API_BASE_URL}/order/get-accessories-details`, {}, apiTokenHeader);
    const fetchMediaPlayerList = () => axios.post(`${API_BASE_URL}/order/get-media-player-details`, {}, apiTokenHeader);
    const fetchSpecialShapesList = () => axios.post(`${API_BASE_URL}/order/get-special-shapes`, {}, apiTokenHeader)

    const fetchAllData = async () => {
        // setLoading(true);
        try {
            const values = await Promise.all([fetchMountingTypeList(), fetchLocationList(), fetchClientTypeList(), fetchOrderSeries(), fetchOrderSeriesType(),
            fetchAspectRatioList(), fetchUnitList(), fetchVideoProcessorList(), fetchAccessoriesList(), fetchMediaPlayerList(), fetchSpecialShapesList()]);
            addInitialDataToStore(values);

            setTimeout(() => {
                // setLoading(false);
            }, 4000)
        } catch (error) {
            console.error("Error while fetching data ", error?.message);
            Toast('error', 'Error', "Error while fetching data: " + error?.message);
        }
    }

    const logout = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/user/logout`, {}, apiTokenHeader);
            dispatch(changeLogoutDetails(response));
            return true;
        } catch (error) {
            console.error("Error while logging out user.");
            console.error(error);
            return false;
        }
    }

    const userLogout = async () => {
        await logout();
        navigate('/');
    }

    const saveDataLoopingScreenshot = async () => {
        try {
            const originalDataNode = document.getElementById('data-cabinet-div');
            const clonedDataNode = originalDataNode.cloneNode(true);

            dispatch(addDataSnapshot({
                snapshot: clonedDataNode
            }))
        } catch (error) {
            console.error("Error while saving Data Looping Screenshot");
        }
    }

    useUpdateEffect(() => {
        if (formChangeFlag) {
            setPowerTabFlag(false);
        }
    }, [formChangeFlag])

    useEffect(() => {
        fetchAllData();
    }, [])

    return (
        <div style={{ height: '100%', overflow: 'auto', overflowX: 'hidden' }}>
            <Row style={{ height: '100%' }}>
                <Col md={12} style={{ height: '100%' }}>
                    <Row className='d-flex align-items-center' style={{ backgroundColor: '#f7f7f7' }}>
                        <Col md={12} sm={7} style={{ width: "100%" }}>
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '10%', width: '20%', margin: '0', fontSize: '2vw', color: '#e14504', float: 'left' }} > <b>XM Order Form</b> </div>
                            <img src={XMLogo} alt="XM-Logo" style={{ height: '45px', margin: '4px', float: 'right' }} />
                            <CustomButton style={{ float: "right", margin: '4px' }} outline onClick={userLogout}>Logout</CustomButton>
                        </Col>
                    </Row>
                    <Row style={{ height: '95%', width: "98%", marginLeft: "1%", marginTop: "2%", alignContent: "flex-start" }}>
                        <Nav tabs style={{ height: '45px', padding: '0' }}>
                            <NavItem style={{ width: '25%' }}>
                                <NavLink active={activeTab === '1'} className={`d-flex justify-content-center align-items-center`} onClick={() => toggle('1')}>
                                    Order Form
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ width: '25%' }} disabled={formChangeFlag} >
                                <NavLink active={activeTab === '2'} className={`d-flex justify-content-center align-items-center ${formData !== null ? '' : 'tab-events'}`} disabled={formChangeFlag} onClick={() => toggle('2')}>
                                    Order Specification
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ width: '25%' }} disabled={formChangeFlag} >
                                <NavLink active={activeTab === '3'} className={`d-flex justify-content-center align-items-center ${formData !== null ? '' : 'tab-events'}`} disabled={formChangeFlag} onClick={() => toggle('3')}>
                                    Data Looping
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ width: '25%' }} hidden={formChangeFlag || !powerTabFlag} >
                                <NavLink active={activeTab === '4'} className={`d-flex justify-content-center align-items-center ${formData !== null ? '' : 'tab-events'}`} disabled={formChangeFlag || !powerTabFlag} onClick={() => toggle('4')}>
                                    Power Looping
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <TabPane tabId="1">
                                <OrderForm setFormData={setFormData} toggleTab={toggle} activeTab={activeTab} setFormChangeFlag={setFormChangeFlag} setPowerTabFlag={setPowerTabFlag} />
                            </TabPane>
                            <TabPane tabId="2">
                                <Specifications formData={formData} toggleTab={toggle} activeTab={activeTab} />
                            </TabPane>
                            <TabPane tabId="3">
                                {formData !== null && (
                                    <OrderFormDataLooping formData={formData} toggleTab={toggle} activeTab={activeTab} saveDataLoopingScreenshot={saveDataLoopingScreenshot} setPowerTabFlag={setPowerTabFlag} />
                                )}
                            </TabPane>
                            <TabPane tabId="4">
                                {formData !== null && (
                                    <OrderFormPowerLooping formData={formData} toggleTab={toggle} activeTab={activeTab} saveDataLoopingScreenshot={saveDataLoopingScreenshot} />
                                )}
                            </TabPane>
                        </TabContent>
                    </Row>
                </Col>
            </Row >
        </div >
    )
}

export default memo(Landing);