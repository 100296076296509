import { memo, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { changeTransform } from '../../redux/reducers/ledReducer';
import LedRoomWrapper from './LedRoomWrapper';
import Wall from "./Wall";
import ExportButton from './ExportButton';
import ZoomChangeButtons from './ZoomChangeButton';
import Specifications from './Specifications';
import LoopingDiagram from './DataLooping';
import EnquiryButton from './EnquiryButton';
import { DISABLE_ENQUIRY_FORM, DISABLE_PDF_EXPORT, DISABLE_SPECS_COMPARE, DISABLE_ZOOM } from '../../config/config';
import { LoaderContext } from '../../context/context';

const Canvas = () => {
    const dispatch = useDispatch();
    const handleTransform = useCallback((_, { positionX, positionY, scale }) => {
        dispatch(changeTransform({
            x: positionX, y: positionY, scale: scale
        }))
    }, [])
    const { diagram } = useContext(LoaderContext);

    return (
        <TransformWrapper onTransformed={handleTransform} minScale={1} maxScale={3} panning={{ disabled: true }} pinch={{ disabled: true }} doubleClick={{ disabled: true }} wheel={{ touchPadDisabled: true, disabled: DISABLE_ZOOM }}>
            {({ zoomOut, zoomIn }) => (
                <>
                    {
                        !DISABLE_ZOOM &&
                        <ZoomChangeButtons zoomIn={zoomIn} zoomOut={zoomOut} />
                    }
                    {
                        !DISABLE_PDF_EXPORT &&
                        <ExportButton style={{ borderRadius: '50%', padding: '10px', position: 'absolute', bottom: '4%', right: '2em', zIndex: '99', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }} />

                    }
                    {
                        !DISABLE_SPECS_COMPARE &&
                        <Specifications />
                    }
                    {
                        !DISABLE_ENQUIRY_FORM &&
                        <EnquiryButton style={{ borderRadius: '50%', padding: '10px', position: 'absolute', bottom: '4%', right: '10%', zIndex: '99', backgroundColor: '#e14504a1', borderColor: '#e14504a1' }} />

                    }
                    <TransformComponent>
                        {
                            diagram === 'design' &&
                            <LedRoomWrapper>
                                <Wall />
                            </LedRoomWrapper>
                        }
                        {
                            diagram === 'data-looping' &&
                            <LoopingDiagram type='data' />
                        }
                        {
                            diagram === 'power-looping' &&
                            <LoopingDiagram type='power' />
                        }
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    )
}

export default memo(Canvas);